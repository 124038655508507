import moment from 'moment';

export const requiredText = 'Поле должно быть заполнено';

export function checkINN(string) {
  // проверка на число
  if (!string || string.match(/\D/)) {
    return false;
  }
  // проверка на 10 и 12 цифр
  if (string.length != 12 && string.length != 10) {
    return false;
  }
  // проверка по контрольным цифрам
  if (string.length == 10) {
    const dgt10 = String(
      ((2 * string[0]
                + 4 * string[1]
                + 10 * string[2]
                + 3 * string[3]
                + 5 * string[4]
                + 9 * string[5]
                + 4 * string[6]
                + 6 * string[7]
                + 8 * string[8])
                % 11)
                % 10,
    );

    if (string[9] == dgt10) {
      return true;
    }
    return false;
  }

  if (string.length == 12) {
    const dgt11 = String(
      ((7 * string[0]
                + 2 * string[1]
                + 4 * string[2]
                + 10 * string[3]
                + 3 * string[4]
                + 5 * string[5]
                + 9 * string[6]
                + 4 * string[7]
                + 6 * string[8]
                + 8 * string[9])
                % 11)
                % 10,
    );
    const dgt12 = String(
      ((3 * string[0]
                + 7 * string[1]
                + 2 * string[2]
                + 4 * string[3]
                + 10 * string[4]
                + 3 * string[5]
                + 5 * string[6]
                + 9 * string[7]
                + 4 * string[8]
                + 6 * string[9]
                + 8 * string[10])
                % 11)
                % 10,
    );

    if (string[10] == dgt11 && string[11] == dgt12) {
      return true;
    }
    return false;
  }
}

export function checkKPP(string) {
  if (!string || string.length != 9) {
    // alert("КПП должен представлять собой девятизначный код");
    return false;
  }
  if (!string.match(/\d{4}[\dA-Z][\dA-Z]\d{3}/)) {
    // alert("КПП не соответствует формату");
    return false;
  }

  return true;
}

export function checkOGRN(string) {
  let dgt13;
  // проверка на число
  if (!string || string.match(/\D/)) {
    // alert("Введённый ОГРН не является числом");
    return false;
  }
  // проверка на 13 и 15 цифр
  if (string.length != 13 && string.length != 15) {
    return false;
  }
  // проверка ОГРН для ЮЛ
  if (string.length == 13) {
    // проверка по контрольным цифрам
    let num12 = string;
    num12 = Math.floor((num12 / 10) % 11);
    if (num12 == 10) {
      dgt13 = 0;
    } else {
      dgt13 = num12;
    }
    if (string[12] == dgt13) {
      return true;
    }
    return false;
  }
  // проверка ОГРН для ИП
  if (string.length == 15) {
    // проверка по контрольным цифрам
    let num14 = string;
    num14 = Math.floor((num14 / 10) % 13);
    const dgt15 = num14 % 10;
    if (string[14] == dgt15) {
      return true;
    }
    // alert("Введённый ОГРН не прошёл проверку по контрольным цифрам");
    return false;
  }

  return true;
}

export function checkNDS(string) {
  const regex = {
    AT: /^(AT)(U\d{8}$)/i, // Austria
    BE: /^(BE)(\d{10}$)/i, // Belgium
    BG: /^(BG)(\d{9,10}$)/i, // Bulgaria
    CY: /^(CY)([0-5|9]\d{7}[A-Z]$)/i, // Cyprus
    CZ: /^(CZ)(\d{8,10})?$/i, // Czech Republic
    DE: /^(DE)([1-9]\d{8}$)/i, // Germany
    DK: /^(DK)(\d{8}$)/i, // Denmark
    EE: /^(EE)(10\d{7}$)/i, // Estonia
    EL: /^(EL)(\d{9}$)/i, // Greece
    ES: /^(ES)([0-9A-Z][0-9]{7}[0-9A-Z]$)/i, // Spain
    EU: /^(EU)(\d{9}$)/i, // EU-type
    FI: /^(FI)(\d{8}$)/i, // Finland
    FR: /^(FR)([0-9A-Z]{2}[0-9]{9}$)/i, // France
    GB: /^(GB)((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$)/i, // UK (Standard = 9 digits), (Branches = 12 digits), (Government = GD + 3 digits), (Health authority = HA + 3 digits)
    GR: /^(GR)(\d{8,9}$)/i, // Greece
    HR: /^(HR)(\d{11}$)/i, // Croatia
    HU: /^(HU)(\d{8}$)/i, // Hungary
    IE: /^(IE)([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/i, // Ireland
    IT: /^(IT)(\d{11}$)/i, // Italy
    LV: /^(LV)(\d{11}$)/i, // Latvia
    LT: /^(LT)(\d{9}$|\d{12}$)/i, // Lithunia
    LU: /^(LU)(\d{8}$)/i, // Luxembourg
    MT: /^(MT)([1-9]\d{7}$)/i, // Malta
    NL: /^(NL)(\d{9}B\d{2}$)/i, // Netherlands
    NO: /^(NO)(\d{9}$)/i, // Norway (not EU)
    PL: /^(PL)(\d{10}$)/i, // Poland
    PT: /^(PT)(\d{9}$)/i, // Portugal
    RO: /^(RO)([1-9]\d{1,9}$)/i, // Romania
    RU: /^(RU)(\d{10}$|\d{12}$)/i, // Russia
    RS: /^(RS)(\d{9}$)/i, // Serbia
    SI: /^(SI)([1-9]\d{7}$)/i, // Slovenia
    SK: /^(SK)([1-9]\d[(2-4)|(6-9)]\d{7}$)/i, // Slovakia Republic
    SE: /^(SE)(\d{10}01$)/i, // Sweden
  };

  if (!string) {
    return false;
  }

  return Object.values(regex).some((r) => string.match(r));
}

export const emailRe = (value) => value
    && value.match(
      /(?:[a-z0-9!#$%&' * +/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    );

export function phoneRe(value) {
  const length = value && value.match(/\d/g).length;
  return length && (length === 10 || length === 11);
}

export function personRe(value) {
  if (!value || typeof value !== 'string') {
    return false;
  }

  const arr = value
        ?.trim()
        .split(' ')
        .filter((el) => el);
  return arr.length === 3;
}

export function checkBIK(value) {
  const bik = value && value.split(' ').join('');
  if (!bik || bik.match(/\D/)) {
    return false;
  }

  return bik.length === 9;
}

export function checkCorrespondedAccount(value) {
  const corAcc = value && value.split(' ').join('');
  if (!corAcc || corAcc.match(/\D/)) {
    return false;
  }

  return corAcc.length === 20;
}

export function checkCheckingAccount(value) {
  const cheAcc = value && value.split(' ').join('');
  if (!cheAcc || cheAcc.match(/\D/)) {
    return false;
  }

  return cheAcc.length === 20;
}

export function checkPassportSerialNumber(value) {
  return value && value.length === 4;
}

export function checkPassportNumber(value) {
  return value && value.length === 6;
}

export function checkIssuedDepartmentCode(value) {
  let code = '';
  if (value && value.includes('-')) {
    code = value && value.split('-').join('');
  } else if (value && value.includes(' ')) {
    code = value && value.split(' ').join('');
  } else {
    code = value;
  }

  return code && !Number.isNaN(Number(code)) && code.length === 6;
}

export function checkPassportDate(value) {
  if (!value) {
    return false;
  }

  return moment(value?.trim(), 'DD.MM.YYYY', true).isValid();
}
