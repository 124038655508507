import React, { useState } from 'react';
import { StarIcon, StarIconFilled } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const style = () => ({
  root: {
    cursor: 'pointer',
    zIndex: 1000000,
    color: 'white',
  },
});
const StarButton = ({
  className, classes, onChangeSelected, selected: selectedProps,
}) => {
  const [selected, setSelected] = useState(selectedProps);
  if (selected) {
    return (
      <StarIconFilled
        className={clsx(classes.root, className)}
        onClick={() => {
          onChangeSelected(false);
          setSelected(false);
        }}
      />
    );
  }
  return (
    <StarIcon
      className={clsx(classes.root, className)}
      onClick={() => {
        onChangeSelected(true);
        setSelected(true);
      }}
    />
  );
};

export default withStyles(style)(StarButton);
