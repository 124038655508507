import DashboardCorrectReportButton from '@pages/dashboard/components/CorrectReportButton';
import React from 'react';

const DashboardEditableTabText = ({ text, onClickCorrectIcon }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight: '15px' }}>
      {text}
    </div>
    <DashboardCorrectReportButton onClick={onClickCorrectIcon} />
  </div>
);

export default DashboardEditableTabText;
