import React from 'react';

export default function AISalesIcon({ ...rest }) {
  return (
    <svg width="42" height="35" viewBox="0 0 42 35" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M29.2065 9.8687L33.3619 4.07638L17.1182 0.172852L17.9996 7.09846L29.2065 9.8687Z" fill="currentColor" />
      <path d="M24.1692 16.7941L28.3245 10.8758L18.125 8.35742L18.7546 13.1424L24.1692 16.7941Z" fill="currentColor" />
      <path d="M5.15576 4.20235L14.7257 10.4984L16.7404 7.6022L15.859 0.298828L5.15576 4.20235Z" fill="currentColor" />
      <path d="M30.4653 10.1207L33.9911 11.0021L34.2429 4.83203L30.4653 10.1207Z" fill="currentColor" />
      <path d="M38.9017 13.3942L35.376 12.6387L35.7537 17.0459L38.9017 13.3942Z" fill="currentColor" />
      <path d="M35.5023 6.46875L35.2505 11.2537L39.5318 12.387L35.5023 6.46875Z" fill="currentColor" />
      <path d="M24.4214 18.4307L20.1401 24.4748L25.051 27.2451L26.1843 27.8747L30.8433 22.586L24.4214 18.4307Z" fill="currentColor" />
      <path d="M16.9921 9.36523L15.7329 11.254L17.3699 12.2614L16.9921 9.36523Z" fill="currentColor" />
      <path d="M0.874023 11.8828L2.88875 18.3047L7.04411 18.8084L0.874023 11.8828Z" fill="currentColor" />
      <path d="M20.644 26.1123L25.0512 34.0453L26.9401 33.7934L25.5549 29.0085L20.644 26.1123Z" fill="currentColor" />
      <path d="M31.8504 23.3418L27.0654 28.7564L29.5838 30.897L36.0058 28.7564L35.8798 25.9861L31.8504 23.3418Z" fill="currentColor" />
      <path d="M17.6214 13.8977L15.103 12.2607L8.55518 21.9566L12.9624 25.1046L18.8806 24.2232L17.6214 13.8977Z" fill="currentColor" />
      <path d="M35.8799 18.6834L36.5095 24.8535L41.1685 21.4536L40.1612 13.8984L35.8799 18.6834Z" fill="currentColor" />
      <path d="M0.496582 9.742L8.93323 19.0601L9.05915 19.186L14.096 11.5049L4.14827 4.95703L0.496582 9.742Z" fill="currentColor" />
      <path d="M34.6208 18.3054L34.1171 12.2613L29.7099 11.2539L25.1768 17.424L31.5987 21.7053L34.6208 18.3054Z" fill="currentColor" />
      <path d="M23.4135 17.8014L18.8804 14.7793L19.8877 22.5863L23.4135 17.8014Z" fill="currentColor" />
      <path d="M34.8726 19.9424L32.7319 22.3349L35.2503 24.0977L34.8726 19.9424Z" fill="currentColor" />
    </svg>
  );
}
