import { withStyles } from '@material-ui/styles';
import React from 'react';
import Table from '@pages/profile/components/Blocks/Table';

const macrosPage1 = [
  { name: 'utm_source', description: 'Параметр ссылки utm_source' },
  { name: 'utm_campaign', description: 'Параметр ссылки utm_campaign' },
  { name: 'utm_medium', description: 'Параметр ссылки utm_medium' },
  { name: 'utm_extra1', description: 'Параметр ссылки utm_extra1' },
  { name: 'utm_extra2', description: 'Параметр ссылки utm_extra2' },
  { name: 'offer_id', description: 'ID оффера' },
  { name: 'channel', description: 'Канал (telegram, facebook и т.д.)' },
  { name: 'group_id', description: 'ID площадки подключенной к офферу (id группы ВКонтакте, страницы в Facebook или бота в Telegram и Viber' },
  { name: 'conversion_id', description: 'ID конверсии' },
  { name: 'event_id', description: 'ID события' },
  { name: 'status', description: 'Статус конверсии: 0 - создана, 1 - подтверждена, 2 - отклонена' },
  { name: 'payout', description: 'Сумма выплаты' },
];

const macrosPage2 = [
  { name: 'conversation_id', description: 'ID конверсии' },
  { name: 'adv_sub', description: 'SubId рекламодателя' },
  { name: 'status', description: 'Статус конверсии (approved,rejected,pending)' },
  {
    name: 'type_event ',
    description: 'Тип события побудившее отправку postback (new - новая конверсия, payout -\n'
      + 'изменился заработок, status - изменился статус конверсии)',
  },
  { name: 'pa', description: 'Статус конверсии для электронной коммерции в Google Analytics (add, remove, checkout)"' },
  { name: 'payout', description: 'Заработок' },
  { name: 'payout_round', description: 'Заработок (без дробной части)' },
  { name: 'payout_type', description: 'Тип расчета выплаты' },
  { name: 'referrer', description: 'HTTP_REFERRER' },
  { name: 'user_agent', description: 'User Agent' },
  { name: 'offer_id', description: 'ID оффера' },
  { name: 'offer_name', description: 'Название оффера' },
  { name: 'affiliate_id', description: 'ID вебмастера' },
];

function Rows({ classes, macros }) {
  return macros.map((macro) => (
    <tr className={classes.row}>
      <td style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
        <div className={classes.tdNameContent}><p>{`{${macro.name}}`}</p></div>
      </td>
      <td className={classes.description}><p>{macro.description}</p></td>
    </tr>
  ));
}

function MacrosTable({ classes }) {
  return (
    <div style={{ display: 'flex' }}>
      <Table>
        <Rows macros={macrosPage1} classes={classes} />
      </Table>
      {/* <Table style={{borderLeft: '1px dashed #D6D6D6'}}> */}
      {/*  <Rows macros={macrosPage2} classes={classes}/> */}
      {/*  <tr className={classes.row}> */}
      {/*    <td style={{borderRight: '1px dashed #D6D6D6', borderLeft: '1px dashed #D6D6D6'}}> */}
      {/*      <div className={classes.tdNameContent}/> */}
      {/*    </td> */}
      {/*    <td className={classes.description}><p/></td> */}
      {/*  </tr> */}
      {/* </Table> */}
    </div>
  );
}

const style = {
  table: {
    backgroundColor: 'white',
    borderCollapse: 'collapse',
    width: '100%',
    '& > tr:nth-child(even)': {
      backgroundColor: '#FBFBFB',
    },
  },
  tdNameContent: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#55C7E1',
    fontWeight: '600',
    fontSize: '14px',
  },
  description: {
    opacity: '0.67',
    fontWeight: 'normal',
    fontSize: '12px',
    '& > p': {
      marginLeft: '37px',
    },
  },
  row: {
    height: '56.29px',
  },
};

export default withStyles(style)(MacrosTable);
