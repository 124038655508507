import { withStyles } from '@material-ui/styles';
import React from 'react';
import clsx from 'clsx';
import {
  FbIcon, OdnoklassnikiIcon, TelegramIcon, ViberIcon, VKIcon, WhatsappIcon, Tooltip,
  FilledHelpIcon, blueColor,
} from '@aisales/react-ui-kit';

import { channels as configChannels } from '@pages/channels/constants';

const channelsLineStyle = () => ({
  root: {
    display: 'flex',
  },
  channelIcon: {
    width: '16px',
    height: '16px',
  },
  channelIconBig: {
    width: '40px',
    height: '40px',
    marginRight: '15px',

  },
  channelIconWrapper: {
    width: '26px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
  },
  channelIconWrapperHoverable: {
    '&:hover': {
      backgroundColor: blueColor[20],
    },
  },
});

const ChannelsLine = withStyles(channelsLineStyle)(({
  channels, className, classes, descriptions, isBig,
}) => {
  const ChannelLineElement = ({ channel, className }) => React.cloneElement(configChannels[channel].icon,
    {
      className: clsx(
        classes.channelIcon,
        className,
        { [classes.channelIconBig]: isBig },
      ),
    });
  return (
    <span className={clsx(className, classes.root)}>
      {channels.map((channel, index) => (descriptions ? (
        <div>
          <Tooltip variant="small" text={descriptions[index]}>
            <div className={clsx(classes.channelIconWrapper, classes.channelIconWrapperHoverable)}>
              <ChannelLineElement channel={channel} />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className={!isBig && classes.channelIconWrapper}>
          <ChannelLineElement channel={channel} />
        </div>
      )))}
    </span>
  );
});

export default ChannelsLine;
