import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Badge } from '@aisales/react-uikit';

/*
chat_id: "db1512d1-295c-4158-9cb4-4ed02df1c416"
created_time: "1591313224"
creation_date: "05.06.2020"
description: "Помогите найти деньги, всё украли, а что делать я не знаю"
id: 21
status: 1
topic: "У меня украли все деньги"
user_id: "5ed8bed823cd998c9ad446e1"
*/
export default function Ticket({
  active,
  creation_date,
  description,
  id,
  status,
  topic,
  ...props
}) {
  const classes = useStyles({ active });

  return (
    <div className={classes.root} {...props}>
      <div className={classes.header}>
        <Typography className={classes.name}>
          Тикет №
          {id}
        </Typography>
        <Badge
          text={creation_date}
          bgColor="rgba(171, 171, 183, 0.23)"
          className={classes.time}
        />
      </div>
      <Typography className={classes.lastMessage}>
        {topic || 'Тема отсутствует'}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    borderBottom: '1px solid #EDEDF2',
    width: '100%',
    padding: 13,
    cursor: 'pointer',

    background: props.active ? '#EDEDF2' : 'white',
    '&:hover': {
      background: '#EDEDF2',
    },
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#20204D',
  },
  time: {
    padding: 3,
    '& p': {
      fontSize: 12,
    },
  },
  lastMessage: {
    width: '100%',
    maxHeight: 60,
    paddingTop: 15,
    wordWrap: 'break-word',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
