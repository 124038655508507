import {
  InfinityIcon,
  ShieldExclamationMarkIcon,
  ShieldIcon,
  ShieldQuestionMarkIcon,
  ShieldTickIcon,
} from '@aisales/react-ui-kit';
import React from 'react';
import { financePaths } from '@pages/finance/constants/financePaths';

const financeHistorySubTabs = [{
  // text: 'Всё',
  text: 'All',
  icon: <InfinityIcon />,
  link: `${financePaths.pathPrefixHistory}/all`,
},
{
  // text: 'Созданные',
  text: 'Created',
  link: `${financePaths.pathPrefixHistory}/created`,
  icon: <ShieldIcon />,
},
{
  // text: 'В обработке',
  text: 'In processing',
  icon: <ShieldQuestionMarkIcon />,
  link: `${financePaths.pathPrefixHistory}/in-process`,
},
{
  // text: 'Завершенные',
  text: 'Completed',
  icon: <ShieldTickIcon />,
  link: `${financePaths.pathPrefixHistory}/finished`,
},
{
  // text: 'Отклоненные',
  text: 'Отклоненные',
  icon: <ShieldExclamationMarkIcon />,
  link: `${financePaths.pathPrefixHistory}/rejected`,
}];

export const financeTabs = [{
  // text: 'Баланс ', link: financePaths.balancePagePath,
  text: 'Balance ', link: financePaths.balancePagePath,
},
{
  // text: 'История выплат',
  text: 'Payment history',
  link: `${financePaths.pathPrefixHistory}/all`,
  regex: `${financePaths.pathPrefixHistory}`,
  subtabs: financeHistorySubTabs,
},
{
  // text: 'Снятие холда',
  text: 'Removing a hold',
  link: financePaths.holdWithdrawalPagePath,
},
{
  // text: 'Сроки и порядок выплат',
  text: 'Terms and procedure of payments',
  link: financePaths.termsAndProcedurePaymentsPagePath,
},
];
