import {
  blueColor, greyColor, Stepper, Typography,
} from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import clsx from 'clsx';
import { channels } from '@pages/channels/constants';

const offerStepperStyle = () => ({
  root: {
    width: '100%',
    backgroundColor: greyColor.background,
    padding: '30px',
    paddingBottom: '0px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'stretch',
  },
  button: {
    cursor: 'pointer',
    flexGrow: '1',
    textAlign: 'center',
    '&:not(:first-of-type)': {
      borderLeft: '0.5px',
    },
    '&:first-of-type': {
      borderRadius: '5px 0 0 5px',
    },
    '&:last-of-type': {
      borderRadius: '0px 5px 5px 0px',
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: blueColor[20],
    padding: '12px 14px',
    textOverflow: 'ellipsis',
  },
  selectedButton: {
    borderColor: blueColor[100],
    backgroundColor: blueColor[100],
    color: 'white',
  },
  rightPart: {},
  rightPartStyle: {},
  stepper: {
    marginTop: '28px',
  },
  step: {
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hold: {
    color: blueColor[100],
    whiteSpace: 'nowrap',
  },
});

const currencies = {
  1: 'р',
};

const OfferStepper = withStyles(offerStepperStyle)((
  { className, classes, offerInfo },
) => {
  const changeChannel = (channelIdx) => {
    setSelectedChannelIndex(channelIdx);
  };
  const [selectedChannelIndex, setSelectedChannelIndex] = useState(0);
  const steps = offerInfo.events.filter(((event) => (event.channels[0] === '*' || event.channels.includes(offerInfo.available_channels[selectedChannelIndex])))).map((event) => ({
    component: <div className={classes.step}>
      <div>
        <Typography bold>{event.name}</Typography>
        <div>{event.description}</div>
      </div>
      <Typography className={classes.hold} variant="title">
        +
        {event.amounts[0]}
        {' '}
        <Typography bold>
          {currencies?.[event.currency]
                || ''}
        </Typography>
        {' '}
        /
        {event.holdDays}
        {' '}
        <Typography bold>д.</Typography>
      </Typography>
               </div>,
  }));
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.buttons}>
        {offerInfo.available_channels.filter((channel) => channel !== 'authless_widget').map((channel, idx) => (
          <div
            className={clsx({ [classes.selectedButton]: selectedChannelIndex === idx }, classes.button)}
            onClick={() => {
              changeChannel(idx);
            }}
          >
            {channels[channel]?.name}
          </div>
        ))}
      </div>

      <Stepper className={classes.stepper} steps={steps} allConnected />
    </div>
  );
});

export default OfferStepper;
