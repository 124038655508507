import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import VKGroups from './vk_groups';
import { showToast } from '../../../oldOffers/utils';
import { backendURL } from '../../../../toRemove';

class VKAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: null,
      found: false,
      pending: false,
    };

    if (
      localStorage.getItem('vk_access_token')
        && !this.props.platform
    ) {
      this.getList(this);
    }
  }

    getList = (self) => {
      const fetchJsonp = require('fetch-jsonp');
      fetchJsonp(
        `https://api.vk.com/method/groups.get?filter=admin&extended=1&access_token=${
          localStorage.getItem('vk_access_token')
        }&v=5.92&callback=JSONP_CALLBACK'`,
        { JSONP_CALLBACK: 'JSONP_CALLBACK' },
      )
        .then((response) => response.json())
        .then((json) => {
          self.setState({ groups: json.response });
        })
        .catch((ex) => {
          localStorage.removeItem('vk_access_token');
        });
    };

    project = JSON.parse(localStorage.getItem('user'))?.current_project;

    saveGroup = (language, country, translate) => {
      this.setState({ pending: true });
      fetch(`${backendURL}/api/channels/vk/${this.props.platform.id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          'offer-id': this.props.platform.offer_id,
        },
        body: JSON.stringify({ language, country, translate }),
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              this.props.updateChannelsList({
                ...this.props.platform,
                language,
                country,
                translate,
              });
              showToast('Данные обновлены', true);
              this.props.onClose();
            });
          } else {
            showToast('Ошибка при сохранении', false);
          }
        })
        .finally(() => {
          this.setState({ pending: false });
        });
    };

    render() {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
          }}
        >
          {!this.state.pending
          && (this.state.groups !== null
                    || Boolean(this.props.platform)) && (
                    <>

                      <VKGroups
                        classes={this.props.classes}
                        platform={this.props.platform}
                        saveGroup={this.saveGroup.bind(this)}
                        groups={this.state.groups}
                        onClose={this.props.onClose}
                      />
                    </>
          )}
          {this.state.pending && (
          <div
            style={{
              textAlign: 'center',
              height: '100%',
              outline: 'none',
            }}
          >
            <CircularProgress
              style={{ marginTop: '200px', outline: 'none' }}
            />
          </div>
          )}
        </div>
      );
    }
}

export default VKAuth;
