import { GET_BALANCE_FAILURE, GET_BALANCE_SUCCESS, START_GET_BALANCE } from '@store/actions/balanceActions';

export const initialState = {
  isLoading: false,
  balance: null,
  error: null,
};

export default function BalanceReducer(state = initialState, { payload, type }) {
  switch (type) {
    case START_GET_BALANCE:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BALANCE_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
