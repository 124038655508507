import { withStyles } from '@material-ui/core';
import { Button } from '@aisales/react-ui-kit';
import React from 'react';
import clsx from 'clsx';

const AlternativeEnterButton = (({
  //children, className, classes, icon, href, platformName, mode = 'Быстрый вход',
  children, className, classes, icon, href, platformName, mode = 'Login',
}) => (
  <Button href={href} className={clsx(className, classes.element_centralizedVertically)} classes={{ root: classes.alternativeEnterButton }} color="secondary">
    {icon}
    {' '}
    <span className={classes.fastEnterText}>
      {/* {`${mode} с помощью ${platformName}`} */}
      {`${mode} with ${platformName}`}

    </span>
  </Button>
));

const style = () => ({
  fastEnterText: {
    marginLeft: '15px',
  },
  alternativeEnterButton: {
    '&>span': {
      display: 'flex',
      alignItems: 'center',
    },
    width: '100%',

    padding: '0px',
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(4, 134, 254, 0.2)',
      borderRadius: '5px',
    },
  },
});

export default withStyles(style)(AlternativeEnterButton);
