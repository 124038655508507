import React from 'react';
import {
  InputBase, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import MuiAvatar from '@material-ui/core/Avatar';
import Modal from '@material-ui/core/Modal';
import classNames from 'classnames';

const Avatar = ({
  classes, initial, style, onChange = () => {},
}) => {
  const [modal, setModal] = React.useState(false);
  const [link, setLink] = React.useState(initial);
  const [valid, setValid] = React.useState(true);

  const re = /^(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)$/i;
  const validate = (str) => {
    str = str.trim();
    setValid(re.test(str));
    return re.test(str);
  };

  return (
    <>
      <Modal open={modal} onBackdropClick={() => setModal(false)}>
        <div className={classes.modal}>
          <Typography variant="caption" style={{ color: 'white' }}>Ссылка на картинку</Typography>
          <Tooltip title="Ссылка должна заканчиваться на .gif, .png или .jpg">
            <div className={classNames(classes.inputWrapper, { invalid: !valid })}>
              <InputBase
                onFocus={(e) => e.target.select()}
                value={link}
                autoFocus
                autoComplete="off"
                inputProps={{ spellCheck: false }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (validate(link)) {
                      onChange(link.trim());
                      setModal(false);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  } else if (e.key === 'Escape') {
                    setModal(false);
                    setValid(true);
                    setLink(initial);
                  }
                }}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Имя"
                style={{ width: '100%', marginLeft: '10px', paddingTop: '2px' }}
              />
            </div>
          </Tooltip>
        </div>
      </Modal>
      <MuiAvatar src={initial} className={classes.root} style={style} onClick={() => setModal(true)} />

    </>
  );
};

const styles = {
  root: {
    border: '2px dashed #00000021',
    // borderRadius: '20px',
    transition: 'border .3s',
    cursor: 'pointer',
    '&:hover': {
      border: '2px dashed #02abd3cf',
    },
  },
  modal: {
    height: '70px',
    minWidth: '600px',
    width: '70vw',
    maxWidth: '800px',
    margin: 'calc(50vh - 35px) auto',
  },
  inputWrapper: {
    display: 'flex',
    width: '75%',
    background: 'rgb(255, 255, 255)',
    paddingRight: '10px',
    border: '1px solid rgb(230, 230, 230)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '40px',
    transition: 'border .2s',
    '&.invalid': {
      border: '2px solid rgb(234, 132, 132)',
    },
  },
};

export default withStyles(styles)(Avatar);
