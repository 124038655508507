import { ReactComponent as ZaleycashIcon } from '@pages/finance/assets/payments/zaleycash.svg';
import uniqIcon from '@pages/finance/assets/payments/getuniq.png';
import webmoneyIcon from '@pages/finance/assets/payments/webmoney.png';
import yandexMoneyIcon from '@pages/finance/assets/payments/yandex money.png';
import solarStaff from '@pages/finance/assets/payments/solar-staff.png';
import capitalist from '@pages/finance/assets/payments/capitalist.png';
import googleAdwords from '@pages/finance/assets/payments/google adwords.png';
import myTarget from '@pages/finance/assets/payments/mytarget.png';
import yandexDirect from '@pages/finance/assets/payments/yandex money.png';
import { ReactComponent as VkIcon } from '@pages/finance/assets/payments/vk.svg';
import { ReactComponent as BankIcon } from '@pages/finance/assets/payments/bank card.svg';
import { ReactComponent as BankTransferIcon } from '@pages/finance/assets/payments/bank transfer.svg';
import React from 'react';

export const paymentsMethods = [
  {
    name: 'ZaleyCash',
    icon: <ZaleycashIcon />,
    numOfDays: 3,
    commission: 0,
  },
  {
    name: 'GetUniq',
    icon: <img src={uniqIcon} />,
    numOfDays: 7,
    commission: 0,
  },
  {
    name: 'Webmoney (WMZ)',
    icon: <img src={webmoneyIcon} />,
    numOfDays: 7,
    commission: 3.9,
  },

  {
    name: 'Yandex money',
    icon: <img src={yandexMoneyIcon} />,
    numOfDays: 7,
    commission: 3.9,
  },
  {
    // name: 'Solar-staff (Банковская карта)',
    name: 'Solar-staff (bank card)',
    icon: <img src={solarStaff} />,
    numOfDays: 7,
    commission: 4.5,
  },
  {
    // name: 'Capitalist (Платёжная система RUB)',
    name: 'Capitalist (RUB payment system)',
    icon: <img src={capitalist} />,
    numOfDays: 7,
    commission: 2.9,
  },
  {
    name: 'Google adwords (РКС)',
    icon: <img src={googleAdwords} />,
    numOfDays: 7,
    commission: 0,
  },
  {
    name: 'Mytarget (РКС)',
    icon: <img src={myTarget} />,
    numOfDays: 3,
    commission: 0,
  },
  {
    name: 'YandexDirect (РКС) ',
    icon: <img src={yandexDirect} />,
    numOfDays: 3,
    commission: 0,
  },
  /* {
    name: 'Вконтакте (РКС) ',
    icon: <VkIcon />,
    numOfDays: 3,
    commission: 0,
  }, */
  {
    // name: 'Банковская карта',
    name: 'Bank card',
    icon: <BankIcon />,
    numOfDays: 3,
    commission: 4.5,
  },
  {
    // name: 'Банковский перевод (Только для юридических лиц)',
    name: 'Bank transfer (Only for legal entities)',
    icon: <BankTransferIcon />,
    numOfDays: 7,
    commission: 0,
  },
  {
    // name: 'Банковский перевод (С подключением ЭДО)',
    name: 'Bank transfer (With EDI connection)',
    icon: <BankTransferIcon />,
    numOfDays: 7,
    commission: 0,
  },
  {
    // name: 'Банковский перевод (Самозанятые)',
    name: 'Bank transfer (Self-employed)',
    icon: <BankTransferIcon />,
    numOfDays: 7,
    commission: 0,
  },
];
