import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Avatar,
  blueColor,
  ChannelIcon,
  ExitIcon,
  FinanceIcon,
  greyColor,
  Header,
  OfferIcon,
  redColor,
  ReportIcon,
  Select,
  SelectItem,
  Sidebar,
  TextWithIcon,
} from '@aisales/react-ui-kit';
import { useHistory } from 'react-router-dom';
import {
  CHANNELS, FINANCES, OFFERS, REPORTS,
} from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance, getStatisticsReports, signOut } from '@store/actions';
import { getCookie } from '@pages/finance/utils';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { financeTabs } from '@pages/finance/constants/financeTabs';

import { dashboardPaths } from '@pages/dashboard/constants/dashboardPaths';
import { getDashboardTabsForNavigation } from '@pages/dashboard/utils/tabsGetters';

const styles = {
  root: {
    margin: '15px',
    '& .MuiInputBase-root': {
      width: '180px',
    },
  },
  selectContent: {
    height: '100vh',
  },
  hoveredSelectItemRoot: {
    'li&': {
      backgroundColor: blueColor[15],
    },
    color: blueColor[90],
  },
  selectOneRoot: {
    width: 'fit-content',
    background: blueColor[20],
    border: 'none',
    color: blueColor[100],
    '& > div': {
      fontWeight: '600',
    },
  },
  selectOneOpen: {
    background: blueColor[100],
    color: 'white',

  },
  elementTwoRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    marginRight: '10px',
  },
  selectTwoRoot: {
    width: 'fit-content',
    background: 'transparent',
    border: 'none',
  },
  supportIcon: {
    marginLeft: '30px',
  },
  exitText: {
    color: redColor[100],
  },
  languageSwitchRoot: {
    color: greyColor[100],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {},
  language: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  chosenLanguage: {
    color: blueColor[100],
  },
  selectItemRoot: {
    paddingRight: '15px',
  },
};

const FirstSelect = ({ classes }) => {
  const accountID = getCookie('account_id');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const balance = useSelector((state) => state.balance.balance);
  useEffect(() => {
    dispatch(getBalance(accountID));
  }, []);

  function handleChange(event) {
    history.push('/app/finance/balance');
  }

  let balanceImpl = { total: '', available: '' };
  if (balance) {
    balanceImpl = balance;
  }

  // const values = [`Итого на счету: ${balanceImpl.total ? balanceImpl.total : 0}`,
  const values = [`Total on account: ${balanceImpl.total ? balanceImpl.total : 0}`,
    // `Доступно: ${balanceImpl.available ? balanceImpl.available : 0}`,
    `Available: ${balanceImpl.available ? balanceImpl.available : 0}`,
    // `В ожидании: ${(balanceImpl.total && balanceImpl.available) ? balanceImpl.total - balanceImpl.available : 0}`];
    `Pending: ${(balanceImpl.total && balanceImpl.available) ? balanceImpl.total - balanceImpl.available : 0}`];

  return (
    <Select
      classes={{ content: classes.selectContent, inputRoot: classes.selectOneRoot, inputOpen: classes.selectOneOpen }}
      value={values[0]}
    >
      {values.map((name) => (
        <SelectItem maxkey={name} value={name} onClick={handleChange}>
          <TextWithIcon label={name} />
        </SelectItem>
      ))}
    </Select>
  );
};

const SecondSelect = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const selectEl = useRef(null);
  const moveToCabinet = () => {
    history.push('/app/profile');
  };
  const logout = () => {
    dispatch(signOut());
  };
  const username = JSON.parse(localStorage.getItem('user'))?.user?.username;
  const values = [{ // { label: 'Личный кабинет', icon: <AccountIcon />, onClick: moveToCabinet },
    // label: 'Выйти',
    label: 'Exit',
    icon: <ExitIcon />,
    onClick: () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      logout();
    },
  }];
  return (
    <div
      ref={selectEl}
      className={classes.elementTwoRoot}
      onClick={() => {
        setOpen(true);
      }}
    >
      <Avatar classes={{ root: classes.avatar }} />
      <Select
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        MenuProps={{
          MenuListProps: {
            // style: {
            //     width: selectEl?.current?.offsetWidth
            // }
          },
          anchorEl: selectEl?.current,
          anchorOrigin: {
            horizontal: 'center',
          },
          transformOrigin: {
            horizontal: 'center',
          },
          style: {
            top: '65px',
          },
        }}
        value={username}
        classes={{ inputRoot: classes.selectTwoRoot }}
      >
        {values.map((value, index) => (
          <SelectItem
            className={classes.selectItemRoot}
            maxkey={value.label}
            value={value.label}
            onClick={value.onClick}
          >
            <TextWithIcon
              classes={index === 1 ? { root: classes.exitText } : undefined}

              iconFirst
              label={value.label}
              icon={value.icon}
            />
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

const LanguageSwitch = ({ classes }) => {
  const [chosenLanguage, setChosenLanguage] = useState(localStorage.getItem('lang')
        || navigator.language.split('-')[0]
        || 'en');
  const chooseLanguage = (language) => {
    setChosenLanguage(language);
    localStorage.setItem('lang', language);
    window.location.reload();
  };
  const Language = ({ language }) => (
    <div
      className={clsx(classes.language, { [classes.chosenLanguage]: chosenLanguage === language })}
      onClick={() => {
        chooseLanguage(language);
      }}
    >
      {language}
    </div>
  );
  const languages = ['ru', 'en'];
  return (
    <div className={classes.languageSwitchRoot}>
      <Language language={languages[0]} />
      <div className={classes.divider}>&nbsp;|&nbsp;</div>
      <Language language={languages[1]} />
    </div>
  );
};
const HeaderRight = ({ classes }) => ([<FirstSelect classes={classes} />,
  <SecondSelect classes={classes} />,
  <LanguageSwitch classes={classes} />]);

const bottom = [
  // { text: 'Инструменты', icon: <SettingsIcon />, link: '/app/profile' },

];

function getWindowDimensions() {
  const { innerHeight: height, innerWidth: width } = window;
  return {
    width,
    height,
  };
}

const Navigation = ({ children, classes }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [top, setTop] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const statisticsReports = useSelector((state) => state?.reports?.reports);
  const statisticsReportsLoading = useSelector((state) => state?.reports.isLoading);
  useEffect(() => {
    if (!statisticsReports && !statisticsReportsLoading) {
      dispatch(getStatisticsReports);
    }
  }, []);
  useEffect(() => {
    setTop([
      {
        disabled: false,
        text: t(OFFERS),
        link: '/app/offers/all-offers',
        icon: <OfferIcon />,
        subItems: [{
          // text: 'Все офферы',
          text: 'All offers',
          link: '/app/offers/all-offers',
          regex: '/app/offers/(all-offers|favorites)',
        }, {
          // text: 'Мои офферы',
          text: 'My offers',
          link: '/app/offers/my-offers',
        }],
      },
      {
        disabled: false, text: t(CHANNELS), link: '/app/channels', icon: <ChannelIcon />,
      },
      {
        disabled: false,
        text: t(REPORTS),
        link: `${dashboardPaths.byDayReportPrefix}?${dashboardPaths.sevenDaysPostfix}`,
        regex: `${dashboardPaths.indexPath}`,
        icon: <ReportIcon />,
        subItems: statisticsReports ? getDashboardTabsForNavigation(statisticsReports) : [],
      },
      {
        disabled: false,
        text: t(FINANCES),
        link: '/app/finance/balance',
        icon: <FinanceIcon />,
        subItems: financeTabs,
      },
    ]);
  }, [statisticsReports]);

  useEffect(() => {
  }, [top]);

  return (
    <Sidebar
      expanded={JSON.parse(sessionStorage.getItem('sidebarIsOpened') || (getWindowDimensions().width > 1300) ? 'true' : 'false')}
      history={history}
      location={location}
      top={top}
      bottom={bottom}
      onClickLogo={() => { history.push('/app/offers/my-offers'); }}
      onChangeOpen={(open) => {
        sessionStorage.setItem('sidebarIsOpened', JSON.stringify(open));
      }}
    >
      <Header right={HeaderRight({ classes })}>
        {children}
      </Header>
    </Sidebar>

  );
};

export default withStyles(styles)(Navigation);
