import React from 'react';
import {
  AuthlessIcon,
  FbIcon,
  TelegramIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
} from '@aisales/react-ui-kit';
import VKAuth from '../components/vk/vk_auth';
import Facebook from '../components/facebook/facebook';
import questionMark from '../question_mark.svg';
import Bot from '../components/bot';
import telegramIcon from '../icons/telegram.png';
import viberIcon from '../icons/viber.png';
import yandexIcon from '../icons/yandex.png';
import authlessIcon from '../icons/authless.png';
import whatsappIcon from '../icons/whatsapp.png';
import instagramIcon from '../icons/instagram.svg';
import androidIcon from '../icons/android.png';
import appleIcon from '../icons/apple.svg';
import iosIcon from '../icons/ios.svg';

export const messengerChannels = {
  telegram: {
    name: 'Telegram',
    icon: telegramIcon,
  },
  viber: {
    name: 'Viber',
    icon: viberIcon,
  },
  vk: {
    name: 'VK',
    icon: VKIcon,
  },
  authless_widget: {
    name: 'Authless Widget',
    icon: authlessIcon,
  },
  yandex: {
    name: 'Yandex',
    icon: yandexIcon,
  },
  whatsapp: {
    name: 'WhatsApp',
    icon: whatsappIcon,
  },
  facebook: {
    name: 'Facebook',
    icon: FbIcon,
  },
  ok: {
    name: 'Одноклассники',
    icon: WhatsappIcon,
  },
  instagram: {
    name: 'Instagram',
    icon: instagramIcon,
  },
  ios: {
    name: 'IOS',
    icon: iosIcon,
  },
  android: {
    name: 'Android',
    icon: androidIcon,
  },
  apple: {
    name: 'Apple Business Chat',
    icon: appleIcon,
  },
};

export const makeRefLink = (channel, groupID, ref) => {
  switch (channel) {
    case 'vk':
      return `https://vk.me/${groupID}?ref=${ref}`;
    case 'telegram':
      return `tg://resolve?domain=${groupID}&start=${ref}`;
    case 'facebook':
      return `http://m.me/${groupID}?ref=${ref}`;
    case 'viber':
      return 'viber://pa?chatURI=<URI>&context=<YourContext>&text=<YourText>';
    default:
      return '';
  }
};

const setSize = (component, props = {}) => (size) => React.createElement(component, {
  ...props,
  style: { fontSize: size, height: size },
});

export const channels_full = {
  ...(() => {
    const chnls = JSON.parse(JSON.stringify(messengerChannels));
    Object.keys(chnls).forEach((key) => {
      chnls[key].icon = setSize('img', {
        src: chnls[key].icon,
        alt: `${chnls[key].name} logo`,
      });
    });
    return chnls;
  })(),
};

export const unknownAvatar = questionMark;

export const languages = [
  {
    name: 'Русский',
    code: 'ru',
  },
  {
    name: 'Чешский',
    code: 'cs',
  },
  {
    name: 'Украинский',
    code: 'uk',
  },
  {
    name: 'Испанский',
    code: 'es',
  },
  {
    name: 'Польский',
    code: 'pl',
  },
  {
    name: 'Английский',
    code: 'en',
  },
  {
    name: 'Казахский',
    code: 'kk',
  },
  {
    name: 'Итальянский',
    code: 'it',
  },
  {
    name: 'Немецкий',
    code: 'de',
  },
];

export const channels = {
  vk: {
    icon: <VKIcon />,
    name: 'ВКонтакте',
    color: '#5C80B8',
    addComponent: VKAuth,
  },
  facebook: {
    icon: <FbIcon />,
    name: 'Facebook',
    color: '#4886EF',
    addComponent: Facebook,
  },
  instagram: {
    name: 'Instagram',
    color: '#4886EF',
    addComponent: Facebook,
    icon: <img src={instagramIcon}/>
  },
  whatsapp: {
    icon: <WhatsappIcon />,
    name: 'WhatsApp',
    color: '#25D366',
    addComponent: null,
  },
  viber: {
    icon: <ViberIcon />,
    name: 'Viber',
    color: '#7F4DA0',
    addComponent: Bot,
  },
  telegram: {
    icon: <TelegramIcon />,
    name: 'Telegram',
    color: '#61A2D0',
    addComponent: Bot,
  },
  authless_widget: {
    icon: <AuthlessIcon />,
    name: 'Чат',
    color: 'rgb(2, 171, 211)',
    addComponent: null,
  },
};
