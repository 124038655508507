import React from 'react';
import { Button, Modal, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { SketchPicker as ColorPicker } from 'react-color';
import GradientPicker from './GradientPicker';
import ImagePicker from './ImagePicker';

const Background = ({ classes, ...props }) => {
  const [background, setBackground] = React.useState(props.initial);
  const getView = (background) => {
    if (background.includes('gradient')) {
      return 'gradient';
    }
    if (background.includes('url')) {
      return 'picture';
    }
    return 'color';
  };

  const handleChange = (bg) => {
    setBackground(bg);
    props.onChange(bg);
  };

  const [view, setView] = React.useState(getView(background));
  const [modal, setModal] = React.useState(false);

  return (
    <div className={classNames(classes.root)}>
      <Modal open={modal} onBackdropClick={() => setModal(false)}>
        <div className={classes.modalRoot}>
          <div style={{ flex: 1, overflow: 'hidden', background }}>
            {view === 'gradient' && (
              <div>
                <GradientPicker onChange={handleChange} background={background} />
              </div>
            )}
            {view === 'color' && (
              <div style={{ margin: 'auto', width: 'fit-content', paddingTop: '20px' }}>
                <ColorPicker disableAlpha color={background} onChange={(c) => handleChange(c.hex)} />
              </div>
            )}
            {view === 'picture' && (
              <div>
                <ImagePicker onChange={(background) => handleChange(background)} background={background} />
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <ButtonGroup style={{ margin: 'auto' }} variant="contained" color="primary" aria-label="contained primary button group">
              <Button
                disabled={view === 'color'}
                className={classNames('', { [classes.selectedView]: view === 'color' })}
                onClick={() => setView('color')}
              >
                Цвет
              </Button>
              <Button
                disabled={view === 'gradient'}
                className={classNames('', { [classes.selectedView]: view === 'gradient' })}
                onClick={() => setView('gradient')}
              >
                Градиент
              </Button>
              <Button
                disabled={view === 'picture'}
                className={classNames('', { [classes.selectedView]: view === 'picture' })}
                onClick={() => setView('picture')}
              >
                Картинка
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Modal>
      <div
        className={classes.button}
        style={{ background: `${background} content-box` }}
        onClick={() => setModal(true)}
      />
    </div>
  );
};

const styles = (theme) => ({
  root: {
    width: '46px',
    height: '24px',
    transition: 'all .3s',
    '&.maximized': {
      width: '100%',
      height: '200px',
    },
    marginRight: '5px',
  },
  button: {
    width: '100%',
    height: '100%',
    border: '1px dashed #00000021',
    borderRadius: '5px',
    transition: 'border .3s',
    cursor: 'pointer',
    '&:hover': {
      border: '2px dashed #02abd3cf',
    },
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '-35px',
    marginRight: '10px',
    color: '#999999',
    transition: 'color .2s',
    cursor: 'pointer',
    '&:hover': {
      color: '#555555',
    },
  },
  modalRoot: {
    width: '50vw',
    maxWidth: '600px',
    height: '70vh',
    maxHeight: '700px',
    background: 'white',
    margin: 'auto',
    marginTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
  },
  modalFooter: {
    display: 'flex',
    borderTop: '1px solid #00000014',
    alignItems: 'center',
    height: '60px',
    padding: '0 10px',
  },
  selectedView: {
    color: '#ffffffcf !important',
  },
});

export default withStyles(styles, { withTheme: true })(Background);
