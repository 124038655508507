import { CloseIcon, greyColor, Typography } from '@aisales/react-ui-kit';
import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = () => ({
  root: {
    width: '100%',
    background: '#F2F6F9',
    borderRadius: '5px',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: '28.75px',
    right: '28.17px',
    width: '13.12px',
    height: '13.12px',
    color: greyColor[200],
    cursor: 'pointer',
  },
  illustration: {
    zIndex: '0',
    position: 'absolute',
    right: '0',
  },
  front: {
    zIndex: '10000',
  },
  description: {
    marginTop: '5px',
    width: '41.751152073732717%',
    // paddingBottom: '25px'
  },
  text: {
    paddingLeft: '30px',
    width: '100%',

  },
  content: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
});

const Message = ({
  bottomText, className, classes, illustration, onClose, upperText, withoutCloseIcon,
}) => (
  <div className={clsx(classes.root, className)}>

    {!withoutCloseIcon && (
    <CloseIcon
      className={clsx(classes.closeIcon, classes.front)}
      onClick={() => {
            onClose?.();
      }}
    />
    )}
    {React.cloneElement(illustration, { className: classes.illustration })}
    <div className={classes.content}>
      <div className={classes.text}>
        {/* <Typography className={classes.front} variant={"title"}>{firstName}, добро пожаловать в Финансы</Typography> */}
        <Typography className={classes.front} variant="title">{upperText}</Typography>
        {/* <div className={clsx(classes.front, classes.description)}> */}
        {/*    На данной странице Вы можете выводить свои средста, просматривать историю выплат, сравнивать доходность и т.д. */}
        {/* </div> */}
        <div className={clsx(classes.front, classes.description)}>
          {bottomText}
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(styles)(Message);
