import { Button, Input } from '@aisales/react-ui-kit';
import React, { useEffect, useState } from 'react';
import OfferStepper from '@pages/offers/components/OfferConnect/components/Stepper';
import { withStyles } from '@material-ui/core';
import MessengerConnector from '@pages/offers/components/OfferConnect/components/MessengerStepper/MessengerConnector';

const messengerInputStyle = () => ({
  buttonAddChat: {
    marginTop: '30px',
    width: '290px',
  },
  input: {
    '&&&&': {
      width: '600px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

const MessengerInput = withStyles(messengerInputStyle)(({
  chosenChannel,
  classes,
  currentStep,
  setError,
  setStep,
  setTokenToAdd,
  tokenToAdd,
}) => {
  useEffect(() => {
    setError(!isValidToken());
  }, [tokenToAdd]);

  const isValidToken = () => {
    if (chosenChannel === 'telegram') {
      return /\d+:(.*)/.test(tokenToAdd);
    }
    return tokenToAdd?.trim().length > 5;
  };
  return (
    <div>
      <Input
        // errorText={tokenToAdd && !isValidToken() ? 'Токен введен не верно' : undefined}
        errorText={tokenToAdd && !isValidToken() ? 'Token entered incorrectly' : undefined}
        onChange={(event) => {
          setTokenToAdd(event.target.value);
        }}
        classes={{ root: classes.input }}
        // placeholder="Введите токен"
        placeholder="Enter token"
      />
      {currentStep < 3 && (
      <div className={classes.buttonWrapper}>
        <Button
          onClick={() => {
            setStep(currentStep + 1);
          }}
          disabled={!tokenToAdd || currentStep === 1 || !isValidToken()}
          classes={{ root: classes.buttonAddChat }}
        >
          {/* Подключить
          чат */}
          Connect
          chat
        </Button>
      </div>
      )}

    </div>
  );
});

const MessengerStepper = ({ chosenChannel, ...otherProps }) => {
  const [error, setError] = useState(false);
  const [tokenToAdd, setTokenToAdd] = useState(null);

  function specialSteps({ currentStep, finished, setStep }) {
    return [{
      // title: 'Введите токен ',
      title: 'Enter token ',
      component: React.cloneElement(<MessengerInput />, {
        chosenChannel,
        setStep,
        currentStep,
        tokenToAdd,
        setTokenToAdd,
        setError,
        ...otherProps,
      }),
    }];
  }

  return (
    <MessengerConnector token={tokenToAdd} chosenChannel={chosenChannel} {...otherProps}>
      <OfferStepper
        error={error}
        chosenChannel={chosenChannel}
        specialSteps={specialSteps}
        monetizationStepIdx={3}
        {...otherProps}
      />
    </MessengerConnector>
  );
};
export default MessengerStepper;
