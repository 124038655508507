import React, { useLayoutEffect, useRef, useState, } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
  blueColor,
  greyColor,
  Select,
  SelectItem,
  TextWithIcon,
  Tooltip,
  Typography,
  typographyDefaultTheme,
} from '@aisales/react-ui-kit';
import Block from '@components/Block/Block';
import { withStyles } from '@material-ui/core';
import ChartTooltip from '@pages/finance/components/Chart/components/ChartTooltip';
import ReactDOMServer from 'react-dom/server';
import clsx from 'clsx';

import indicator from './assets/indicator.svg';
import { emptyData } from './config';
import Loader from '@components/Loader';

am4core.useTheme(am4themes_animated);

const formatDate = (date) => {
  const dateParts = new Date(date).toLocaleDateString('RU', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  }).split(',');
  return `${dateParts[1]} (${dateParts[0]})`;
};

const styles = () => ({
  root: {
    height: '462px',
    padding: '30px',
    width: '100%',
    position: 'relative',
  },
  chart: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '327px',
  },
  top: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  topHead: {
    '&>div': {
      marginTop: '6px',
    },
    marginBottom: '30px',
  },
  tooltipWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1000',

  },
  tooltip: {
    marginTop: '-120px',
  },
  tooltipPopper: {
    zIndex: '1',
  },
  tooltipCrossIcon: {
    display: 'none',
  },
  chartDataIsLoadingWrapper: {
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
});

const options = [7, 14, 30];
const mapping = {
  7: 'Последние 7 дней',
  14: 'Последние 14 дней',
  30: 'Последние 30 дней',
};

function Chart({
  chartData, className, classes, selectValue, setSelectValue, tooltipData, bottomData, chartTitle, description, enableSelect, chartDataLoading,
}) {
  const chart = useRef(null);
  const [dataHovered, setDataHovered] = useState(undefined);
  const [tooltipIsOpened, setTooltipIsOpened] = useState(true);

  const parseData = (data) => data.map((dataPiece) => [dataPiece[0], dataHovered[dataPiece[1]], dataPiece[2]]);

  const parseBottomData = (data) => [data[0], dataHovered[data[1]]];

  const getPeriodString = () => {
    const options = {
      month: 'long',
      day: 'numeric',
    };
    const today = new Date();
    const priorDate = new Date(new Date().setDate(new Date().getDate() - (selectValue - 1)));

    return `${priorDate.toLocaleDateString('RU', options)} - ${today.toLocaleDateString('RU', options)}`;
  };

  useLayoutEffect(() => {
    if (chart.current?.series) {
      if (chartData && chartData.length === 0) {
        chart.current.series.values[0].tooltipHTML = `${ReactDOMServer.renderToStaticMarkup(<div />).toString()}`;
      } else {
        chart.current.series.values[0].tooltipHTML = `${ReactDOMServer.renderToStaticMarkup(<ChartTooltip
          date={formatDate(dataHovered.date)}
          data={parseData(tooltipData)}
          bottomData={bottomData ? parseBottomData(bottomData) : undefined}
        />).toString()}`;
      }
    }
  }, [dataHovered]);
  useLayoutEffect(() => {
    const x = am4core.create('chartdiv', am4charts.XYChart);

    x.paddingRight = 20;
    let data = [];
    const visits = 10;

    if (chartData && chartData.length !== 0) {
      for (const chartDataPiece of chartData) {
        data.push({
          date: new Date(chartDataPiece.date), name: 'name', value: chartDataPiece.total, color: '#cc0000',
        });
      }
    } else {
      data = emptyData;
    }

    x.data = data;

    const dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    dateAxis.tooltip.disabled = true;
    const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.fill = greyColor[50];
    dateAxis.renderer.labels.template.fill = greyColor[50];
    valueAxis.renderer.labels.template.fontSize = typographyDefaultTheme.typography.detailed.fontSize;
    dateAxis.renderer.labels.template.fontSize = typographyDefaultTheme.typography.detailed.fontSize;
    valueAxis.renderer.labels.template.fontFamily = typographyDefaultTheme.typography.root.fontFamily;
    dateAxis.renderer.labels.template.fontFamily = typographyDefaultTheme.typography.root.fontFamily;
    valueAxis.renderer.minWidth = 35;
    valueAxis.renderer.fontSize = typographyDefaultTheme.typography.detailed.fontSize;
    valueAxis.labelColorField = 'color';
    const series = x.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.strokeWidth = 3;
    series.stroke = am4core.color(blueColor[100]);
    series.dataFields.valueY = 'value';
    if (chartData && chartData.length === 0) {
      series.tooltipHTML = `${ReactDOMServer.renderToStaticMarkup(<div />).toString()}`;
    } else {
      series.tooltipHTML = `${ReactDOMServer.renderToString(<ChartTooltip />).toString()}`;
    }
    series.tooltip.background.fillOpacity = 0;
    series.tooltip.background.strokeOpacity = 0;
    const bullet = series.bullets.push(new am4core.Image());
    bullet.hidden = true;
    bullet.horizontalCenter = 'middle';
    bullet.verticalCenter = 'middle';
    bullet.href = indicator;
    bullet.zIndex = 5;
    bullet.properties.width = 0;
    bullet.properties.height = 0;
    bullet.events.on('over', (event) => { setDataHovered(chartData[event.target.dataItem._index]); });
    const bulletHover = bullet.states.create('hover');
    bulletHover.properties.width = 50;
    bulletHover.properties.height = 50;
    x.cursor = new am4charts.XYCursor();
    x.cursor.lineX.tooltip.disabled = true;
    x.cursor.tooltip.disabled = true;
    x.cursor.lineX.strokeWidth = 1;
    x.cursor.zIndex = 0;
    x.cursor.lineX.strokeOpacity = 1;
    x.cursor.lineX.stroke = blueColor[100];
    x.cursor.lineX.strokeDasharray = '';
    x.cursor.lineY.disabled = true;
    x.cursor.behavior = 'panX';
    x.mouseWheelBehavior = 'zoomXY';

    chart.current = x;
    return () => {
      x.dispose();
    };
  }, [chartData]);

  return (
    <Block classes={{ root: classes.root }} className={className}>
      { ((chartData && !chartData[0].total) || chartDataLoading)
      && (
      <div className={clsx(classes.tooltipWrapper, { [classes.chartDataIsLoadingWrapper]: chartDataLoading })}>
        { (chartData && !chartData[0].total)
        && (
        <Tooltip
          onClickCloseIcon={() => {
            setTooltipIsOpened(false);
          }}
          className={classes.tooltip}
          classes={{ popper: classes.tooltipPopper, crossIcon: classes.tooltipCrossIcon }}
          opened={tooltipIsOpened}
          // text="После того как вы начнете лить трафик, данный график сообщит Вам сколько денег у Вас было в определенный день: всего на счету, в холде, доступно, выплачено."
          text="After you start sending traffic, this chart will tell you how much money you had on a certain day: total on the account, in hold, available, paid out."
          placement="bottom"
        >
          <div />
        </Tooltip>
        )}
        {chartDataLoading && <Loader />}
      </div>
      )}
      <div>
        <div className={classes.top}>
          <div className={classes.topHead}>
            {/* <ChartTooltip/> */}
            <Typography variant="subtitle">{chartTitle}</Typography>
            <div>
              {enableSelect ? getPeriodString() : description}
            </div>
          </div>
          {enableSelect
          && (
          <Select
            maxElementsNum={1}
            value={selectValue}
            renderValue={() => mapping[selectValue]}
            onChange={(event) => {
              setSelectValue(event.target.value);
            }}
          >
            {options.map((name, index) => (
              <SelectItem key={index} maxkey={name} value={name}>
                <TextWithIcon label={mapping[name]} />
              </SelectItem>

            ))}
          </Select>
          )}
        </div>
        <div className={classes.chartWrapper}>

          <div className={classes.chart} id="chartdiv" />
        </div>
      </div>

    </Block>
  );
}
export default withStyles(styles)(Chart);
