import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import { showToast } from '@pages/oldOffers/utils';

export const START_GET_COUNTER_DATA = 'statistics/START_GET_COUNTER_DATA';
export const FINISH_GET_COUNTER_DATA = 'statistics/FINISH_GET_COUNTER_DATA';

const startGetCounterData = () => ({ type: START_GET_COUNTER_DATA });
const finishGetCounterData = (payload) => ({ type: FINISH_GET_COUNTER_DATA, payload });

export const getCounterData = (body) => async (dispatch) => {
  dispatch(startGetCounterData());
  return fetch(
    `${backendURL}/twirp/statistics.cpa.CpaService/GetTotalStatistics`,
    {
      ...twirpFetchOptions,
      body: JSON.stringify(body),
    },
  )
    .then((res) => res.json())
    .then((res) => dispatch(finishGetCounterData(res)));
};
