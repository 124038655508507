export default function vkRedirectComponent() {
  const tmp = window.location.hash;
  if (tmp.indexOf('access_token') !== -1) {
    const res = tmp.substring(1).split('&');
    const token = res[0].split('=')[1];
    localStorage.setItem('vk_access_token', token);
    window.close();
  } else {
    window.close();
  }
}
