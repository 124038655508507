import React, { useState } from 'react';
import { Typography } from '@aisales/react-ui-kit';
import PhoneVerificationV2 from '@components/PhoneVerificationV2';
import { fetchSecretKey, fetchVerificationId } from '@pages/finance/pages/BalancePage/services/phoneConfirmation';
import {useHistory} from "react-router";

const EntrancePasswordRestorationSendPhoneCodeForm = ({phoneVerificationToken, setPhoneVerificationToken}) => {
  const [phoneConfirmationCode, setPhoneConfirmationCode] = useState('');
  const history = useHistory()
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeError, setCodeError] = useState('');
  const submitPhone = () => {
    const type = 'NUMBER_VERIFICATION';
    fetchVerificationId(phoneNumber, type).then((json) => {
      setPhoneVerificationToken(json.token);
    }).finally(() => {
    });
  };

  const submitCode = () => {
    const type = 'RESET_PASSWORD';
    fetchSecretKey(phoneVerificationToken, phoneConfirmationCode, type).then(() => {
      history.push({
        pathname: window.location.pathname,
        search: '?token=' + phoneVerificationToken+'&type=SMS'
      })
    });
  };
  return (
    <div>
      <div>Введите код, который мы выслали на Ваш номер телефона.</div>
      <PhoneVerificationV2
        phoneConfirmationCode={phoneConfirmationCode}
        setPhoneConfirmationCode={setPhoneConfirmationCode}
        submitPhone={submitPhone}
        submitCode={submitCode}
      />
    </div>
  );
};

export default EntrancePasswordRestorationSendPhoneCodeForm;
