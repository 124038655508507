import SocialNetworkStepper from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper';
import React from 'react';
import FacebookConnector
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/FacebookStepper/components/FacebookConnector';

const FacebookStepper = (props) => (
  <FacebookConnector offerId={props.offer.id}>
    <SocialNetworkStepper {...props} />
  </FacebookConnector>
);

export default FacebookStepper;
