import React from 'react';
import { withStyles } from '@material-ui/styles';

import Chat from './components/Chat';

const Support = ({ classes }) => (
  <>
    <div className={classes.root}>
      <Chat />
    </div>

  </>
);

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#f6f7fd',
    height: '100vh',
    padding: '20px 5% 1em 5%',
  },
  form: {
    width: '30%',
    maxWidth: '500px',
  },
  shadowDropper: {
    height: '100vh',
    boxShadow: '0 0 20px 10px #00000038',
    width: '1px',
    background: '#00000014',
  },
};

export default withStyles(styles)(Support);
