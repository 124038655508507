import {
  greyColor,
  Input, Modal, Select, SelectItem, TextWithIcon, Typography, Checkbox,
  FormControlLabel, Button, AlertContents,
} from '@aisales/react-ui-kit';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { cellsWithDataOptions, counterOptions, groupingSelectOptions } from '@pages/dashboard/constants/modalFormOptions';
import { fieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';
import { useDispatch } from 'react-redux';
import { getCounterData, getStatisticsReports } from '@store/actions';
import { eliminatePercentFields } from '@pages/dashboard/utils';
import { toast } from 'react-toastify';

const renderTagsValue = (value) => groupingSelectOptions.map((item) => item.options).flat(1)?.find((someObj) => JSON.stringify(someObj.value) === JSON.stringify(value))?.label;
const DashboardReportCreationUpdateFormBase = ({
  classes,
  open,
  name, setName,
  onClose,
  counters,
  setCounters,
  groupedCells,
  cellsWithData,
  setGroupedCells,
  setCellsWithData,
  onSave,
  onFinish,
  finishedStateElement,
  modalTitle,
  countersAreEnabled, setCountersAreEnabled,
}) => {
  const [finished, setFinished] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
  }, [cellsWithData]);

  const handleChangeGroupedCellsSelect = (event) => {
    setGroupedCells(event.target.value);
  };

  const handleChangeCellsWithDataSelect = (event) => {
    setCellsWithData(event.target.value);
  };

  const handleChangeCountersSelect = (event) => {
    setCounters(event.target.value);
  };

  const resetState = () => {
    setFinished(false);
    setGroupedCells(groupedCells);
    setCellsWithData(cellsWithData);
    setCounters(counters);
    setCountersAreEnabled(Boolean(counters));
    setName(name);
  };

  const selectDropdownContent = groupingSelectOptions.flatMap(
    (selectPart, index) => [
      <div className={clsx({ [classes?.firstSelectTitle]: index === 0 })}>
        {index !== 0 && <hr className={clsx(classes?.selectHr, classes.greyHr)} />}
        <div className={clsx(classes?.selectTitle)}>
          <Typography variant="detailed">{selectPart.title}</Typography>
        </div>

      </div>,
      ...selectPart.options.map((item) => (
        <SelectItem group={selectPart.title} key={item.value} value={item.value}>
          <TextWithIcon label={item.label} />
        </SelectItem>
      ))],
  );

  useEffect(() => {
  }, [finished]);

  return (
    <Modal title={modalTitle} open={open} onClose={() => { onClose(); resetState(); }}>
      { !finished ? (
        <div>
          <div>
            <div className={classes.title}>
              <Typography variant="subtitle">
                {/* Название отчёта */}
                Report title
              </Typography>
            </div>
            <Input value={name} placeholder="Отчёт №1" className={classes?.input} onChange={(event) => { setName(event.target.value); }} />
          </div>
          <div>
            <div className={classes.title}>
              <Typography variant="subtitle">
                {/* Выберите группирующие ячейки */}
                Select grouping cells
              </Typography>
            </div>
            <div className={classes.description}>
              {/* Для создания таблицы необходимо выбрать хотя бы одну группирующую ячейку. */}
              To create a table, you must select at least one grouping cell.
            </div>

            <Select
              renderTagsValue={renderTagsValue}
              classes={{ inputRoot: classes.input }}
              onChange={handleChangeGroupedCellsSelect}
              value={groupedCells}
              multiple
              draggable
              placeholder="По дням"
              className={classes?.input}
            >
              { selectDropdownContent }
            </Select>
          </div>
          <div>
            <div className={classes.title}>
              <Typography variant="subtitle">
                {/* Выберите ячейки с данными */}
                Select cells with data
              </Typography>
            </div>
            <Select
              maxElementsNum={3}
              renderTagsValue={(value) => fieldTitleMapping[value]?.title}
              classes={{ inputRoot: classes.input }}
              multiple
              value={eliminatePercentFields(cellsWithData)}
              onChange={handleChangeCellsWithDataSelect}
            >
              { Object.entries(fieldTitleMapping).map(([cellWithDataKey, cellsWithDataValue]) => (
                <SelectItem value={cellWithDataKey}>
                  <TextWithIcon label={cellsWithDataValue.title} />
                </SelectItem>
              ))}
            </Select>
          </div>
          <div>
            <hr className={clsx(classes.bottomHr, classes.greyHr)} />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={countersAreEnabled}
                  onChange={(event, checked) => { setCountersAreEnabled(checked); }}
                />
                            )}
              // label="Отображать счётчики"
              label="Show counters"
            />
            <div className={classes.title}>
              <Typography variant="subtitle">
                {/* Какие счётчики отображать */}
                Which counters to display
              </Typography>
            </div>
            <Select
              classes={{ inputRoot: classes.input }}
              disabled={!countersAreEnabled}
              value={counters}
              multiple
              maxElementsNum={4}
              renderTagsValue={(value) => fieldTitleMapping[value].title}
              onChange={handleChangeCountersSelect}
            >
              {counterOptions.map((counterOption) => (
                <SelectItem value={counterOption.value}>
                  <TextWithIcon label={counterOption.label} />
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className={clsx(classes.centralizedHorizontally, classes.buttonWrapper)}>

            <Button onClick={() => {
              onSave({
                name,
                groups: groupedCells.flat(1),
                columns: cellsWithData,
                need_counters: countersAreEnabled,
                counters,
              }).then((res) => {
                dispatch(getStatisticsReports);
                setFinished(true);
                onFinish?.(res.id);
              }).catch(() => {
                toast(<AlertContents severity="error" title="Нельзя оставлять поля пустыми" />);
              });
            }}
            >
              Сохранить
            </Button>

          </div>
        </div>
      ) : React.cloneElement(finishedStateElement, { onClickButton: () => { resetState(); } })}
    </Modal>
  );
};

const styles = {
  input: {
    width: '100%',
  },
  selectTitle: {
    color: greyColor[50],
    marginLeft: '15px',
    marginTop: '15px',
  },

  firstSelectTitle: {
    marginTop: '15px',
  },
  selectHr: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 'calc(100% - 30px)',
  },
  greyHr: {
    border: 'none',
    height: '1px',
    color: greyColor[10],
    backgroundColor: greyColor[10],
  },
  bottomHr: {
    marginTop: '40px',
    marginBottom: '40px',
  },
  title: {
    marginBottom: '20px',
    marginTop: '40px',
  },
  description: {
    marginTop: '-10px',
    marginBottom: '20px',
  },
  buttonWrapper: {
    width: '100%',
    marginTop: '30px',
    '&>button': {
      width: '290px',
    },
  },
  centralizedHorizontally: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default withStyles(styles)(DashboardReportCreationUpdateFormBase);
