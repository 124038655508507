import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Loader } from '@aisales/react-uikit';
import { useHistory } from 'react-router-dom';
import { showToast } from '@components/utils';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { fetchOffers } from '@store/actions';
import {
  BackIcon,
  blueColor,
  Button,
  FilledHelpIcon,
  Tooltip,
  Typography,
} from '@aisales/react-ui-kit';
import OfferHeader from '@pages/offers/components/Offer/components/Header';
import OfferStepper from '@pages/offers/components/Offer/components/Stepper';
import ChannelsLine from '@pages/offers/components/ChannelsLine';
import OfferConnect from '@pages/offers/components/OfferConnect';

function Offer({
  getList,
  loading
}) {
  const temp = window?.location?.pathname?.replace('/app/offers/', '')
    .split('/');
  const pathId = Number(
    temp[temp.findIndex((value) => value === 'all-offers' || value === 'favorites') + 1],
  );
  const history = useHistory();
  const classes = useStyles();
  const [offer, setOffer] = useState(null);
  const [offerInfo, setOfferInfo] = useState(null);
  const id = pathId;
  const isConnectPage = window?.location?.pathname.includes('connect');
  const dispatch = useDispatch();
  const offers = useSelector((state) => state?.offers?.offers);

  useEffect(() => {
    getList();
    dispatch(fetchOffers);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    async function fetchOfferInfo() {
      try {
        // setLoading(true);
        const response = await fetch(
          `${backendURL
          }/twirp/cpa.offers.OffersManagementService/GetOffer`,
          {
            ...twirpFetchOptions,
            body: JSON.stringify({
              id,
            }),
          },
        );

        if (!response.ok) {
          throw new Error(response);
        }

        const json = await response.json();
        const formattedArray = [];
        json.offer.events.forEach((event) => {
          const foundIndex = formattedArray.findIndex((formattedElement) => formattedElement.name === event.name);
          if (foundIndex !== -1) {
            formattedArray[foundIndex].channels.push(event.channel);
            formattedArray[foundIndex].amounts.push(event.amount);
          } else {
            formattedArray.push({
              name: event.name,
              channels: [event.channel],
              amounts: [event.amount],
              currency: event.currency,
              description: event.description,
              holdDays: event.hold_days,
            });
          }
        });
        json.offer.events = formattedArray;
        setOfferInfo(json.offer);
      } catch (e) {
        Sentry.captureException(e.message);
        showToast('Ошибка');
      } finally {
        // setLoading(false);
      }
    }

    fetchOfferInfo();
  }, []);

  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  useEffect(() => {

  }, [offerInfo]);

  const monetizationOptions = [{
    name: 'Cost per Action',
    description: 'Оплата за какое-либо указанное в оффере действие, приведенного человека в диалог. Быстрые деньги, но большие холды. Есть лимиты на выплаты в сутки / неделю.\n'
      + 'Возможность отклонения конверсий. Не всегда быстрые согласования с рекламодателями.',
  }, {
    name: 'Revenue Share',
    description: 'Модель партнерской программы, по которой вебмастер получает процент от принесенной прибыли. Короткие холды. Данная модель будет приносить доход еще минимум несколько месяцев. Никаких лимитов на выплаты. Количество выплат зависит напрямую от вашего качественного трафика.'
  }];
  if (offerInfo) {
    return (
      <div className={classes.offer}>
        <OfferConnect
          offer={offerInfo}
          open={isConnectPage}
          onClose={() => {
            history.push(window?.location?.pathname?.replace('/connect', ''));
          }}
        />
        <div
          className={classes.backButton}
          onClick={() => {
            const temp = window.location.pathname.split('/');
            const temp1 = window.location.pathname.split('/')
              .slice(0, temp.length - 1)
              .join('/');
            history.push(temp1);
          }}
        >
          <BackIcon/>
          {' '}
          <p>назад</p>
        </div>
        <OfferHeader offerInfo={offerInfo} icon={offerInfo.icon}/>
        <div className={classes.content}>
          <div className={classes.leftPart}>
            {/* <Typography variant="subtitle">Выплаты</Typography> */}
            <Typography variant="subtitle">Payouts</Typography>
            <OfferStepper className={classes.offerStepper} offerInfo={offerInfo}/>
            <div className={classes.connectButtonWrapper}>

              {
                isAuthenticated ? (
                  <Button
                    color="primary"
                    onClick={() => history.push(`${window.location.pathname}/connect`)}
                  >
                    {/* Подключить оффер */}
                    Connect offer
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => history.push('/register')}
                  >
                    {/* Зарегистрироваться */}
                    Registration
                  </Button>
                )
              }

            </div>
          </div>
          <div>
            <div>
              <div>
                {/* <Typography variant="subtitle">Описание оффера</Typography> */}
                <Typography variant="subtitle">Offer description</Typography>
              </div>
              <div className={classes.description}>
                {offerInfo.description}
              </div>
            </div>
            <div>
              <div className={classes.subtitle}>
                {/* <Typography variant="subtitle">Варианты монетизации</Typography> */}
                <Typography variant="subtitle">Monetization options</Typography>
              </div>
              {monetizationOptions.map(option => <div className={classes.monetizationOption}>
                <span>{option.name}</span>
                <Tooltip text={option.description}>
                  <div>
                    <FilledHelpIcon
                      className={classes.tooltipIcon}
                      ellipseColor="#CACACA"
                      questionMarkColor="white"
                    />
                  </div>
                </Tooltip>
              </div>)}
            </div>
            <div>
              <div className={classes.title}>
                {/* <Typography variant="subtitle">Доступные мессенджеры</Typography> */}
                <Typography variant="subtitle">Available messengers</Typography>
              </div>
              <ChannelsLine isBig channels={offerInfo.available_channels}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div>{loading && <Loader/>}</div>;
}

const useStyles = makeStyles((theme) => ({
  monetizationOption: {
    display: 'flex',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '20px'
    }
  },
  tooltipIcon: {
    verticalAlign: 'middle',
    marginLeft: '5px',
    cursor: 'pointer',
    height: '14px',
    width: '14px',
    '& > ellipse': {
      transition: 'fill .2s'
    },
    '&:hover > ellipse': {
      fill: '#0486FE'
    }
  },
  subtitle: {
    marginBottom: '10px',
    '& > span': {
      fontSize: '14px'
    }
  },
  offer: {
    padding: '30px',
  },
  scrollablePart: {
    paddingTop: '20px',

    paddingLeft: '30px',
    overflowY: 'auto',
    flex: '1',
    paddingRight: '30px',
  },
  title: {
    marginBottom: '15px',
  },
  content: {
    marginTop: '30px',
    display: 'grid',
  },

  [theme.breakpoints.down('md')]: {
    content: {
      gridTemplateColumns: '7fr 5fr',
    },
  },
  [theme.breakpoints.up('lg')]: {
    content: {
      gridTemplateColumns: '6fr 6fr',
    },
  },
  leftPart: {
    width: '100%',
    paddingRight: '30px',
  },
  radioItem: {
    // position: 'absolute',
    '&&&&&&': {
      width: '100%',
    },
    '&:first-of-type': {
      marginRight: '10px',
    },

  },
  radioItems: {
    display: 'flex',
  },
  radioItemWrapper: {
    position: 'absolute',
  },
  radioItemWrapperWrapper: {
    position: 'relative',
  },
  description: {
    marginTop: '15px',
    overflow: 'scroll',
    whiteSpace: 'pre-line',
    marginBottom: '18px'
  },
  backButton: {
    color: blueColor[100],
    marginBottom: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '10px',
    },
  },
  offerStepper: {
    marginTop: '15px',
  },

  connectButtonWrapper: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
}));

const mapStateToProps = (state) => ({
  offers: state?.offers?.offers || [],
  loading: state?.offers?.loading || false,
});

const mapDispatchToProps = (dispatch) => ({
  getList: () => dispatch(fetchOffers),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
