import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';

export const START_GET_STATISTICS_REPORTS = 'START_FETCH_STATISTICS_REPORTS';
export const FINISH_GET_STATISTICS_REPORTS = 'FINISH_FETCH_STATISTICS_REPORTS';

const startGetStatisticsReports = () => ({
  type: START_GET_STATISTICS_REPORTS,
});

const finishGetStatisticsReports = (payload) => ({
  type: FINISH_GET_STATISTICS_REPORTS,
  payload,
});

export const getStatisticsReports = async (dispatch) => {
  dispatch(startGetStatisticsReports());
  const response = await fetch(
    `${backendURL}/twirp/statistics.cpa.CpaService/GetStatisticsReports`,
    {
      ...twirpFetchOptions,
      alert: false,
    },
  );
  let json = {};
  if (response.ok) {
    json = await response.json();
  }
  dispatch(finishGetStatisticsReports(json));
};
