import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import { showToast } from '@pages/oldOffers/utils';
import fetch from '@main/utils/handledFetch';

export const fetchPlatforms = async (offerID) => {
  try {
    const resp = await fetch(
      `${backendURL
      }/twirp/cpa.offers.OfferSubcriptionsService/GetMany`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({ offer_id: Number(offerID) || 0 }),
      },
    );

    if (!resp.ok) {
      throw new Error('Ошибка загрузки площадок');
    }

    const json = await resp.json();

    return json.subs;
  } catch (err) {
    Sentry.captureException(err);
    showToast(err.message);
  }
};
