import { toast } from 'react-toastify';
import React from 'react';
import { AlertContents } from '@aisales/react-ui-kit';

function getTextByCode(code) {
  switch (code) {
    case 404:
      return 'Данные не найдены';
    case 400:
      return 'Запрос был отправлен некорректно';
    case 409:
      return 'Пользователь не зарегестрирован';
    case 500:
      return 'Внутренняя ошибка сервера. Пожалуйста, обратитесь в поддержку.';
    case 502:
    case 503:
      return 'Сервер временно не отвечает. Пожалуйста, повторите попытку позднее';
    default:
      return null;
  }
}


const showAlert = (() => {
  const visibleMessages = new Set();
  return (msg) => {
    if (visibleMessages.has(msg)) {
      return;
    }
    visibleMessages.add(msg);
    toast(<AlertContents severity="error" title={msg} />);
    setTimeout(() => visibleMessages.delete(msg), 3000);
  };
})();

async function handledFetch(url, options) {
  const {
    alert = true,
    errHandler = null,
  } = options;
  const response = await fetch(url, options);
  if (response.status === 401) {
    localStorage.removeItem('user');
  } else if ((response.status < 200 || response.status >= 300) && alert) {
    if (typeof errHandler !== 'function') {
      showAlert(getTextByCode(response.status) || response.statusText);
    } else {
      // noinspection JSValidateTypes
      errHandler(response);
    }
  }
  return response;
}

export default handledFetch;
