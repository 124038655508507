import SocialNetworkStepper from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper';
import Authentication
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/Authentication';
import Groups from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/Groups';
import React from 'react';

const EmptyStepper = (props) => (
  <SocialNetworkStepper
    authenticationComponent={<Authentication />}
    groupsComponent={<Groups />}
    {...props}
  />
);

export default EmptyStepper;
