"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var Colors = /** @class */ (function () {
    function Colors(m) {
        this._json = {};
        if (m) {
            this._json['background'] = m['background'];
            this._json['text'] = m['text'];
            this._json['links'] = m['links'];
            this._json['name'] = m['name'];
            this._json['time'] = m['time'];
        }
    }
    Object.defineProperty(Colors.prototype, "background", {
        // background (background)
        get: function () {
            return this._json.background;
        },
        set: function (value) {
            this._json.background = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Colors.prototype, "text", {
        // text (text)
        get: function () {
            return this._json.text;
        },
        set: function (value) {
            this._json.text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Colors.prototype, "links", {
        // links (links)
        get: function () {
            return this._json.links;
        },
        set: function (value) {
            this._json.links = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Colors.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Colors.prototype, "time", {
        // time (time)
        get: function () {
            return this._json.time;
        },
        set: function (value) {
            this._json.time = value;
        },
        enumerable: true,
        configurable: true
    });
    Colors.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Colors({
            background: m['background'],
            text: m['text'],
            links: m['links'],
            name: m['name'],
            time: m['time']
        });
    };
    Colors.prototype.toJSON = function () {
        return this._json;
    };
    return Colors;
}());
exports.Colors = Colors;
var BotMessageLayout = /** @class */ (function () {
    function BotMessageLayout(m) {
        this._json = {};
        if (m) {
            this._json['name'] = m['name'];
            this._json['avatar'] = m['avatar'];
            this._json['colors'] = m['colors'];
        }
    }
    Object.defineProperty(BotMessageLayout.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BotMessageLayout.prototype, "avatar", {
        // avatar (avatar)
        get: function () {
            return this._json.avatar;
        },
        set: function (value) {
            this._json.avatar = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BotMessageLayout.prototype, "colors", {
        // colors (colors)
        get: function () {
            return this._json.colors;
        },
        set: function (value) {
            this._json.colors = value;
        },
        enumerable: true,
        configurable: true
    });
    BotMessageLayout.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new BotMessageLayout({
            name: m['name'],
            avatar: m['avatar'],
            colors: Colors.fromJSON(m['colors'])
        });
    };
    BotMessageLayout.prototype.toJSON = function () {
        return this._json;
    };
    return BotMessageLayout;
}());
exports.BotMessageLayout = BotMessageLayout;
var UserMessageLayout = /** @class */ (function () {
    function UserMessageLayout(m) {
        this._json = {};
        if (m) {
            this._json['colors'] = m['colors'];
        }
    }
    Object.defineProperty(UserMessageLayout.prototype, "colors", {
        // colors (colors)
        get: function () {
            return this._json.colors;
        },
        set: function (value) {
            this._json.colors = value;
        },
        enumerable: true,
        configurable: true
    });
    UserMessageLayout.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UserMessageLayout({
            colors: Colors.fromJSON(m['colors'])
        });
    };
    UserMessageLayout.prototype.toJSON = function () {
        return this._json;
    };
    return UserMessageLayout;
}());
exports.UserMessageLayout = UserMessageLayout;
var Layout = /** @class */ (function () {
    function Layout(m) {
        this._json = {};
        if (m) {
            this._json['background'] = m['background'];
            this._json['first_message'] = m['firstMessage'];
            this._json['bot_message'] = m['botMessage'];
            this._json['user_message'] = m['userMessage'];
        }
    }
    Object.defineProperty(Layout.prototype, "background", {
        // background (background)
        get: function () {
            return this._json.background;
        },
        set: function (value) {
            this._json.background = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Layout.prototype, "firstMessage", {
        // firstMessage (first_message)
        get: function () {
            return this._json.first_message;
        },
        set: function (value) {
            this._json.first_message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Layout.prototype, "botMessage", {
        // botMessage (bot_message)
        get: function () {
            return this._json.bot_message;
        },
        set: function (value) {
            this._json.bot_message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Layout.prototype, "userMessage", {
        // userMessage (user_message)
        get: function () {
            return this._json.user_message;
        },
        set: function (value) {
            this._json.user_message = value;
        },
        enumerable: true,
        configurable: true
    });
    Layout.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Layout({
            background: m['background'],
            firstMessage: m['first_message'],
            botMessage: BotMessageLayout.fromJSON(m['bot_message']),
            userMessage: UserMessageLayout.fromJSON(m['user_message'])
        });
    };
    Layout.prototype.toJSON = function () {
        return this._json;
    };
    return Layout;
}());
exports.Layout = Layout;
var Landbot = /** @class */ (function () {
    function Landbot(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['alias'] = m['alias'];
            this._json['layout'] = m['layout'];
            this._json['created_time'] = m['createdTime'];
            this._json['last_update_time'] = m['lastUpdateTime'];
        }
    }
    Object.defineProperty(Landbot.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Landbot.prototype, "alias", {
        // alias (alias)
        get: function () {
            return this._json.alias;
        },
        set: function (value) {
            this._json.alias = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Landbot.prototype, "layout", {
        // layout (layout)
        get: function () {
            return this._json.layout;
        },
        set: function (value) {
            this._json.layout = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Landbot.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Landbot.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Landbot.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Landbot({
            id: m['id'],
            alias: m['alias'],
            layout: Layout.fromJSON(m['layout']),
            createdTime: m['created_time'],
            lastUpdateTime: m['last_update_time']
        });
    };
    Landbot.prototype.toJSON = function () {
        return this._json;
    };
    return Landbot;
}());
exports.Landbot = Landbot;
var LandbotInfo = /** @class */ (function () {
    function LandbotInfo(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
            this._json['name'] = m['name'];
            this._json['alias'] = m['alias'];
            this._json['created_time'] = m['createdTime'];
            this._json['last_update_time'] = m['lastUpdateTime'];
        }
    }
    Object.defineProperty(LandbotInfo.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LandbotInfo.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LandbotInfo.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LandbotInfo.prototype, "alias", {
        // alias (alias)
        get: function () {
            return this._json.alias;
        },
        set: function (value) {
            this._json.alias = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LandbotInfo.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LandbotInfo.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    LandbotInfo.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new LandbotInfo({
            id: m['id'],
            project: m['project'],
            name: m['name'],
            alias: m['alias'],
            createdTime: m['created_time'],
            lastUpdateTime: m['last_update_time']
        });
    };
    LandbotInfo.prototype.toJSON = function () {
        return this._json;
    };
    return LandbotInfo;
}());
exports.LandbotInfo = LandbotInfo;
var LandBotCreateInput = /** @class */ (function () {
    function LandBotCreateInput(m) {
        this._json = {};
        if (m) {
            this._json['alias'] = m['alias'];
            this._json['layout'] = m['layout'];
        }
    }
    Object.defineProperty(LandBotCreateInput.prototype, "alias", {
        // alias (alias)
        get: function () {
            return this._json.alias;
        },
        set: function (value) {
            this._json.alias = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LandBotCreateInput.prototype, "layout", {
        // layout (layout)
        get: function () {
            return this._json.layout;
        },
        set: function (value) {
            this._json.layout = value;
        },
        enumerable: true,
        configurable: true
    });
    LandBotCreateInput.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new LandBotCreateInput({
            alias: m['alias'],
            layout: Layout.fromJSON(m['layout'])
        });
    };
    LandBotCreateInput.prototype.toJSON = function () {
        return this._json;
    };
    return LandBotCreateInput;
}());
exports.LandBotCreateInput = LandBotCreateInput;
var GetOneByAliasRequest = /** @class */ (function () {
    function GetOneByAliasRequest(m) {
        this._json = {};
        if (m) {
            this._json['alias'] = m['alias'];
        }
    }
    Object.defineProperty(GetOneByAliasRequest.prototype, "alias", {
        // alias (alias)
        get: function () {
            return this._json.alias;
        },
        set: function (value) {
            this._json.alias = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneByAliasRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneByAliasRequest({
            alias: m['alias']
        });
    };
    GetOneByAliasRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneByAliasRequest;
}());
exports.GetOneByAliasRequest = GetOneByAliasRequest;
var GetOneByIdRequest = /** @class */ (function () {
    function GetOneByIdRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(GetOneByIdRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneByIdRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneByIdRequest({
            id: m['id']
        });
    };
    GetOneByIdRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneByIdRequest;
}());
exports.GetOneByIdRequest = GetOneByIdRequest;
var GetOneResponse = /** @class */ (function () {
    function GetOneResponse(m) {
        this._json = {};
        if (m) {
            this._json['landbot'] = m['landbot'];
        }
    }
    Object.defineProperty(GetOneResponse.prototype, "landbot", {
        // landbot (landbot)
        get: function () {
            return this._json.landbot;
        },
        set: function (value) {
            this._json.landbot = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneResponse({
            landbot: Landbot.fromJSON(m['landbot'])
        });
    };
    GetOneResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
var CreateRequest = /** @class */ (function () {
    function CreateRequest(m) {
        this._json = {};
        if (m) {
            this._json['input'] = m['input'];
        }
    }
    Object.defineProperty(CreateRequest.prototype, "input", {
        // input (input)
        get: function () {
            return this._json.input;
        },
        set: function (value) {
            this._json.input = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateRequest({
            input: LandBotCreateInput.fromJSON(m['input'])
        });
    };
    CreateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
var CreateResponse = /** @class */ (function () {
    function CreateResponse(m) {
        this._json = {};
        if (m) {
            this._json['landbot'] = m['landbot'];
        }
    }
    Object.defineProperty(CreateResponse.prototype, "landbot", {
        // landbot (landbot)
        get: function () {
            return this._json.landbot;
        },
        set: function (value) {
            this._json.landbot = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateResponse({
            landbot: Landbot.fromJSON(m['landbot'])
        });
    };
    CreateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
var UpdateAliasRequest = /** @class */ (function () {
    function UpdateAliasRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['alias'] = m['alias'];
        }
    }
    Object.defineProperty(UpdateAliasRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateAliasRequest.prototype, "alias", {
        // alias (alias)
        get: function () {
            return this._json.alias;
        },
        set: function (value) {
            this._json.alias = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateAliasRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateAliasRequest({
            id: m['id'],
            alias: m['alias']
        });
    };
    UpdateAliasRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateAliasRequest;
}());
exports.UpdateAliasRequest = UpdateAliasRequest;
var UpdateLayoutRequest = /** @class */ (function () {
    function UpdateLayoutRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['layout'] = m['layout'];
        }
    }
    Object.defineProperty(UpdateLayoutRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateLayoutRequest.prototype, "layout", {
        // layout (layout)
        get: function () {
            return this._json.layout;
        },
        set: function (value) {
            this._json.layout = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateLayoutRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateLayoutRequest({
            id: m['id'],
            layout: Layout.fromJSON(m['layout'])
        });
    };
    UpdateLayoutRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateLayoutRequest;
}());
exports.UpdateLayoutRequest = UpdateLayoutRequest;
var UpdateResponse = /** @class */ (function () {
    function UpdateResponse(m) {
        this._json = {};
        if (m) {
            this._json['landbot'] = m['landbot'];
        }
    }
    Object.defineProperty(UpdateResponse.prototype, "landbot", {
        // landbot (landbot)
        get: function () {
            return this._json.landbot;
        },
        set: function (value) {
            this._json.landbot = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateResponse({
            landbot: Landbot.fromJSON(m['landbot'])
        });
    };
    UpdateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateResponse;
}());
exports.UpdateResponse = UpdateResponse;
var LandbotsServiceSecured = /** @class */ (function () {
    function LandbotsServiceSecured(hostname, fetch) {
        this.path = '/twirp/admin.tools.landbots.LandbotsServiceSecured/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    LandbotsServiceSecured.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    LandbotsServiceSecured.prototype.getOneById = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOneById'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneResponse.fromJSON(m); });
        });
    };
    LandbotsServiceSecured.prototype.updateAlias = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('UpdateAlias'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return UpdateResponse.fromJSON(m); });
        });
    };
    LandbotsServiceSecured.prototype.updateLayout = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('UpdateLayout'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return UpdateResponse.fromJSON(m); });
        });
    };
    return LandbotsServiceSecured;
}());
exports.LandbotsServiceSecured = LandbotsServiceSecured;
var LandbotsServiceUnsecured = /** @class */ (function () {
    function LandbotsServiceUnsecured(hostname, fetch) {
        this.path = '/twirp/admin.tools.landbots.LandbotsServiceUnsecured/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    LandbotsServiceUnsecured.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    LandbotsServiceUnsecured.prototype.getOneByAlias = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOneByAlias'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneResponse.fromJSON(m); });
        });
    };
    return LandbotsServiceUnsecured;
}());
exports.LandbotsServiceUnsecured = LandbotsServiceUnsecured;
