import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import { showToast } from '@pages/oldOffers/utils';

import {
  START_FETCH_PAYMENT_INFO, FETCH_PAYMENT_INFO_FAILURE, FETCH_PAYMENT_INFO_SUCCESS, FETCH_TICKETS_FAILURE, FETCH_TICKETS_SUCCESS, START_FETCH_TICKETS,
} from '@store/actions/financeActions';

export const initialState = {
  isLoadingPaymentInfo: false,
  isLoadingTickets: false,
  selectedPayment: null,
  paymentInfo: [],
  error: false,
  canMakeRequest: true,
  tickets: [],

};

export default function FinanceReducer(state = initialState, { payload, type }) {
  switch (type) {
    case START_FETCH_PAYMENT_INFO:
      return {
        ...state,
        isLoadingPaymentInfo: true,
      };
    case FETCH_PAYMENT_INFO_FAILURE:
      return {
        ...state,
        error: true,
        isLoadingPaymentInfo: false,
      };
    case FETCH_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoadingPaymentInfo: false,
      };
    case START_FETCH_TICKETS:
      return {
        ...state,
        isLoadingTickets: true,
      };
    case FETCH_TICKETS_FAILURE:
      return {
        ...state,
        error: true,
        isLoadingTickets: false,
      };
    case FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoadingTickets: false,
      };
    default:
      return state;
  }
}
