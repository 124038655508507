import { combineReducers } from 'redux';

import login from './loginReducer';
import layout from './layoutReducer';
import offers from './offersReducer';
import splitview from './splitViewReducer';
import tickets from './ticketReducer';
import noob from './noobReducer';
import profile from './profileReducer';
import balance from './balanceReducer';
import finance from './financeReducer';
import reports from './reportsReducer';
import statistics from './statisticsReducer';
import banners from './bannersReducer'

export * from './loginReducer';

export default combineReducers({
  layout,
  login,
  offers,
  splitview,
  tickets,
  noob,
  profile,
  balance,
  finance,
  reports,
  statistics,
  banners
});
