import React, { useEffect, useState } from 'react';
import DashboardFilterModalFormRightPartListItem
  from '@pages/dashboard/components/FilterModal/components/RightPartList/components/Item';

const DashboardFilterModalFormRightPartList = ({
  classes, filterValues, onReset, onDeleteValue, mappings, onChangeInput, filterInputValues,
}) => {
  const [allTagsLabels, setAllTagsLabels] = useState([]);
  useEffect(() => {
    setAllTagsLabels(getAllTagsLabels());
  }, [filterValues, mappings, filterInputValues]);
  const getTagsLabelsByCategory = (category) => filterValues[category].map((filterValue) => mappings[category].options[filterValue]);
  const getAllTagsLabels = () => {
    const labels = [];
    for (const [field, values] of Object.entries(filterValues)) {
      for (const value of values) {
        if (mappings[field].inputs) {
          labels.push(`${value}=${filterInputValues[value]}`);
        } else {
          labels.push(mappings[field].options[value]);
        }
      }
    }
    return labels;
  };

  return (
    <div>
      {Object.entries(filterValues)
        .filter(([key, filterValue]) => filterValue.length).map(([key, filterValue]) => (
          <DashboardFilterModalFormRightPartListItem
            elements={getTagsLabelsByCategory(key)}
            title={mappings[key].label}
            onReset={() => onReset(key)}
            onDelete={(value) => { onDeleteValue(key, value); }}
            inputsValues={filterInputValues}
            inputs={mappings[key].inputs}
            correspondingInputNames={filterValue}
            onChangeInput={(inputName, value) => { onChangeInput(inputName, value); }}
          />
        ))}
      {/* <DashboardFilterModalFormRightPartListItem */}
      {/*  elements={allTagsLabels} */}
      {/*  title="Выбранные фильтры" */}
      {/*  disabled */}
      {/*  notUnderlined */}
      {/* /> */}
    </div>
  );
};

export default DashboardFilterModalFormRightPartList;
