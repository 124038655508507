import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import {
  blueColor, DropdownIcon, FilledHelpIcon, greyColor, LockIcon, Tooltip, Typography,
} from '@aisales/react-ui-kit';
import clsx from 'clsx';
import Block from '@components/Block/Block';
import GreyHr from '@pages/finance/components/GreyHr';
import { paymentMapping } from '@pages/finance/constants/paymentMapping';

const styles = () => ({
  root: {
    width: '100%',
    boxShadow: '0px 4px 10px rgba(4, 134, 254, 0.2)',
    borderRadius: '5px',
    background: 'white',
  },
  topText: {},
  bottomText: {
    marginTop: '20px',
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  description: {},
  numberText: {
    marginTop: '20px',
  },
  image: {
    position: 'absolute', right: '0px',
  },
  icon: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    top: '17px',
    right: '20px',
    cursor: 'pointer',
    zIndex: '1',
  },
  topPart: {
    borderRadius: '5px',
    padding: '16px 20px',
    position: 'relative',
    width: '100%',
  },
  bottomPart: {
    width: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '5px',
    paddingBottom: '0px',
    cursor: 'pointer',
  },
  payment: {
    marginLeft: '20px',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  noTickets: {
    padding: '20px',
  },

});

const paymentStyle = () => ({
  root: {
    display: 'flex',
    paddingTop: '20px',
    paddingBottom: '20px',
    flexDirection: 'column',
    '& > div:first-of-type': {
      marginBottom: '10px',
    },
  },
  icon: {
    marginRight: '10px',
  },

});

const Payment = withStyles(paymentStyle)(({
  className, classes, number, source,
}) => (
  <div className={clsx(classes.root, className)}>
    <div>
      <LockIcon className={classes.icon} />
      <Typography variant="subtitle">{number}</Typography>
    </div>
    <div>{source}</div>

  </div>
));
const InformationBlock = ({
  bottomText,
  className,
  classes,
  description,
  image,
  numberText,
  paymentInfo,
  tickets,
  tooltipStyle,
  topText,
}) => {
  const [tooltipIsOpened, setTooltipIsOpened] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const {
    openedEllipseColor = blueColor[100],
    openedQuestionMarkColor = 'white',
    ellipseColor = greyColor['25'],
    questionMarkColor = 'white',
  } = tooltipStyle || {};

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.topPart)}>
        <FilledHelpIcon
          className={classes.icon}
          ellipseColor={tooltipIsOpened ? openedEllipseColor : ellipseColor}
          questionMarkColor={tooltipIsOpened ? openedQuestionMarkColor : questionMarkColor}
        />

        <Tooltip
          onClose={() => {
            setTooltipIsOpened(false);
          }}
          text={description}
        >
          <div
            onClick={() => {
              setTooltipIsOpened(true);
            }}
            className={classes.icon}
          >
            <FilledHelpIcon />
          </div>
        </Tooltip>
        <div className={classes.image}>{image}</div>
        <div className={classes.topText}>
          {topText}
        </div>
        <div className={classes.numberText}>
          <Typography variant="title">{numberText}</Typography>
        </div>
        {bottomText
            && (
            <div>
              <div
                className={classes.bottomText}
                onClick={() => {
                  setIsOpened(!isOpened);
                }}
              >
                {bottomText}
                {' '}
                <DropdownIcon className={isOpened ? classes.rotated : undefined} />
              </div>
            </div>
            )}
      </div>
      {isOpened
        && (
        <Block className={classes.bottomPart}>
          {tickets.length !== 0 ? tickets.filter((ticket) => ticket.status === 2 || ticket.status === 1)?.map((ticket) => (
            <div>
              <Payment
                number={ticket.amount}
                source={paymentMapping[paymentInfo.find((infoPiece) => infoPiece?.id === ticket?.info_id)?.requisites_type]?.name}
                className={classes.payment}
              />
              <GreyHr />
            </div>
          )) : <div className={classes.noTickets}>На данный момент у Вас не заказаны выплаты.</div>}

        </Block>
        )}
    </div>
  );
};

export default withStyles(styles)(InformationBlock);
