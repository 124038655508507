import { withStyles } from '@material-ui/core';
import React from 'react';
import { Button, Typography } from '@aisales/react-ui-kit';
import { useHistory } from 'react-router';

const style = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    marginTop: '5vh',
    marginBottom: '60px',
  },
  title: {
  },
  text: {
    maxWidth: '550px',
    marginTop: '10px',
    textAlign: 'center',
  },
  button: {
    marginTop: '30px',
  },
});
const EmptyPage = ({
  image, title, text, classes, buttonText, link,
}) => {
  const history = useHistory();
  return (
    <div className={classes.root}>
      {React.cloneElement(image, { className: classes.image })}
      <div className={classes.title}>
        <Typography variant="title">{title}</Typography>
      </div>
      <div className={classes.text}>
        {text}
      </div>
      {buttonText && <Button className={classes.button} onClick={() => { history.push(link); }}>{buttonText}</Button>}
    </div>
  );
};
export default withStyles(style)(EmptyPage);
