import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import reducers from './reducers';
import Landbot from './components/Landbot';

const isProduction = process.env.NODE_ENV === 'production';
// Creating store
let store = null;
const middleware = applyMiddleware(thunk);

if (isProduction) {
  store = createStore(reducers, middleware);
} else {
  const { composeWithDevTools } = require('redux-devtools-extension');
  const enhancer = composeWithDevTools(
    middleware,
  );

  store = createStore(reducers, enhancer);
}

const Tools = () => (
  <Provider store={store}>
    <Switch>
      <Route path="/app/channels/landbot/" component={Landbot} />
      {/* <Route exact path={pathPrefix + '/'} component={ToolsList}/> */}
    </Switch>
  </Provider>
);

export default Tools;
