import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import arrow from '@pages/profile/components/Blocks/assets/arrow.png';
import { Typography } from '@material-ui/core';
import { Multiselect } from 'multiselect-react-dropdown';

export function SelectInput({
  arrowDown, className, disabled, label, onChange, options, placeholder, singleSelect, style, value, ...rest
}) {
  const formatSelectedValues = (backendValues) => {
    let arrayBackendValues;
    if (!Array.isArray(backendValues)) {
      arrayBackendValues = [backendValues];
    } else {
      arrayBackendValues = backendValues;
    }
    let result = arrayBackendValues.map((backendValue) => options.find((element) => element.id === backendValue));
    result = result.filter((el) => !!el);
    result = result.length !== 0 ? result : undefined;

    return result;
  };
  const classes = useStyles({ disabled, singleSelect, value: !value ? undefined : formatSelectedValues(value) });
  // const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={classNames(classes.selectInput, className)}>
      <Typography className={classes.label}>{label}</Typography>
      <div>
        <Multiselect
          selectedValues={!value ? undefined : formatSelectedValues(value)}
          closeOnSelect={singleSelect}
          singleSelect={singleSelect}
          disable={disabled}
          placeholder={placeholder}
          hidePlaceholder
          onSelect={(values) => {
            onChange(values.map((value) => value.id));
          }}
          options={options} // Options to display in the dropdown
          style={{
            multiselectContainer: { // To change css for multiselect (Width,height,etc..)
              backgroundColor: 'pink',
              '-webkit-appearance': 'none',
              background: singleSelect ? `url("${arrow}") no-repeat 96%` : 'none',
              '&:focus': {
                outline: 'none',
              },
              ...style,
            },
            chips: {
              background: '#2cabce',
              color: 'white',
            },
            inputField: {
              width: '150px',
            },
          }}
          showCheckbox
          displayValue="name"
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => (
  {
    selectInput: ((props) => ({
      display: 'flex',
      flexDirection: 'column',
      '& i': {
        display: 'none',
      },
      '& ._2iA8p44d0WZ-WqRBGcAuEV': {
        ...((!props.value || props.singleSelect) ? {
          height: '40px',
        } : { height: 'auto' }),
        minHeight: '40px',
      },

    })),
    label: (props) => ({
      fontSize: '12px',
      lineHeight: '15px',
      fontWeight: 'normal',
      color: props.error ? 'red' : 'rgba(0, 0, 0, 0.67)',
      marginLeft: '2px',
      marginBottom: '6px',
    }),
    inputRoot: (props) => ({
      paddingLeft: '1em',
      width: '100%',
      height: '40px',
      outlineColor: 'rgb(180, 180, 180)',
      background: '#FFFFFF',
      border: props.error ? '1px solid #ff9900' : '1px solid #E6E6E6',
      boxSizing: 'border-box',
      borderRadius: '5px',
      fontWeight: props.disabled ? 'bold' : 'normal',
    }),
  }));

export default SelectInput;
