import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button, GrayCircle, Panel, Polyline,
} from '@aisales/react-uikit';
import * as Sentry from '@sentry/react';

import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import PhoneVerification from '@components/PhoneVerification';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '@store/actions';
import InputField from './InputField';
import { personRe, phoneRe, requiredText } from './utils';
import SelectField from './SelectField';

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),
  phoneNumber: Yup.string()
    .test('phone', 'Номер телефона невалидный', (value) => phoneRe(value))
    .required(requiredText),

  // equareType: "",
  cardNumber: Yup.string()
    .when('equareType', {
      is: 'CARD',
      then: (fieldSchema) => fieldSchema.matches(
        /^(\d{4}[ ]){3}\d{4}|\d{16}$/,
        'Неправильный номер карты',
      ),
    })
    .when('equareType', {
      is: 'YANDEX',
      then: (fieldSchema) => fieldSchema.matches(
        /^(\d{4}[ ]){3}\d{4}|\d{16}$/,
        'Неправильный номер счета',
      ),
    })
    .when('equareType', {
      is: 'RAPIDA',
      then: Yup.string().test(
        'rapida',
        'Неправильный номер счета',
        (value) => {
          if (!value || value.match(/\D/)) {
            return false;
          }

          return value.split(' ').join('').length === 10;
        },
      ),
    })
    .required(requiredText),
  // cardDate: "",
  // currency: "RUB",
});

export default function IndividualForm() {
  const formValues = useSelector((state) => state.profile.formValues);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);
  const classes = useStyles();
  const history = useHistory();
  const [validationToken, setValidationToken] = useState(null);

  const handleSubmit = async (values, actions) => {
    if (!validationToken) {
      return;
    }

    try {
      const cardNumber = values.cardNumber.split(' ').join('');
      let account = {};

      switch (values.equareType) {
        case 'CARD':
          account = {
            name: 'Card',
            type: 'CARD',
            currency: values.currency,
            card: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        case 'YANDEX':
          account = {
            name: 'Yandex Money',
            type: 'YM',
            currency: values.currency,
            ym: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        case 'WEBMONEY':
          account = {
            name: 'Webmoney',
            type: 'WEBMONEY',
            currency: values.currency,
            webmoney: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        case 'RAPIDA':
          account = {
            name: 'Rapida',
            type: 'RAPIDA',
            currency: values.currency,
            rapida: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        default:
          account = {
            name: values.equareType,
            type: values.equareType,
            currency: values.currency,
            card: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
      }

      const response = await fetch(
        `${backendURL}/twirp/cpa.users.BillingInfoService/Create`,
        {
          ...twirpFetchOptions,
          headers: {
            ...twirpFetchOptions?.headers,
            auth: validationToken,
          },
          body: JSON.stringify({
            name: 'Физическое лицо',
            recipient_type: 'INDIVIDUAL',
            individual: {
              general: {
                full_name: values.fullName,
                phone: values.phoneNumber,
              },
              account,
            },
          }),
        },
      );

      if (response.status === 500) {
        showToast('Ошибка сервера');
      } else if (response.status === 400) {
        showToast('Ошибка в данных');
      } else {
        showToast('Платежный метод успешно добавлен', true);
        history.push('/app/profile');
      }
    } catch (e) {
      showToast('Неизвестная ошибка');
      Sentry.captureException(e);
    }
  };

  const cardLabel = (value) => {
    switch (value) {
      case 'CARD':
        return 'Номер карты';
      case 'YANDEX':
        return 'Номер кошелька';
      case 'WEBMONEY':
        return 'Номер кошелька';
      case 'RAPIDA':
        return 'Номер счета';
      default:
        return '';
    }
  };

  return (
    <Formik
      initialValues={{
        fullName: '',
        phoneNumber: '',

        equareType: '',
        cardNumber: '',
        cardDate: '',
        currency: 'RUB',
      }}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className={classes.padding}>
          <Panel
            width="372px"
            height="48px"
            style={{
              marginBottom: '37px',
              position: 'relative',
            }}
          >
            <Polyline className={classes.polyline} />
            <GrayCircle
              width="11px"
              height="11px"
              className={classes.grayCircle}
            />
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Общая информация о физическом лице
            </Typography>
          </Panel>
          <Panel
            width="184px"
            height="22px"
            type="primary"
            style={{ marginBottom: '22px' }}
          >
            <Typography className={classes.primaryBadgeText}>
              Основная информация
            </Typography>
          </Panel>
          <div className={classes.formWrapper}>
            <div className={classes.leftInputs}>
              <InputField
                name="fullName"
                label="Полное ФИО владельца счета"
              />
              {/* <InputField
                                name="fullNameLatin"
                                label="Полное ФИО владельца счета на латинице"
                            /> */}
              {/* <InputField
                                name="bankName"
                                label="Название банка"
                            /> */}
            </div>
            <div className={classes.centerInputs}>
              <InputField
                name="phoneNumber"
                label="Номер телефона"
              />
              {/* <InputField name="bankBik" label="БИК банка" />
                            <InputField
                                name="checkingAccount"
                                label="Расчетный счет"
                            /> */}
            </div>
          </div>
          {/* <Badge
                        width="184px"
                        height="22px"
                        type="primary"
                        style={{ marginBottom: "22px" }}
                    >
                        <Typography className={classes.primaryBadgeText}>
                            Документ
                        </Typography>
                    </Badge>
                    <div className={classes.formWrapper}>
                        <div className={classes.leftInputs}>
                            <InputField name="country" label="Страна" />
                            <InputField
                                name="passportSerialNumber"
                                label="Серия паспорта"
                            />
                            <InputField
                                name="passportNumber"
                                label="Номер паспорта"
                            />
                        </div>
                        <div className={classes.centerInputs}>
                            <InputField name="issuedBy" label="Кем выдан" />
                            <InputField name="issuedDate" label="Дата выдачи" />
                        </div>
                    </div> */}
          <Panel
            width="250px"
            height="48px"
            style={{
              marginBottom: '35px',
              position: 'relative',
            }}
          >
            <Polyline className={classes.polyline} />
            <GrayCircle
              width="11px"
              height="11px"
              className={classes.grayCircle}
            />
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Счет физического лица
            </Typography>
          </Panel>
        </div>
        <div
          className={classNames(classes.padding, classes.white)}
          style={{
            marginBottom: '32px',
            height: '107px',
          }}
        >
          <SelectField
            name="equareType"
            label="Выберите экваера"
            options={[
              { label: 'Банковская карта', value: 'CARD' },
              { label: 'Yandex Money', value: 'YANDEX' },
              // { label: "Webmoney", value: "WEBMONEY" },
              { label: 'Rapida', value: 'RAPIDA' },
            ]}
            style={{
              paddingTop: '17px',
              paddingBottom: '29px',
              width: '284px',
              height: '40px',
            }}
          />
        </div>
        <div className={classes.padding}>
          <div className={classes.formWrapper}>
            {/* <div className={classes.leftInputs}>
                            <Badge
                                width="184px"
                                height="22px"
                                type="primary"
                                style={{ padding: "0px", marginBottom: "36px" }}
                            >
                                <Typography
                                    className={classes.primaryBadgeText}
                                >
                                    Основная информация
                                </Typography>
                            </Badge>
                            <InputField name="schet" label="Название счета" />
                            <SelectField
                                name="schetType"
                                label="Тип счета"
                                options={[
                                    { label: "Тип счета 1", value: "1" },
                                    { label: "Тип счета 2", value: "2" },
                                ]}
                            />
                            <SelectField
                                name="currency"
                                label="Валюта"
                                options={[
                                    { label: "Валюта 1", value: "1" },
                                    { label: "Валюта 2", value: "2" },
                                ]}
                            />
                        </div> */}
            <div className={classes.leftInputs}>
              <Panel
                width="152px"
                height="22px"
                type="primary"
                style={{
                  padding: '0px',
                  // marginLeft: "45px",
                  marginBottom: '36px',
                }}
              >
                <Typography
                  className={classes.primaryBadgeText}
                >
                  Реквизиты счета
                </Typography>
              </Panel>
              <Field name="equareType">
                {({ field }) => (
                  <>
                    {(field.value === 'CARD'
                          || field.value === 'YANDEX') && (
                          <InputField
                            name="cardNumber"
                            label={cardLabel(field.value)}
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                          />
                    )}
                    {field.value === 'RAPIDA' && (
                      <InputField
                        name="cardNumber"
                        label={cardLabel(field.value)}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className={classes.centerInputs} />
          </div>
        </div>
        <div className={classes.padding} style={{ marginBottom: 30 }}>
          <PhoneVerification
            onSuccess={(token) => setValidationToken(token)}
            type="WITHDRAWAL_TICKET_CREATION"
            style={{ marginBottom: 30 }}
            field={{ field: { value: formValues.phoneNumber } }}
            disabled={false}
            editingDisabled
          />
          <Panel
            width="550px"
            height="150px"
            style={{
              marginBottom: '50px',
              position: 'relative',
            }}
            type="secondary"
          >
            <Polyline
              className={classes.polyline}
              style={{ top: '-783px' }}
            />
            <GrayCircle
              width="11px"
              height="11px"
              className={classes.grayCircle}
              style={{ top: '11px' }}
            />
            <Typography
              style={{
                fontSize: '13px',
                lineHeight: '16px',
                textAlign: 'left',
              }}
            >
              Нажимая "сохранить", вы подтверждаете, что введенные
              {' '}
              <b>данные верны</b>
              .
              <br />
              <br />
              Пожалуйста, учитывайте, что прямой проверки
              корректности вводимых вами данных на данный момент
              нет. Будьте бдительны при вводе.
              {' '}
              <br />
              <br />
              С уважением, Команда Aisales.
            </Typography>
          </Panel>
        </div>
        <div className={classes.padding}>
          <Button
            type="submit"
            variant="contained"
            style={{ marginRight: '37px' }}
          >
            Сохранить
          </Button>
          <Button
            type="reset"
            variant="outlined"
            onClick={() => history.push('/app/profile')}
          >
            Отмена
          </Button>
        </div>
      </Form>
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  grayCircle: {
    position: 'absolute',
    left: '-6px',
    top: '17px',
  },
  greenCircle: {
    position: 'absolute',
    right: '-5px',
    top: '20px',
  },
  polyline: {
    top: '-778px',
    left: '-345px',
  },
  line: {
    width: '32px',
    top: '23px',
    right: '-32px',
  },
  rightInfo: {
    position: 'absolute',
    display: 'flex',
    top: '14px',
    right: '-153px',
    alignItems: 'center',
  },
  padding: {
    padding: '0 7% 0 calc(7% + 84px)',
  },
  white: {
    background: 'white',
  },
  primaryBadgeText: {
    fontSize: '11px',
    lineHeight: '13px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.67)',
    whiteSpace: 'nowrap',
    padding: '0',
  },
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '56px',
    width: '73%',
  },
  leftInputs: {
    flex: '1 0 31%',
    '& > div': {
      marginRight: '40px',
      paddingBottom: '13px',
    },
  },
  centerInputs: {
    flex: '1 0 37%',
    '& > div': {
      padding: '0 18px 13px 45px',
      borderLeft: '1px solid #E6E6E6',
    },
  },
}));
