import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlatforms } from '@pages/services/fetchPlatforms';
import { fetchBanners, fetchOffers, setBanner } from '@store/actions';
import getOfferName from '@pages/channels/utils/getOfferName';
import PageTemplate from '@components/PageTemplate';
import ChannelsPageFilterBlock from '@pages/channels/components/FilterBlock';
import ChannelsTable from '@pages/channels/components/Table';
import Message from '@pages/finance/pages/BalancePage/components/Message';
import { withStyles } from '@material-ui/core';
import Loader from '@components/Loader';
import { ReactComponent as MessageIllustration } from './assets/MessageIllustration.svg';

const ChannelsPage = ({ classes }) => {
  const offers = useSelector((state) => state?.offers?.offers);
  const banners = useSelector((state) => state?.banners?.banners);
  const bannersAreLoading = useSelector((state) => state?.banners?.bannersAreLoading);
  const [channels, setChannels] = useState(null);
  const dispatch = useDispatch();
  const [filterSearchInput, setFilterSearchInput] = useState('');
  const [filterOfferInput, setFilterOfferInput] = useState([]);
  const [filterPlatformInput, setFilterPlatformInput] = useState([]);
  const [messageIsShown, setMessageIsShown] = useState(true);
  useEffect(() => {
    dispatch(fetchOffers);
    dispatch(fetchBanners());
  }, []);

  useEffect(() => {
    setMessageIsShown(Boolean(banners.channels_hello));
  }, [banners]);
  useEffect(() => {
    if (offers) {
      const promises = [];
      for (const offer of offers) {
        const promise = fetchPlatforms(offer.id);
        promises.push(promise);
      }
      Promise.all(promises)
        .then((results) => setChannels(results.flat(1)
          .map((channel) => ({
            ...channel,
            offerName: getOfferName(channel.offer_id, offers),
            clickPerLink: channel.click_per_link,
          }))));
    }
  }, [offers]);

  useEffect(() => {
  }, [channels]);
  return (
    !bannersAreLoading ? (
      // <PageTemplate title="Каналы" classes={{ tabpanel: classes.pageTemplate__tabPanel }}>
      <PageTemplate title="Channels" classes={{ tabpanel: classes.pageTemplate__tabPanel }}>
        {messageIsShown && (
        <Message
          className={classes.channelsPage__message}
          // upperText="Каналы"
          upperText="Channels"
          classes={{ root: classes.messageRoot }}
          illustration={<MessageIllustration />}
          // bottomText="На данной странице Вы можете посмотреть сроки,порядок, комиссии по выплатам, и подобрать для себя лучший вариант."
          bottomText="On this page you can see the terms, order, commissions for payments, and choose the best option for yourself."
          onClose={() => {
            setMessageIsShown(false);
            dispatch(setBanner({ key: 'channels_hello', value: 0 }));
          }}
        />
        )}

        { !channels ? <div className={classes.loaderWrapper}><Loader /></div>
          : (
            <div>
              <ChannelsPageFilterBlock
                channels={channels}
                offers={offers}
                onChangePlatformIds={(value) => { setFilterPlatformInput(value); }}
                onChangeOfferIds={(value) => { setFilterOfferInput(value); }}
                onChangeSearchInputs={(value) => { setFilterSearchInput(value); }}
                platformIds={filterPlatformInput}
                offerIds={filterOfferInput}
                searchInputs={filterSearchInput}
              />
              <ChannelsTable
                channels={channels}
                filterPlatformInput={filterPlatformInput}
                filterOfferInput={filterOfferInput}
                filterSearchInput={filterSearchInput}
              />
            </div>

          )}
      </PageTemplate>
    ) : <div className={classes.channelsPage__loaderWrapper}><Loader size="large" /></div>
  );
};

const styles = () => ({

  pageTemplate__tabPanel: {
    padding: '30px',
  },
  channelsPage__message: {
    height: '120px',
    marginBottom: '30px',
  },
  channelsPage__loaderWrapper: {
    paddingTop: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderWrapper: {
    textAlign: 'center',
    paddingTop: '100px',
  },
});

export default withStyles(styles)(ChannelsPage);
