import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import {
  FilledHelpIcon, greyColor, TableCell, Tooltip,
  FilterIcon, TableHead, CheckboxSelect, RadioSelect,
  blueColor,
} from '@aisales/react-ui-kit';
import clsx from 'clsx';

const SortableTooltipHeadCell = withStyles({
  centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
  },
  sortableTooltipHeadCell__filterIconWrapper: {
    display: 'none',
    '& svg': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: blueColor[20],
    },
    width: '30px',
  },
  // sortableTooltipHeadCell__filterIconWrapper_menuIsOpen: {
  //   borderRight: `1px solid ${greyColor[25]}`,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   '& svg': {
  //     visibility: 'visible',
  //   },
  // },
  sortable: {

  },
  root_menuIsOpen: {
    '&&&&&&&&&&': {
      padding: '0px',
    },

    borderRight: `1px solid ${greyColor[25]}`,
    borderTop: `1px solid ${greyColor[25]}`,
    borderLeft: `1px solid ${greyColor[25]}`,
    backgroundColor: blueColor[10],
    '& $sortableTooltipHeadCell__filterIconWrapper': {
      backgroundColor: blueColor[30],
      borderRight: `1px solid ${greyColor[25]}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        visibility: 'visible',
        color: blueColor[100],
      },
    },
  },
  '@keyframes example': {
    '0%': { },
    '50%': { backgroundColor: blueColor[30] },
    '100%': { backgroundColor: blueColor[10] },
  },
  root_withStartAdornment: {
    '&&&&:not(:hover)': {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '1px',
      paddingBottom: '1px',
    },
  },
  root_hoverable: {
    '&&&:not(:hover)': {
      padding: '1px',
    },

    '&&&:hover': {
      borderRight: `1px solid ${greyColor[25]}`,
      borderTop: `1px solid ${greyColor[25]}`,
      borderLeft: `1px solid ${greyColor[25]}`,
      backgroundColor: blueColor[10],
      '& $sortableTooltipHeadCell__filterIconWrapper': {
        borderRight: `1px solid ${greyColor[25]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          visibility: 'visible',
        },
      },
    },
  },
  root: {
    boxSizing: 'border-box',
    '&[animation=\'1\']': {

      '& $sortable': {
        animationName: '$example',
        animationDuration: '0.25s',
      },
    },

    padding: '0px',
    '& div': {
      height: '60px',
    },

  },
  centralizedHorizontally: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableHead__internalContents: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
  tableHead__internalContents__headerTitle: {
    marginRight: '5px',
  },
})(React.forwardRef((props, ref) => {
  const {
    header,
    classes,
    description,
    onChangeRepresentation,
    radioItems,
    hoverable,
    representation,
    ...otherProps
  } = props;
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [animation, setAnimation] = useState(0);
  return (
    <TableCell
      ref={ref}
      onClickSortableCell={() => { setAnimation(1); }}
      onAnimationEnd={() => {
        setAnimation(0);
      }}
      animation={animation}
      align="center"
      classes={{
        root: clsx({ [classes.root_menuIsOpen]: menuIsOpen },
          classes.root, { [classes.root_withStartAdornment]: radioItems }, { [classes.root_hoverable]: hoverable }),
        centralizedHorizontally: classes.centralizedHorizontally,
        sortable: classes.sortable,

      }}
      startAdornment={radioItems && (
      <RadioSelect
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={() => { setMenuIsOpen(false); }}
        title={header}
        radioItems={radioItems}
        value={representation}
        onChange={(value) => { onChangeRepresentation(value); setMenuIsOpen(false); }}
        menuIsOpen={menuIsOpen}
        anchorElement={<div className={clsx(classes.sortableTooltipHeadCell__filterIconWrapper, { [classes.sortableTooltipHeadCell__filterIconWrapper_menuIsOpen]: menuIsOpen })} onClick={() => { setMenuIsOpen(!menuIsOpen); }}><FilterIcon /></div>}
      />
      )}
      {...otherProps}
    >
      <div className={clsx(classes.centralizedVertically,
        classes.header,
        classes.centralizedHorizontally)}
      >
        <div>
          <Tooltip text={description} opened={tooltipIsOpen} onClose={() => { setTooltipIsOpen(false); }}>
            <div className={classes.tableHead__internalContents}>
              <div className={clsx({ [classes.tableHead__internalContents__headerTitle]: description })}>
                {header}
              </div>
              {description && <FilledHelpIcon questionMarkColor="white" ellipseColor={greyColor[25]} onClick={(event) => { setTooltipIsOpen(true); event.stopPropagation(); }} />}
            </div>
          </Tooltip>
        </div>
      </div>
    </TableCell>
  );
}));

export default SortableTooltipHeadCell;
