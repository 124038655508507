"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var GetOneResponse_ActiveBlocksEntry = /** @class */ (function () {
    function GetOneResponse_ActiveBlocksEntry(m) {
        this._json = {};
        if (m) {
            this._json['key'] = m['key'];
            this._json['value'] = m['value'];
        }
    }
    Object.defineProperty(GetOneResponse_ActiveBlocksEntry.prototype, "key", {
        // key (key)
        get: function () {
            return this._json.key;
        },
        set: function (value) {
            this._json.key = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse_ActiveBlocksEntry.prototype, "value", {
        // value (value)
        get: function () {
            return this._json.value;
        },
        set: function (value) {
            this._json.value = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneResponse_ActiveBlocksEntry.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneResponse_ActiveBlocksEntry({
            key: m['key'],
            value: m['value']
        });
    };
    GetOneResponse_ActiveBlocksEntry.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneResponse_ActiveBlocksEntry;
}());
exports.GetOneResponse_ActiveBlocksEntry = GetOneResponse_ActiveBlocksEntry;
var GetOneResponse = /** @class */ (function () {
    function GetOneResponse(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['time'] = m['time'];
            this._json['created_time'] = m['createdTime'];
            this._json['end_time'] = m['endTime'];
            this._json['last_update_time'] = m['lastUpdateTime'];
            this._json['pending'] = m['pending'];
            this._json['frontend_view'] = m['frontendView'];
            this._json['active_blocks'] = m['activeBlocks'];
            this._json['statistics'] = m['statistics'];
        }
    }
    Object.defineProperty(GetOneResponse.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "time", {
        // time (time)
        get: function () {
            return this._json.time;
        },
        set: function (value) {
            this._json.time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "endTime", {
        // endTime (end_time)
        get: function () {
            return this._json.end_time;
        },
        set: function (value) {
            this._json.end_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "pending", {
        // pending (pending)
        get: function () {
            return this._json.pending;
        },
        set: function (value) {
            this._json.pending = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "frontendView", {
        // frontendView (frontend_view)
        get: function () {
            return this._json.frontend_view;
        },
        set: function (value) {
            this._json.frontend_view = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "activeBlocks", {
        // activeBlocks (active_blocks)
        get: function () {
            return this._json.active_blocks || [];
        },
        set: function (value) {
            this._json.active_blocks = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "statistics", {
        // statistics (statistics)
        get: function () {
            return this._json.statistics || [];
        },
        set: function (value) {
            this._json.statistics = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneResponse({
            id: m['id'],
            name: m['name'],
            project: m['project'],
            time: m['time'],
            createdTime: m['created_time'],
            endTime: m['end_time'],
            lastUpdateTime: m['last_update_time'],
            pending: m['pending'],
            frontendView: m['frontend_view'],
            activeBlocks: (m['active_blocks'] || []).map(function (v) { return GetOneResponse_ActiveBlocksEntry.fromJSON(v); }),
            statistics: (m['statistics'] || []).map(function (v) { return StatisticsEntity.fromJSON(v); })
        });
    };
    GetOneResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
var StatisticsEntity_EntityEntry = /** @class */ (function () {
    function StatisticsEntity_EntityEntry(m) {
        this._json = {};
        if (m) {
            this._json['key'] = m['key'];
            this._json['value'] = m['value'];
        }
    }
    Object.defineProperty(StatisticsEntity_EntityEntry.prototype, "key", {
        // key (key)
        get: function () {
            return this._json.key;
        },
        set: function (value) {
            this._json.key = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatisticsEntity_EntityEntry.prototype, "value", {
        // value (value)
        get: function () {
            return this._json.value;
        },
        set: function (value) {
            this._json.value = value;
        },
        enumerable: true,
        configurable: true
    });
    StatisticsEntity_EntityEntry.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new StatisticsEntity_EntityEntry({
            key: m['key'],
            value: m['value']
        });
    };
    StatisticsEntity_EntityEntry.prototype.toJSON = function () {
        return this._json;
    };
    return StatisticsEntity_EntityEntry;
}());
exports.StatisticsEntity_EntityEntry = StatisticsEntity_EntityEntry;
var StatisticsEntity = /** @class */ (function () {
    function StatisticsEntity(m) {
        this._json = {};
        if (m) {
            this._json['entity'] = m['entity'];
        }
    }
    Object.defineProperty(StatisticsEntity.prototype, "entity", {
        // entity (entity)
        get: function () {
            return this._json.entity || [];
        },
        set: function (value) {
            this._json.entity = value;
        },
        enumerable: true,
        configurable: true
    });
    StatisticsEntity.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new StatisticsEntity({
            entity: m['entity']
        });
    };
    StatisticsEntity.prototype.toJSON = function () {
        return this._json;
    };
    return StatisticsEntity;
}());
exports.StatisticsEntity = StatisticsEntity;
var GetManyResponse = /** @class */ (function () {
    function GetManyResponse(m) {
        this._json = {};
        if (m) {
            this._json['processes'] = m['processes'];
        }
    }
    Object.defineProperty(GetManyResponse.prototype, "processes", {
        // processes (processes)
        get: function () {
            return this._json.processes || [];
        },
        set: function (value) {
            this._json.processes = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyResponse({
            processes: (m['processes'] || []).map(function (v) { return GetOneResponse.fromJSON(v); })
        });
    };
    GetManyResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
var CreateResponse = /** @class */ (function () {
    function CreateResponse(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(CreateResponse.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateResponse({
            id: m['id']
        });
    };
    CreateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
var Empty = /** @class */ (function () {
    function Empty(m) {
        this._json = {};
        if (m) {
        }
    }
    Empty.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Empty({});
    };
    Empty.prototype.toJSON = function () {
        return this._json;
    };
    return Empty;
}());
exports.Empty = Empty;
var CreateRequest = /** @class */ (function () {
    function CreateRequest(m) {
        this._json = {};
        if (m) {
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['time'] = m['time'];
            this._json['frontend_view'] = m['frontendView'];
        }
    }
    Object.defineProperty(CreateRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "time", {
        // time (time)
        get: function () {
            return this._json.time;
        },
        set: function (value) {
            this._json.time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "frontendView", {
        // frontendView (frontend_view)
        get: function () {
            return this._json.frontend_view;
        },
        set: function (value) {
            this._json.frontend_view = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateRequest({
            name: m['name'],
            project: m['project'],
            time: m['time'],
            frontendView: m['frontend_view']
        });
    };
    CreateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
var GetOneRequest = /** @class */ (function () {
    function GetOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(GetOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneRequest({
            id: m['id']
        });
    };
    GetOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
var DeleteOneRequest = /** @class */ (function () {
    function DeleteOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(DeleteOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    DeleteOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteOneRequest({
            id: m['id']
        });
    };
    DeleteOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteOneRequest;
}());
exports.DeleteOneRequest = DeleteOneRequest;
var GetManyRequest = /** @class */ (function () {
    function GetManyRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['pending'] = m['pending'];
        }
    }
    Object.defineProperty(GetManyRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "pending", {
        // pending (pending)
        get: function () {
            return this._json.pending;
        },
        set: function (value) {
            this._json.pending = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyRequest({
            project: m['project'],
            pending: m['pending']
        });
    };
    GetManyRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
var UpdateOneRequest = /** @class */ (function () {
    function UpdateOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['frontend_view'] = m['frontendView'];
            this._json['time'] = m['time'];
        }
    }
    Object.defineProperty(UpdateOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "frontendView", {
        // frontendView (frontend_view)
        get: function () {
            return this._json.frontend_view;
        },
        set: function (value) {
            this._json.frontend_view = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "time", {
        // time (time)
        get: function () {
            return this._json.time;
        },
        set: function (value) {
            this._json.time = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateOneRequest({
            id: m['id'],
            name: m['name'],
            frontendView: m['frontend_view'],
            time: m['time']
        });
    };
    UpdateOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateOneRequest;
}());
exports.UpdateOneRequest = UpdateOneRequest;
var ActivateRequest = /** @class */ (function () {
    function ActivateRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(ActivateRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    ActivateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ActivateRequest({
            id: m['id']
        });
    };
    ActivateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return ActivateRequest;
}());
exports.ActivateRequest = ActivateRequest;
var DeactivateRequest = /** @class */ (function () {
    function DeactivateRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(DeactivateRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    DeactivateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeactivateRequest({
            id: m['id']
        });
    };
    DeactivateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return DeactivateRequest;
}());
exports.DeactivateRequest = DeactivateRequest;
var ProcessService = /** @class */ (function () {
    function ProcessService(hostname, fetch) {
        this.path = '/twirp/admin.flowBuilder.ProcessService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    ProcessService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    ProcessService.prototype.create = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return CreateResponse.fromJSON(m); });
        });
    };
    ProcessService.prototype.getOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneResponse.fromJSON(m); });
        });
    };
    ProcessService.prototype.getMany = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetManyResponse.fromJSON(m); });
        });
    };
    ProcessService.prototype.deleteOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('DeleteOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    ProcessService.prototype.updateOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('UpdateOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    ProcessService.prototype.activate = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Activate'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    ProcessService.prototype.deactivate = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Deactivate'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    return ProcessService;
}());
exports.ProcessService = ProcessService;
