import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress, withStyles } from '@material-ui/core';
import { getOne } from '../../actions/landbot';
import Browser from './Browser';
import Form from './Form';

const Landbot = ({ classes, ...props }) => {
  if (!props.loading && props.data.id !== window.location.pathname.replace('/app/channels/landbot/', '')) {
    props.get(window.location.pathname.replace('/app/channels/landbot/', ''));
  }

  return (
    <div className={classes.root}>
      {!props.loading && JSON.stringify(props.data) !== '{}' && (
        <>
          <div className={classes.form}>
            <Form />
          </div>
          <div className={classes.shadowDropper} />
          <Browser />

        </>
      )}
      {props.loading && (
        <div style={{ marginTop: 'calc(50vh - 20px)', textAlign: 'center', width: '100%' }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
    background: '#f6f7fd',
    height: '100vh',
  },
  form: {
    width: '40%',
    maxWidth: '600px',
  },
  shadowDropper: {
    height: '100vh',
    boxShadow: '0 0 20px 10px #00000038',
    width: '1px',
    background: '#00000014',
  },
};

export default connect((state) => ({
  data: state.landbot.get('data'),
  changed: state.landbot.get('changed'),
  loading: state.landbot.get('loading'),
  frameLoading: state.landbot.get('frameLoading'),
}), (dispatch) => ({
  get: (id) => dispatch(getOne(id)),
}))(withStyles(styles)(Landbot));
