import React, { useEffect, useState } from 'react';
import {
  Modal, Select, SelectItem, TextWithIcon, FilterBlueIcon, Button,
  greyColor,
} from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import DashboardFilterModalFormRightPartList
  from '@pages/dashboard/components/FilterModal/components/RightPartList';
import declOfNum from '@pages/finance/pages/utils/declOfNum';
import defaultDropdownMapping from '@pages/dashboard/components/FilterModal/constants/defaultDropdownMapping';
import {
  makeByOfferOptions,
  makeByPlatformsOptions,
  makeFilterValues,
} from '@pages/dashboard/components/FilterModal/utils/makeOptions';
import getDropdownMappingWithNewOptions
  from '@pages/dashboard/components/FilterModal/utils/getDropdownMappingWithNewOptions';
import getIsNothingSelected from '@pages/dashboard/components/FilterModal/utils/getIsNothingSelected';
import clsx from 'clsx';
import filterFields from '@pages/dashboard/components/FilterModal/constants/filterFields';
import defaultInputMapping from '@pages/dashboard/components/FilterModal/constants/defaultInputMapping';

const DashboardFilterModal = ({
  classes,
  open,
  onClose,
  offers,
  platforms,
  filterSelectValues: filterSelectValuesProps, filterInputValues: filterInputValuesProps, onResetAll, onApplyFilters,
}) => {
  const [dropdownsMappings, setDropdownMapping] = useState(defaultDropdownMapping);
  const [filterSelectValues, setSelectFilterValues] = useState(makeFilterValues(Object.keys(defaultDropdownMapping)));
  const [filterInputValues, setFilterInputValues] = useState(defaultInputMapping);

  useEffect(() => {
    setFilterInputValues(filterInputValuesProps);
    setSelectFilterValues(filterSelectValuesProps);
  }, [filterSelectValuesProps, filterInputValuesProps]);

  useEffect(() => {
    if (offers) {
      setDropdownMapping(
        getDropdownMappingWithNewOptions(dropdownsMappings,
          filterFields.offer,
          makeByOfferOptions(offers)),
      );
    }
  }, [offers]);

  useEffect(() => {
    if (platforms) {
      setDropdownMapping(
        getDropdownMappingWithNewOptions(
          dropdownsMappings,
          filterFields.platform,
          makeByPlatformsOptions(platforms),
        ),
      );
    }
  }, [platforms]);

  const setFilterValue = (value, key) => {
    setSelectFilterValues({ ...filterSelectValues, [key]: value });
  };

  const resetAll = () => {
    setSelectFilterValues(makeFilterValues(Object.keys(defaultDropdownMapping)));
    setFilterInputValues(defaultInputMapping);
    // setRequestBody({ ...requestBody, filters: {} });
  };
  const buttons = (
    <div className={classes.filterModalForm__buttons}>
      <Button color="secondary" onClick={resetAll}>
        {/* Сбросить всё */}
        Reset all
      </Button>
      <Button onClick={() => onApplyFilters(filterSelectValues, filterInputValues)}>
        {/* Применить фильтры */}
        Apply filters
      </Button>
    </div>
  );

  return (
    <Modal
      className={classes.filterModal}
      // title="Фильтры"
      title="Filters"
      open={open}
      onClose={() => {
        onClose(); setFilterInputValues(filterInputValuesProps);
        setSelectFilterValues(filterSelectValuesProps);
      }}
      headerWithGreyLine
      bottomPart={buttons}
      classes={{ contents: classes.filterModalForm__contents }}
    >
      <div className={classes.filterModalForm}>
        <div className={classes.filterModalForm__leftPart}>
          {Object.entries(dropdownsMappings).map(([key, dropdown]) => (
            <Select
              multiple
              renderValue={(value) => `${declOfNum(filterSelectValues[key].length, dropdown.participleForms)} ${value.length} ${declOfNum(filterSelectValues[key].length, dropdown.wordForms)}`}
              className={classes.filterModalForm__select}
              label={dropdown.label}
              value={filterSelectValues[key]}
              renderTagsValue={(value) => dropdown.options[value]}
              onChange={(event) => { setFilterValue(event.target.value, key); }}
              // renderValue={(value) => dropdown.options[value]}
            >
              {dropdown.options && Object.entries(dropdown.options).map((option) => (
                <SelectItem value={option[0]}>
                  <TextWithIcon label={option[1]} />
                </SelectItem>
              ))}
            </Select>
          ))}

        </div>
        {getIsNothingSelected(filterSelectValues) ? (
          <div className={clsx(classes.filterModalForm__rightPart,
            classes.filterModalForm__rightPart_empty)}
          >
            <FilterBlueIcon className={classes.filterModalForm__rightPartIcon} />
            <div className={classes.filterModalForm__rightPartText}>
              {/* Выбирайте нужные фильтры и они */}
              {/* появятся в этой области */}
              Choose the filters you want
              appear in this area
            </div>
          </div>
        ) : (
          <div className={classes.filterModalForm__rightPart}>
            <DashboardFilterModalFormRightPartList
              onChangeInput={() => (inputName, value) => {
                setFilterInputValues({ ...filterInputValues, [inputName]: value });
              }}
              mappings={dropdownsMappings}
              filterValues={filterSelectValues}
              filterInputValues={filterInputValues}
              onDeleteValue={(key, value) => {
                const newArray = filterSelectValues[key].filter((element) => element !== value);

                setFilterValue(newArray, key);
              }}
              onReset={(key) => { setFilterValue([], key); }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const styles = () => ({
  filterModal: {

  },
  filterModalForm: {
    display: 'flex',
    '& > div': {
      width: '100%',
    },
  },
  filterModalForm__select: {
    '& .MuiInputBase-root': {
      width: '340px',
    },
    marginBottom: '30px',
  },
  filterModalForm__rightPart_empty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  filterModalForm__rightPart: {
    height: '456px',
    overflowY: 'scroll',
    paddingRight: '30px',

  },
  filterModalForm__leftPart: {
    paddingTop: '30px',
    borderRight: `1px solid ${greyColor[10]}`,
    height: '456px',
    overflowY: 'scroll',
  },
  filterModalForm__rightPartText: {
    width: '310px',
    textAlign: 'center',
    marginTop: '15px',
  },
  filterModalForm__rightPartIcon: {
    marginTop: '166px',
  },
  filterModalForm__contents: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
  },
  filterModalForm__buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& button:first-of-type': {
      marginRight: '15px',
    },
    '& button': {
      width: '290px',
    },
  },
});

export default withStyles(styles)(DashboardFilterModal);
