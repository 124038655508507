import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import {
  blueColor,
  Button,
  FbIcon,
  FilledHelpIcon,
  greyColor,
  OdnoklassnikiIcon,
  OfferBackgroundSemiTransparentParts,
  OfferBackgroundWhiteParts,
  TelegramIcon,
  Tooltip,
  Typography,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
} from '@aisales/react-ui-kit';
import { formatNumber } from '@components/utils';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import OfferIcon from '@pages/offers/components/OfferIcon';
import TagNewAndStar from '@pages/offers/components/TagNewAndStar';

export default function Offer({ className, ...rest }) {
  // Offer data
  const {
    channels,
    cost,
    countries,
    date,
    description,
    icon,
    id,
    isDark,
    isFavorite,
    languages,
    name,
    onUpdateList,
    rate,
    statistics,
    status,
  } = rest;
  const {
    ban_percent: banPercent,
    click_per_link: clickPerLink,
    conversations,
    conversations_percent: conversationsPercent,
    income,
    income_per_conversation: incomePerConversation,
    leads,
    link_percent: linkPercent,
  } = statistics;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const shownStatistics = [
    {
      // number: formatNumber(statistics.income, 1), symbol: '₽', name: 'Доход', description: 'TODO: Описание дохода',
      number: formatNumber(statistics.income, 1), symbol: '₽', name: 'Income', description: 'Income',
    },
    {
      number: formatNumber(statistics.click_per_link, 1),
      symbol: '%',
      // name: 'Конверсия',
      name: 'Conversion',
      // description: 'Конверсия — это отношение числа посетителей сайта, выполнивших на нём какие-либо целевые действия.',
      description: 'Conversion is the ratio of the number of site visitors who completed any targeted actions on it.',
    },
    // {number: 30, symbol: "дней", name: 'Live time', description: ''},
  ];

  const Signs = () => (
    <div className={classes.signs}>
      Cost per action | Revenue Share
    </div>
  );

  const StatisticsElement = (statisticsPiece) => (
    <div className={classes.statisticsPiece}>
      <Typography style="white" variant="title">
        {' '}
        {statisticsPiece.number}
        {' '}
        <Typography
          variant="common"
        >
          {statisticsPiece.symbol}
        </Typography>
      </Typography>
      <div>
        <Tooltip text={statisticsPiece.description}>
          <div>
            <Typography style="white" className={classes.statisticsLowerLine}>
              {' '}
              {statisticsPiece.name}
              <FilledHelpIcon
                ellipseColor="white"
                questionMarkColor="black"
                className={classes.helpIcon}
              />
            </Typography>
          </div>
        </Tooltip>
      </div>
    </div>
  );
  const moveToOfferPage = () => history.push(`${window.location.pathname}/${id}`);
  const allChannels = [
    { elem: <ViberIcon className={classes.viberIcon} />, name: 'viber' },
    { elem: <VKIcon className={classes.vkIcon} />, name: 'vk' },
    { elem: <TelegramIcon className={classes.telegramIcon} />, name: 'telegram' },
    { elem: <OdnoklassnikiIcon className={classes.odnoklassnikiIcon} />, name: 'odnoklassniki' },
    { elem: <FbIcon className={classes.fbIcon} />, name: 'facebook' },
    { elem: <WhatsappIcon className={classes.whatsappIcon} />, name: 'whatsapp' },
  ];

  return (
    <div className={classNames(classes.root, isDark ? classes.darkTheme : classes.lightTheme, className)}>
      <TagNewAndStar date={date} onUpdateList={onUpdateList} isFavorite={isFavorite} id={id} />
      <OfferBackgroundSemiTransparentParts className={classes.semiTransparentParts} />
      <OfferBackgroundWhiteParts className={classes.whiteParts} />
      <OfferIcon className={classes.offerIcon} icon={icon} />
      {allChannels.map((channel) => (channels.includes(channel.name) ? React.cloneElement(channel.elem, { className: clsx(channel.elem.props.className, classes.channelIcon) }) : undefined))}
      <div className={classes.information}>
        <div className={classes.higherPart}>
          <div onClick={moveToOfferPage} className={classes.title}>
            <Typography variant="title" style="white">
              {' '}
              {name}
            </Typography>
          </div>
          <div onClick={moveToOfferPage} className={clsx(classes.description)}>
            <div className={clsx(classes.fade, isDark ? classes.fadeDarkTheme : classes.fadeLightTheme)} />
            <Typography style="white">
              {description}
            </Typography>
          </div>
          <div className={classes.statisticsSection}>
            {shownStatistics.map((statisticsPiece) => StatisticsElement(statisticsPiece))}
          </div>
          <div className={classes.bottomLine}>
            <Button
              className={clsx(classes.statisticsButton, { [classes.whiteButton]: !isDark })}
              color="primary"
              onClick={moveToOfferPage}
            >
              {/* Подробнее */}
              More
            </Button>
            <Signs />
          </div>
        </div>
        <div className={classes.lowerPart} />
      </div>

    </div>
  );
}
const darkThemeColor = greyColor.background2;
const lightThemeColor = blueColor[100];
const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    width: '528px',
    height: '418px',

    fontStyle: 'normal',
    color: 'rgba(0, 0, 0, 0.67)',
    borderRadius: '5px',
    position: 'relative',
  },

  description: {
    cursor: 'pointer',
    position: 'relative',
    height: '2.4em',
    overflow: 'hidden',
    width: '300px',
    marginTop: '17px',
  },
  fade: {
    content: '',
    textAlign: 'right',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '70%',
    height: '1.2em',
  },
  fadeDarkTheme: {
    background: `linear-gradient(to right, rgba(255, 255, 255, 0), ${darkThemeColor} 50%)`,
  },
  fadeLightTheme: {
    background: `linear-gradient(to right, rgba(255, 255, 255, 0), ${lightThemeColor} 50%)`,
  },
  module: {},
  'module p': {
    margin: 0,
    background: 'red',
  },
  darkTheme: {
    background: darkThemeColor,
  },
  lightTheme: {
    background: `${lightThemeColor}`,
  },
  higherPart: {},
  bottomLine: {
    display: 'flex',
    marginBottom: '30px',
    marginTop: '30px',
    alignItems: 'center',
    justifyContent: 'space-between',

  },
  signs: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '30px',
  },
  helpIcon: {
    marginLeft: '5px',
    cursor: 'pointer',
  },
  statisticsLowerLine: {
    display: 'flex',
    alignItems: 'center',
  },
  information: {
    marginLeft: '30px',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',

  },
  whiteParts: {
    position: 'absolute',
    right: '0px',
    top: '13px',
  },
  whiteButton: {
    backgroundColor: 'white',
    '&& span': {
      color: blueColor[100],
    },
  },
  blueButton: {},
  statisticsButton: {},
  // description: {
  //     marginTop: '15px',
  //     width: '296px',
  // },
  semiTransparentParts: {
    position: 'absolute',
    zIndex: 1,
    right: '0px',
  },
  channelIcon: {
    zIndex: '100',
    position: 'absolute',
  },
  viberIcon: {
    left: '336px',
    top: '35px',
  },
  vkIcon: {
    width: '30px',
    height: '30px',
    left: '427px',
    top: '70px',
  },
  telegramIcon: {
    left: '379px',
    top: '138px',
  },
  odnoklassnikiIcon: {
    left: 477,
    top: 149,
  },
  fbIcon: {
    left: 447.58,
    top: 220.08,
  },
  whatsappIcon: {
    left: 404,
    top: 283.78,
  },
  title: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: '85px',
    height: '90px',
    width: '300px',
    '& > span': {
      zIndex: 2,
    }
  },
  statisticsSection: {
    marginTop: '24px',
    display: 'flex',
    width: '325px',
  },
  statisticsNumber: {},
  statisticsSymbol: {},
  lowerPart: {
    zIndex: '1000',
  },
  statisticsPiece: {
    marginRight: '40px',
    zIndex: 2,
    '& span': {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
  },
}));
