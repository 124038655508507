import React, { useEffect } from 'react';
import PageTemplate from '@components/PageTemplate';
import { financePaths } from '@pages/finance/constants/financePaths';
import { financeTabs } from '@pages/finance/constants/financeTabs';
import { withStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import HoldWithdrawalPage from '@pages/finance/pages/HoldWithdrawalPage';
import BalancePage from '@pages/finance/pages/BalancePage';
import TermsAndProcedurePaymentsPage from '@pages/finance/pages/TermsAndProcedurePaymentsPage';
import PaymentHistoryPage from '@pages/finance/pages/PaymentHistoryPage';

const Finance = (props) => {
  const { classes } = props;
  useEffect(() => {

  }, []);
  return (
    <PageTemplate tabs={financeTabs} title="Финансы">
      <div className={classes.content}>
        <Switch>
          <Route path={financePaths.balancePagePath} render={() => <BalancePage />} />
          <Route path={financePaths.pathPrefixHistory} render={() => <PaymentHistoryPage />} />
          <Route
            path={financePaths.termsAndProcedurePaymentsPagePath}
            render={() => <TermsAndProcedurePaymentsPage />}
          />
          <Route
            path={financePaths.holdWithdrawalPagePath}
            render={() => <HoldWithdrawalPage />}
          />
        </Switch>
      </div>
    </PageTemplate>
  );
};

const styles = (theme) => ({
  content: {
    padding: '30px',
  },

});

export default withStyles(styles)(Finance);
