/* eslint-disable no-undef */
import React, { useEffect } from 'react';

const FacebookInit = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '690302514718936',
        xfbml: true,
        version: 'v5.0',
        cookie: true,
      });

      FB.getLoginStatus(
        (response) => {
          // statusChangeCallback(response);
        },
      );

      // Load the SDK asynchronously
      (function (d, s, id) {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          FB.getLoginStatus(
            (response) => {
              // statusChangeCallback(response);
            },
          );
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };
  });
  return <></>;
};

export default FacebookInit;
