"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var CreateFieldsReq = /** @class */ (function () {
    function CreateFieldsReq(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['fields'] = m['fields'];
        }
    }
    Object.defineProperty(CreateFieldsReq.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFieldsReq.prototype, "fields", {
        // fields (fields)
        get: function () {
            return this._json.fields || [];
        },
        set: function (value) {
            this._json.fields = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateFieldsReq.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateFieldsReq({
            project: m['project'],
            fields: (m['fields'] || []).map(function (v) { return Field.fromJSON(v); })
        });
    };
    CreateFieldsReq.prototype.toJSON = function () {
        return this._json;
    };
    return CreateFieldsReq;
}());
exports.CreateFieldsReq = CreateFieldsReq;
var CreateFieldsResp = /** @class */ (function () {
    function CreateFieldsResp(m) {
        this._json = {};
        if (m) {
            this._json['fields'] = m['fields'];
        }
    }
    Object.defineProperty(CreateFieldsResp.prototype, "fields", {
        // fields (fields)
        get: function () {
            return this._json.fields || [];
        },
        set: function (value) {
            this._json.fields = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateFieldsResp.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateFieldsResp({
            fields: (m['fields'] || []).map(function (v) { return Field.fromJSON(v); })
        });
    };
    CreateFieldsResp.prototype.toJSON = function () {
        return this._json;
    };
    return CreateFieldsResp;
}());
exports.CreateFieldsResp = CreateFieldsResp;
var DeleteFieldReq = /** @class */ (function () {
    function DeleteFieldReq(m) {
        this._json = {};
        if (m) {
            this._json['field'] = m['field'];
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(DeleteFieldReq.prototype, "field", {
        // field (field)
        get: function () {
            return this._json.field;
        },
        set: function (value) {
            this._json.field = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteFieldReq.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    DeleteFieldReq.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteFieldReq({
            field: m['field'],
            project: m['project']
        });
    };
    DeleteFieldReq.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteFieldReq;
}());
exports.DeleteFieldReq = DeleteFieldReq;
var DeleteFieldResp = /** @class */ (function () {
    function DeleteFieldResp(m) {
        this._json = {};
        if (m) {
        }
    }
    DeleteFieldResp.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteFieldResp({});
    };
    DeleteFieldResp.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteFieldResp;
}());
exports.DeleteFieldResp = DeleteFieldResp;
var GetFieldReq = /** @class */ (function () {
    function GetFieldReq(m) {
        this._json = {};
        if (m) {
            this._json['field'] = m['field'];
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(GetFieldReq.prototype, "field", {
        // field (field)
        get: function () {
            return this._json.field;
        },
        set: function (value) {
            this._json.field = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetFieldReq.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    GetFieldReq.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetFieldReq({
            field: m['field'],
            project: m['project']
        });
    };
    GetFieldReq.prototype.toJSON = function () {
        return this._json;
    };
    return GetFieldReq;
}());
exports.GetFieldReq = GetFieldReq;
var GetFieldsReq = /** @class */ (function () {
    function GetFieldsReq(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(GetFieldsReq.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    GetFieldsReq.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetFieldsReq({
            project: m['project']
        });
    };
    GetFieldsReq.prototype.toJSON = function () {
        return this._json;
    };
    return GetFieldsReq;
}());
exports.GetFieldsReq = GetFieldsReq;
var GetFieldsResp = /** @class */ (function () {
    function GetFieldsResp(m) {
        this._json = {};
        if (m) {
            this._json['fields'] = m['fields'];
        }
    }
    Object.defineProperty(GetFieldsResp.prototype, "fields", {
        // fields (fields)
        get: function () {
            return this._json.fields || [];
        },
        set: function (value) {
            this._json.fields = value;
        },
        enumerable: true,
        configurable: true
    });
    GetFieldsResp.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetFieldsResp({
            fields: m['fields']
        });
    };
    GetFieldsResp.prototype.toJSON = function () {
        return this._json;
    };
    return GetFieldsResp;
}());
exports.GetFieldsResp = GetFieldsResp;
var Field = /** @class */ (function () {
    function Field(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
            this._json['name'] = m['name'];
            this._json['type'] = m['type'];
        }
    }
    Object.defineProperty(Field.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Field.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Field.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Field.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Field.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Field({
            id: m['id'],
            project: m['project'],
            name: m['name'],
            type: m['type']
        });
    };
    Field.prototype.toJSON = function () {
        return this._json;
    };
    return Field;
}());
exports.Field = Field;
var FieldsService = /** @class */ (function () {
    function FieldsService(hostname, fetch) {
        this.path = '/twirp/admin.clients.FieldsService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    FieldsService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    FieldsService.prototype.createField = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('CreateField'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return CreateFieldsResp.fromJSON(m); });
        });
    };
    FieldsService.prototype.deleteField = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('DeleteField'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return DeleteFieldResp.fromJSON(m); });
        });
    };
    FieldsService.prototype.getField = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetField'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Field.fromJSON(m); });
        });
    };
    FieldsService.prototype.getFields = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetFields'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetFieldsResp.fromJSON(m); });
        });
    };
    return FieldsService;
}());
exports.FieldsService = FieldsService;
