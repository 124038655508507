import {
  blueColor, DropdownIcon, FormControlLabel, greyColor, Radio,
} from '@aisales/react-ui-kit';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';

const radioItemStyle = () => ({
  radioItemWrapper: {
    width: 'inherit',
    height: 'fit-content',
    position: 'absolute',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: '5px',
    backgroundColor: greyColor.background,
    minHeight: '60px',
    overflow: 'visible',
    userSelect: 'none',
  },

  rotated: {
    transform: 'rotate(180deg)',
  },
  radioItemDescription: {
    paddingBottom: '15px',
  },
  arrow: {
    position: 'absolute',
    right: '18px',
    top: '27px',
    color: greyColor[100],
    cursor: 'pointer',
  },
  radioItemWrapperExpanded: {
    backgroundColor: blueColor[20],
  },
  input: {

  },
  radioItem: {
    padding: '10px',
  },
  withoutRadio: {
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '15px',
  },
  radioItemWrapperWrapper: {
    '&&&&': {
      width: '600px',
      height: '60px',
    },
    marginTop: '10px',
    position: 'relative',
    overflow: 'visible',
  },
  radioItemWithoutOverflow: {
    '&&&&': {
      width: '600px',
      height: 'fit-content',
    },
    '& $radioItemWrapper': {
      position: 'relative',
    },
  },
});
const MonetizationItem = withStyles(radioItemStyle)((props) => {
  const {
    className, classes, onChangeOpen, opened, radioValue, removeRadio, withoutOverflow,
  } = props;
  const [expanded, setExpanded] = useState(opened !== undefined ? opened : false);

  useEffect(() => {
    setExpanded(opened);
  }, [opened]);

  return (
    <div
      onClick={(event) => {
        if (opened === undefined) {
          setExpanded(!expanded);
        }
        onChangeOpen?.(!opened);
      }}
      className={clsx(classes.radioItemWrapperWrapper, className, { [classes.radioItemWithoutOverflow]: withoutOverflow })}
    >
      <div className={clsx(classes.radioItemWrapper, { [classes.radioItemWrapperExpanded]: expanded })}>
        <FormControlLabel
          onClick={(event) => {
            if (!removeRadio) {
              event.stopPropagation();
            }
          }}
          className={clsx(classes.radioItem, { [classes.withoutRadio]: removeRadio })}
          value={radioValue.id}
          control={removeRadio ? <div /> : <Radio />}
          label={radioValue.name}
        />
        <DropdownIcon className={clsx(classes.arrow, { [classes.rotated]: expanded })} />
        {expanded && (
        <div className={classes.radioItemDescription}>
          {radioValue.description}
        </div>
        )}
      </div>
    </div>
  );
});

export default MonetizationItem;
