import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@aisales/react-ui-kit';
import { validatePassword, validatePasswordConfirmation } from '@pages/entrance/components/Registration/utils/validators';
// import validatePassword, { validatePasswordConfirmation } from '@pages/entrance/components/Registration/passwordValidation';
import { showToast } from '@pages/oldOffers/utils';
import { backendURL } from '@main/toRemove';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '@store/actions';
import { Redirect } from 'react-router-dom';
import PasswordInput from '@pages/entrance/components/PasswordInput';
import { withStyles } from '@material-ui/core';

const style = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&>div': {
      width: '100%',
      '&:first-child': {
        marginBottom: '10px',
      },
      '&:nth-child(2)': {
        marginBottom: '30px',
      },
      '&:nth-child(3)': {
        marginBottom: '20px',
      },
      '&:nth-child(4)': {
        marginBottom: '30px',
      },
    },
  },
  input: {
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
});

function NewPasswordForm({ classes, type}) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      return <Redirect to={{ pathname: '/app/dashboard' }} />;
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      return <Redirect to={{ pathname: '/app/dashboard' }} />;
    }
  }, []);

  async function sendNewPassword(password, passwordConfirmation) {
    const passwordErrorSync = validatePassword(password);
    const passwordConfirmationErrorSync = validatePasswordConfirmation(password, passwordConfirmation);
    setPasswordError(passwordErrorSync);
    setPasswordConfirmationError(passwordConfirmationError);
    if (passwordErrorSync || passwordConfirmationErrorSync) {
      return;
    }
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    const type = query.get('type') || 'MAIL';

    const response = await fetch(`${backendURL}/api/password/reset/2`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        password,
        type
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });

    const json = await response.json();

    const { error } = json;// '\';
    if (error) {
      return Promise.reject(error);
    }
    return json;
  }

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        {/* <Typography variant="title">Изменение пароля</Typography> */}
        <Typography variant="title">Change password</Typography>
      </div>
      <div className={classes.text}>
        {/* Придумайте новый пароль. */}
        Create new password.
      </div>
      <div>
        <PasswordInput
          name="password"
          label="Новый пароль"
          setPassword={setPassword}
          setError={setPasswordError}
          errorText={passwordError}
          className={classes.input}
        />
      </div>
      <div className={classes.input}>
        <PasswordInput
          name="passwordConfirmation"
          setPassword={setPasswordConfirmation}
          setError={setPasswordConfirmationError}
          errorText={passwordConfirmationError}
          label="Повторите новый пароль"
          className={classes.input}
        />
      </div>
      <Button
        style={{ width: '100%', marginBottom: '58px', marginTop: '17px' }}
        variant="contained"
        disabled={passwordError || passwordConfirmationError}
        onClick={() => {
          sendNewPassword(password, passwordConfirmation).then((res) => {
            if (type==="MAIL") {
              dispatch(loginUser(res.user.user_mail, password)).then(
                  history.push('/app/dashboard'),
              );
            } else {
              dispatch(loginUser(res.user.phone, password)).then(
                  history.push('/app/dashboard'),
              );
            }

          })
            .catch((error) => {
              showToast(error);
            });
        }}
      >
        {/* Изменить пароль */}
        Change password
      </Button>
    </div>
  );
}

export default withStyles(style)(NewPasswordForm);
