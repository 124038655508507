import React from 'react';
import { Chat, Notification } from '@aisales/react-uikit';
import { toast } from 'react-toastify';
import chatBackground from './tools-icons/landbot.png';

export const toolTypes = [
  {
    name: 'Чат',
    type: 'landbot',
    picture: chatBackground,
  },
];

export const getType = (alias) => {
  switch (alias) {
    case 'landbot':
      return {
        name: 'Чат',
        icon: <Chat style={{ fontSize: 'inherit' }} />,
      };
    default:
      return 'Неизвестный тип';
  }
};

export const showToast = (str, ok) => {
  // toast(
  //     <Notification
  //         type={ok ? "info" : "report"}
  //         message={str}
  //         variant={"contained"}
  //         color={ok ? "success" : "error"}
  //     />,
  //     {
  //         position: toast.POSITION.TOP_RIGHT,
  //         progressClassName: "progress",
  //         className: "notification",
  //     }
  // );
};

export const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const updateSearchParam = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newUrl = `${window.location.origin + window.location.pathname}?`;
  searchParams.forEach((value, key) => (newUrl += `${key}=${value}&`));
  newUrl = newUrl.substr(0, newUrl.length - 1);
  window.history.pushState({ path: newUrl }, '', newUrl);
};
