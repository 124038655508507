import moment from 'moment';
import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '../../toRemove';

export const TICKETS_FETCH_START = 'TICKETS_FETCH_START';
export const TICKETS_FETCH_SUCCESS = 'TICKETS_FETCH_SUCCESS';
export const TICKETS_FETCH_FAIL = 'TICKETS_FETCH_FAIL';
export const TICKETS_FRAME_LOADING_END = 'TICKETS_FRAME_LOADING_END';
export const TICKETS_RESET = 'TICKETS_RESET';
export const SET_CURRENT_TICKET = 'SET_CURRENT_TICKET';
export const CREATE_TICKET_START = 'CREATE_TICKET_START';
export const CREATE_TICKET_FAIL = 'CREATE_TICKET_FAIL';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const SET_FORM_OPEN = 'SET_FORM_OPEN';
export const CLEAR_TICKETS = 'CLEAR_TICKETS';

const parseRPC = (tickets) => {
  const res = tickets.toJSON();
  res.layout = res.layout.toJSON();
  res.layout.bot_message = res.layout.bot_message.toJSON();
  res.layout.bot_message.colors = res.layout.bot_message.colors.toJSON();
  res.layout.user_message = res.layout.user_message.toJSON();
  res.layout.user_message.colors = res.layout.user_message.colors.toJSON();
  return res;
};

export const addTicket = (topic, description) => (dispatch) => {
  dispatch({ type: CREATE_TICKET_START });
  fetch(`${backendURL}/twirp/cpa.support.SupportTicketsService/Create`, {
    ...twirpFetchOptions,
    method: 'POST',
    body: JSON.stringify({ topic, description }),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('bad response code');
    })
    .then((json) => {
      dispatch({
        type: CREATE_TICKET_SUCCESS,
        payload: {
          ...json.ticket,
          creation_date: moment(
            new Date(json.ticket.created_time * 1000),
          ).format('DD.MM.YYYY'),
        },
      });
    })
    .catch(() => {
      dispatch({ type: CREATE_TICKET_FAIL });
    });
};

export const setFormOpen = (isOpen) => (dispatch) => {
  dispatch({ type: SET_FORM_OPEN, payload: isOpen });
};

export const fetchTickets = (folder) => (dispatch) => {
  fetch(`${backendURL}/twirp/cpa.support.SupportTicketsService/GetMany`, {
    ...twirpFetchOptions,
    method: 'POST',
    body: JSON.stringify({ account_id: 10, folder }),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('bad response code');
    })
    .then((json) => {
      const tickets = json.tickets || [];
      dispatch({
        type: TICKETS_FETCH_SUCCESS,
        payload: tickets.map((ticket) => ({
          ...ticket,
          creation_date: moment(
            new Date(ticket.created_time * 1000),
          ).format('DD.MM.YYYY'),
        })),
      });
    })
    .catch((err) => {
      dispatch({
        type: TICKETS_FETCH_FAIL,
        payload: err,
      });
    });

  return dispatch({
    type: TICKETS_FETCH_START,
  });
};

export const setCurrentTicket = (ticket) => (dispatch) => dispatch({ type: SET_CURRENT_TICKET, payload: ticket });
export const frameLoaded = (dispatch) => dispatch({ type: TICKETS_FRAME_LOADING_END });
export const reset = (dispatch) => dispatch({ type: TICKETS_RESET });

export const clearTickets = (dispatch) => dispatch({ type: CLEAR_TICKETS });
