const defaultReports = [
  {
    id: 'byDayReport',
    // name: 'Отчёт по дням',
    name: 'Daily report',
    groups: ['year', 'month', 'day'],
    columns: ['users', 'active', 'active_percent', 'conversations', 'conversations_percent', 'ban', 'ban_percent', 'approved'],
    need_counters: true,
    counters: ['conversations', 'ban'],
  },
  {
    id: 'byTouchReport',
    // name: 'Отчёт по касаниям',
    name: 'Touch report',
    groups: ['year', 'month', 'day'],
    columns: ['users', 'approved', 'approved_percent', 'conversations', 'conversations_percent', 'ban', 'ban_percent', 'rejected', 'rejected_percent', 'combo', 'combo_percent', 'pending'],
    need_counters: true,
    counters: ['conversations', 'ban'],
  },
];

export default defaultReports;
