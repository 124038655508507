import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store';
import './App.css';
import 'moment/locale/ru';

if (window.location.hostname === 'affiliate.aisales.network') {
  Sentry.init({
    dsn:
            'https://8ce8b7f6d75b41ebb8d47a6ed7408584@o295515.ingest.sentry.io/5370008',
  });
}
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <div id="measure-layer" />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
