import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import MaterialModal from '@material-ui/core/Modal';
import Table from '@pages/profile/components/Blocks/Table';
import { ReactComponent as CloseIcon } from '../assets/closeIcon.svg';
import { ReactComponent as RoundIndicator } from '../assets/roundIndicator.svg';

const getIndicatorColor = (status) => {
  if (String(status).startsWith('2')) {
    return '#45CC15';
  } if (String(status).startsWith('4')) {
    return '#cc9e15';
  }
  return '#cc1515';
};

const getTime = (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  return date.toLocaleDateString('ru');
};

function RequestsModal({
  classes, handleClickedToClose, open, postbackLogs,
}) {
  const [style, setStyle] = useState({ color: 'white', marginRight: '10px' });
  const mocks = [
    {
      time: '20.12.2020 14:57:32',
      description: 'message_new',
      status: '200',
    },
    {
      time: '20.12.2020 14:57:32',
      description: 'message_new',
      status: '0',
    },

  ];
  return (
    <MaterialModal
      style={{ width: '800px', margin: 'auto', marginTop: '3em' }}
      open={open}
      onBackdropClick={handleClickedToClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ height: '500px', background: 'white', overflow: 'scroll' }}>
        <div style={{
          backgroundColor: '#20204D',
          color: 'white',
          fontWeight: 500,
          fontSize: '15px',
          textAlign: 'center',
          position: 'sticky',
          height: '40px',
          top: '0',
          zIndex: '1000',
        }}
        >
          <span style={{
            paddingTop: '11px',
            paddingBottom: '11px',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
          >
            Запросы
          </span>
          <span style={{
            paddingTop: '11px',
            paddingBottom: '11px',
            width: '100%',
            position: 'absolute',
            left: '0px',
            top: '0px',
          }}
          >
            <span
              className={classes.modalBody}
              style={{
                display: 'flex', height: '100%', alignItems: 'center', flexDirection: 'row-reverse',
              }}
            >
              <CloseIcon
                onMouseEnter={() => setStyle({ color: '#b8b7b7', marginRight: '10px' })}
                onMouseLeave={() => setStyle({ color: 'white', marginRight: '10px' })}
                onClick={() => {
                  handleClickedToClose();
                }}
                style={style}
              />
            </span>
          </span>
        </div>
        <div className={classes.requestsModalTable}>
          <Table style={{ borderLeft: '1px dashed #D6D6D6', height: '100%' }} className={classes.table}>
            <tr>
              <th style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
                <div><p> Время </p></div>
              </th>
              <th style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
                <div><p> Статус </p></div>
              </th>
              <th style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
                <div><p> Тело ответа </p></div>
              </th>
            </tr>
            {postbackLogs.map((postbackLog) => (
              <tr key={postbackLog.id} className={classes.row}>
                <td style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
                  <div className={classes.tdNameContent}><p>{getTime(postbackLog.created)}</p></div>
                </td>
                <td style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <RoundIndicator
                      style={{ color: getIndicatorColor(postbackLog.status_code) }}
                    />
                    <p>{postbackLog.status_code}</p>
                  </div>
                </td>
                <td style={{ borderRight: '1px dashed #D6D6D6', width: '161px' }}>
                  <div className={classes.tdNameContent}>
                    <p style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: '300px',
                    }}
                    >
                      {postbackLog.response_body}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </MaterialModal>
  );
}

const style = {
  requestsModalTable: {
    '& th': {
      fontWeight: 'bold',
      fontSize: '11px',
      textTransform: 'uppercase',
      paddingTop: '21px',
      paddingBottom: '11px',
      top: '40px',
      color: '#000000',
      position: 'sticky',
      backgroundColor: 'white',
      zIndex: '1000',
      '& p': {
        opacity: '0.56',
      },
    },
    '& td': {
      fontSize: '12px',
      opacity: '0.67',
      paddingBottom: '11px',
      paddingTop: '11px',
      textAlign: 'center',
    },
  },
  icon: {
    '$:hover': {
      color: 'black',
    },
    '$ svg': {
      color: 'pink',
    },
  },
};
export default withStyles(style)(RequestsModal);
