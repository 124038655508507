import { TOGGLE_SIDEBAR } from '../actions';

export const initialState = {
  isSidebarOpened: true,
  project: null,
  intent: null,
  projectsArray: null,
  dialogflow: null,
  intentsArray: null,
};

export default function LoginReducer(state = initialState, { payload, type }) {
  switch (type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpened: !state.isSidebarOpened,
      };
    default:
      return state;
  }
}
