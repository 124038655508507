import {
  AddIcon,
  Button,
  FilledOkIcon,
  greyColor,
  Input,
  Modal,
  redColor,
  Select,
  SelectItem,
  TextWithIcon,
  Typography,
  typographyDefaultTheme,
} from '@aisales/react-ui-kit';
import PhoneVerification from '@pages/finance/pages/BalancePage/PhoneVerification';
import { financePaths } from '@pages/finance/constants/financePaths';
import { paymentMapping } from '@pages/finance/constants/paymentMapping';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '@store/actions';
import fetch from '@main/utils/handledFetch';
import clsx from 'clsx';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { getCookie } from '@pages/finance/utils';
import { useHistory } from 'react-router';

const styles = () => ({
  labelStar: {
    '&&': {
      color: redColor[100],
    },
  },
  label: {
    '& span': {
      ...typographyDefaultTheme.typography.root,
      ...typographyDefaultTheme.typography.subtitle,
      ...typographyDefaultTheme.typography.normal,
    },
    marginBottom: '20px',
  },
  inputWrapper: {
    marginTop: '40px',
  },
  input: {
    width: '100%',
  },
  description: {
    marginTop: '-10px',
    marginBottom: '20px',
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      width: '290px',
    },
  },
  textArea: {
    height: 'auto',
  },
  icon: {
    marginTop: '10px',
    marginBottom: '15px',

  },
  successContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonChangedPadding: {
    '&>button': {
      paddingLeft: '15px',
      paddingRight: '15px',
    },

  },
  button: {
    width: '290px',
  },
  successText: {
    marginTop: '3px',
    width: '280px',
    textAlign: 'center',
  },
  modalContent: {
    width: '600px',
  },
  modalContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  lastSelectItem: {
    '&&>div': {
      width: '100%',
      marginRight: '15px',
      borderTop: '1px solid',
      borderColor: greyColor[10],
    },
    // paddingLeft: '0px',
    // paddingRight: '0px',
    // marginLeft: '15px',
    // marginRight: '15px',
    height: 'fit-content',

    '&>div': {
      '&>div': {
        paddingTop: '24px',
        marginBottom: '14px',

      },
    },
  },
  beforeLastSelectItem: {
    paddingBottom: '16px',
    paddingTop: '14px',
  },
  twoButtonsWrapper: {
    width: '100%',
    marginTop: '27px',
    justifyContent: 'center',
    display: 'flex',
    '&>button': {
      '&:first-of-type': {
        marginRight: '20px',
      },
      '&:last-of-type': {
        padding: '0px',
      },
      width: '290px',
    },

  },

});

const NewTicketForm = ({
  availablePaymentBlock, balanceAvailable, classes, modalOpen, paymentInfo, setModalOpen,
}) => {
  const accountID = getCookie('account_id');
  const [payment, setPayment] = useState(null);
  const [finished, setFinished] = useState(false);
  const [amount, setAmount] = useState(balanceAvailable);
  const [comment, setComment] = useState(null);
  const history = useHistory();
  const [validationToken, setValidationToken] = useState(null);
  const [formIsValid, setFormIsValid] = useState(null);
  const dispatch = useDispatch();
  const phoneNumberStore = useSelector((state) => state.profile.formValues.phoneNumber);
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  const checkFormIsValid = () => {
    if (validationToken !== null && payment !== null && amount !== null) {
      return true;
    }
    return false;
  };

  const reset = () => {
    setPayment(null);
    setComment(null);
    setAmount(null);
    setFinished(false);
  };

  const createWithdrawalTicket = () => {
    // if (!validationToken) {
    //     showToast("Подтвердите номер телефона");
    //     return;
    // }

    const ticket = {
      amount,
      comment,
    };

    // if (ticket.comment?.trim().length <= 10) {
    //     setValidateRequestForm(true);
    //     showToast("Длина комментария должна быть больше 10 символов");
    //     return;
    // }
    // setFormLoading(true);
    fetch(`${backendURL}/twirp/cpa.billing.BillingService/CreateTicket`, {
      ...twirpFetchOptions,
      headers: {
        ...twirpFetchOptions?.headers,
        auth: validationToken,
      },
      body: JSON.stringify({
        account_id: accountID,
        amount: Number(ticket.amount),
        comment: ticket.comment || '',
        info_id: paymentInfo[payment].id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setFinished(true);
          return res.json();
        }
        throw new Error('');
      })
      .then((json) => {

        // const res = json.ticket;
        // setTickets([
        //     {
        //         id: res.id,
        //         amount: ticket.requestFormSum,
        //         status: res.status,
        //         created_time: moment(
        //             new Date(Number(res.created_time) * 1000)
        //         ).format("DD.MM.YYYY"),
        //         comment: ticket.requestFormComment,
        //         chat_id: res.chat_id,
        //     },
        //     ...tickets,
        // ]);
        // setCanMakeRequest(false);
        // setWithdrawalFormOpen(false);
      })
      .catch((e) => {
        // Sentry.captureException(e);
        // showToast("Ошибка создания запроса");
      })
      .finally(() => {
        // setFormLoading(false);
      });
  };

  useEffect(() => {
    setFormIsValid(checkFormIsValid());
  }, [validationToken, payment, amount]);

  const parseAccountNumber = (number) => String(number).slice(-4);

  const Label = ({ label, withoutStar }) => (
    <div className={classes.label}>
      <span>
        {label}
        {!withoutStar && (
        <span
          className={classes.labelStar}
        >
          {' '}
          *
        </span>
        )}
      </span>
    </div>
  );
  return (
    <Modal
      title="Заявка на выплату"
      open={modalOpen}
      onClose={() => {
        setFinished(false);
        setAmount(null);
        setComment(null);
        setPayment(null);
        setValidationToken(null);
        history.push(financePaths.balancePagePath);
      }}
    >
      <div className={classes.modalContentWrapper}>
        <div className={classes.modalContent}>
          {!finished
            ? (
              <div>
                {availablePaymentBlock}
                <div className={classes.inputWrapper}>
                  <Label label="Введите сумму для вывода" />
                  <Input
                    value={amount}
                    onChange={(event) => {
                      setAmount(event.target.value);
                    }}
                    type="number"
                    placeholder="Введите сумму для вывода"
                    classes={{ label: classes.label }}
                    className={classes.input}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Label label="Выберите реквизиты для вывода средств" />
                  <Select
                    renderValue={() => paymentMapping[paymentInfo[payment].requisites_type].name}
                    value={payment}
                    classes={{ label: classes.label }}
                    classes={{ inputRoot: classes.input }}
                    onChange={(event) => {
                      setPayment(event.target.value);
                    }}
                  >
                    {[...paymentInfo?.map((payment, index) => (
                      <SelectItem key={index} value={index} className={clsx({ [classes.beforeLastSelectItem]: paymentInfo.length - 1 === index })}>
                        <div style={{ height: 'fit-content' }}>
                          <TextWithIcon
                            iconFirst
                            icon={paymentMapping[payment.requisites_type].icon}
                            label={`${paymentMapping[payment.requisites_type].name}  (...${parseAccountNumber(payment.account_number)})`}
                          />
                        </div>

                      </SelectItem>
                    )), <SelectItem className={classes.lastSelectItem} value={0} key={paymentInfo ? paymentInfo?.length : 0} onClick={() => { history.push('/app/profile'); }}>
                      <div>
                        <TextWithIcon
                          iconFirst
                          icon={<AddIcon />}

                          label="Добавить реквизиты"
                        />
                      </div>
                    </SelectItem>]}

                  </Select>
                </div>
                <div className={classes.inputWrapper}>
                  <Label label="Подтверждение номера телефона" />
                  {phoneNumberStore
                    ? (
                      <div>
                        <div className={classes.description}>
                          <Typography>
                            На Ваш номер телефона будет отправлен код подтверждения, введите
                            его.
                          </Typography>
                        </div>
                        <PhoneVerification
                          type="NUMBER_VERIFICATION"
                          editingDisabled
                          onSuccess={(token, number) => {
                            setValidationToken(token);
                          }}
                        />
                      </div>
                    )
                    : (
                      <div>
                        У Вас отсутствует номер телефона. Перейдите в личный кабинет и укажите номер
                        телефона на который мы сможет выслать вам код.
                        <div className={classes.buttonWrapper}>
                          <Button onClick={() => {
                            history.push('/app/profile');
                          }}
                          >
                            Перейти в личный кабинет
                          </Button>
                        </div>
                      </div>
                    )}
                </div>
                <div className={classes.inputWrapper}>
                  <Label withoutStar label="Оставьте комментарий" />
                  <Input
                    className={clsx(classes.input, classes.textArea)}
                    multiline
                    value={comment}
                    onChange={(event) => {
                      setComment(event.target.value);
                    }}
                  />
                  <div className={classes.buttonWrapper}>
                    <Button
                      disabled={!formIsValid}
                      onClick={() => {
                        createWithdrawalTicket();
                      }}
                    >
                      Создать заявку
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.successContent}>
                <FilledOkIcon className={classes.icon} />
                <Typography bold>Ваша заявка успешно создана</Typography>
                <div className={classes.successText}>
                  <Typography>Отслеживать статус заявки вы можете на странице «История выплат»</Typography>
                </div>
                <div className={classes.twoButtonsWrapper}>
                  <Button color="secondary" onClick={reset}>Создать еще одну заявку</Button>
                  <Button onClick={() => {
                    history.push(financePaths.pathPrefixHistory);
                  }}
                  >
                    Перейти на страницу история выплат
                  </Button>
                </div>
              </div>
            )}
        </div>
      </div>

    </Modal>
  );
};

export default withStyles(styles)(NewTicketForm);
