/* eslint-disable no-undef */
import React from 'react';
import Button from '@material-ui/core/Button';
import { ExpansionList, ListElem } from '@aisales/react-uikit';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from 'react-i18next';
import { showToast } from '@pages/oldOffers/utils';
import { backendURL } from '@main/toRemove';
import {
  CONNECT,
  COUNTRY,
  CZECH_REPUBLIC,
  GERMANY,
  ITALY,
  KAZAKHSTAN,
  POLAND,
  RUSSIA,
  SPAIN,
  UKRAINE,
  USA,
} from '@main/i18n/aliases';
import questionMark from '../../question_mark.svg';
import { languages } from '../../constants';

const countries = [];

class Facebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: null,
      found: false,
    };

    this.t = this.props.t;

    countries.push(
      { code: 'CZ', name: this.t(CZECH_REPUBLIC) },
      { code: 'UA', name: this.t(UKRAINE) },
      { code: 'ES', name: this.t(SPAIN) },
      { code: 'PL', name: this.t(POLAND) },
      { code: 'US', name: this.t(USA) },
      { code: 'KZ', name: this.t(KAZAKHSTAN) },
      { code: 'RU', name: this.t(RUSSIA) },
      { code: 'IT', name: this.t(ITALY) },
      { code: 'DE', name: this.t(GERMANY) },
    );

    this.classes = this.props.classes;
    if (this.isEdit()) {
      let language = 6;
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].code === this.props.platform.language) {
          language = i;
          break;
        }
      }
      let country = 6;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].code === this.props.platform.country) {
          country = i;
          break;
        }
      }
      this.state.groups = [{
        ...this.props.platform,
        picture: {
          data: {
            url: (this.props.platform.photo || questionMark),
          },
        },
      }];
      this.state.addGroup = {
        selectedGroup: 0, groupId: this.props.platform.id, lang: language, country, translate: this.props.platform.translate,
      };
    }
  }

    project = JSON.parse(localStorage.getItem('user'))?.current_project;

    saveGroup = (language, country, translate) => {
      fetch(`${backendURL}/api/channels/facebook/${this.state.addGroup.groupId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          'offer-id': this.state.addGroup.offer_id,
        },
        body: JSON.stringify({ language, country, translate }),
      }).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            this.props.updateChannelsList({
              ...this.props.platform, language, country, translate,
            });
            showToast('Данные обновлены', true);
            this.props.onClose();
          });
        } else {
          showToast('Ошибка при сохранении', false);
        }
      });
    };

    isEdit() {
      return Boolean(this.props.platform);
    }

    render() {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
          <span id="status" />
          {((this.state.addGroup != null && this.state.groups != null) || this.isEdit()) && (
          <div style={{
            textAlign: 'center', width: '200px', margin: 'auto', marginTop: '20px',
          }}
          >
            <div>
              <img src={this.state.groups[this.state.addGroup.selectedGroup].picture.data.url} width={50} height={50} style={{ borderRadius: '100%' }} alt="avatar" />
            </div>
            <div style={{ borderBottom: '1px solid #0000001f', paddingBottom: '10px' }}>
              <h4 style={{ fontWeight: 6000 }}>
                {this.state.groups[this.state.addGroup.selectedGroup].name}
                {' '}
              </h4>
              {' '}
              <span style={{ color: '#a0a0a0' }}>
                ID:
                {this.state.addGroup.groupId}
              </span>
            </div>
            <div style={{
              textAlign: 'left', marginTop: '10px', paddingTop: '10px', width: '165px', margin: 'auto',
            }}
            >
              <div>
                <Typography variant="caption" style={{ display: 'block', marginLeft: '5px' }}>
                  {this.t(COUNTRY)}
                  :
                </Typography>
                <ExpansionList
                  closeOnClick
                  maxHeight="300px"
                  style={{
                    boxShadow: 'none',
                    maxWidth: '165px',
                    width: '165px',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    lineHeight: '13px',
                    color: 'black',
                    height: '40px',
                  }}
                  textValue={countries[this.state.addGroup.country].name}
                >
                  {countries.map((country, index) => (country.code !== countries[this.state.addGroup.country].code && (
                  <ListElem
                    key={`country${country.code}`}
                    onClick={() => this.setState({ addGroup: { ...this.state.addGroup, country: index } })}
                  >
                    {country.name}
                  </ListElem>
                  )))}
                </ExpansionList>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Typography variant="caption" style={{ display: 'block', marginLeft: '5px' }}>Язык:</Typography>
                <ExpansionList
                  closeOnClick
                  maxHeight="300px"
                  style={{
                    boxShadow: 'none',
                    maxWidth: '165px',
                    width: '165px',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    lineHeight: '13px',
                    color: 'black',
                    height: '40px',
                  }}
                  textValue={languages[this.state.addGroup.lang].name}
                >
                  {languages.map((lang, index) => (lang.code !== languages[this.state.addGroup.lang].code && (
                  <ListElem
                    key={`lang${lang.code}`}
                    onClick={() => this.setState({ addGroup: { ...this.state.addGroup, lang: index } })}
                  >
                    {lang.name}
                  </ListElem>
                  )))}
                </ExpansionList>
              </div>
              <div>
                <FormControlLabel
                  style={{
                    margin: 0, marginTop: '10px', width: '100%', justifyContent: 'space-between', paddingLeft: '5px',
                  }}
                  control={(
                    <Checkbox
                      style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        opacity: 0.7,
                        padding: '0',
                        color: '#4786ef',
                      }}
                      color="primary"
                    />
                                  )}
                  onChange={() => this.setState({ addGroup: { ...this.state.addGroup, translate: !this.state.addGroup.translate } })}
                  checked={this.state.addGroup.translate}
                  labelPlacement="start"
                  label={<Typography variant="caption" style={{ userSelect: 'none' }}>Перевод</Typography>}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: '10px', backgroundColor: 'rgb(80, 132, 220)', fontSize: '12px', fontWeight: 600,
                }}
                onClick={() => {
                  if (this.isEdit()) {
                    this.saveGroup(languages[this.state.addGroup.lang].code, countries[this.state.addGroup.country].code, this.state.addGroup.translate);
                  } else {
                    this.addGroup(this.state.groups[this.state.addGroup.selectedGroup].name, this.state.groups[this.state.addGroup.selectedGroup].access_token, this.state.addGroup.groupId, languages[this.state.addGroup.lang].code, countries[this.state.addGroup.country].code);
                  }
                }}
                fullWidth
              >
                {this.isEdit() ? 'Сохранить' : this.t(CONNECT)}
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: '10px', fontSize: '12px', color: 'white' }}
                onClick={() => {
                  if (this.isEdit()) {
                    this.props.onClose();
                  } else {
                    this.setState({ addGroup: null });
                  }
                }}
                fullWidth
              >
                Отмена
              </Button>
            </div>
          </div>
          )}
        </div>
      );
    }
}

export default withTranslation()(Facebook);
