import {
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_ERROR,
  SIGN_OUT_SUCCESS,
} from '../actions';

export const initialState = {
  isLoading: false,
  isAuthenticated: !!localStorage.getItem('user'),
  username: '',
  nickname: '',
  userEmail: '',
  userPicture: '',
  error: null,
};

export default function LoginReducer(state = initialState, { payload, type }) {
  switch (type) {
    case START_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null,
        ...payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case RESET_ERROR:
      return {
        error: false,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
