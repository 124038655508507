import React from 'react';
import { SketchPicker } from 'react-color';
import { ClickAwayListener, Popover, withStyles } from '@material-ui/core';

class ColorPicker extends React.Component {
    state = {
      displayColorPicker: false,
      color: this.props.current,
    };

    handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
      this.setState({ displayColorPicker: false });
    };

    handleChange = (color) => {
      this.setState({ color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
      this.props.onChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    classes = this.props.classes;

    ref = React.createRef();

    render() {
      return (
        <div>
          <div className={this.classes.swatch} onClick={this.handleClick} ref={this.ref}>
            <div className={this.classes.color} style={{ background: `${this.state.color} content-box` }} />
          </div>
          <Popover open={this.state.displayColorPicker} anchorEl={this.ref.current}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <SketchPicker color={this.state.color} onChange={this.handleChange} />
            </ClickAwayListener>
          </Popover>
        </div>
      );
    }
}

const styles = {
  color: {
    width: '46px',
    height: '24px',
    border: '1px dashed #00000021',
    borderRadius: '5px',
    transition: 'border .3s',
    cursor: 'pointer',
    '&:hover': {
      border: '2px dashed #02abd3cf',
    },
    backgroundClip: 'content-box',
  },
  swatch: {
    marginRight: '5px',
  },
};

export default withStyles(styles)(ColorPicker);
