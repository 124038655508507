import { backendURL } from '@main/toRemove';
import fetch from '@main/utils/handledFetch';

export const START_LOGIN = 'Login/START_LOGIN';
export const LOGIN_SUCCESS = 'Login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'Login/LOGIN_FAILURE';
export const RESET_ERROR = 'Login/RESET_ERROR';
export const LOGIN_USER = 'Login/LOGIN_USER';
export const SIGN_OUT_SUCCESS = 'Login/SIGN_OUT_SUCCESS';

export const startLogin = () => ({
  type: START_LOGIN,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOut = () => (dispatch) => {
  localStorage.removeItem('user');
  dispatch(signOutSuccess());
};

export const loginUser = (login, password, rememberMe) => async (dispatch) => {
  dispatch(resetError());
  dispatch(startLogin());

  if (!login || !password) {
    dispatch(loginFailure());
    return;
  }

  try {
    const response = await fetch(`${backendURL}/api/login`, {
      method: 'POST',
      body: JSON.stringify({
        user_type: 'WEBMASTER',
        login,
        remember_me: rememberMe,
        password,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });

    const json = await response.json();

    const { error } = json;
    if (error) {
      throw new Error(error);
    }

    localStorage.setItem('user', JSON.stringify(json));
    dispatch(
      loginSuccess({
        username: json?.user?.username,
        userEmail: json?.user?.userEmail,
        userPicture: json?.user?.picture,
        nickname: json?.user?.contact_info?.nickname,
      }),
    );
  } catch (error) {
    dispatch(loginFailure());
  }
};
