import { withStyles } from '@material-ui/core';
import React from 'react';

const RoundImage = ({ src, classes }) => <img className={classes.roundImage} alt="channel image" src={src} />;

const styles = () => ({
  roundImage: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },
});
export default withStyles(styles)(RoundImage);
