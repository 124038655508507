import { Button, Input } from '@aisales/react-ui-kit';
import HelpText from '@pages/entrance/components/HelpText';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';

const PhoneVerificationV2 = ({
  phoneConfirmationCode,
  setPhoneConfirmationCode,
  classes,
  submitPhone,
  submitCode,
}) => {
  const [timeTillNextCode, setTimeTillNextCode] = useState(30);
  const secondsTillNextCode = 30;
  useEffect(() => {
    startTimer(secondsTillNextCode);
  }, []);
  const startTimer = (numOfSeconds) => {
    let timeleft = numOfSeconds;
    const downloadTimer = setInterval(() => {
      timeleft -= 1;
      setTimeTillNextCode(timeleft);
      if (timeleft <= 0) clearInterval(downloadTimer);
    }, 1000);
  };
  return (
    <div>
      <Input
        label="Код подтверждения"
        className={classes.codeInput}
        value={phoneConfirmationCode}
        onChange={(event) => { setPhoneConfirmationCode(event.target.value); }}
      />
      <HelpText className={classes.phoneVerificationV2__helpText_countdown} textBeforeLink={timeTillNextCode ? `Повторную отправку можно выполнить через ${timeTillNextCode}c` : 'Если код не был доставлен, мы можем отправить его повторно'} />
      <div className={clsx(classes.element__horizontallyAlignedButtons, classes.phoneVerificationV2__buttons)}>
        <Button
          className={clsx(classes.phoneVerificationV2__buttons__button, classes.phoneVerificationV2__buttons__buttonOneMoreTime)}
          color="secondary"
          disabled={timeTillNextCode !== 0}
          onClick={() => { submitPhone(); startTimer(secondsTillNextCode); }}
        >
          Выслать ещё раз
        </Button>
        <Button
          className={classes.phoneVerificationV2__buttons__button}
          disabled={phoneConfirmationCode.length !== 6}
          onClick={submitCode}
        >
          Подтвердить
        </Button>

      </div>
    </div>
  );
};

const styles = () => ({
  phoneVerificationV2__buttons__buttonOneMoreTime: {
    paddingLeft: '36px',
    paddingRight: '36px',
    marginRight: '20px',

  },
  codeInput: {
    marginTop: '20px',
    width: '100%',
    '& div:nth-of-type(2)': {
      width: '100%',
    },
    '& div:nth-of-type(1)': {
      textAlign: 'left',
    },
  },
  phoneVerificationV2__helpText_countdown: {
    marginTop: '10px',
    width: '100%',
    textAlign: 'left',
  },
  element__horizontallyAlignedButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  phoneVerificationV2__buttons: {
    marginTop: '30px',
  },
  phoneVerificationV2__buttons__button: {
    width: '190px',
  },
});

export default withStyles(styles)(PhoneVerificationV2);
