import React, { useState } from 'react';
import VKStepper
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/VKStepper';
import EmptyStepper
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/EmptyStepper';
import MessengerStepper from '@pages/offers/components/OfferConnect/components/MessengerStepper';
import FacebookStepper
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/FacebookStepper';
import LandbotStepper from '@pages/offers/components/OfferConnect/components/LandbotStepper';

const OfferConnect = ({ offer, onClose, open }) => {
  /**
     * TODO: Rewrite props pass, in order to make it more specific (remove {...props} pass )
     */
  const [chosenChannel, setChosenChannel] = useState('');
  switch (chosenChannel) {
    case 'viber':
    case 'whatsapp':
    case 'telegram':
      return (
        <MessengerStepper
          chosenChannel={chosenChannel}
          setChosenChannel={setChosenChannel}
          offer={offer}
          open={open}
          onClose={onClose}
        />
      );
    case 'vk':
      return (
        <VKStepper
          chosenChannel={chosenChannel}
          setChosenChannel={setChosenChannel}
          offer={offer}
          open={open}
          onClose={onClose}
        />
      );
    case 'authless_widget':
      return (
        <LandbotStepper
          chosenChannel={chosenChannel}
          setChosenChannel={setChosenChannel}
          offer={offer}
          open={open}
          onClose={onClose}
        />
      );
    case 'instagram':
    case 'facebook':
      return (
        <FacebookStepper
          chosenChannel={chosenChannel}
          setChosenChannel={setChosenChannel}
          offer={offer}
          open={open}
          onClose={onClose}
        />
      );
    default:
      return (
        <EmptyStepper
          chosenChannel={chosenChannel}
          setChosenChannel={setChosenChannel}
          open={open}
          onClose={onClose}
          offer={offer}
        />
      );
  }
};

export default OfferConnect;
