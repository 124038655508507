export const TOOLS = 'TOOLS';
export const BALANCE = 'BALANCE';
export const REPORTS = 'REPORTS';
export const BY_INTERACTION = 'BY_INTERACTION';
export const BY_DAY = 'BY_DAY';
export const GROUPING_BY_HOURS = 'GROUPING_BY_HOURS';
export const GROUPING_BY_DAYS = 'GROUPING_BY_DAYS';
export const GROUPING_BY_MONTHS = 'GROUPING_BY_MONTHS';
export const GROUPING_BY_YEARS = 'GROUPING_BY_YEARS';
export const HOUR = 'HOUR';
export const DAY = 'DAY';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const CUSTOM_DATES = 'CUSTOM_DATES';
export const TODAY = 'TODAY';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_14_DAYS = 'LAST_14_DAYS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const CURRENT_MONTH = 'CURRENT_MONTH';
export const LAST_MONTH = 'LAST_MONTH';
export const ADD_FILTER = 'ADD_FILTER';
export const ADD_GROUPING = 'ADD_GROUPING';
export const ADD_COLUMNS = 'ADD_COLUMNS';
export const DATE = 'DATE';
export const USERS = 'USERS';
export const APPROVED_CONVERSIONS = 'APPROVED_CONVERSIONS';
export const HOLD_CONVERSIONS = 'HOLD_CONVERSIONS';
export const REJECTED_CONVERSIONS = 'REJECTED_CONVERSIONS';
export const ALL_GOALS = 'ALL_GOALS';
export const OFFER = 'OFFER';
export const CHANNEL = 'CHANNEL';
export const PLATFORM = 'PLATFORM';
export const OFFERS = 'OFFERS';
export const ACTIONS = 'ACTIONS';
export const ACTIONS_COUNT = 'ACTIONS_COUNT';
export const CHURN_COUNT = 'CHURN_COUNT';
export const INCOME = 'INCOME';
export const TOTAL = 'TOTAL';
export const ACTION_TYPE = 'ACTION_TYPE';
export const NEW_USERS = 'NEW_USERS';
export const ACTIVE = 'ACTIVE';
export const ACTIVE_USERS = 'ACTIVE_USERS';
export const CHURN = 'CHURN';
export const DESCRIPTION = 'DESCRIPTION';
export const AVAILABLE_CHANNELS = 'AVAILABLE_CHANNELS';
export const OLDEST_FIRST = 'OLD_FIRST';
export const NEWEST_FIRST = 'NEWEST_FIRST';
export const GOALS_AND_ACTIONS = 'GOALS_AND_ACTIONS';
export const HOLD = 'HOLD';
export const ON_HOLD = 'ON_HOLD';
export const COUNTRY = 'COUNTRY';
export const CZECH_REPUBLIC = 'CZECH_REPUBLIC';
export const UKRAINE = 'UKRAINE';
export const SPAIN = 'SPAIN';
export const POLAND = 'POLAND';
export const USA = 'USA';
export const KAZAKHSTAN = 'KAZAKHSTAN';
export const RUSSIA = 'RUSSIA';
export const ITALY = 'ITALY';
export const GERMANY = 'GERMANY';
export const RESET = 'RESET';
export const DIALOGS_COUNT = 'DIALOGS_COUNT';
export const LEADS = 'LEADS';
export const CR_CLICKED_LINKS = 'CR_CLICKED_LINKS';
export const EARNINGS_PER_CONVERSION = 'EARNINGS_PER_CONVERSION';
export const CR_GIVEN_LINKS = 'CR_GIVEN_LINKS';
export const AUDIENCE_CHURN = 'AUDIENCE_CHURN';
export const EARNINGS_PER_DIALOG = 'EARNINGS_PER_DIALOG';
export const PAYMENTS_AMOUNT = 'PAYMENTS_AMOUNT';
export const RATING_NOT_YET_AVAILABLE = 'RATING_NOT_YET_AVAILABLE';
export const DIALOG_COST = 'DIALOG_COST';
export const CONNECT = 'CONNECT';
export const CHANNELS = 'CHANNELS';
export const NAME = 'NAME';
export const SETTINGS = 'SETTINGS';
export const DIALOGS = 'DIALOGS';
export const LINKS = 'LINKS';
export const ANALYTICS = 'ANALYTICS';
export const REMOVE = 'REMOVE';
export const FINANCES = 'FINANCES';
export const GEOGRAPHY = 'GEOGRAPHY';
export const UNWANTED_CITIES = 'UNWANTED_CITIES';
export const PREFERRED_CITIES = 'PREFERRED_CITIES';
export const CITIES = 'CITIES';
export const NO_DATA = 'NO_DATA';
export const ALL_CHANNELS = 'ALL_CHANNELS';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const TRACKING_LINK = 'TRACKING_LINK';
export const SOURCE = 'SOURCE';
export const TRAFFIC_TYPE = 'TRAFFIC_TYPE';
export const CAMPAIGN_NAME = 'CAMPAIGN_NAME';
export const EXTRA_FIELD = 'EXTRA_FIELD';
export const MAIN_PARAMETERS = 'MAIN_PARAMETERS';
export const ADDITIONAL_PARAMETERS = 'ADDITIONAL_PARAMETERS';
export const DIRECT_LINK = 'DIRECT_LINK';
export const HTML_CODE = 'HTML_CODE';
export const CLICK_TO_COPY = 'CLICK_TO_COPY';
export const COPIED = 'COPIED';
export const ORDER_A_PAYMENT = 'ORDER_A_PAYMENT';
export const TOTAL_ON_ACCOUNT = 'TOTAL_ON_ACCOUNT';
export const AVAILABLE_FOR_WITHDRAWAL = 'AVAILABLE_FOR_WITHDRAWAL';
export const PAYOUT_HISTORY = 'PAYOUT_HISTORY';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const AMOUNT = 'AMOUNT';
export const STATUS = 'STATUS';
export const ESTIMATE_DATE = 'ESTIMATE_DATE';
export const BILL = 'BILL';
export const BILLING_METHOD = 'BILLING_METHOD';
export const COMMENT = 'COMMENT';
export const CREATED = 'CREATED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CLOSED = 'CLOSED';
export const NEW_REQUEST = 'NEW_REQUEST';
export const ENTER_THE_AMOUNT_TO_WITHDRAW = 'ENTER_THE_AMOUNT_TO_WITHDRAW';
export const DOWNLOAD = 'DOWNLOAD';
export const REQUEST = 'REQUEST';
export const SWITCH_PROFILE = 'SWITCH_PROFILE';
export const YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED = 'YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED';
export const YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED = 'YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED';
export const GROUPS_VK = 'GROUPS_VK';
export const PAGES_FACEBOOK = 'PAGES_FACEBOOK';
export const CONNECTING_TELEGRAM_BOT = 'CONNECTING_TELEGRAM_BOT';
export const CONNECTING_VIBER_BOT = 'CONNECTING_VIBER_BOT';
export const TOKEN = 'TOKEN';
export const AUTHORIZATION = 'AUTHORIZATION';
export const SIGN_IN = 'SIGN_IN';
export const WHICH_CHANNEL_TO_CONNECT = 'WHICH_CHANNEL_TO_CONNECT';
export const LOGIN_WELCOME_TEXT = 'LOGIN_WELCOME_TEXT';
export const LOGIN = 'LOGIN';
export const PASSWORD = 'PASSWORD';
export const DOES_NOT_HAVE_ACCOUNT_YET_SIGN_UP = 'DOES_NOT_HAVE_ACCOUNT_YET_SIGN_UP';
export const PAUSE = 'PAUSE';
export const START = 'START';
export const MANAGE = 'MANAGE';
export const OFFER_CONNECT_TOOLTIP = 'OFFER_CONNECT_TOOLTIP';
export const CHANNELS_EXPANSION_LIST_TOOLTIP = 'CHANNELS_EXPANSION_LIST_TOOLTIP';
export const CHANNELS_ADD_BUTTON_TOOLTIP = 'CHANNELS_ADD_BUTTON_TOOLTIP';
export const CHANNELS_DIALOGS_TOOLTIP = 'CHANNELS_DIALOGS_TOOLTIP';
export const CHANNELS_LINKS_TOOLTIP = 'CHANNELS_LINKS_TOOLTIP';
export const CHANNELS_ANALYTICS_TOOLTIP = 'CHANNELS_ANALYTICS_TOOLTIP';
export const APPEARANCE = 'APPEARANCE';
