// First step
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import fetch from '@main/utils/handledFetch';
import * as Sentry from '@sentry/react';
import { showToast } from '@components/utils';

export const fetchVerificationId = async (phoneNumber, type) => {
  const response = await fetch(
    `${backendURL}/twirp/cpa.backend.SMSVerificator/CreateTicket`,
    {
      ...twirpFetchOptions,
      headers: {
        ...twirpFetchOptions?.headers,
        auth: 'AS&fghaos78%EU92pqdfas',
      },
      method: 'POST',
      body: JSON.stringify({
        number: (phoneNumber).match(/\d/g).join(''),
        type,
      }),
    },
  );

  if (!response.ok) {
    throw new Error(response);
  }

  return response.json();
};

// Second step
export const fetchSecretKey = async (verificationId, code, type) => {
  const response = await fetch(
    `${backendURL}/twirp/cpa.backend.SMSVerificator/Verify`,
    {
      ...twirpFetchOptions,
      headers: {
        ...twirpFetchOptions?.headers,
        auth: 'AS&fghaos78%EU92pqdfas',
      },
      method: 'POST',
      body: JSON.stringify({
        token: verificationId,
        code,
        type,
      }),
    },
  );

  // if (!response.ok) {
  //   throw new Error(response);
  // }
};
