import { withStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const WordWithLine = ({ word, classes, className }) => {
  const line = (
    <span className={classes.line}>
      <div />
      <div />
    </span>
  );
  return (
    <div className={clsx(classes.wordWithLine, className)}>
      {line}
      {word}
      {line}
    </div>
  );
};

const wordWithLineStyle = () => ({
  wordWithLine: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  line: {
    '&>div': {
      height: '50%',
    },
    width: '171px',
    '&>div:first-of-type': {
      borderBottom: '1px solid #CACACA',
    },

  },
});
export default withStyles(wordWithLineStyle)(WordWithLine);
