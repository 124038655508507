import { withStyles } from '@material-ui/styles';
import { FullWhiteLogoIcon, Typography } from '@aisales/react-ui-kit';
import React from 'react';

const AisalesTitle = (({ classes }) => (
  <div className={classes.aisalesTitle}>
    <FullWhiteLogoIcon className={classes.logoIcon} />
    <div className={classes.text}>
      <div className={classes.text__upperPart}>
        <Typography variant="title" style="white" className={classes.aisalesTitle__title}>
          Chat Exchange Network
        </Typography>
      </div>
      <div className={classes.text__bottomPart}>

        <Typography style="white" className={classes.aisalesTitle__text}>
          {/* Программы онлайн-консультанты и программы-клиенты для обмена сообщениями в реальном времени через Интернет. */}
          Online consultant programs and client programs for real-time messaging over the Internet.
        </Typography>

      </div>
    </div>
  </div>
));

const aisalesTitleStyle = () => ({
  aisalesTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  aisalesTitle__title: {
    fontSize: '32px',
    marginBottom: '15px',
  },
  aisalesTitle__text: {
    fontSize: '20px',
    lineHeight: '157%',
  },
  logoIcon: {
    marginBottom: '27px',
    marginLeft: '60px',
    marginTop: '60px',

  },
  text: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '50%',
    marginBottom: '66px',
    fontSize: '35px',
  },
  text__upperPart: {
    width: '600px',
    marginBottom: '15px',
  },
  text__bottomPart: {
    width: '600px',

  },
});
export default withStyles(aisalesTitleStyle)(AisalesTitle);
