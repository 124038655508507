import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { setLoggedVK } from '@store/actions';
import { languages } from '@pages/channels/constants';
import {
  CZECH_REPUBLIC,
  GERMANY,
  ITALY,
  KAZAKHSTAN,
  POLAND,
  RUSSIA,
  SPAIN,
  UKRAINE,
  USA,
} from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import fetch from '@main/utils/handledFetch';

const style = () => ({});

// const socialNetworkName = 'Вконтакте';
const socialNetworkName = 'Vkontakte';
const VKConnector = ({ children, offerId, ...otherProps }) => {
  const [groupToAdd, setGroupToAdd] = useState(null);
  const isLoggedVK = useSelector((state) => state.splitview.get('isLoggedVK'));
  const dispatch = useDispatch();
  const project = JSON.parse(localStorage.getItem('user'))?.current_project;
  const { t } = useTranslation();
  const history = useHistory();
  const countries = [{ code: 'RU', name: t(RUSSIA) },
    { code: 'CZ', name: t(CZECH_REPUBLIC) },
    { code: 'UA', name: t(UKRAINE) },
    { code: 'ES', name: t(SPAIN) },
    { code: 'PL', name: t(POLAND) },
    { code: 'US', name: t(USA) },
    { code: 'KZ', name: t(KAZAKHSTAN) },
    { code: 'IT', name: t(ITALY) },
    { code: 'DE', name: t(GERMANY) }];

  const [groups, setGroups] = useState(null);
  const [userName, setUserName] = useState(null);

  const addGroup = async (name, platform_id, language, country, translate, uri, monetization) => {
    const token = localStorage.getItem('group_access_token');
    // this.setState({pending: true});
    const response = await fetch(
      `${backendURL
      }/twirp/cpa.offers.OffersManagementService/ConnectOffer`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          offer_id: Number(offerId),
          monetization,
          channel: 'vk',
          group_id: platform_id,
          url: uri,
          platform_input: {
            name,
            channel: 'vk',
            token,
            id: platform_id,
            language,
            country,
            translate,
          },
        }),
      },
    );
    if (response.ok) {
      const result = await response.json();
      history.push('/app/channels');
      return result;
    }
    return {};
  };

  const getList = () => {
    const fetchJsonp = require('fetch-jsonp');
    fetchJsonp(
      `https://api.vk.com/method/groups.get?filter=admin&extended=1&access_token=${
        localStorage.getItem('vk_access_token')
      }&v=5.92&callback=JSONP_CALLBACK'`,
      { JSONP_CALLBACK: 'JSONP_CALLBACK' },
    )
      .then((response) => response.json())
      .then((json) => {
        setGroups(json.response);
      })
      .catch((ex) => {
        localStorage.removeItem('vk_access_token');
      });
  };

  const getUserName = async () => {
    const fetchJsonp = require('fetch-jsonp');
    fetchJsonp(
      `https://api.vk.com/method/users.get?access_token=${
        localStorage.getItem('vk_access_token')
      }&v=5.92`,
      { JSONP_CALLBACK: 'JSONP_CALLBACK' },
    )
      .then((response) => response.json())
      .then((json) => {
        setUserName(`${json.response[0].first_name} ${json.response[0].last_name}`);
      })
      .catch((ex) => {
        localStorage.removeItem('vk_access_token');
      });
  };

  const vkConnect = async () => {
    localStorage.removeItem('vk_access_token');
    const auth = window.open(
      `https://oauth.vk.com/authorize?client_id=6781535&display=popup&redirect_uri=${window.location.origin}/redirect/vk/user&scope=groups&response_type=token&v=5.92`,
      'Авторзация',
      'height=600,width=600',
    );
    const poptick = setInterval(
      () => {
        if (auth.closed) {
          if (localStorage.getItem('vk_access_token') !== null) {
            clearInterval(poptick);
            dispatch(setLoggedVK(true));
            getList();
            getUserName();
          }
        }
      },
      500,
    );
  };

  const getGroupToken = async ({ group, monetization }) => {
    localStorage.removeItem('group_access_token');
    const groupPermWindow = window.open(
      `https://oauth.vk.com/authorize?client_id=6781535&group_ids=${
             group?.groupId
      }&display=page&redirect_uri=${
        window.location.origin
      }/redirect/vk/group&scope=manage,messages,docs,photos,stories&response_type=token&v=5.92`,
      'Доступ к группе',
      'height=600,width=800',
    );
    const poptick = setInterval(async () => {
      let answer;
      if (groupPermWindow.closed) {
        if (localStorage.getItem('group_access_token') !== null) {
          answer = await addGroup(
            groupToAdd.name,
            String(groupToAdd.groupId),
            languages[groupToAdd.lang]
              .code,
            countries[
              groupToAdd.country
            ].code,
            monetization,
          );
        }
        clearInterval(poptick);
        if (answer) {
          return answer;
        }
      }
    }, 500);
  };

  useEffect(() => {
    if (localStorage.getItem('vk_access_token')) {
      getList();
      getUserName();
    }
  }, []);

  // if (localStorage.getItem("vk_access_token")) {
  //     getList();
  //     getUserName()
  // }

  const adaptGroups = (groups) => groups?.map?.((group) => {
    group.image = group.photo_50;
    return group;
  });

  return React.cloneElement(children, {
    setLogged: (isLogged) => {
      dispatch(setLoggedVK(isLogged));
    },
    socialNetworkName,
    connect: vkConnect,
    userName,
    groupToAdd,
    setGroupToAdd,
    groups: { items: adaptGroups(groups?.items) },
    onFinish: getGroupToken,
    isLogged: isLoggedVK,
    ...children.props,
    ...otherProps,
  });
};

export default withStyles(style)(VKConnector);
