import React from 'react';
import Button from '@material-ui/core/Button';
import { InputBase, Typography, withStyles } from '@material-ui/core';
import { ExpansionList, ListElem } from '@aisales/react-uikit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import {
  CONNECT,
  COUNTRY,
  CZECH_REPUBLIC,
  GERMANY,
  ITALY,
  KAZAKHSTAN,
  NAME,
  POLAND,
  RUSSIA,
  SPAIN,
  UKRAINE,
  USA,
} from '@main/i18n/aliases';
import { withTranslation } from 'react-i18next';
import { showToast } from '../../../oldOffers/utils';
import { backendURL } from '../../../../toRemove';
import { languages } from '../../constants';

const countries = [];

class Bot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      name: '',
      id: '',
      found: false,
      country: {},
      lang: {},
      check: false,
    };

    this.t = props.t;

    countries.push(
      { code: 'CZ', name: this.t(CZECH_REPUBLIC) },
      { code: 'UA', name: this.t(UKRAINE) },
      { code: 'ES', name: this.t(SPAIN) },
      { code: 'PL', name: this.t(POLAND) },
      { code: 'US', name: this.t(USA) },
      { code: 'KZ', name: this.t(KAZAKHSTAN) },
      { code: 'RU', name: this.t(RUSSIA) },
      { code: 'IT', name: this.t(ITALY) },
      { code: 'DE', name: this.t(GERMANY) },
    );

    this.channel = props.channel;

    if (props.platform) {
      let language = {};
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].code === props.platform.language) {
          language = languages[i];
          break;
        }
      }
      let country = {};
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].code === props.platform.country) {
          country = countries[i];
          break;
        }
      }
      this.state = {
        ...this.state,
        country,
        lang: language,
        ...props.platform,
      };
    }

    this.classes = this.props.classes;

    this.project = JSON.parse(localStorage.getItem('user'))?.current_project;
  }

    addPlatform = () => {
      const body = {
        name: this.state.name?.trim(),
        country: countries[this.state.country].code,
        language: languages[this.state.lang].code,
        translate: this.state.translate,
      };
      let method = 'POST';
      let suffix = '';
      if (this.isEdit()) {
        delete body.token;
        method = 'PUT';
        suffix = this.props.platform.id;
      }

      fetch(`${backendURL}/api/channels/${this.channel}/${suffix}`, {
        method,
        headers: {
          'Content-type': 'application/json',
          'offer-id': this.state.offer_id,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (!response.ok) {
          showToast(
            // this.isEdit() ? 'Ошибка записи' : 'Ошибка подключения',
            this.isEdit() ? 'Write error' : 'Connection error',
          );
        }
        showToast(
          this.isEdit() ? '' +
            // 'Изменения сохранены' : 'Бот подключен',
            'Changes was save' : 'Bot connected',
          true,
        );
        response.json().then((json) => {
          this.props.updateChannelsList({
            id: json.platform_id || this.props.platform.id,
            name: this.state.name,
            token: this.state.token,
            enabled: this.isEdit() ? this.props.platform.enabled : true,
            country: countries[this.state.country].code,
            language: languages[this.state.lang].code,
            translate: this.state.translate,
            channel: this.channel,
          });
        });
      });
    };

    isValidToken(token) {
      if (this.channel === 'telegram') {
        return /\d+:(.*)/.test(token);
      }
      return token?.trim().length > 5;
    }

    isEdit() {
      return Boolean(this.props.platform);
    }

    render() {
      return (
        <div style={{ display: 'flex', height: '100%' }}>
          <div
            style={{
              textAlign: 'center',
              width: '200px',
              margin: 'auto',
              marginTop: '20px',
              flex: '1 0 350px',
            }}
          >
            <div
              style={{
                textAlign: 'left',
                marginTop: '10px',
                paddingTop: '10px',
                width: '215px',
                margin: 'auto',
              }}
            >
              <div>
                <Typography
                  variant="caption"
                  style={{ display: 'block', marginLeft: '5px' }}
                >
                  {this.t(NAME)}
                  :
                </Typography>
                <div
                  className={classNames(
                    this.classes.inputWrapper,
                    {
                      invalid:
                                  this.state.name?.trim() === ''
                                  && this.state.check,
                    },
                  )}
                >
                  <InputBase
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    placeholder={this.t(NAME)}
                    style={{
                      width: '100%',
                      marginLeft: '10px',
                      paddingTop: '2px',
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Typography
                  variant="caption"
                  style={{ display: 'block', marginLeft: '5px' }}
                >
                  {this.t(COUNTRY)}
                  :
                </Typography>
                <ExpansionList
                  closeOnClick
                  maxHeight="300px"
                  style={{
                    boxShadow: 'none',
                    maxWidth: '215px',
                    width: '215px',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    lineHeight: '13px',
                    color: 'black',
                    height: '40px',
                  }}
                  textValue={this.state.country.name}
                >
                  {countries.map(
                    (country, index) => country.code
                          !== this.state.country.code && (
                          <ListElem
                            key={`country${country.code}`}
                            onClick={() => this.setState({
                              country: index,
                            })}
                          >
                            {country.name}
                          </ListElem>
                    ),
                  )}
                </ExpansionList>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Typography
                  variant="caption"
                  style={{ display: 'block', marginLeft: '5px' }}
                >
                  Язык:
                </Typography>
                <ExpansionList
                  closeOnClick
                  maxHeight="300px"
                  style={{
                    boxShadow: 'none',
                    maxWidth: '215px',
                    width: '215px',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    lineHeight: '13px',
                    color: 'black',
                    height: '40px',
                  }}
                  textValue={this.state.lang.name}
                >
                  {languages.map(
                    (lang, index) => lang.code !== this.state.lang.code && (
                      <ListElem
                        key={`lang${lang.code}`}
                        onClick={() => this.setState({
                          lang: index,
                        })}
                      >
                        {lang.name}
                      </ListElem>
                    ),
                  )}
                </ExpansionList>
              </div>
              <div>
                <FormControlLabel
                  style={{
                    margin: 0,
                    marginTop: '10px',
                    width: '100%',
                    justifyContent: 'space-between',
                    paddingLeft: '5px',
                  }}
                  control={(
                    <Checkbox
                      style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        opacity: 0.7,
                        padding: '0',
                        color:
                                                this.channel === 'telegram'
                                                  ? 'rgb(91, 127, 184)'
                                                  : '#7e4ca0',
                      }}
                      color="primary"
                    />
                                  )}
                  onChange={() => this.setState({
                    translate: !this.state.translate,
                  })}
                  checked={this.state.translate}
                  labelPlacement="start"
                  label={(
                    <Typography
                      variant="caption"
                      style={{ userSelect: 'none' }}
                    >
                      {/* Перевод */}
                      Translate
                    </Typography>
                                  )}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: '10px',
                  backgroundColor:
                                    this.channel === 'telegram'
                                      ? '#60a2d0'
                                      : '#7e4ca0',
                  fontSize: '12px',
                  fontWeight: 600,
                }}
                onClick={() => {
                  if (
                    (!this.isValidToken(this.state.token)
                                        && !this.isEdit())
                                    || this.state.name?.trim() === ''
                  ) {
                    this.setState({ check: true });
                    return;
                  }
                  this.addPlatform();
                }}
                fullWidth
              >
                {/* {this.isEdit() ? 'Сохранить' : this.t(CONNECT)} */}
                {this.isEdit() ? 'Save' : this.t(CONNECT)}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: '10px',
                  fontSize: '12px',
                  color: 'white',
                }}
                onClick={() => {
                  this.props.onClose();
                }}
                fullWidth
              >
                {/* Отмена */}
                Cancel
              </Button>
            </div>
          </div>
          {!this.isEdit() && (
          <div
            style={{
              padding: '15px',
              boxShadow: 'inset 0 0px 15px 6px #00000024',
            }}
          >
            {this.channel === 'telegram' && (
            <>
              {/* <h3>Как подключить Telegram бота</h3> */}
              {/* <p> */}
              {/*   Подключите Telegram Бота в 3 простых шага: */}
              {/*   <ol> */}
              {/*     <li> */}
              {/*       Откройте приложение Telegram и */}
              {/*       перейдите в аккаунт @Botfather, */}
              {/*       создайте нового бота используя */}
              {/*       команду /newbot, если Вы еще этого */}
              {/*       не сделали. Можно также: задать */}
              {/*       аватар, описание и приветствие */}
              {/*       вашего бота. Создать и управлять */}
              {/*       ботом можно бесплатно. */}
              {/*     </li> */}
              {/*     <li> */}
              {/*       После этого с помощью команды /token */}
              {/*       получите ключ доступа к боту */}
              {/*       (токен). Обычно он выглядит так: */}
              {/*       3245214234:AAsdgfgre87qJddYccR-ynswefcWz21324 */}
              {/*     </li> */}
              {/*     <li> */}
              {/*       Скопируйте его и вставьте в поле */}
              {/*       выше. */}
              {/*     </li> */}
              {/*   </ol> */}
              {/* </p> */}
              <h3>How to connect Telegram bot</h3>
              <p>
                Connect Telegram Bot in 3 easy steps:
                <ol>
                  <li>
                    Open the Telegram app and
                    go to @Botfather account,
                    create a new bot using
                    command /newbot if you still have this
                    did not do. You can also: set
                    avatar, description and greeting
                    your bot. Create and manage
                    bot is free.
                  </li>
                  <li>
                    After that, using the /token command
                    get a bot access key
                    (token). It usually looks like this:
                    3245214234:AAsdgfgre87qJddYccR-ynswefcWz21324
                  </li>
                  <li>
                    Copy it and paste it into the field
                    above.
                  </li>
                </ol>
              </p>
            </>
            )}
            {this.channel === 'viber' && (
            <>
              {/* <h3>Как подключить бота Viber</h3> */}
              {/* <p> */}
              {/*   <ol> */}
              {/*     <li> */}
              {/*       Перейдите по ссылке: */}
              {/*       {' '} */}
              {/*       <a href="https://partners.viber.com/account/create-bot-account"> */}
              {/*         https://partners.viber.com/account/create-bot-account */}
              {/*       </a> */}
              {/*       {' '} */}
              {/*       и зарегистрируйтесь, указав свой */}
              {/*       номер телефона. */}
              {/*     </li> */}
              {/*     <li> */}
              {/*       В разделе "Create Bot Account" */}
              {/*       загрузите логотип, заполните все */}
              {/*       поля. В поле Url укажите, как будет */}
              {/*       отображаться адрес вашего бота. */}
              {/*       Выберите локацию, передвинув значок */}
              {/*       на карте в нужное место. Нажмите */}
              {/*       "Create". */}
              {/*     </li> */}
              {/*     <li> */}
              {/*       Вы попадете на страницу, где будет */}
              {/*       указан токен вашего аккаунта. */}
              {/*       Нажмите "Copy", чтобы скопировать */}
              {/*       токен. */}
              {/*     </li> */}
              {/*     <li> */}
              {/*       Полученный токен вставьте в поле */}
              {/*       выше. */}
              {/*     </li> */}
              {/*   </ol> */}
              {/* </p> */}
              <h3>How to connect a Viber bot</h3>
              <p>
                <ol>
                  <li>
                    Follow this link:
                    {' '}
                    <a href="https://partners.viber.com/account/create-bot-account">
                      https://partners.viber.com/account/create-bot-account
                    </a>
                    {' '}
                    and register with your
                    phone number.
                  </li>
                  <li>
                    Under "Create Bot Account"
                    upload logo, fill in all
                    fields. In the Url field, specify how it will be
                    display the address of your bot.
                    Select a location by moving the icon
                    on the map to the right place. Click
                    "Create".
                  </li>
                  <li>
                    You will be taken to a page where
                    your account token is specified.
                    Click "Copy" to copy
                    token.
                  </li>
                  <li>
                    Paste the received token into the field
                    above.
                  </li>
                </ol>
              </p>
            </>
            )}
          </div>
          )}
        </div>
      );
    }
}

const styles = {
  inputWrapper: {
    display: 'flex',
    background: 'rgb(255, 255, 255)',
    paddingRight: '10px',
    border: '1px solid rgb(230, 230, 230)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '40px',
    transition: 'border .2s',
    '&.invalid': {
      border: '1px solid rgb(234, 132, 132)',
    },
  },
};

export default withTranslation()(withStyles(styles)(Bot));
