import { FbIcon, GoogleIcon } from '@aisales/react-ui-kit';
import React from 'react';

export const getHrefGoogleButton = (refCode, mode = 'login') => {
  const link = `https://accounts.google.com/o/oauth2/v2/auth?client_id=872429235493-rhd06dl47r6pmnbhj3e6e8th92jpvlik.apps.googleusercontent.com\n&response_type=code&state=${btoa(JSON.stringify({
    from: 'google',
    ref: refCode,
  }))}&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&redirect_uri=${window.location.origin}`;
  return `${link}/${mode}`;
};
export const getHrefFacebookButton = (refCode, mode = 'login') => `https://www.facebook.com/v9.0/dialog/oauth/?scope=email&client_id=690302514718936&state=${btoa(JSON.stringify({
  from: 'facebook',
  ref: refCode,
}))}&redirect_uri=${encodeURIComponent(`https://${window.location.host}/${mode}`)
  }`;

const alternativeEnterInfo = {
  google: {
    name: 'Google',
    icon:
      <GoogleIcon />,
    getHref:
      getHrefGoogleButton,
  },
  /*facebook: {
    name: 'Facebook',
    icon:
  <FbIcon />,
    getHref:
  getHrefFacebookButton,
  }*/

};

export default alternativeEnterInfo;
