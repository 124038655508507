import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Tab, Tabs } from '@aisales/react-ui-kit';
import Profile from './Profile';
import AddPayment from './AddPayment';

export default function Index() {
  return (
    <Switch>
      <Tabs>
        <Tab label="" />
        <Tab label="" />
      </Tabs>
      <Route path="/app/profile" render={() => <Profile />} exact />
      <Route
        path="/app/profile/add_payment"
        render={() => <AddPayment />}
        exact
      />
    </Switch>
  );
}
