import React from 'react';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { showToast } from '@pages/oldOffers/utils';
import {
  blueColor, Typography,
} from '@aisales/react-ui-kit';
import Top from '@pages/entrance/components/EntranceWrapper/components/Top';
import AisalesTitle from '@pages/entrance/components/EntranceWrapper/components/AisalesTitle';
import clsx from 'clsx';
import background from '../../assets/background.jpg';

/** *
 * Табы некрасиво показываются, нужно обернуть switch в entranceWrapper
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const Index = (props) => {
  const {
    children,
    classes,
    currentTabIdx,
    error,
    isAuthenticated,
    // eslint-disable-next-line no-unused-vars
    onChangeTabIdx,
    resetError,
  } = props;

  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/app/dashboard' }} />;
  }

  if (error) {
    showToast('Логин или пароль не верны', false);
    resetError();
  }

  return (
    <div className={classes.entranceWrapper}>
      <div className={classes.entranceWrapper__leftPart}>
        <img className={classes.entranceWrapper__leftPartBackground} src={background} alt="background" />
        <div className={clsx(classes.entranceWrapper__leftPartBackground, classes.entranceWrapper__leftPartBackground_blue)} />
        <div className={classes.entranceWrapper__aisalesTitle}>
          <AisalesTitle style={{ marginBottom: '41px' }} />
        </div>

      </div>
      <div className={clsx(classes.entranceWrapper__rightPart, classes.element_centralizedHorizontally)}>
        <div className={classes.entranceWrapper__form}>
          <div className={classes.entranceWrapper__tabs}>
            {
                    currentTabIdx !== undefined
                      ? <Top currentTabIdx={currentTabIdx} /> : undefined
        }
          </div>

          {children}
          <Typography variant="detailed" className={clsx(classes.entranceWrapper__aisalesTag, classes.element_centralizedHorizontally)}>
            © 2022 AIsales
          </Typography>
        </div>

      </div>

    </div>
  );
};

const styles = {
  entranceWrapper: {
    display: 'flex',
    height: '100vh',
  },
  entranceWrapper__tabs: {
    marginBottom: '24px',
  },
  entranceWrapper__aisalesTitle: {
    zIndex: '5',
    position: 'absolute',
    width: '100%',
  },
  entranceWrapper__leftPart: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  entranceWrapper__rightPart: {
    width: '100%',
  },
  entranceWrapper__form: {
    width: '400px',
    marginTop: '70px',
    position: 'relative',
  },
  element_centralizedHorizontally: {
    display: 'flex',
    justifyContent: 'center',
  },
  entranceWrapper__leftPartBackground: {
    position: 'absolute',
    width: '100%',
    zIndex: '1',
  },
  entranceWrapper__leftPartBackground_blue: {
    backgroundColor: blueColor[100],
    height: '100%',
    bottom: '0',
    zIndex: '0',
  },
  entranceWrapper__aisalesTag: {
    width: '100%',
    position: 'absolute',
    bottom: '40px',
    color: '#949595',

  },
  background: {
    position: 'absolute',
    width: '100%',
    top: '0',
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    right: '0',
    left: '0',
    bottom: '0',
  },
};

export default withStyles(styles)(Index);
