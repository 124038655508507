import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { greyColor, OfferIcon, StarIcon } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import MyOffers from '@pages/offers/components/MyOffers';
import { useHistory, useLocation } from 'react-router';
import PageTemplate from '@components/PageTemplate';
import Offer from './components/Offer/index';
import OffersList from './components/OffersList';
import { useSelector } from 'react-redux';

const style = (theme) => ({
  root: {
    backgroundColor: greyColor.background,
    height: '100%',
  },
  content: {
    height: '100%',
    paddingTop: '40px',
    // paddingLeft: '30px',
    // paddingRight: '30px',
    flexFlow: 'column',
    display: 'flex',
  },
  tabs: {
    marginTop: '15px',
  },
  subtabsWrapper: {
    borderBottom: `1px solid ${greyColor[25]}`,
  },
  title: {
    marginBottom: '15px',
  },
  internalContent: {
    // paddingRight: '30px',
    // paddingLeft: '30px',
    // paddingTop: '30px',
  },
  footer: {
    height: '30px',
    width: '100%',
  },
  subtabs: {
    paddingTop: '30px',
    marginLeft: '30px',
  },
  upperPart: {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  lowerPart: {
    overflowY: 'scroll',
    flex: '1',
    display: 'flex',
    paddingRight: '30px',
    paddingLeft: '30px',
    paddingBottom: '30px',
  },
  tabpanel: {
    height: 'fit-content',
    width: '100%',
    minHeight: '100%',
  },
});
const Offers = (props) => {
  const pathPrefix = '/app/offers';
  const handleExternalChangeTab = (value) => {
    setExternalTabValue(value);
  };
  const history = useHistory();
  const location = useLocation();
  const [externalTabValue, setExternalTabValue] = useState(0);

  useEffect(() => {
    if (location.pathname === `${pathPrefix}/my-offers`) {
      setExternalTabValue(1);
    }
  }, [location]);
  const [internalTabValue, setInternalTabValue] = useState(0);
  const { classes } = props;

  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  let customTabs = []

  if (isAuthenticated) {
    customTabs = [
      {
        // text: 'Все офферы',
        text: 'All offers',
        link: '/app/offers/all-offers',
        regex: '/app/offers/all-offers',
        subtabs: [{
          // text: 'Всё',
          text: 'All',
          icon: <OfferIcon />,
          link: '/app/offers/all-offers',
          regex: '/app/offers/all-offers',
        },
        {
          // text: 'Избранное',
          text: 'Favorites',
          link: '/app/offers/favorites',
          regex: '/app/offers/favorites',
          icon: <StarIcon />,
        }],
      },
      {
        // text: 'Мои офферы',
        text: 'My offers',
        link: '/app/offers/my-offers',
        regex: '/app/offers/my-offers',
      },
    ]
  } else {
    customTabs = [
      {
        // text: 'Все офферы',
        text: 'All offers',
        link: '/app/offers/all-offers',
        regex: '/app/offers/all-offers',
        subtabs: [{
          // text: 'Всё',
          text: 'All',
          icon: <OfferIcon />,
          link: '/app/offers/all-offers',
          regex: '/app/offers/all-offers',
        }],
      }
    ]
  }

  return (
    <>
      <PageTemplate
        // title="Офферы"
        title="Offers"
        tabs={customTabs}
      >
        <div className={classes.internalContent}>
          <Switch>
            <Route exact path={`${pathPrefix}/all-offers`} component={OffersList} />
            <Route path={`${pathPrefix}/all-offers/(\\d+)/connect`} component={Offer} />
            <Route path={`${pathPrefix}/favorites/(\\d+)/connect`} component={Offer} />
            <Route path={`${pathPrefix}/all-offers/(\\d+)`} component={Offer} />
            <Route path={`${pathPrefix}/favorites/(\\d+)`} component={Offer} />
            <Route exact path={`${pathPrefix}/my-offers`} render={() => <MyOffers />} />
            <Route
              exact
              path={`${pathPrefix}/favorites`}
              render={() => <OffersList favoritesOnly />}
            />
          </Switch>
        </div>
      </PageTemplate>
    </>
  );
};

export default withStyles(style)(Offers);
