import {
  AddIcon,
  blueColor, ExclamationMarkIcon,
  FilledHelpIcon,
  greyColor, ReportIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@aisales/react-ui-kit';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import { fetchOffers } from '@store/actions';
import fetch from '@main/utils/handledFetch';
import { useDispatch, useSelector } from 'react-redux';
import ChannelsLine from '@pages/offers/components/ChannelsLine';
import EllipsisDropdown from '@pages/offers/components/MyOffers/components/EllipsisDropdown';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import EmptyPage from '@components/EmptyPage';
import { emptyPages } from '@pages/constants/emptyPages';
import { fetchGroupedOffers } from '@pages/services/fetchGroupedOffers';
import Loader from '@components/Loader';
import { useHistory } from 'react-router';

// const headers = [{ name: 'Лого' },
//   { name: 'Оффер' },
//   { name: 'Подключенные каналы', description: 'Подключенные мессенджеры к офферу' },
//   { name: 'Аудитория', description: 'Объем активных подписчиков, который накоплен у вас в боте (исключая забаненных и неактивных юзеров)' },
//   { name: 'ltv с юзера', description: '3' },
//   { name: 'Доход', description: 'Общее кол-во дохода' },
//   { name: 'Доход с диалога', description: 'Сумма выплат за 1 оплачиваемую цель' },
// ];


const headers = [{ name: 'Logo' },
  { name: 'Offer' },
  { name: 'Connected channels', description: 'Connected messengers to offers' },
  { name: 'The audience', description: 'The volume of active subscribers that you have accumulated in the bot (excluding banned and inactive users)' },
  { name: 'ltv', description: '' },
  { name: 'Income', description: 'Total income' },
  { name: 'Income per dialog', description: 'Average income from dialogue' },
];

const rows = [{
  logo: '',
  offer: '',
  connectedChannels: '',
  audience: '',
  ltv: '',
  income: '',
  dialogIncome: '',
}];

const style = () => ({
  tableCell: {},
  root: {
    padding: '30px',
  },
  loaderWrapper: {
    paddingTop: '100px',
    textAlign: 'center',
  },
  groupLogo: {
    height: '21px',
  },
  activeHelpIcon: {
    backgroundColor: blueColor[100],
  },
  headerCellContent: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '5px',
    },
  },
  helpIcon: {
    width: '12px',
    height: '12px',
    cursor: 'pointer',
  },
  lastCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ellipsisDropdown: {
    marginRight: '',
  }.marginRight,
  actionsMenu: {
    marginRight: '15px',
  },
  rowWithOpenedMenu: {
    '&&&& td, &&&& th': {
      backgroundColor: blueColor[20],
      transition: 'background 0.2s',
    },
  },
});

const HeaderContent = ({ classes, header }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const tooltipContent = (
    <div className={classes.headerCellContent}>
      {header.name}
      {header.description && (
        <FilledHelpIcon
          className={classes.helpIcon}
          onClick={() => {
            setTooltipIsOpen(true);
          }}
          ellipseColor={tooltipIsOpen ? blueColor[100] : greyColor[25]}
          questionMarkColor="white"
        />
      )}
    </div>
  );
  return header.description ? (
    <Tooltip
      text={header.description}
      opened={tooltipIsOpen}
      onClose={() => {
        setTooltipIsOpen(false);
      }}
    >
      {tooltipContent}
    </Tooltip>
  ) : tooltipContent;
};

const MyOffers = (props) => {
  const deletePlatform = (platform) => {
    fetch(
      `${backendURL
      }/twirp/cpa.offers.OffersManagementService/DisconnectOffer`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          channel: platform.channel,
          group_id: platform.id,
        }),
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('bad response code');
      })
      .then((json) => {
        // showToast('Площадка удалена', true);
        showToast('Place deleted', true);
        let index = -1;
        for (let i = 0; i < groupedOffers.length; i++) {
          if (
            groupedOffers[i].id === platform.id
          ) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          setGroupedOffers([
            ...groupedOffers
              .slice(0, index)
              .concat(groupedOffers.slice(index + 1)),
          ]);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        // showToast('Ошибка удаления', false);
        showToast('Delete error', false);
      });
  };
  const getOfferName = (offerId) => offers.filter((offer) => offer.id === offerId)[0].name;

  const handleZero = (value) => (value || 0);
  const [openedMenuIndex, setOpenedMenuIndex] = useState(-1);
  const { classes } = props;
  const [groupedOffers, setGroupedOffers] = useState([]);
  const offersLoading = useSelector((state) => state?.offers?.loading || false);
  const offers = useSelector((state) => state?.offers?.offers || []);
  const [groupedOffersLoading, setGroupedOffersLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const emptyPage = emptyPages().offers.myOffers;

  useEffect(() => {
    async function fetchData() {
      setGroupedOffersLoading(true);
      const result = await fetchGroupedOffers();
      setGroupedOffers(result);
      setGroupedOffersLoading(false);
    }

    fetchData();
    dispatch(fetchOffers);
  }, []);
  return (
    <div className={classes.root}>
      {groupedOffers?.length !== 0
            && (!offersLoading && !groupedOffersLoading)
            && (
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell align="left" className={classes.tableCell}>
                      {' '}
                      <HeaderContent
                        classes={classes}
                        header={header}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {(!offersLoading && !groupedOffersLoading)
                && (
                <TableBody>
                  {groupedOffers?.map((offer, index) => (
                    <TableRow className={clsx({ [classes.rowWithOpenedMenu]: index === openedMenuIndex })}>
                      <TableCell align="left" component="th" scope="row">
                        <img alt="group logo" className={classes.groupLogo} src={offer.icon} />
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        {getOfferName(offer.offer_id)}
                        {' '}
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        <ChannelsLine
                          channels={Object.keys(groupedOffers[0].channels)}
                          descriptions={Object.values(groupedOffers[0].channels)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        {handleZero(offer.stats.conversations)}
                        {' '}
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        {handleZero(offer.stats.ltv)}
                        {' '}
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        {handleZero(offer.stats.income)}
                        {' '}
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        <div className={classes.lastCell}>
                          {handleZero(offer.stats.income_per_conversation)}
                          <EllipsisDropdown
                            // dropdownItems={[{ name: 'Статистика', icon: <ReportIcon />, action: () => { history.push(`/app/dashboard/byDayReport?filter={"offer":"${offer.offer_id}"}`); } },
                            //   { name: 'Инфо об оффере', icon: <ExclamationMarkIcon />, action: () => { history.push(`/app/offers/all-offers/${offer.offer_id}`); } },
                            //   { name: 'Добавить канал', icon: <AddIcon />, action: () => { history.push(`/app/offers/all-offers/${offer.offer_id}/connect`); } }]}
                            dropdownItems={[{ name: 'Statistics', icon: <ReportIcon />, action: () => { history.push(`/app/dashboard/byDayReport?filter={"offer":"${offer.offer_id}"}`); } },
                              { name: 'Offer info', icon: <ExclamationMarkIcon />, action: () => { history.push(`/app/offers/all-offers/${offer.offer_id}`); } },
                              { name: 'Add channel', icon: <AddIcon />, action: () => { history.push(`/app/offers/all-offers/${offer.offer_id}/connect`); } }]}
                            className={classes.actionsMenu}
                            index={index}
                            offer={offer}
                            menuIsOpen={index === openedMenuIndex}
                            onClick={(index) => {
                              setOpenedMenuIndex(index);
                            }}
                          />
                          {' '}

                        </div>
                        {' '}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                )}
            </Table>
)}
      {groupedOffers?.length === 0 && (!offersLoading && !groupedOffersLoading)
            && (
            <EmptyPage
              image={emptyPage.image}
              title={emptyPage.title}
              text={emptyPage.text}
              buttonText={emptyPage.buttonText}
              link={emptyPage.link}
            />
)}
      {(offersLoading || groupedOffersLoading) && (
      <div className={classes.loaderWrapper}>
        <Loader size="large" />
      </div>
      )}
    </div>
  );
};

export default withStyles(style)(MyOffers);
