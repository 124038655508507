import { emptyPages } from '@pages/constants/emptyPages';
import { defaultRenderer, fieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';

export default function between(x, range) {
  return x >= range[0] && x <= range[1];
}
export function precise(x) {
  return Number.parseFloat(x).toPrecision(2);
}

export const addDatePadding = (x) => String(x).padStart(2, '0');

export const parseDate = ({
  day, year, month, hour,
}) => {
  const parsedHour = hour ? `${hour}ч. ` : '';
  const parsedDay = day ? `${addDatePadding(
    day,
  )}.` : '';
  const parsedMonth = month ? `${day ? `${addDatePadding(month)}.` : new Date(year, month, 1).toLocaleString('ru', { month: 'short' })}` : '';
  return `${parsedHour}${parsedDay}${parsedMonth}${
    year
  }`;
};

export const eliminatePercentFields = (fields) => fields.filter((field) => !field.includes('percent'));

export const emptyPage = emptyPages().dashboard;

export const getPercentFieldName = (field) => `${field}_percent`;

export const getRenderer = (field) => (
  fieldTitleMapping[field]
    ? fieldTitleMapping[field].renderer || defaultRenderer : (number) => number);

export const getAssociatedPercentDataString = (field, dataRow) => {
  const percentFieldName = getPercentFieldName(field);
  const associatedPercentData = dataRow[percentFieldName];
  const renderedData = getRenderer(percentFieldName)(associatedPercentData);
  return associatedPercentData && `${(renderedData)}%`;
};
