export default function fbRedirect() {
  if (window.location.hostname === 'r.aisales.host') {
    window.location.href = window.location.href.replace(window.location.hostname, 'admin.aisales.online');
    return '';
  } if (window.location.hostname === 'cpar.aisales.host') {
    window.location.href = window.location.href.replace(window.location.hostname, 'affiliate.aisales.network');
    return '';
  }
  const tmp = window.location.hash;
  if (tmp.indexOf('access_token') !== -1) {
    const res = tmp.substring(1).split('&');
    const token = res[0].split('=')[1];
    localStorage.setItem('fb_access_token', token);
    window.close();
  } else {
    window.close();
  }
  return '';
}
