import { FINISH_GET_STATISTICS_REPORTS, START_GET_STATISTICS_REPORTS } from 'src/store/actions/reportsActions.js';
import defaultReports from '@pages/dashboard/constants/defaultReports';
import defaultNamePrefix from '@pages/dashboard/constants/defaultNamePrefix';

const initialState = {
  isLoading: false,
  reports: null,
  nextDefaultName: 0,
};

const getNextDefaultName = (reports) => {
  let i = 1;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const possibleName = `${defaultNamePrefix} ${i}`;
    if (reports.map((report) => report.name).includes(possibleName)) {
      i += 1;
    } else {
      return possibleName;
    }
  }
};

export default function ReportsReducer(state = initialState, { payload, type }) {
  switch (type) {
    case START_GET_STATISTICS_REPORTS:
      return {
        ...state,
        isLoading: true,
      };
    case FINISH_GET_STATISTICS_REPORTS:
      return {
        ...state,
        isLoading: false,
        reports: [...defaultReports, ...(payload.report || [])],
        nextDefaultName: getNextDefaultName(
          [...defaultReports, ...(payload.report || [])],
        ),
      };
    default:
      return state;
  }
}
