import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Button, Input } from '@aisales/react-uikit';
import * as Sentry from '@sentry/react';
import MaskedInput from 'react-text-mask';

import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { codeRe, phoneRe, showToast } from '@components/utils';

/**
 *
 * @param {string} type Type of the operation
 */
export default function PhoneVerification({
  className,
  disabled = true,
  editingDisabled = false,
  field,
  initialization = false,
  mask, // выключить редактирование всего
  onChange, // выключить редактирование только поля (кнопка подтвердить остаётся активной)
  onSuccess,
  type,
  ...rest
}) {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [code, setCode] = useState('');

  const [verificationId, setVerificationId] = useState(null);
  const [timeLeft, setTimeLeft] = useState(45);
  const [refreshCode, setRefreshCode] = useState(false);

  useEffect(() => {
    setPhoneNumber(field.field.value);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (refreshCode) {
        setTimeLeft(45);
        setRefreshCode(false);
      } else if (step === 1 && step > 0) {
        setTimeLeft((prev) => prev - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [step, refreshCode]);

  const isValid = () => {
    switch (step) {
      case 0:
        if (
          !phoneRe(
                (phoneNumber || field.value)?.match(/\d/g)?.join(''),
          )
        ) {
          setError(
            'Номер телефона неверный. Пример: +7 (999) 777 66 55',
          );
          return false;
        }

        setError('');
        return true;
      case 1:
        if (!codeRe(code)) {
          setError('Код неверный');
          return false;
        }

        setError('');
        return true;
      default:
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    switch (step) {
      case 0:
        fetchVerificationId();
        return;
      case 1:
        fetchSecretKey();

      default:
    }
  };

  // First step
  const fetchVerificationId = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${backendURL}/twirp/cpa.backend.SMSVerificator/CreateTicket`,
        {
          ...twirpFetchOptions,
          headers: {
            ...twirpFetchOptions?.headers,
            auth: 'AS&fghaos78%EU92pqdfas',
          },
          method: 'POST',
          body: JSON.stringify({
            number: (phoneNumber || field.value).match(/\d/g).join(''),
            type,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(response);
      }

      const json = await response.json();

      setVerificationId(json.token);
      setStep(1);
    } catch (e) {
      Sentry.captureException(e.message);
      showToast('Ошибка');
    } finally {
      setLoading(false);
    }
  };

  // Second step
  const fetchSecretKey = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${backendURL}/twirp/cpa.backend.SMSVerificator/Verify`,
        {
          ...twirpFetchOptions,
          headers: {
            ...twirpFetchOptions?.headers,
            auth: 'AS&fghaos78%EU92pqdfas',
          },
          method: 'POST',
          body: JSON.stringify({
            token: verificationId,
            code,
            type,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(response);
      }

      const json = await response.json();

      onSuccess(verificationId, (phoneNumber || field.value)?.match(/\d/g)?.join(''));
      setStep(2);
    } catch (e) {
      Sentry.captureException(e.message);
      showToast('Ошибка');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(classes.root, className)} {...rest}>
      <Typography style={{ 'margin-bottom': '6px' }} variant="caption" className={classes.title}>
        Подтвердите номер телефона
      </Typography>
      {loading && <CircularProgress />}
      {!loading && step === 0 && (
      <>

        <div className={classes.row}>
          {field.field.value === null && !initialization ? (
            <Typography className={classes.phoneNumber}>
              Добавьте номер в разделе профиль
            </Typography>
          ) : (
            <MaskedInput
              showMask
              style={{ 'align-self': 'stretch' }}
              value={phoneNumber || field.field.value}
              className={classNames(
                classes.input,
                classes.phoneNumber,
                { disabled: disabled || editingDisabled },
              )}
              onChange={(e) => {
                onChange && onChange(e.target.value);
                setPhoneNumber(e.target.value);
              }}
              placeholder="Номер телефона"
              mask={[
                '+',
                /\d/,
                ' ',
                '(',
                /\d/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
              ]}
              disabled={disabled || editingDisabled}
            />
          )}
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleSubmit}
            disabled={disabled}
          >
            <Typography variant="caption">Получить код</Typography>
          </Button>
        </div>

        {error && (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
        )}
        {!error && (
        <Typography variant="caption" className={classes.description}>
          На ваш номер будет отправлен код
        </Typography>
        )}
      </>
      )}
      {!loading && step === 1 && (
      <>
        <div className={classes.row}>
          {!refreshCode ? (
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className={classes.code}
              placeholder="Код"
            />
          ) : (
            <CircularProgress />
          )}
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleSubmit}
          >
            <Typography variant="caption"> Подтвердить</Typography>
          </Button>
        </div>

        {timeLeft > 0 ? (
          <Typography variant="caption">{`Получить новый код через ${timeLeft} секунд`}</Typography>
        ) : (
          <Typography
            variant="caption"
            onClick={() => {
              setRefreshCode(true);
              fetchVerificationId();
            }}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Получить новый код
          </Typography>
        )}

        {error && (
        <Typography style={{ color: 'red' }}>
          {error}
        </Typography>
        )}

        {!error && (
        <div>
          <Typography variant="caption" className={classes.description}>
            Введите код из СМС
          </Typography>
        </div>
        )}
      </>
      )}
      {!loading && step === 2 && (
        <div className={classes.row}>
          <Input
            value={phoneNumber}
            className={classes.phoneNumber}
            disabled
          />
          <Typography variant="caption" className={classes.success}>
            Подтвержден
          </Typography>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: { width: '100%' },
  row: {
    marginTop: 6,
    display: 'flex',
    marginBottom: 6,
    gridTemplateColumns: '3fr 3fr',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  description: {
    opacity: 0.8,
  },
  phoneNumber: {
    maxWidth: '300px',
    width: '100%',
    '&.disabled': {
      background: 'none',
    },
  },
  code: {
  },
  button: {
    minWidth: '8em',
    marginLeft: '0.5em',
    paddingLeft: '0.25em',
    paddingRight: '0.25em',
  },
  success: {
    color: '#47E599',
  },
  input: (props) => ({
    background: !props.disabled ? '#FFFFFF' : 'none',
    border: props.error ? '1px solid #ff9900' : '1px solid #E6E6E6',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '0 10px',
    fontWeight: props.disabled ? 'bold' : 'normal',
    outlineColor: 'rgb(180, 180, 180) !important',
  }),
}));
