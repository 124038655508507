"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var Project = /** @class */ (function () {
    function Project(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['shell_id'] = m['shellId'];
            this._json['name'] = m['name'];
            this._json['created_time'] = m['createdTime'];
            this._json['last_update_time'] = m['lastUpdateTime'];
        }
    }
    Object.defineProperty(Project.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "shellId", {
        // shellId (shell_id)
        get: function () {
            return this._json.shell_id;
        },
        set: function (value) {
            this._json.shell_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Project.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Project({
            id: m['id'],
            shellId: m['shell_id'],
            name: m['name'],
            createdTime: m['created_time'],
            lastUpdateTime: m['last_update_time']
        });
    };
    Project.prototype.toJSON = function () {
        return this._json;
    };
    return Project;
}());
exports.Project = Project;
var GetOneByIdResponse = /** @class */ (function () {
    function GetOneByIdResponse(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(GetOneByIdResponse.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneByIdResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneByIdResponse({
            project: Project.fromJSON(m['project'])
        });
    };
    GetOneByIdResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneByIdResponse;
}());
exports.GetOneByIdResponse = GetOneByIdResponse;
var GetOneByShellIdResponse = /** @class */ (function () {
    function GetOneByShellIdResponse(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(GetOneByShellIdResponse.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneByShellIdResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneByShellIdResponse({
            project: Project.fromJSON(m['project'])
        });
    };
    GetOneByShellIdResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneByShellIdResponse;
}());
exports.GetOneByShellIdResponse = GetOneByShellIdResponse;
var GetManyResponse = /** @class */ (function () {
    function GetManyResponse(m) {
        this._json = {};
        if (m) {
            this._json['projects'] = m['projects'];
        }
    }
    Object.defineProperty(GetManyResponse.prototype, "projects", {
        // projects (projects)
        get: function () {
            return this._json.projects || [];
        },
        set: function (value) {
            this._json.projects = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyResponse({
            projects: (m['projects'] || []).map(function (v) { return Project.fromJSON(v); })
        });
    };
    GetManyResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
var GetOneByIdRequest = /** @class */ (function () {
    function GetOneByIdRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(GetOneByIdRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneByIdRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneByIdRequest({
            id: m['id']
        });
    };
    GetOneByIdRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneByIdRequest;
}());
exports.GetOneByIdRequest = GetOneByIdRequest;
var GetOneByShellIdRequest = /** @class */ (function () {
    function GetOneByShellIdRequest(m) {
        this._json = {};
        if (m) {
            this._json['shell_id'] = m['shellId'];
        }
    }
    Object.defineProperty(GetOneByShellIdRequest.prototype, "shellId", {
        // shellId (shell_id)
        get: function () {
            return this._json.shell_id;
        },
        set: function (value) {
            this._json.shell_id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneByShellIdRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneByShellIdRequest({
            shellId: m['shell_id']
        });
    };
    GetOneByShellIdRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneByShellIdRequest;
}());
exports.GetOneByShellIdRequest = GetOneByShellIdRequest;
var GetManyRequest = /** @class */ (function () {
    function GetManyRequest(m) {
        this._json = {};
        if (m) {
        }
    }
    GetManyRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyRequest({});
    };
    GetManyRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
var ProjectsService = /** @class */ (function () {
    function ProjectsService(hostname, fetch) {
        this.path = '/twirp/admin.backend.ProjectsService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    ProjectsService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    ProjectsService.prototype.getOneById = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOneById'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneByIdResponse.fromJSON(m); });
        });
    };
    ProjectsService.prototype.getOneByShellId = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOneByShellId'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneByShellIdResponse.fromJSON(m); });
        });
    };
    ProjectsService.prototype.getMany = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetManyResponse.fromJSON(m); });
        });
    };
    return ProjectsService;
}());
exports.ProjectsService = ProjectsService;
