import React from 'react';
import Message from '@pages/finance/pages/BalancePage/components/Message';
import { withStyles } from '@material-ui/core';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@aisales/react-ui-kit';
import { paymentsMethods } from '@pages/finance/constants/paymentMethods';
import declOfNum from '@pages/finance/pages/utils/declOfNum';
import { ReactComponent as Illustration } from './assets/Illustration.svg';

const styles = () => ({
  message: {
    height: '135px',
    marginBottom: '30px',
    marginTop: '0px',
  },
  iconNameCell: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    }
  },
  icon: {
    marginRight: '10px',
  },

});

const TermsAndProcedurePaymentsPage = ({ classes }) => (
  <div>
    <Message
      // upperText="Сроки и порядок выплат"
      upperText="Terms and procedure of payments"
      classes={{ root: classes.message }}
      illustration={<Illustration />}
      withoutCloseIcon
      // bottomText="Срок указан ориентировочно, при условии быстрого обмена документами. Оплата производится в течение 3 рабочих дней с момента предоставления вебмастером подписаных оригиналов документов."
      bottomText="The deadline is approximate, subject to a quick exchange of documents. Payment is made within 3 working days from the moment the webmaster provides signed original documents."
    />
    <Table>
      <TableHead>
        <TableCell align="left">
          {/* Способ выплаты */}
          Payout method
        </TableCell>
        <TableCell>
          {/* Срок выплаты */}
          Payment term
        </TableCell>
        <TableCell>
          {/* Размер комиссии */}
          Commission amount
        </TableCell>
      </TableHead>
      <TableBody>
        {paymentsMethods.map((payment) => (
          <TableRow>
            <TableCell className={classes.iconNameCell} align="left">
              {React.cloneElement(payment.icon, { className: classes.icon })}
              {payment.name}

            </TableCell>
            <TableCell>
              {payment.numOfDays}
              {' '}
              {/* {declOfNum(payment.numOfDays, ['рабочий день', 'рабочих дня', 'рабочих дней'])} */}
              {declOfNum(payment.numOfDays, ['working day', 'working days', 'working days'])}
            </TableCell>
            <TableCell>
              {payment.commission}
              {' '}
              %
            </TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>

  </div>
);

export default withStyles(styles)(TermsAndProcedurePaymentsPage);
