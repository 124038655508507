import {
  greenColor, greyColor, redColor, orangeColor,
} from '@aisales/react-ui-kit';
import between from '@pages/dashboard/utils/index';

export const getPercentColorMaker = ({ red, orange, green }) => {
  const getPercentColor = (percent) => {
    if (between(percent, red)) {
      return redColor[100];
    } if (between(percent, orange)) {
      return orangeColor[100];
    } if (between(percent, green)) {
      return greenColor[100];
    }
    return greyColor[100];
  };
  return getPercentColor;
};
