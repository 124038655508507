import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import {
  Button, CircleIcon, GrayCircle, GreenCircle, Line, Panel, Polyline, WhiteCircle,
} from '@aisales/react-uikit';

import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/offers/utils';
import PhoneVerification from '@components/PhoneVerification';
import { REMOVE } from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '@store/actions';
import {
  checkBIK,
  checkCheckingAccount,
  checkCorrespondedAccount,
  checkINN,
  checkKPP,
  checkNDS,
  checkOGRN,
  emailRe,
  personRe,
  phoneRe,
  requiredText,
} from './utils';
import addIcon from '../assets/add.svg';
import deleteIcon from '../assets/delete.svg';
import InputField from './InputField';

const validationSchema = Yup.object().shape({
  // General
  form: Yup.string().required(requiredText),
  status: Yup.string().required(requiredText),
  name: Yup.string().required(requiredText),

  // Requisites
  registrationNumber: Yup.string()
    .test('ogrn', 'Неверный номер регистрации', (value) => checkOGRN(value))
    .required(requiredText),
  taxpayerNumber: Yup.string()
    .test('inn', 'Неверный номер ИНН', (value) => checkINN(value))
    .required(requiredText),
  valueAddedTax: Yup.string()
    .test('nds', 'Неверный номер НДС', (value) => checkNDS(value))
    .required(requiredText),
  kpp: Yup.string()
    .test('kpp', 'Неверный номер КПП', (value) => checkKPP(value))
    .required(requiredText),
  phoneNumber: Yup.string()
    .test('phoneNumber', 'Неверный номер телефона', (value) => phoneRe(value))
    .required(requiredText),
  email: Yup.string()
    .test('email', 'Неверный адрес почты', (value) => emailRe(value))
    .required(requiredText),

  // Document
  whoDoes: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),
  whoDoesGenitive: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),
  whoDoesDative: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),
  whatBasis: Yup.string().min(3).required(requiredText),
  signatoryPosition: Yup.string().min(3).required(requiredText),
  signatoryPositionGenitive: Yup.string().min(3).required(requiredText),
  signatoryFullName: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),
  accountantFullName: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),

  // File
  file: Yup.mixed().test(
    'file present',
    'Нужно прикрепить файл',
    (value) => value && value.name,
  ),

  // Bank account
  // currency: "RUB",
  bik: Yup.string()
    .test('bik', 'Неправильно введенный БИК', (value) => checkBIK(value))
    .required(requiredText),
  bankName: Yup.string().required(requiredText),
  bankCountry: Yup.string().required(requiredText),
  correspondedAccount: Yup.string()
    .test(
      'correspondedAccount',
      'Неправильно введенный корреспондетский счет',
      checkCorrespondedAccount,
    )
    .required(requiredText),
  checkingAccount: Yup.string()
    .test(
      'checkingAccount',
      'Неправильно введенный расчетный счет',
      checkCheckingAccount,
    )
    .required(requiredText),
});

export default function LegalPersonForm() {
  const classes = useStyles();
  const history = useHistory();
  const formValues = useSelector((state) => state.profile.formValues);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);
  const [validationToken, setValidationToken] = useState(null);

  const { t } = useTranslation();

  const handleSubmit = async (values, actions) => {
    if (!validationToken) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', values.file);

      const responseFilelink = await fetch(`${backendURL}/api/upload`, {
        method: 'POST',
        body: formData,
      });

      const json = await responseFilelink.json();

      const response = await fetch(
        `${backendURL}/twirp/cpa.users.BillingInfoService/Create`,
        {
          ...twirpFetchOptions,
          headers: {
            ...twirpFetchOptions?.headers,
            auth: validationToken,
          },
          body: JSON.stringify({
            name: 'Legal person',
            recipient_type: 'LEGAL',
            legal: {
              general: {
                form: values.form,
                name: values.name,
                status: values.status,
              },
              address: {
                registration_number: values.registrationNumber,
                taxpayer_number: values.taxpayerNumber,
                value_added_tax: values.valueAddedTax,
                kpp: values.kpp,
                phone_number: values.phoneNumber,
                email: values.email,
              },
              document: {
                who_does: values.whoDoes,
                who_does_genitive: values.whoDoesGenitive,
                who_does_dative: values.whoDoesDative,
                what_bases: values.whatBasis,
                signatory_position: values.signatoryPosition,
                signatory_position_genitive:
                                    values.signatoryPositionGenitive,
                signatory_full_name: values.signatoryFullName,
                accountant_full_name: values.accountantFullName,
              },
              copy_of_attorney_document: json.link,
              account: {
                name: 'Bank',
                type: 'BANK',
                currency: values.currency,
                bank: {
                  bik: values.bik,
                  bank_name: values.bankName,
                  bank_country: values.bankCountry,
                  corresponded_account:
                                        values.correspondedAccount,
                  checking_account: values.checkingAccount,
                },
              },
            },
          }),
        },
      );

      if (response.status === 400) {
        showToast('Неправильно введеные данные');
      } else if (response.status === 500) {
        showToast('Ошибка сервера');
      } else {
        showToast('Платежный метод добавлен', true);
        history.push('/app/profile');
      }
    } catch (e) {
      Sentry.captureException(e);
      showToast('Неизвестная ошибка');
    }
  };

  return (
    <Formik
      initialValues={{
        // General
        form: '',
        status: '',
        name: '',

        // Requisites
        registrationNumber: '',
        taxpayerNumber: '',
        valueAddedTax: '',
        kpp: '',
        phoneNumber: '',
        email: '',

        // Document
        whoDoes: '',
        whoDoesGenitive: '',
        whoDoesDative: '',
        whatBasis: '',
        signatoryPosition: '',
        signatoryPositionGenitive: '',
        signatoryFullName: '',
        accountantFullName: '',

        // File
        file: null,

        // Bank account
        currency: 'RUB',
        bik: '',
        bankName: '',
        bankCountry: '',
        correspondedAccount: '',
        checkingAccount: '',
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <>
        <Form>
          <div className={classes.padding}>
            <Panel
              width="372px"
              height="48px"
              style={{
                marginBottom: '37px',
                position: 'relative',
              }}
            >
              <Polyline className={classes.polyline} />
              <GrayCircle
                width="11px"
                height="11px"
                className={classes.grayCircle}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  lineHeight: '17px',
                }}
              >
                Общая информация о юридическом лице
              </Typography>
            </Panel>
          </div>
          <div
            className={classNames(
              classes.formWrapper,
              classes.padding,
            )}
            style={{ marginBottom: '20px' }}
          >
            <div className={classes.leftInputs}>
              <InputField
                name="name"
                label="Наименование юридического лица"
              />
              <InputField name="form" label="Форма учреждения" />
              <InputField
                name="status"
                label="Статус учреждения"
              />
            </div>
            <div className={classes.centerInputs}>
              <InputField
                name="registrationNumber"
                label="ОГРН"
              />
              <InputField name="taxpayerNumber" label="ИНН" />
              <InputField name="valueAddedTax" label="НДС" />
            </div>
            <div className={classes.rightInputs}>
              <InputField name="kpp" label="КПП" />
              <InputField name="phoneNumber" label="Телефон" />
              <InputField name="email" label="E-mail" />
            </div>
          </div>
          {/* <div className={classes.padding}>
                    <Badge
                        width="220px"
                        height="48px"
                        style={{ marginBottom: "37px", position: "relative" }}
                    >
                        <Polyline className={classes.polyline} />
                        <GrayCircle
                            width="11px"
                            height="11px"
                            className={classes.grayCircle}
                        />
                        <Typography
                            style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                lineHeight: "17px",
                            }}
                        >
                            Юридический адрес
                        </Typography>
                        <CheckboxField
                            name="isAddressMatch"
                            label="Фактический адрес совпадает с юридическим"
                            style={{
                                position: "absolute",
                                right: "-400px",
                                top: "8px",
                            }}
                        />
                    </Badge>
                </div>
                <div
                    className={classNames(classes.formWrapper, classes.padding)}
                    style={{ marginBottom: "0px" }}
                >
                    <div className={classes.leftInputs}>
                        <InputField name="index" label="Индекс" />
                        <InputField name="region" label="Регион" />
                    </div>
                    <div className={classes.centerInputs}>
                        <InputField name="city" label="Населенный пункт" />
                        <InputField name="street" label="Улица" />
                    </div>
                    <div className={classes.rightInputs}>
                        <InputField name="house" label="Дом" />
                        <InputField name="zdanie" label="Здание" />
                        <InputField name="apartment" label="Квартира/Офис" />
                    </div>
                </div> */}
          <div className={classes.padding}>
            <Panel
              width="430px"
              height="48px"
              style={{
                marginBottom: '37px',
                position: 'relative',
              }}
            >
              <Polyline className={classes.polyline} />
              <GrayCircle
                width="11px"
                height="11px"
                className={classes.grayCircle}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  lineHeight: '17px',
                }}
              >
                Данные для подписания документов и договоров
              </Typography>
            </Panel>
          </div>
          <div
            className={classNames(
              classes.formWrapper,
              classes.padding,
            )}
            style={{ marginBottom: '68px' }}
          >
            <div className={classes.leftInputs}>
              <InputField
                name="whoDoes"
                label="От чьего лица действует"
              />
              <InputField
                name="whoDoesGenitive"
                label="От чьего лица действует (Род. падеж Кого?)"
              />
              <InputField
                name="whoDoesDative"
                label="От чьего лица действует (Дат. падеж Кому?)"
              />
            </div>
            <div className={classes.centerInputs}>
              <InputField
                name="whatBasis"
                label="На каком основании действует"
              />
              <InputField
                name="signatoryPosition"
                label="Должность подписанта"
              />
              <InputField
                name="signatoryPositionGenitive"
                label="Должность подписанта (Род. падеж Кого?)"
              />
            </div>
            <div className={classes.rightInputs}>
              <InputField
                name="signatoryFullName"
                label="ФИО Подписанта"
              />
              <InputField
                name="accountantFullName"
                label="ФИО Главного бухгалтера"
              />
            </div>
          </div>
          <div
            className={classNames(classes.padding, classes.white)}
            style={{
              marginBottom: '67px',
              paddingTop: '15px',
              paddingBottom: '30px',
            }}
          >
            Копия доверености приказа
            <Field name="file" className={classes.rightInfo}>
              {({ field, form, meta }) => (
                <Panel
                  width="250px"
                  height="48px"
                  style={{
                    position: 'relative',
                  }}
                >
                  <Line className={classes.line} />
                  <GreenCircle
                    width="8px"
                    height="8px"
                    className={classes.greenCircle}
                  />

                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: '17px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {field.value
                      ? field.value.name
                      : 'Документ не выбран'}
                  </Typography>
                  {!field.value && (
                  <>

                    <Typography
                      component="label"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#55C7E1',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeihgt: '15px',
                        marginRight: '30px',
                        cursor: 'pointer',
                        right: '-162px',
                      }}
                      className={classes.rightInfo}
                      for={field.name}
                    >
                      <CircleIcon
                        src={addIcon}
                        alt="Edit"
                        style={{
                          marginRight: '8px',
                        }}
                      />
                      <input
                        id={field.name}
                        {...field}
                        onChange={(event) => form.setFieldValue(
                          field.name,
                          event.currentTarget
                            .files[0],
                        )}
                        type="file"
                        style={{ display: 'none' }}
                      />
                      Прикрепить
                    </Typography>

                    {meta.touched && meta.error && (
                    <Typography
                      style={{
                        color: 'red',
                        position: 'absolute',
                        top: '11px',
                        left: '420px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {meta.error}
                    </Typography>
                    )}
                  </>
                  )}
                  {field.value && (
                  <Typography
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#55C7E1',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      lineHeihgt: '15px',
                      marginRight: '30px',
                      cursor: 'pointer',
                      right: '-140px',
                    }}
                    className={classes.rightInfo}
                    onClick={() => form.setFieldValue(
                      field.name,
                      null,
                    )}
                  >
                    <CircleIcon
                      src={deleteIcon}
                      alt="Delete"
                      style={{ marginRight: '8px' }}
                    />
                    {t(REMOVE)}
                  </Typography>
                  )}
                </Panel>
              )}
            </Field>
          </div>
          <div className={classes.padding}>
            <Panel
              width="300px"
              height="48px"
              style={{
                marginBottom: '111px',
                position: 'relative',
              }}
            >
              <Polyline className={classes.polyline} />
              <Line
                className={classes.line}
                style={{ width: '100px', left: '300px' }}
              />
              <GrayCircle
                width="11px"
                height="11px"
                className={classes.grayCircle}
              />
              <GreenCircle
                width="8px"
                height="8px"
                className={classes.greenCircle}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  lineHeight: '17px',
                }}
              >
                Электронный документооборот
              </Typography>
              <div
                style={{
                  position: 'absolute',
                  top: '13px',
                  left: '400px',
                  marginBottom: '58px',
                }}
              >
                <Panel
                  width="230px"
                  height="22px"
                  type="primary"
                  style={{
                    position: 'relative',
                    marginBottom: '20px',
                  }}
                >
                  <WhiteCircle
                    width="4px"
                    height="4px"
                    style={{
                      position: 'absolute',
                      top: '7.5px',
                      left: '-3px',
                    }}
                  />
                  <Typography
                    className={classes.primaryBadgeText}
                  >
                    Способ подписи документов
                  </Typography>
                </Panel>
                <Typography
                  component="label"
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: 'rgba(0, 0, 0, 0.67)',
                    marginBottom: '15px',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                >
                  <Field
                    type="radio"
                    name="signatureType"
                    value="1"
                    style={{
                      margin: '0 8px 0 0',
                      cursor: 'pointer',
                    }}
                  />
                  Вручную
                </Typography>
                <Typography
                  component="label"
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: 'rgba(0, 0, 0, 0.67)',
                    lineHeight: '15px',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                >
                  <Field
                    type="radio"
                    name="signatureType"
                    value="2"
                    style={{
                      margin: '0 8px 0 0',
                      cursor: 'pointer',
                    }}
                  />
                  Через СБИС (автоматически)
                </Typography>
              </div>
            </Panel>
          </div>
          <div className={classes.padding}>
            <Panel
              width="250px"
              height="48px"
              style={{
                marginBottom: '35px',
                position: 'relative',
              }}
            >
              <Polyline className={classes.polyline} />
              <GrayCircle
                width="11px"
                height="11px"
                className={classes.grayCircle}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  lineHeight: '17px',
                }}
              >
                Счет юридического лица
              </Typography>
            </Panel>
          </div>
          <div
            className={classNames(
              classes.formWrapper,
              classes.padding,
            )}
            style={{ width: '73%', marginBottom: '50px' }}
          >
            {/* <div className={classes.leftInputs}>
                        <Badge
                            width="184px"
                            height="22px"
                            type="primary"
                            style={{ padding: "0px", marginBottom: "36px" }}
                        >
                            <Typography className={classes.primaryBadgeText}>
                                {" "}
                                Основная информация
                            </Typography>
                        </Badge>
                        <InputField name="schet" label="Название счета" />
                        <SelectField
                            name="schetType"
                            label="Тип счета"
                            options={[
                                { label: "Тип счета 1", value: "1" },
                                { label: "Тип счета 2", value: "2" },
                            ]}
                        />
                        <SelectField
                            name="country"
                            label="Страна бенефициара"
                            options={[
                                { label: "Страна бенефициара 1", value: "1" },
                                { label: "Страна бенефициара 2", value: "2" },
                            ]}
                        />
                        <SelectField
                            name="currency"
                            label="Валюта"
                            options={[
                                { label: "Валюта 1", value: "1" },
                                { label: "Валюта 2", value: "2" },
                            ]}
                        />
                    </div> */}
            <div className={classes.leftInputs} style={{}}>
              <Panel
                width="152px"
                height="22px"
                type="primary"
                style={{
                  padding: '0px',
                  // marginLeft: "45px",
                  marginBottom: '36px',
                }}
              >
                <Typography
                  className={classes.primaryBadgeText}
                >
                  Банк бенифициара
                </Typography>
              </Panel>
              <InputField name="bik" label="БИК" />
              <InputField
                name="bankName"
                label="Название банка"
              />
              <InputField
                name="bankCountry"
                label="Страна бенефициара"
              />
              <InputField
                name="correspondedAccount"
                label="Корреспондентский счет"
              />
              <InputField
                name="checkingAccount"
                label="Расчетный счет"
              />
            </div>
            <div className={classes.centerInputsNoRightBorder} />
          </div>
          <div
            className={classes.padding}
            style={{ marginBottom: 30 }}
          >
            <PhoneVerification
              field={{ field: { value: formValues.phoneNumber } }}
              disabled={false}
              editingDisabled
              onSuccess={(token) => setValidationToken(token)}
              type="WITHDRAWAL_TICKET_CREATION"
            />
          </div>
          <div className={classes.padding}>
            <Panel
              width="550px"
              height="150px"
              style={{
                marginBottom: '50px',
                position: 'relative',
              }}
              type="secondary"
            >
              <Polyline
                className={classes.polyline}
                style={{ top: '-783px' }}
              />
              <GrayCircle
                width="11px"
                height="11px"
                className={classes.grayCircle}
                style={{ top: '11px' }}
              />
              <Typography
                style={{
                  fontSize: '13px',
                  lineHeight: '16px',
                  textAlign: 'left',
                }}
              >
                Нажимая "сохранить", вы подтверждаете, что
                введенные
                {' '}
                <b>данные верны</b>
                .
                {' '}
                <br />
                <br />
                Пожалуйста, учитывайте, что прямой проверки
                корректности вводимых вами данных на данный
                момент нет. Будьте бдительны при вводе.
                {' '}
                <br />
                <br />
                С уважением, Команда Aisales.
              </Typography>
            </Panel>
          </div>
          <div className={classNames(classes.padding)}>
            <Button
              type="submit"
              variant="contained"
              style={{ marginRight: '37px' }}
            >
              Сохранить
            </Button>
            <Button
              type="reset"
              variant="outlined"
              onClick={() => history.push('/app/profile')}
            >
              Отмена
            </Button>
          </div>
        </Form>
      </>
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  grayCircle: {
    position: 'absolute',
    left: '-6px',
    top: '17px',
  },
  greenCircle: {
    position: 'absolute',
    right: '-5px',
    top: '20px',
  },
  polyline: {
    top: '-778px',
    left: '-345px',
  },
  line: {
    width: '32px',
    top: '23px',
    right: '-32px',
  },
  rightInfo: {
    position: 'absolute',
    display: 'flex',
    top: '14px',
    right: '-153px',
    alignItems: 'center',
  },
  padding: {
    padding: '0 7% 0 calc(7% + 84px)',
  },
  white: {
    background: 'white',
  },
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '56px',
  },
  leftInputs: {
    flex: '1 0 31%',
    '& > div': {
      marginRight: '40px',
      paddingBottom: '13px',
    },
  },
  centerInputs: {
    flex: '1 0 37%',
    '& > div': {
      padding: '0 45px 13px 45px',
      borderRight: '1px solid #E6E6E6',
      borderLeft: '1px solid #E6E6E6',
    },
  },
  rightInputs: {
    flex: '1 0 31%',
    '& > div': {
      marginLeft: '40px',
      paddingBottom: '13px',
    },
  },
  centerInputsNoRightBorder: {
    flex: '1 0 37%',
    '& > div': {
      padding: '0 18px 13px 45px',
      borderLeft: '1px solid #E6E6E6',
    },
  },
  primaryBadgeText: {
    fontSize: '11px',
    lineHeight: '13px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.67)',
    whiteSpace: 'nowrap',
    padding: '0',
  },
}));
