import AddPostbackForm from '@pages/profile/components/Blocks/Postback/AddPostbackForm';
import React, { useEffect, useState } from 'react';
import Block from '@components/Block/Block';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import * as Sentry from '@sentry/react';
import { getCookie } from '@pages/finance/utils';
import MacrosList from '@pages/profile/components/Blocks/MacrosList';
import Form from '@pages/profile/components/Blocks/Postback/Form';
import urlParser from 'url';

const queryString = require('query-string');

function Postback({ style }) {
  function getPossibleGoals() {
    return new Promise((resolve, reject) => {
      fetch(
        `${backendURL
        }/twirp/cpa.offers.OffersManagementService/GetEventsOfConnected`,
        {
          ...twirpFetchOptions,
          body: JSON.stringify({}),
        },
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          reject(res.statusText);
        })
        .then((res) => {
          setPossibleGoals(res.events.map((element) => ({ id: element.id, name: element.name })));
          resolve();
        })
        .catch((err) => {
          Sentry.captureException(err);
          showToast('Ошибка при получении списка постбеков', false);
          // setOffers([]);
          setPossibleGoals([]);
          reject(err);
        })
        .finally(() => {
          setOptionsLoading(false);
        });
    });
  }

  function getPostbacks() {
    return new Promise((resolve, reject) => {
      // setOffersLoading(true);
      const accountID = getCookie('account_id');
      fetch(
        `${backendURL
        }/twirp/observer.postbacks.PostbacksService/GetMany`,
        {
          ...twirpFetchOptions,
          body: JSON.stringify({ user_id: accountID }),
        },
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(res.statusText);
        })
        .then((res) => {
          // res.postbacks.forEach((postbacks.status)=>{postbacks.status})
          if (res.postbacks) {
            res.postbacks.forEach((postback) => {
              postback.status = postback.status ? postback.status : '0';
            });
            setPostbacks(res.postbacks);
          }
          resolve();
        })
        .catch((err) => {
          Sentry.captureException(err);
          showToast('Ошибка при получении списка офферов', false);
          // setOffers([]);
          setPostbacks([]);
          reject(err);
        })
        .finally(() => {
          setPostbacksLoading(false);
        });
    });
  }

  function createPostback({
    goal, name, status, url,
  }) {
    const isUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(url);

    const params = queryString.parse(urlParser.parse(url).search);

    const validParams = ['{channel}',
      '{group_id}',
      '{offer_id}',
      '{conversion_id}',
      '{event_id}',
      '{status}',
      '{payout}',
      '{utm_source}',
      '{utm_medium}',
      '{utm_campaign}',
      '{utm_extra1}',
      '{utm_extra2}'];
    let paramsAreValid = true;
    Object.values(params).every((param) => {
      if (!validParams.includes(param)) {
        paramsAreValid = false;
        return false;
      }
    });
    if (!isUrl) {
      showToast('Некорректная ссылка', false);
      return;
    }
    if (!paramsAreValid) {
      showToast('Присутствуют недоступные макросы', false);
      return;
    }
    return new Promise((resolve, reject) => {
      fetch(
        `${backendURL
        }/twirp/observer.postbacks.PostbacksService/Create`,
        {
          ...twirpFetchOptions,
          body: JSON.stringify({
            name, url, handle_type: status ? status[0] : '', handle_events: goal,
          }),
        },
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          reject(res.statusText);
        })
        .then((res) => {
          if (!res.postback.status) {
            res.postback.status = 0;
          }
          setPostbacks([res.postback, ...postbacks]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureException(err);
          showToast('Ошибка при создании постбека', false);
          // setOffers([]);
          reject(err);
        })
        .finally(() => {
        });
    });
  }

  const removePostback = (id) => new Promise((resolve, reject) => {
    fetch(
      `${backendURL
      }/twirp/observer.postbacks.PostbacksService/Delete`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({ id }),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then((res) => {
        setPostbacks(postbacks.filter((postback) => postback.id !== id));
        resolve();
      })
      .catch((err) => {
        Sentry.captureException(err);
        showToast('Ошибка при получении списка офферов', false);
        // setOffers([]);
        reject(err);
      })
      .finally(() => {
      });
  });
  const [postbacks, setPostbacks] = useState([]);
  useEffect(() => {

  }, [postbacks]);
  const [possibleGoals, setPossibleGoals] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState('true');
  const [postbacksLoading, setPostbacksLoading] = useState('true');
  useEffect(() => {
    getPostbacks();
    getPossibleGoals();
  }, []);
  return (
    <>
      {(!optionsLoading && !postbacksLoading)
        && (
        <>
          <Block style={style} title="Постбеки">
            <AddPostbackForm createPostback={createPostback} possibleGoals={possibleGoals} />
            {postbacks && postbacks.map((postback, index) => (
              <Form
                key={postback.id}
                background={index % 2 === 0}
                possibleGoals={possibleGoals}
                postback={postback}
                removePostback={removePostback}
              />
            ))}
          </Block>
          <MacrosList style={{ marginBottom: '24px' }} />
        </>
        )}
    </>
  );
}

export default Postback;
