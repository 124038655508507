import StarButton from '@pages/offers/components/StarButton';
import { isRecent, setFavorite } from '@pages/offers/utils';
import TagNew from '@pages/offers/components/TagNew';
import React from 'react';
import { withStyles } from '@material-ui/core';

const style = () => ({
  tag: {
    zIndex: 10000,
    position: 'absolute',
    marginTop: '18px',
    right: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  tagNew: {
    marginLeft: '-20px',
  },
  starButton: {
    marginRight: '30px',
    width: '16px',
    height: '15px',
  },
});
const TagNewAndStar = ({
  classes, date, id, isFavorite, onUpdateList,
}) => (
  <div className={classes.tag}>
    <StarButton
      className={classes.starButton}
      selected={isFavorite}
      onChangeSelected={(isFavorite) => {
        setFavorite({ offerId: id, isFavorite });
                                                        onUpdateList?.(isFavorite);
      }}
    />
    {' '}
    {isRecent(date) && <TagNew className={classes.tagNew} />}
  </div>
);

export default withStyles(style)(TagNewAndStar);
