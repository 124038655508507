import React from 'react';
import { blueColor, greyColor, Typography } from '@aisales/react-ui-kit';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const HelpText = ({
  classes, textBeforeLink, linkText, link, className, detailed, ...otherProps
}) => {
  const history = useHistory();
  return (

  // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={clsx(classes.helpText, className)} {...otherProps}>
      <Typography variant={detailed && 'detailed'}>
        {textBeforeLink}
        {' '}
        <a onClick={() => {
          history.push(link);
        }}
        >
          {linkText}
        </a>
      </Typography>
    </div>

  );
};

const styles = () => ({
  helpText: {
    color: greyColor[50],
    '& a': {
      color: blueColor[100],
      cursor: 'pointer',
    },
    textAlign: 'center',
  },
});

export default withStyles(styles)(HelpText);
