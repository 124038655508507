import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import { showToast } from '@pages/oldOffers/utils';

export const getStatistics = async (body) => fetch(
  `${backendURL}/twirp/statistics.cpa.CpaService/GetStatistics`,
  {
    ...twirpFetchOptions,
    body: JSON.stringify(body),
  },
)
  .then((res) => {
    if (res.ok) {
      return res.json();
    }
    // throw new Error('bad response code');
  })
  .then((res) => {
    if (!res.count) {
      return {
        data: [],
        total: 0,
        totalByColumn: {},

      };
    }
    if (!res.rows) {
      res.rows = [];
    }
    return res;
  })
  .catch((err) => {
    Sentry.captureException(err);
    showToast('Ошибка загрузки данных');
  });

export const getTotalStatistics = async (body) => fetch(
  `${backendURL}/twirp/statistics.cpa.CpaService/GetTotalStatistics`,
  {
    ...twirpFetchOptions,
    body: JSON.stringify(body),
  },
)
  .then((res) => {
    if (res.ok) {
      return res.json();
    }
    // throw new Error('bad response code');
  })
  .then((res) => res)
  .catch((err) => {
    Sentry.captureException(err);
    showToast('Ошибка загрузки данных');
  });
