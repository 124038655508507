import {
  SWITCH_CHANGE,
} from '../actions';

export const initialState = {
  isActive: false,
};

export default function NoobReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SWITCH_CHANGE:
      return {
        isActive: !state.isActive,
      };
    default:
      return state;
  }
}
