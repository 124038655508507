import React, { useEffect, useState } from 'react';
import { makeStyles, Popover, Typography } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import {
  CircleIcon,
  GrayCircle,
  GreenCircle,
  Line,
  Loader,
  Panel,
  Polyline,
  Table,
  TableCell,
  TableRow,
} from '@aisales/react-uikit';

import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import { NAME, REMOVE } from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import Postback from '@pages/profile/components/Blocks/Postback';
import addIcon from './assets/add.svg';
import editIcon from './assets/edit.svg';
import menuIcon from './assets/menu.svg';
import saveIcon from './assets/save.svg';
import greyArrow from './assets/greyArrow.svg';
import greenArrow from './assets/greenArrow.svg';
import ProfileForm from './components/ProfileForm';

export default function Profile() {
  const [disabled, setDisabled] = useState(true);
  const [paymentInfos, setPaymentInfos] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [popupRef, setPopupRef] = useState({});
  const [formValues, setFormValues] = useState(null);

  const { t } = useTranslation();

  const classes = useStyles({ disabled });
  const history = useHistory();

  const activate = (id) => async () => {
    const response = await fetch(
      `${backendURL}/twirp/cpa.users.BillingInfoService/Activate`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          id,
        }),
      },
    );

    if (!response.ok) {
      showToast('Ошибка во время активации платежного метода');
    } else {
      fetchPaymentInfos();
    }

    setPopupRef({});
  };

  const deactivate = (id) => async () => {
    const response = await fetch(
      `${backendURL}/twirp/cpa.users.BillingInfoService/Deactivate`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          id,
        }),
      },
    );

    if (!response.ok) {
      showToast('Ошибка во время деактивации платежного метода');
    } else {
      fetchPaymentInfos();
    }
    setPopupRef({});
  };

  const setDefault = (id) => () => {
    fetch(`${backendURL}/twirp/cpa.users.BillingInfoService/SetDefault`, {
      ...twirpFetchOptions,
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          showToast('Ошибка во время установления по умолчанию');
        } else {
          fetchPaymentInfos();
        }
      })
      .finally(() => setPopupRef({}));
  };

  const deleteRequest = (id) => () => {
    fetch(`${backendURL}/twirp/cpa.users.BillingInfoService/Delete`, {
      ...twirpFetchOptions,
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          showToast('Ошибка во время удаления платежного метода');
        } else {
          fetchPaymentInfos();
        }
      })
      .finally(() => {
        setPopupRef({});
      });
  };

  const fetchPaymentInfos = async () => {
    setIsPaymentLoading(true);
    const response = await fetch(
      `${backendURL}/twirp/cpa.users.BillingInfoService/GetByUser`,
      {
        ...twirpFetchOptions,
        body: '{}',
      },
    );

    const json = await response.json();

    if (!response.ok) {
      showToast('Ошибка загрузки способов выплат');
    } else {
      // TODO: be careful !!! response and {}!!!
      setPaymentInfos(json.response);
    }

    setIsPaymentLoading(false);
  };

  useEffect(() => {
    fetchPaymentInfos();
  }, []);

  return (
    <div style={{ backgroundColor: '#F6F7FD' }}>
      <div className={classes.root} style={{ paddingTop: '50px' }}>
        <Panel
          width="194px"
          height="60px"
          style={{ marginBottom: '42px' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '22px',
            }}
          >
            Ваш профиль
          </Typography>
        </Panel>
        <Panel
          width="269px"
          height="48px"
          style={{ marginBottom: '37px', position: 'relative' }}
        >
          <Polyline className={classes.polyline} />
          <Line className={classes.line} />
          <GrayCircle
            width="11px"
            height="11px"
            className={classes.grayCircle}
          />
          <GreenCircle
            width="8px"
            height="8px"
            className={classes.greenCircle}
          />
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              lineHeight: '17px',
            }}
          >
            Информация о пользователе
          </Typography>
          <div
            className={classes.rightInfo}
            onClick={() => setDisabled((prev) => !prev)}
          >
            {disabled && (
            <CircleIcon
              src={editIcon}
              alt="Edit"
              style={{ marginRight: '8px' }}
            />
            )}
            {!disabled && (
            <CircleIcon
              src={saveIcon}
              alt="Save"
              style={{ marginRight: '8px' }}
            />
            )}
            <Typography
              style={{
                color: '#55C7E1',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeihgt: '15px',
              }}
            >
              {!disabled ? 'Сохранить' : 'Редактировать'}
            </Typography>
          </div>
        </Panel>

        <ProfileForm disabled={disabled} />
        <Postback style={{ marginBottom: '24px' }} />

        <Panel
          width="269px"
          height="48px"
          style={{ marginBottom: '55px', position: 'relative' }}
        >
          <Polyline className={classes.polyline} />
          <Line className={classes.line} />
          <GrayCircle
            width="11px"
            height="11px"
            className={classes.grayCircle}
          />
          <GreenCircle
            width="8px"
            height="8px"
            className={classes.greenCircle}
          />
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              lineHeight: '17px',
            }}
          >
            Платежная информация
          </Typography>
          <div
            className={classes.rightInfo}
            style={{ right: '-238px', cursor: 'pointer' }}
            onClick={() => history.push('/app/profile/add_payment')}
          >
            <CircleIcon
              src={addIcon}
              alt="Edit"
              style={{ marginRight: '8px' }}
            />
            <Typography
              style={{
                color: '#55C7E1',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeihgt: '15px',
              }}
            >
              Добавить платёжный способ
            </Typography>
          </div>
        </Panel>

        <Table style={{ width: '100%' }}>
          <TableRow type="head">
            <TableCell
              center
              hasBorderRight
              style={{ width: '178px' }}
            >
              <Typography className={classes.tableHeadCell}>
                {t(NAME)}
              </Typography>
            </TableCell>

            <TableCell
              center
              hasBorderRight
              style={{ width: '157px' }}
            >
              <Typography className={classes.tableHeadCell}>
                По умолчанию
              </Typography>
            </TableCell>

            <TableCell
              center
              hasBorderRight
              style={{ width: '195px' }}
            >
              <Typography className={classes.tableHeadCell}>
                Статус получателя
              </Typography>
            </TableCell>

            <TableCell
              center
              hasBorderRight
              style={{ width: '163px' }}
            >
              <Typography className={classes.tableHeadCell}>
                Тип оплаты
              </Typography>
            </TableCell>

            <TableCell
              center
              hasBorderRight
              style={{ width: '112px' }}
            >
              <Typography className={classes.tableHeadCell}>
                Валюта
              </Typography>
            </TableCell>

            <TableCell
              center
              hasBorderRight
              style={{ width: '182px' }}
            >
              <Typography className={classes.tableHeadCell}>
                Счет
              </Typography>
            </TableCell>

            <TableCell
              center
              hasBorderRight
              style={{ width: '104px' }}
            >
              <Typography className={classes.tableHeadCell}>
                Статус
              </Typography>
            </TableCell>

            <TableCell style={{ width: '110px' }} />
          </TableRow>

          {!isPaymentLoading && paymentInfos
                    && paymentInfos.map(
                      (
                        {
                          account_number: accountNumber,
                          currency,
                          id,
                          is_default: isDefault,
                          name,
                          recipient_type: recipientType,
                          requisites_type: requisitesType,
                          status,
                        },
                        index,
                      ) => (
                        <TableRow key={id}>
                          <TableCell hasBorderRight light>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                              style={{
                                fontWeight: 'bold',
                                marginRight: '25px',
                                paddingLeft: '10px',
                              }}
                            >
                              {index + 1}
                              .
                            </Typography>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                            >
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell center hasBorderRight light>
                            {isDefault && (
                            <img
                              src={greenArrow}
                              alt="Green arrow"
                            />
                            )}
                            {!isDefault && (
                            <img
                              src={greyArrow}
                              alt="Grey arrow"
                            />
                            )}
                          </TableCell>
                          <TableCell center hasBorderRight light>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                            >
                              {recipientType}
                            </Typography>
                          </TableCell>
                          <TableCell center hasBorderRight light>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                            >
                              {requisitesType}
                            </Typography>
                          </TableCell>
                          <TableCell center hasBorderRight light>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                            >
                              {currency}
                            </Typography>
                          </TableCell>
                          <TableCell center hasBorderRight light>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                            >
                              {accountNumber}
                            </Typography>
                          </TableCell>
                          <TableCell center hasBorderRight light>
                            <Typography
                              component="span"
                              className={
                                                    classes.tableBodyCell
                                                }
                              style={{}}
                            >
                              {status && 'Активен'}
                              {!status && 'Не активен'}
                            </Typography>
                          </TableCell>
                          <TableCell
                            center
                            hasBorderRight
                            light
                            contentStyle={{
                              padding: '8px 0 0 40px',
                            }}
                          >
                            <Popover
                              open={!!popupRef.container}
                              anchorEl={popupRef.container}
                              style={{ boxShadow: 'none' }}
                              onBackdropClick={() => {
                                setPopupRef({});
                              }}
                            >
                              <div>
                                <div
                                  className={
                                                            classes.popoverButton
                                                        }
                                  onClick={activate(
                                    popupRef.id,
                                  )}
                                >
                                  Активировать
                                </div>

                                <div
                                  className={
                                                            classes.popoverButton
                                                        }
                                  onClick={deactivate(
                                    popupRef.id,
                                  )}
                                >
                                  Деактивировать
                                </div>

                                <div
                                  className={
                                                            classes.popoverButton
                                                        }
                                  onClick={setDefault(
                                    popupRef.id,
                                  )}
                                >
                                  Сделать по умолчанию
                                </div>
                                <div
                                  className={
                                                            classes.popoverButton
                                                        }
                                  onClick={deleteRequest(
                                    popupRef.id,
                                  )}
                                >
                                  {t(REMOVE)}
                                </div>
                              </div>
                            </Popover>
                            <CircleIcon
                              style={{ cursor: 'pointer' }}
                              src={menuIcon}
                              alt="Menu"
                              onClick={(event) => setPopupRef({
                                container:
                                                            event.currentTarget,
                                id,
                              })}
                            />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
        </Table>
        {isPaymentLoading && <Loader />}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 85px)',
    padding: '0 7% 200px 7%',
    overflow: 'auto',
  },
  inputFormWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '56px',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    fontSize: '11px',
    fontHeight: '13px',
    color: 'rgba(0, 0, 0, 0.56)',
  },
  popoverButton: {
    '&:last-child': {
      borderBottom: 'none',
    },
    borderBottom: '1px solid #e6e6e6',
    padding: '5px 10px',
    cursor: 'pointer',
    transition: 'background .2s',
    '&:hover': {
      background: '#f6f6f6',
    },
  },
  grayCircle: {
    position: 'absolute',
    left: '-6px',
    top: '17px',
  },
  greenCircle: {
    position: 'absolute',
    right: '-5px',
    top: '20px',
  },
  polyline: {
    top: '-779px',
    left: '-345px',
  },
  line: {
    width: '32px',
    top: '23px',
    right: '-32px',
  },
  rightInfo: (props) => ({
    position: 'absolute',
    display: 'flex',
    top: '14px',
    right: props.disabled ? '-153px' : '-128px',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  tableBodyCell: {
    fontWeight: 'normal',
    fontSize: '12px',
    fontHeight: '15px',
    color: 'rgba(0, 0, 0, 0.67)',
  },
}));
