"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var CreateResponse = /** @class */ (function () {
    function CreateResponse(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(CreateResponse.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateResponse({
            id: m['id']
        });
    };
    CreateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
var GetOneResponse = /** @class */ (function () {
    function GetOneResponse(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['pending'] = m['pending'];
            this._json['ids'] = m['ids'];
            this._json['filters'] = m['filters'];
        }
    }
    Object.defineProperty(GetOneResponse.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "pending", {
        // pending (pending)
        get: function () {
            return this._json.pending;
        },
        set: function (value) {
            this._json.pending = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "ids", {
        // ids (ids)
        get: function () {
            return this._json.ids || [];
        },
        set: function (value) {
            this._json.ids = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneResponse.prototype, "filters", {
        // filters (filters)
        get: function () {
            return this._json.filters;
        },
        set: function (value) {
            this._json.filters = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneResponse({
            id: m['id'],
            name: m['name'],
            project: m['project'],
            pending: m['pending'],
            ids: (m['ids'] || []).map(function (v) { return String(v); }),
            filters: m['filters']
        });
    };
    GetOneResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
var GetManyResponse = /** @class */ (function () {
    function GetManyResponse(m) {
        this._json = {};
        if (m) {
            this._json['segments'] = m['segments'];
        }
    }
    Object.defineProperty(GetManyResponse.prototype, "segments", {
        // segments (segments)
        get: function () {
            return this._json.segments || [];
        },
        set: function (value) {
            this._json.segments = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyResponse({
            segments: (m['segments'] || []).map(function (v) { return GetManyEntity.fromJSON(v); })
        });
    };
    GetManyResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
var GetManyEntity = /** @class */ (function () {
    function GetManyEntity(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['pending'] = m['pending'];
            this._json['count'] = m['count'];
        }
    }
    Object.defineProperty(GetManyEntity.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyEntity.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyEntity.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyEntity.prototype, "pending", {
        // pending (pending)
        get: function () {
            return this._json.pending;
        },
        set: function (value) {
            this._json.pending = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyEntity.prototype, "count", {
        // count (count)
        get: function () {
            return this._json.count;
        },
        set: function (value) {
            this._json.count = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyEntity.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyEntity({
            id: m['id'],
            name: m['name'],
            project: m['project'],
            pending: m['pending'],
            count: m['count']
        });
    };
    GetManyEntity.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyEntity;
}());
exports.GetManyEntity = GetManyEntity;
var Empty = /** @class */ (function () {
    function Empty(m) {
        this._json = {};
        if (m) {
        }
    }
    Empty.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Empty({});
    };
    Empty.prototype.toJSON = function () {
        return this._json;
    };
    return Empty;
}());
exports.Empty = Empty;
var CreateRequest = /** @class */ (function () {
    function CreateRequest(m) {
        this._json = {};
        if (m) {
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['ids'] = m['ids'];
            this._json['filters'] = m['filters'];
        }
    }
    Object.defineProperty(CreateRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "ids", {
        // ids (ids)
        get: function () {
            return this._json.ids || [];
        },
        set: function (value) {
            this._json.ids = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "filters", {
        // filters (filters)
        get: function () {
            return this._json.filters;
        },
        set: function (value) {
            this._json.filters = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateRequest({
            name: m['name'],
            project: m['project'],
            ids: (m['ids'] || []).map(function (v) { return String(v); }),
            filters: m['filters']
        });
    };
    CreateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
var GetOneRequest = /** @class */ (function () {
    function GetOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(GetOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetOneRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneRequest({
            id: m['id'],
            project: m['project']
        });
    };
    GetOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
var GetManyRequest = /** @class */ (function () {
    function GetManyRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['pending'] = m['pending'];
        }
    }
    Object.defineProperty(GetManyRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "pending", {
        // pending (pending)
        get: function () {
            return this._json.pending;
        },
        set: function (value) {
            this._json.pending = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyRequest({
            project: m['project'],
            pending: m['pending']
        });
    };
    GetManyRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
var DeleteOneRequest = /** @class */ (function () {
    function DeleteOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(DeleteOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteOneRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    DeleteOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteOneRequest({
            id: m['id'],
            project: m['project']
        });
    };
    DeleteOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteOneRequest;
}());
exports.DeleteOneRequest = DeleteOneRequest;
var UpdateOneRequest = /** @class */ (function () {
    function UpdateOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
            this._json['name'] = m['name'];
            this._json['filters'] = m['filters'];
            this._json['ids'] = m['ids'];
        }
    }
    Object.defineProperty(UpdateOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "filters", {
        // filters (filters)
        get: function () {
            return this._json.filters;
        },
        set: function (value) {
            this._json.filters = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateOneRequest.prototype, "ids", {
        // ids (ids)
        get: function () {
            return this._json.ids || [];
        },
        set: function (value) {
            this._json.ids = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateOneRequest({
            id: m['id'],
            project: m['project'],
            name: m['name'],
            filters: m['filters'],
            ids: (m['ids'] || []).map(function (v) { return String(v); })
        });
    };
    UpdateOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateOneRequest;
}());
exports.UpdateOneRequest = UpdateOneRequest;
var ActivateRequest = /** @class */ (function () {
    function ActivateRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(ActivateRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActivateRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    ActivateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ActivateRequest({
            id: m['id'],
            project: m['project']
        });
    };
    ActivateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return ActivateRequest;
}());
exports.ActivateRequest = ActivateRequest;
var DeactivateRequest = /** @class */ (function () {
    function DeactivateRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['project'] = m['project'];
        }
    }
    Object.defineProperty(DeactivateRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeactivateRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    DeactivateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeactivateRequest({
            id: m['id'],
            project: m['project']
        });
    };
    DeactivateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return DeactivateRequest;
}());
exports.DeactivateRequest = DeactivateRequest;
var SegmentService = /** @class */ (function () {
    function SegmentService(hostname, fetch) {
        this.path = '/twirp/admin.flowBuilder.SegmentService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    SegmentService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    SegmentService.prototype.create = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return CreateResponse.fromJSON(m); });
        });
    };
    SegmentService.prototype.getOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneResponse.fromJSON(m); });
        });
    };
    SegmentService.prototype.getMany = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetManyResponse.fromJSON(m); });
        });
    };
    SegmentService.prototype.deleteOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('DeleteOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    SegmentService.prototype.updateOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('UpdateOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    SegmentService.prototype.activate = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Activate'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    SegmentService.prototype.deactivate = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Deactivate'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    return SegmentService;
}());
exports.SegmentService = SegmentService;
