import fetch from '@main/utils/handledFetch';
import { backendURL } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import * as Sentry from '@sentry/react';

export const tryRegisterWithServicesStep1 = async (userType, code, state) => {
  let from;
  if (state?.from === 'google') {
    from = 'GOOGLE';
  } else {
    from = 'FACEBOOK';
  }
  if (code) {
    const response = await fetch(`${backendURL}/api/register/socials`, {
      method: 'POST',
      body: JSON.stringify({
        user_type: userType,
        code,
        social: from,
        ref: state?.ref,
        redirect_uri: `${window.location.origin}/register`,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    if (response?.status === 409) {
      throw Error(String(response.status));
    }

    const json = await response.json();

    if (!response.ok || json.error) {
      if (json.error === 'NOT_FOUND') {
        showToast('Пользователь не был найден');
      } else if (json.error === 'INTERNAL') {
        showToast(
          'Не получилось получить данные о google аккаунте',
        );
      } else {
        showToast('Неизвестная ошибка');
      }
      return;
    }
    return json;
  }
};

export const tryRegisterWithServicesStep2 = async (userType,
  number,
  password,
  numberToken,
  userToken,
  ref) => {
  const response = await fetch(`${backendURL}/api/register/socials/2`, {
    method: 'POST',
    body: JSON.stringify({
      user_type: userType,
      number,
      password,
      number_token: numberToken,
      user_token: userToken, // токен из первого запроса
      ref,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  });

  return response;
};
