import { blueColor, PencilIcon, Typography } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const changeButtonStyle = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: blueColor[100],
    cursor: 'pointer',
  },
  pencilIcon: {
    marginRight: '15px',
  },
  changeButton: {},
});
const ChangeButton = withStyles(changeButtonStyle)((props) => {
  const { className, classes } = props;
  return (
    <div {...props} className={clsx(classes.root, className)}>
      <PencilIcon className={classes.pencilIcon} />
      {' '}
      {/* <Typography variant="common">изменить</Typography> */}
      <Typography variant="common">Change</Typography>
    </div>
  );
});

export default ChangeButton;
