import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// Package components
import Conf from './components/Conf';
import Agreement from './components/Agreement';
// Material UI components
// Own components

class Policy extends React.Component {
  constructor(args) {
    super(args);
  }

  render() {
    return (
      <>
        <div style={{ width: '80vw', margin: 'auto' }}>
          <BrowserRouter>
            <>
              <Switch>
                <Route path="/policy/agreement" render={() => <Agreement />} />
                <Route exact path="/policy/" render={() => <Conf />} />
              </Switch>
            </>
          </BrowserRouter>
        </div>
      </>
    );
  }
}

export default Policy;
