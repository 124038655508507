import {
  blueColor, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination,
} from '@aisales/react-ui-kit';
import React, { useEffect, useState } from 'react';
import { TableContainer, withStyles } from '@material-ui/core';
import RowWithStatusTooltip from '@pages/finance/components/RowWithStatusTooltip';
import SortableTooltipHeadCell from '@pages/dashboard/components/Table/components/SortableTooltipHeadCell';
import {
  eliminatePercentFields, getAssociatedPercentDataString, getRenderer, parseDate,
} from '@pages/dashboard/utils';
import DashboardTableEllipsisCheckboxSelect from '@pages/dashboard/components/Table/EllipsisSquareCheckboxSelect';
import clsx from 'clsx';
import { fieldTitleMapping, reverseFieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';
import dateFormatOptions from '@pages/dashboard/constants/dateFormatOptions';
import percentDataFormatOptions from '@pages/dashboard/constants/percentDataFormatOptions';

const DashboardTable = ({
  tableData, // table data
  classes,
  requestBody, // body of the request
  dataIsLoading,
  headers, // columns names
  footerData, // data used for footer
  rowPerPage,
  currentPage,
  count, // total amount of rows
  setNonExistingReportMode,
  setCurrentPage,
  setRowPerPage,
  setRequestBody,
}) => {
  const [percentRepresentations, setPercentRepresentations] = useState([]);
  const getRadioOptions = (columnName) => {
    if (columnName === 'date') {
      return dateFormatOptions;
    }
    if (Object.keys(tableData[0].data).includes(`${columnName}_percent`)) {
      return percentDataFormatOptions;
    }
  };
  useEffect(() => {
  }, [requestBody]);
  const formatRow = (dataRow) => eliminatePercentFields(requestBody.fields).map((field) => ({
    element:
  <div>
    { percentRepresentations.includes(field) ? getAssociatedPercentDataString(field, dataRow) : getRenderer(field)(dataRow[field])}
    {/* {getRenderer(field)(dataRow[field])} */}
    {/* {' '} */}
    {/* {(getAssociatedPercentDataString(field, dataRow))} */}

  </div>,
  }));

  const changeRepresentation = (columnName, value) => {
    if (columnName === 'date') {
      setNonExistingReportMode(true);
      setRequestBody({ ...requestBody, groups: value });
    } else if (value === 'percent') {
      setPercentRepresentations([...percentRepresentations, columnName]);
    } else {
      setPercentRepresentations(percentRepresentations.filter((element) => element !== columnName));
    }
  };

  const getRepresentation = (columnName) => {
    if (columnName === 'date') {
      return requestBody.groups?.filter((element) => dateFormatOptions[0].value.includes(element));
    }
    return percentRepresentations.includes(columnName) ? 'percent' : 'number';
  };
  const getNewOrder = (order, index, previousIndex) => {
    const mapping = {
      null: 0,
      ascending: 1,
      descending: -1,
    };
    if (previousIndex !== index) {
      return mapping.ascending;
    }

    return mapping[order];
  };

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading

    <div>
      {(dataIsLoading || !footerData) ? <div>Loading</div> : (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead
              sortable
              sortedColumnIdx={headers.indexOf(fieldTitleMapping[requestBody.sorts[0].field])}
              sorting={requestBody.sorts[0].order === 1 ? 'ascending' : requestBody.sorts[0].order === -1 ? 'descending' : null}
              onChangeSorting={(sorting, index) => {
                setRequestBody(
                  {
                    ...requestBody,
                    sorts: [{
                      field: reverseFieldTitleMapping[headers[index].title],
                      order: getNewOrder(sorting,
                        index,
                        headers.indexOf(fieldTitleMapping[requestBody.sorts[0].field])),
                    }],
                  },
                );
              }}
            >
              {[...headers.map((header, index) => (
                <SortableTooltipHeadCell
                  hoverable
                  radioItems={getRadioOptions(reverseFieldTitleMapping[header.title])}
                  className={clsx({ [classes.lastHeader]: headers.length - 1 === index })}
                  classes={{
                    centralizedHorizontally: classes.centralizedAnotherWay,
                    sortable: headers.length - 1 === index ? classes.lastColumnHead : undefined,
                    root: headers.length - 1 === index
                      ? classes.sortableTooltipHeadCell_last : undefined,
                  }}
                  representation={getRepresentation(reverseFieldTitleMapping[header.title])}
                  onChangeRepresentation={(value) => {
                    changeRepresentation(
                      reverseFieldTitleMapping[header.title],
                      value,
                    );
                  }}
                  header={(
                    <div className={classes.header}>
                      {header.title}

                    </div>
)}
                  description={header.description}
                />
              )),
                <TableCell>
                  <div onClick={(event) => { event.stopPropagation(); }}>
                    <DashboardTableEllipsisCheckboxSelect
                      value={requestBody.fields}
                      onChange={(value) => {
                        setRequestBody({ ...requestBody, fields: value });
                        setNonExistingReportMode(true);
                      }}
                      className={classes.table__EllipsisCheckboxSelect}
                      title="Колонки"
                      isSearchable
                      checkboxesItems={Object.entries(fieldTitleMapping)
                        .map(
                          ([key, value]) => ({ value: key, label: value.title }),
                        )
                        .filter((element) => element.value !== 'date')}
                      ellipsisClasses={{ ellipsisSquare: classes.ellipsisSquare }}
                    />
                  </div>
                </TableCell>]}

            </TableHead>
            <TableBody>
              { tableData.map((dataPiece, index) => (
                <RowWithStatusTooltip
                  key={index}
                  tableCellsChildren={[
                    { element: parseDate(dataPiece.date) },
                    ...formatRow(dataPiece.data)]}
                  alternativeIcon=" "
                />
              ))}
            </TableBody>
            {footerData && (
              <TableFooter>
                {[
                  <TableCell>
                    Всего
                  </TableCell>,
                  ...eliminatePercentFields(requestBody.fields).map(
                    (field) => (
                      <TableCell>
                        { percentRepresentations.includes(field)
                          ? getAssociatedPercentDataString(field, footerData)
                          : getRenderer(field)(footerData[field])}
                        {/* {getRenderer(field)(footerData[field])} */}
                        {/* {' '} */}
                        {/* {getAssociatedPercentDataString(field, footerData)} */}
                      </TableCell>
                    ),
                  ), <TableCell />]}
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      ) }
      <TablePagination
        rowPerPageOptions={[10, 15, 30]}
        count={count}
        rowPerPage={rowPerPage}
        page={currentPage}
        onChangePage={(event, page) => { setCurrentPage(page); }}
        onChangeRowsPerPage={(event) => { setRowPerPage(event.target.value); }}
      />
    </div>
  // eslint-disable-next-line react/jsx-props-no-spreading

  );
};

const styles = {
  tableContainer: {

  },
  lastHeader: {
    width: '300px',
  },
  centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  centralizedHorizontally: {
    display: 'flex',
    justifyContent: 'center',
  },
  ellipsisSquare: {
    right: '0px',
    top: '0px',
  },
  table__EllipsisCheckboxSelect: {
    '&>div:hover': {
      border: '1px solid #CACACA',
      background: blueColor[10],
    },

  },
  lastColumnHead: {
    width: '55px',
  },
  sortableTooltipHeadCell_last: {
    // '&&&&&&&&:hover': {
    //   border: 'none',
    //   '&&&& $table__EllipsisCheckboxSelect': {
    //     '& div': {
    //       background: 'white',
    //       borderLeft: `1px solid ${greyColor[25]}`,
    //       borderRight: 'none',
    //     },
    //
    //   },
    // },
  },

};
export default withStyles(styles)(DashboardTable);
