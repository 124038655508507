import { Tab, Tabs, typographyDefaultTheme } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import React from 'react';

const Top = (({
  className, classes, currentTabIdx, onChangeTabIdx,
}) => {
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Tabs
        value={currentTabIdx}
        className={className}
        onChange={onChangeTabIdx}
        styleVariant="underlined"
      >
        <Tab
          classes={{ root: classes.tabRoot, underlined: classes.underlined }}
          // label="Вход"
          label="Login"
          onClick={() => {
            history.push('/login');
          }}
        />
        <Tab
          classes={{ wrapper: classes.tabRoot, underlined: classes.underlined }}
          // label="Регистрация"
          label="Registration"
          onClick={() => {
            history.push('/register');
          }}
        />
      </Tabs>
    </div>
  );
});

const topStyles = () => ({
  root: {

    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabRoot: {
    marginRight: '30px',
    paddingBottom: '0px',
    '&  span': {
      ...typographyDefaultTheme.typography.title,
    },

  },
  underlined: {
    '&&&&': {
      paddingBottom: '10px',
    },

  },
});

export default withStyles(topStyles)(Top);
