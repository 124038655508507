import {
  Button, Input, Link, Typography,
  FilledOkIcon,
} from '@aisales/react-ui-kit';
import React, { useState } from 'react';
import { backendURL } from '@main/toRemove';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import fetch from '@main/utils/handledFetch';
import PhoneVerificationV2 from '@components/PhoneVerificationV2';
import { fetchSecretKey, fetchVerificationId } from '@pages/finance/pages/BalancePage/services/phoneConfirmation';
import EntrancePasswordRestorationSendPhoneCodeForm
  from '@pages/entrance/components/PasswordRestoration/SendPhoneCodeForm';

async function requestToRestorePassword(emailOrPhone) {
  const response = await fetch(`${backendURL}/api/password/reset`, {
    method: 'POST',
    body: JSON.stringify({
      login: emailOrPhone,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  });

  const json = await response.json();

  const { error } = json;
  if (error) {
    return Promise.reject(error);
  }
  return json;
}
const EmailSentForm = ({ classes }) => (
  <div className={classes.centerElements}>
    <FilledOkIcon className={classes.okIcon} />
    <Typography bold>Письмо отправлено</Typography>
    <div className={classes.centerText}>
      Перейдите по ссылке в письме, которое
      мы отправили Вам на почту.
    </div>
  </div>
);

function Form({ classes }) {
  const history = useHistory();
  const [login, setLogin] = useState(null);
  const [phoneVerificationToken, setPhoneVerificationToken] = useState('');
  const [sentToEmail, setSentToEmail] = useState(false);
  const [sentToPhone, setSentToPhone] = useState(false);
  return (
    <div className={classes.top}>
      <div>
        {/* <Typography variant="title">Забыли пароль?</Typography> */}
        <Typography variant="title">Forgot your password?</Typography>
      </div>
      { (!sentToEmail && !sentToPhone)
      && (
      <div className={classes.centerText}>
        {/* Введите номер телефона или e-mail, привязанный */}
        {/* к Вашему аккаунту. */}
        Enter the phone number or email associated with
        to your account.
      </div>
      )}
      {(sentToEmail || sentToPhone)
        ? (
          sentToEmail
            ? <EmailSentForm classes={classes} /> : (
              <EntrancePasswordRestorationSendPhoneCodeForm
                setPhoneVerificationToken={setPhoneVerificationToken}
                phoneVerificationToken={phoneVerificationToken}
              />
            )
        )
        : (
          <form className={classes.form}>
            <Input
              onChange={(event) => {
                setLogin(event.target.value);
              }}
              className={classes.input}
              name="email"
              label="E-mail или телефон"
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                requestToRestorePassword(login).then((res) => {
                  if (res.type === 'SMS') {
                    setSentToPhone(true);
                    setPhoneVerificationToken(res.token);
                  } else {
                    setSentToEmail(true);
                  }
                });
              }}
            >
              Далее
            </Button>
          </form>
        )}
      {/* <div className={classes.return}> */}
      {/*  <Link onClick={() => { */}
      {/*    history.push('/login'); */}
      {/*  }} */}
      {/*  > */}
      {/*    Вернуться на страницу входа */}
      {/*  </Link> */}
      {/* </div> */}
    </div>
  );
}

const style = () => ({
  input: {
    width: '100%',
    marginBottom: '30px',
    marginTop: '40px',
  },
  return: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  okIcon: {
    marginTop: '40px',
    marginBottom: '15px',
  },
  button: {
    margin: '0px',
    width: '100%',
  },
  form: {
    width: '100%',
  },
  top: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    // '& div:first-child': {
    //   marginBottom: '10px',
    // },
    // '& div:last-child': {
    //   marginBottom: '30px',
    // },
  },
  centerText: {
    marginTop: '10px',
    textAlign: 'center',
  },
  centerElements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default withStyles(style)(Form);
