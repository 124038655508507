import React from 'react';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

function Table({
  children, className, classes, style,
}) {
  return (
    <table style={style} className={classNames(classes.table, className)}>
      {children}
    </table>
  );
}

const style = {
  table: {
    backgroundColor: 'white',
    borderCollapse: 'collapse',
    width: '100%',
    '& > tr:nth-child(even)': {
      backgroundColor: '#FBFBFB',
    },
  },
};

export default withStyles(style)(Table);
