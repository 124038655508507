import api from 'api';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/tools/utils';

export const GET_MANY_START = 'GET_MANY_START';
export const GET_MANY_SUCCESS = 'GET_MANY_SUCCESS';
export const GET_MANY_FAIL = 'GET_MANY_FAIL';
export const CREATE_START = 'CREATE_START';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const DELETE_START = 'DELETE_START';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';
export const FETCH_START = 'LANDBOT_FETCH_START';
export const FETCH_SUCCESS = 'LANDBOT_FETCH_SUCCESS';
export const FETCH_FAIL = 'LANDBOT_FETCH_FAIL';
export const FRAME_LOADING_END = 'LANDBOT_FRAME_LOADING_END';
export const RESET = 'LANDBOT_RESET';
export const TRACKER_TOGGLE = 'LANDBOT_TRACKER_TOGGLE';
export const SET_LANGUAGE = 'LANDBOT_SET_LANGUAGE';
export const SET_DATA = 'LANDBOT_SET_DATA';
export const SAVE_START = 'LANDBOT_SAVE_START';
export const SAVE_SUCCESS = 'LANDBOT_SAVE_SUCCESS';

const tools = new api.tools.ToolsService(backendURL, window.fetch.bind(window));
const landbots = new api.landbots.LandbotsServiceSecured(backendURL, window.fetch.bind(window));

const parseRPC = (landbot) => {
  const res = landbot.toJSON();
  res.layout = res.layout.toJSON();
  res.layout.bot_message = res.layout.bot_message.toJSON();
  res.layout.bot_message.colors = res.layout.bot_message.colors.toJSON();
  res.layout.user_message = res.layout.user_message.toJSON();
  res.layout.user_message.colors = res.layout.user_message.colors.toJSON();
  return res;
};

export const getMany = (dispatch) => {
  fetch(`${backendURL}/twirp/admin.tools.landbots.LandbotsServiceSecured/GetMany`, { ...twirpFetchOptions, body: '{}' }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error('bad response');
  }).then((res) => {
    dispatch({
      type: GET_MANY_SUCCESS,
      payload: res.landbots,
    });
  }).catch((err) => {
    showToast('Ошибка при получении списка инструментов', false);
    dispatch({
      type: GET_MANY_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: GET_MANY_START,
  });
};

export const getOne = (id) => (dispatch) => {
  fetch(`${backendURL}/twirp/admin.tools.landbots.LandbotsServiceSecured/GetOneById`, { ...twirpFetchOptions, body: JSON.stringify({ id }) }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error('bad response');
  }).then((res) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: res.landbot,
    });
  }).catch((err) => {
    dispatch({
      type: FETCH_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: FETCH_START,
  });
};

export const update = (data) => (dispatch) => {
  landbots.updateLayout(api.landbots.UpdateLayoutRequest.fromJSON({
    ...data,
  })).then((res) => {
    dispatch({
      type: SAVE_SUCCESS,
      payload: {
        ...parseRPC(res.landbot),
        name: data.name,
        tool_id: data.tool_id,
      },
    });
  });
  return dispatch({ type: SAVE_START });
};

export const remove = (id) => (dispatch) => {
  fetch(`${backendURL}/twirp/admin.tools.landbots.LandbotsServiceSecured/Delete`, { ...twirpFetchOptions, body: JSON.stringify({ id }) }).then((res) => {
    if (res.ok) {
      showToast('Инструмент успешно удалён', true);
      dispatch({
        type: DELETE_SUCCESS,
        payload: id,
      });
    } else {
      throw new Error('bad response');
    }
  }).catch((err) => {
    showToast('Ошибка при удалени', false);
    dispatch({
      type: DELETE_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: DELETE_START,
  });
};

export const create = (data, onSuccess) => (dispatch) => {
  fetch(`${backendURL}/twirp/admin.tools.landbots.LandbotsServiceSecured/Create`, { ...twirpFetchOptions, body: JSON.stringify(data) }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error('bad response');
  }).then((res) => {
    dispatch({
      type: CREATE_SUCCESS,
      payload: res.landbot,
    });
    onSuccess(res.landbot.id);
  }).catch((err) => {
    showToast('Ошибка при создании', false);
    dispatch({
      type: CREATE_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: CREATE_START,
  });
};

export const frameLoaded = (dispatch) => dispatch({ type: FRAME_LOADING_END });
export const reset = (dispatch) => dispatch({ type: RESET });
export const toggleTracker = (dispatch) => dispatch({ type: TRACKER_TOGGLE });
export const setLanguage = (lang) => (dispatch) => dispatch({ type: SET_LANGUAGE, payload: lang });
export const setData = (data) => (dispatch) => dispatch({ type: SET_DATA, payload: data });
