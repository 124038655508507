import { fieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';
//
// export const dateGropingOptions = [
//   { label: 'По часам', value: ['year', 'month', 'day', 'hour'] },
//   { label: 'По дням', value: ['year', 'month', 'day'] },
//   { label: 'По месяцам', value: ['year', 'month'] },
//   { label: 'По годам', value: ['year'] },
// ];
//
// export const offerGroupingOptions = [
//   { label: 'По офферу', value: ['offer'] },
// ];
//
// export const channelGroupingOptions = [
//   { label: 'По каналу', value: ['channel'] },
// ];
//
// export const cellsWithDataOptions = [
//   'Доход',
//   'Клики',
//   'Подписки',
//   'Активные',
//   'Отписки',
//   'Подтвержденные цели',
//   'Ожидающие цели',
//   'Отклоненные цели',
// ];
//
//
// export const groupingSelectOptions = [
//   {
//     title: 'Дата',
//     options: dateGropingOptions,
//   },
//   {
//     title: 'Оффер',
//     options: offerGroupingOptions,
//   },
//   {
//     title: 'Канал',
//     options: channelGroupingOptions,
//   },
// ];


export const dateGropingOptions = [
  { label: 'By hours', value: ['year', 'month', 'day', 'hour'] },
  { label: 'By day', value: ['year', 'month', 'day'] },
  { label: 'By month', value: ['year', 'month'] },
  { label: 'By year', value: ['year'] },
];

export const offerGroupingOptions = [
  { label: 'By offers', value: ['offer'] },
];

export const channelGroupingOptions = [
  { label: 'By channel', value: ['channel'] },
];

export const cellsWithDataOptions = [
  'Income',
  'Clicks',
  'Subscribers',
  'Active',
  'Banned',
  'Approved',
  'Pending',
  'Rejected',
];


export const groupingSelectOptions = [
  {
    title: 'Date',
    options: dateGropingOptions,
  },
  {
    title: 'Offer',
    options: offerGroupingOptions,
  },
  {
    title: 'Channel',
    options: channelGroupingOptions,
  },
];


export const counterOptions = [
  { label: fieldTitleMapping.conversations.title, value: 'conversations' },
  { label: fieldTitleMapping.ban.title, value: 'ban' },
];
