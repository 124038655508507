import React, { useState } from 'react';
import { InputBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { useTranslation } from 'react-i18next';
import OfferStepper from '@pages/offers/components/OfferConnect/components/Stepper';
import { Button } from '@aisales/react-ui-kit';

const countries = [];

const styles = {
  inputWrapper: {
    display: 'flex',
    background: 'rgb(255, 255, 255)',
    paddingRight: '10px',
    paddingLeft: '5px',
    border: '1px solid rgb(230, 230, 230)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '40px',
    transition: 'border .2s',
    '&.invalid': {
      border: '1px solid rgb(234, 132, 132)',
    },
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '10px',
  },
  // Title
  title: {
    position: 'relative',
    width: 'max-content',
    marginBottom: '26px',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.67)',
  },
  titleCircle: {
    position: 'absolute',
    top: '23px',
    left: '-5px',
  },
  titleBadge: {
    position: 'absolute',
    top: '53px',
    left: '-191px',
    cursor: 'pointer',
  },
  polyline: {
    position: 'absolute',
    top: '-771px',
    left: '-492px',
  },
  polylineCircle: {
    position: 'absolute',
    top: '15px',
    left: '-164px',
    cursor: 'pointer',
  },
};

const LandbotStepper = withStyles(styles)(({ classes, setStep, ...otherProps }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [check, setCheck] = useState(false);
  const offerID = window.location.pathname.match(/\d+/)?.[0];
  const { t } = useTranslation();
  const history = useHistory();

  const addPlatform = async ({ monetization }) => {
    setLoading(true);
    return fetch(
      `${backendURL
      }/twirp/cpa.offers.OffersManagementService/ConnectLandbot`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          offer_id: Number(offerID),
          name,
          alias,
          billing_mode: monetization,
        }),
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('bad response code');
      })
      .then((res) => {
        history.push('/app/channels');
        return res;
      })
      .catch(() => {
      })
      .finally((res) => {
        setLoading(false);
        return res;
      });
  };

  const Component = ({ setStep }) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '70px 30% 200px 30%',
      }}
    >
      <div
        className="bot-connect-form-wrapper"
      >
        <div>
          <Typography variant="caption">Название</Typography>

          <InputBase
            style={{ fontSize: '13px', fontFamily: 'Montserrat' }}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Typography variant="caption">Домен</Typography>
          <InputBase
            fullWidth
            value={alias}
                        //            onKeyDown={e => {
                        //     if (!(e.key >= '0' && e.key <= '9') && !(e.key >= 'A' && e.key <= 'z')) {
                        //         e.preventDefault();
                        //         e.stopPropagation();
                        //     }
                        // }}
            onChange={(e) => setAlias(e.target.value)}
            style={{ fontSize: '13px', fontFamily: 'Montserrat' }}
          />
          <span style={{ paddingTop: '9px', fontWeight: '600' }}>.aisales.space</span>
          <Button onClick={() => {
            setStep(3);
          }}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );

  function specialSteps({ currentStep, setStep }) {
    return [{ component: React.cloneElement(<Component setStep={setStep} />, { setStep, currentStep, ...otherProps }) }];
  }

  return <OfferStepper onFinish={addPlatform} specialSteps={specialSteps} monetizationStepIdx={3} {...otherProps} />;
});
export default LandbotStepper;
