import { fetchPlatforms } from '@pages/services/fetchPlatforms';

const getAllPlatforms = async (userOffersIds) => {
  const platforms = new Set();
  for (const userOffersId of userOffersIds) {
    const res = await fetchPlatforms(userOffersId);
    for (const platform of res) {
      platforms.add(platform);
    }
  }
  return Array.from(platforms);
};

export default getAllPlatforms;
