import React, { useEffect, useState } from 'react';
import {
  greenColor,
  greyColor,
  orangeColor,
  redColor,
  Table,
  TableBody,
  TableCell,
  FilledExclamationMarkIcon,
  ExclamationMarkIcon,
  DownloadIcon,
  TableHead,
  TableRow, Tooltip, CircleIcon,
} from '@aisales/react-ui-kit';
import EmptyPage from '@components/EmptyPage';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFinanceTickets, fetchPaymentInfo, fetchUserInfo } from '@store/actions';
import { getCookie } from '@pages/finance/utils';
import { formatCurrency } from '@components/utils';
import { paymentMapping } from '@pages/finance/constants/paymentMapping';
import { useLocation } from 'react-router';
import { emptyPages } from '@pages/constants/emptyPages';
import RowWithStatusTooltip from '@pages/finance/components/RowWithStatusTooltip';
import { CLOSED, CREATED, IN_PROGRESS } from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// const headers = [
//   'ID',
//   'Дата создания',
//   'Сумма',
//   'Метод выплат',
//   'Статус',
// ];

const headers = [
  'ID',
  'Date of creation',
  'Sum',
  'Payout method',
  'Status',
];

const style = () => ({});

const username = JSON.parse(localStorage.getItem('user'))?.user?.username;
const emptyPage = emptyPages(username).finance.paymentHistory;

const PaymentHistoryPage = ({ classes }) => {
  const { t } = useTranslation();
  const tickets = useSelector((state) => state.finance.tickets);
  const accountID = getCookie('account_id');
  const paymentInfo = useSelector((state) => state.finance.paymentInfo);
  const dispatch = useDispatch();

  const location = useLocation();
  const getStatusString = (status) => ((status === 1) ? t(CREATED) : status === 2 ? t(IN_PROGRESS) : status === 3 ? t(CLOSED) : 'Отклонена');

  const getCurrentStatus = () => {
    const mapping = {
      all: null,
      created: 1,
      'in-process': 2,
      finished: 3,
    };
    const temp = location.pathname.split('/');
    return mapping[temp[temp.length - 1]];
  };

  const getRowColor = (status) => {
    switch (status) {
      case 2:
        return 'orange';
      case 4:
        return 'red';
      default:
        return undefined;
    }
  };

  const filterTickets = (tickets) => {
    const status = getCurrentStatus();
    if (status !== null) {
      return tickets.filter((ticket) => ticket.status === status);
    }
    return tickets;
  };

  const getRowInfo = (ticket) => ([
    { element: ticket.id },
    { element: ` ${ticket.created_time} ` },
    { element: ` ${formatCurrency(ticket.amount)} ₽ ` },
    { element: ` ${paymentMapping[paymentInfo.find((infoPiece) => infoPiece?.id === ticket?.info_id)?.requisites_type]?.name}` },
    {
      element: getStatusString(ticket.status),
      align: 'left',
    },
  ]);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchPaymentInfo());
    dispatch(fetchFinanceTickets(accountID));
  }, []);
  return (
    <div>
      {tickets.length !== 0
        ? (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell align={index === headers.length - 1 ? 'left' : 'center'}>
                    {' '}
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterTickets(tickets)?.map((ticket, index) => (
                // <RowWithStatus ticket={ticket} paymentInfo={paymentInfo} />
                <RowWithStatusTooltip
                  tableCellsChildren={getRowInfo(ticket)}
                  color={getRowColor(ticket.status)}
                  tooltipText={ticket.comment}
                  key={index}
                  alternativeIcon={ticket.status === 3 && (
                  <DownloadIcon onClick={(e) => {
                    e.stopPropagation();
                    window.open(ticket.attach_link);
                  }}
                  />
                  )}
                />
              ))}
            </TableBody>
          </Table>
        ) : <EmptyPage {...emptyPage} />}

    </div>
  );
};
export default withStyles(style)(PaymentHistoryPage);
