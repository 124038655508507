import {
  blueColor,
  Modal,
  Button,
  CloseIcon,
  greenColor,
  greyColor,
  RadioGroup,
  Select,
  SelectItem,
  Stepper,
  TextWithIcon,
  Typography,
} from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MonetizationItem from '@pages/offers/components/RadioItem';
import SuccessMessage from '@pages/offers/components/OfferConnect/components/Stepper/components/SuccessMessage';
import { channels } from '@pages/channels/constants';

const style = () => ({
  root: {
    '&&&&&:focus': {
      outline: 'none',
    },
    marginTop: '30px',
    width: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '30px',
    boxSizing: 'border-box',
    position: 'relative',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    borderRadius: '5px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  buttonAddChat: {
    marginTop: '30px',
    width: '290px',
  },
  input: {
    '&&&&': {
      width: '600px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  tickIconWrapperSuccess: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: greenColor[100],
  },
  tickIconSuccess: {
    width: '14px',
    height: '10px',
    color: 'white',
  },
  successMessage: {
    marginBottom: '15px',
  },
  bottom: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomButton: {
    '&:nth-child(1)': {
      marginRight: '20px',
      backgroundColor: blueColor[30],
      '& span': {
        color: greyColor[100],
      },

    },
  },
  successBottomSign: {
    width: '333px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  stepper: {
    marginRight: '53px',
    width: '670px',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    height: '13.13px',
    width: '13.13px',
    top: '33.75px',
    right: '33.12px',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  modal: {
    overflow: 'scroll',
  },
  messengerChoice: {},
});

/**
 * TODO: available_channels
 */

const radioValues = [{
  id: 'cpa',
  name: 'Cost per Action',
  // description: 'Оплата за какое-либо указанное в оффере действие, приведенного человека в диалог. Быстрые деньги, но большие холды. Есть  лимиты на выплаты в сутки/неделю. Возможность отклонения конверсий. Не всегда быстрые согласования с рекламодателями.',
  description: 'Payment for any action specified in the offer, brought to the dialogue by a person. Fast money but big holds. There are limits on payments per day/week. Opportunity to reject conversions. Not always quick approvals with advertisers.',
}, {
  id: 'revshare',
  name: 'Revenue Share',
  // description: 'Модель партнерской программы, по которой вебмастер получает процент от принесенной прибыли. Короткие холды. Данная модель будет приносить доход еще минимум несколько месяцев. Никаких лимитов на выплаты. Количество выплат зависит напрямую от вашего качественного трафика.',
  description: 'Affiliate program model, according to which the webmaster receives a percentage of the profits. Short colds. This model will generate income for at least a few more months. No payout limits. The number of payments depends directly on your quality traffic.',
}];
const OfferStepper = (props) => {
  const {
    chosenChannel,
    classes,
    error,
    groupToAdd,
    monetizationStepIdx = 3,
    offer,
    onClose,
    onFinish,
    open,
    setChosenChannel,
    specialSteps,
  } = props;
  useEffect(() => {
    if (chosenChannel) {
      setActiveStep(2);
    }
  },
  []);
  const history = useHistory();
  const [openMonetizationDescription, setOpenMonetizationDescription] = useState(-1);
  const [activeStep, setActiveStep] = useState(1);
  const [chosenMonetization, setChosenMonetization] = useState(null);
  const [finished, setFinished] = useState(false);
  const steps = [{
    // title: 'Выберите нужный мессенджер ',
    title: 'Choose the right messenger ',
    component:
  <Select
    className={classes.messengerChoice}
    // renderValue={() => (chosenChannel ? channels[chosenChannel]?.name : 'Выберите нужный мессенджер')}
    renderValue={() => (chosenChannel ? channels[chosenChannel]?.name : 'Choose the right messenger')}
    onChange={(event) => {
      setChosenChannel(event.target.value);
      setActiveStep(2);
    }}
    classes={{ inputRoot: classes.input }}
    value={!chosenChannel ? 'muiselectsucks' : chosenChannel}
  >
    {offer.available_channels.map((name) => (
      <SelectItem value={name}>
        <TextWithIcon
          iconFirst
          icon={React.cloneElement(channels[name]?.icon, { className: classes.icon })}
          label={channels[name]?.name}
        />
      </SelectItem>

    ))}
  </Select>,
  },
  ...specialSteps({ currentStep: activeStep, setStep: setActiveStep, finished }),
  {
    // title: 'Выберите способ монетизации для этого канала',
    title: 'Choose a monetization method for this channel',
    component:
  <div>
    {' '}
    {activeStep === monetizationStepIdx
                && (
                <div>
                  <RadioGroup onChange={(event) => { setChosenMonetization(event.target.value); }} aria-label="gender" name="gender1">
                    {radioValues.map((radioValue, index) => (
                      <div>
                        <MonetizationItem
                          onChangeOpen={(open) => {
                            if (open) {
                              setOpenMonetizationDescription(index);
                            } else {
                              setOpenMonetizationDescription(-1);
                            }
                          }}
                          opened={openMonetizationDescription === index}
                          radioValue={radioValue}
                          withoutOverflow
                        />
                      </div>
                    ))}
                  </RadioGroup>

                  <div className={classes.buttonWrapper}>
                    <Button
                      disabled={!chosenMonetization || error}
                      onClick={() => {
                        onFinish({ group: groupToAdd, monetization: chosenMonetization }).then((success) => {
                          if (success) {
                            setFinished(true);
                          }
                        });
                      }}
                      classes={{ root: classes.buttonAddChat }}
                    >
                      {/* Подключить канал */}
                      Connect channel
                    </Button>
                  </div>
                </div>
                )}
  </div>,

  },
  ];

  const bottom = (
    <div className={classes.bottom}>
      <SuccessMessage classes={classes} />
      {/* <Typography bold>Ваш канал успешно подключен</Typography> */}
      <Typography bold>Your channel has been successfully connected</Typography>
      <Typography variant="common" className={classes.successBottomSign}>
        {' '}
        {/* На странице каналы, вы сможете */}
        {/* регулировать настройки у групп более детально */}
        On the channels page, you can
        adjust group settings in more detail
      </Typography>
      <div>
        <Button
          onClick={() => {
            history.push('/app/channels');
          }}
          className={classes.bottomButton}
        >
          {/* Перейти на страницу каналы */}
          Go to channels page
        </Button>
        <Button
          className={classes.bottomButton}
          onClick={() => {
            setChosenChannel('');
          }}
        >
          {/* Подключить еще канал */}
          Connect another channel
        </Button>
      </div>
    </div>
  );
  return (
    <Modal
      className={classes.modal}
      open={open}
      // title="Подключение каналов"
      title="Connecting channels"
      onClose={() => { onClose(); setActiveStep(0); setChosenChannel(''); }}
    >
      {!finished ? (
        <Stepper
          className={classes.stepper}
          steps={steps}
          activeStep={activeStep}
        />
      ) : bottom}
    </Modal>
  );
};
export default withStyles(style)(OfferStepper);
