import React from 'react';
import {
  InputBase, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Modal from '@material-ui/core/Modal';

const re = /url\("([^"]*)"\)/i;

const ImagePicker = ({ background, classes, onChange }) => {
  const list = [
    'https://storage.aisales.online/aisales-assets/landbot-background/finance_0.png',
    'https://storage.aisales.online/aisales-assets/landbot-background/general_0.png',
  ];
  const build = (url) => `url("${url}")`;

  const [modal, setModal] = React.useState(false);
  const [link, setLink] = React.useState(re.test(background) ? background.match(re)[1] : '');
  const [valid, setValid] = React.useState(true);

  const linkre = /^(http(s?):)([/|.\w\s-*])*\.(?:jpg|jpeg|gif|png)$/i;
  const validate = (str) => {
    str = str.trim();
    setValid(linkre.test(str));
    return linkre.test(str);
  };

  return (
    <div className={classes.root}>
      <Modal open={modal} onBackdropClick={() => setModal(false)}>
        <div className={classes.modal}>
          <Typography variant="caption" style={{ color: 'white' }}>Ссылка на картинку</Typography>
          <Tooltip title="Ссылка должна заканчиваться на .gif, .png или .jpg">
            <div className={classNames(classes.inputWrapper, { invalid: !valid })}>
              <InputBase
                onFocus={(e) => e.target.select()}
                value={link}
                autoFocus
                autoComplete="off"
                inputProps={{ spellCheck: false }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (validate(link)) {
                      onChange(build(link.trim()));
                      setModal(false);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  } else if (e.key === 'Escape') {
                    setModal(false);
                    setValid(true);
                    setLink(background);
                  }
                }}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Имя"
                style={{ width: '100%', marginLeft: '10px', paddingTop: '2px' }}
              />
            </div>
          </Tooltip>
        </div>
      </Modal>
      {list.map((image) => (
        <div
          className={classNames(classes.grid, { selected: re.test(background) ? background.match(re)[1] === image : false })}
        >
          <div
            className={classes.image}
            style={{ background: build(image) }}
            onClick={() => onChange(build(image))}
          />
        </div>
      ))}
      <div
        className={classNames(classes.grid, { selected: re.test(background) ? !list.includes(background.match(re)[1]) : false })}
      >
        <div
          className={classes.image}
          style={{
            color: '#0699c2cf', paddingTop: '48px', textAlign: 'center', background: 'white',
          }}
          onClick={() => { setModal(true); }}
        >
          <ControlPointIcon style={{ fontSize: '50px' }} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    padding: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    transition: 'border .3s',
    cursor: 'pointer',
  },
  grid: {
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    backgroundImage: 'linear-gradient(45deg, #8080801a 25%, transparent 25%), linear-gradient(-45deg, #8080801a 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #8080801a 75%), linear-gradient(-45deg, transparent 75%, #8080801a 75%)',
    backgroundColor: 'white',
    width: '150px',
    height: '150px',
    borderRadius: '20px',
    border: '2px dashed #00000021',
    transition: 'background-image .5s',
    '&:hover': {
      border: '2px dashed #02abd3cf',
    },
    '&.selected': {
      border: '2px dashed #02abd3cf',
    },
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '15px',
  },
  modal: {
    height: '70px',
    minWidth: '600px',
    width: '70vw',
    maxWidth: '800px',
    margin: 'calc(50vh - 35px) auto',
  },
  inputWrapper: {
    display: 'flex',
    width: '75%',
    background: 'rgb(255, 255, 255)',
    paddingRight: '10px',
    border: '1px solid rgb(230, 230, 230)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '40px',
    transition: 'border .2s',
    '&.invalid': {
      border: '2px solid rgb(234, 132, 132)',
    },
  },
};

export default withStyles(styles)(ImagePicker);
