import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button, GrayCircle, Panel, Polyline,
} from '@aisales/react-uikit';
import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/offers/utils';
import PhoneVerification from '@components/PhoneVerification';
import { COUNTRY } from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '@store/actions';
import InputField from './InputField';
import {
  checkBIK,
  checkCheckingAccount,
  checkCorrespondedAccount,
  checkINN,
  checkIssuedDepartmentCode,
  checkPassportDate,
  checkPassportNumber,
  checkPassportSerialNumber,
  emailRe,
  personRe,
  phoneRe,
  requiredText,
} from './utils';
import SelectField from './SelectField';

const validationSchema = Yup.object().shape({
  // Contact info
  fullName: Yup.string()
    .test(
      '3 words',
      'Необходимо ввести 3 слова: Фамилия, Имя, Отчество',
      (value) => personRe(value),
    )
    .required(requiredText),
  phoneNumber: Yup.string()
    .test('phoneNumber', 'Неверный номер телефона', (value) => phoneRe(value))
    .required(requiredText),
  email: Yup.string()
    .test('email', 'Неверный адрес почты', (value) => emailRe(value))
    .required(requiredText),

  // Passport
  country: Yup.string().required(requiredText),
  passportSerialNumber: Yup.string()
    .test(
      'serialNumber',
      'Неправильная серия пасспорта',
      checkPassportSerialNumber,
    )
    .required(requiredText),
  passportNumber: Yup.string()
    .test(
      'passportNumber',
      'Неправильный номер пасспорта',
      checkPassportNumber,
    )
    .required(requiredText),
  issuedBy: Yup.string().required(requiredText),
  issuedDepartmentCode: Yup.string()
    .test('issuedCode', 'Неправильный код', checkIssuedDepartmentCode)
    .required(requiredText),
  issueDate: Yup.string()
    .test('issuedDate', 'Неправильная дата выдачи', checkPassportDate)
    .required(requiredText),
  registrationAddress: Yup.string().required(requiredText),
  taxpayerNumber: Yup.string()
    .test('inn', 'Неправильный ИНН', checkINN)
    .required(requiredText),

  // Card account
  cardNumber: Yup.string()
    .when('equareType', {
      is: 'CARD',
      then: (fieldSchema) => fieldSchema
        .matches(
          /^(\d{4}[ ]){3}\d{4}|\d{16}$/,
          'Неправильный номер карты',
        )
        .required(requiredText),
    })
    .when('equareType', {
      is: 'YANDEX',
      then: (fieldSchema) => fieldSchema
        .matches(
          /^(\d{4}[ ]){3}\d{4}|\d{16}$/,
          'Неправильный номер счета',
        )
        .required(requiredText),
    })
    .when('equareType', {
      is: 'RAPIDA',
      then: Yup.string().test(
        'rapida',
        'Неправильный номер счета',
        (value) => {
          if (!value || value.match(/\D/)) {
            return false;
          }

          return value.split(' ').join('').length === 10;
        },
      ),
    })
    .when('equareType', {
      is: 'BANK',
      then: Yup.string(),
    }),

  // Bank account
  bik: Yup.string().when('equareType', {
    is: 'BANK',
    then: (value) => Yup.string()
      .test('bik', 'Неправильный номер БИК', checkBIK)
      .required(),
    otherwise: Yup.string(),
  }),
  bankName: Yup.string().when('equareType', {
    is: 'BANK',
    then: (value) => Yup.string().required(requiredText),
    otherwise: Yup.string(),
  }),
  bankCountry: Yup.string().when('equareType', {
    is: 'BANK',
    then: Yup.string().required(requiredText),
    otherwise: Yup.string(),
  }),
  correspondedAccount: Yup.string().when('equareType', {
    is: 'BANK',
    then: (value) => Yup.string()
      .test(
        'correspondedAccount',
        'Неправильно введенный корреспондетский счет',
        checkCorrespondedAccount,
      )
      .required(requiredText),
    otherwise: Yup.string(),
  }),
  checkingAccount: Yup.string().when('equareType', {
    is: 'BANK',
    then: (value) => Yup.string()
      .test(
        'checkingAccount',
        'Неправильно введенный расчетный счет',
        checkCheckingAccount,
      )
      .required(requiredText),
    otherwise: Yup.string(),
  }),
});

export default function SelfEmployed() {
  const classes = useStyles();
  const history = useHistory();
  const formValues = useSelector((state) => state.profile.formValues);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);
  useEffect(() => {

  }, [formValues]);
  const [validationToken, setValidationToken] = useState(null);

  const { t } = useTranslation();

  const handleSubmit = async (values, actions) => {
    if (!validationToken) {
      return;
    }

    try {
      const cardNumber = values.cardNumber.split(' ').join('');
      const [surname, name, patronymic] = values.fullName.split(' ');
      let account = {};

      switch (values.equareType) {
        case 'CARD':
          account = {
            name: 'Card',
            type: 'CARD',
            currency: values.currency,
            card: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        case 'BANK':
          account = {
            name: 'Bank',
            type: 'BANK',
            currency: values.currency,
            bank: {
              bik: values.bik,
              bank_name: values.bank_name,
              bank_country: values.bank_country,
              corresponded_account: values.correspondedAccount,
              checking_account: values.checkingAccount,
            },
          };
          break;
        case 'YANDEX':
          account = {
            name: 'Yandex',
            type: 'YM',
            currency: values.currency,
            ym: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        case 'WEBMONEY':
          account = {
            name: 'Webmoney',
            type: 'WEBMONEY',
            currency: values.currency,
            webmoney: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        case 'RAPIDA':
          account = {
            name: 'Rapida',
            type: 'RAPIDA',
            currency: values.currency,
            rapida: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
          break;
        default:
          account = {
            name: values.equareType,
            type: values.equareType,
            currency: values.currency,
            card: {
              type: 'DEBIT',
              number: cardNumber,
            },
          };
      }

      const response = await fetch(
        `${backendURL}/twirp/cpa.users.BillingInfoService/Create`,
        {
          ...twirpFetchOptions,
          headers: {
            ...twirpFetchOptions?.headers,
            auth: validationToken,
          },
          body: JSON.stringify({
            name: 'Self employed',
            recipient_type: 'SELF_EMPLOYED',
            self_employed: {
              general: {
                name,
                surname,
                patronymic,
                phone_number: values.phoneNumber,
                email: values.email,
              },
              document: {
                passport_serial_number:
                                    values.passportSerialNumber,
                passport_number: values.passportNumber,
                issued_by: values.issuedBy,
                issue_date: values.issueDate,
                issued_department_code:
                                    values.issuedDepartmentCode,
                registration_address:
                                    values.registrationAddress,
                taxpayer_number: values.taxpayerNumber,
              },
              account,
            },
          }),
        },
      );

      if (response.status === 400) {
        showToast('Неправильно введеные данные');
      } else if (response.status === 500) {
        showToast('Ошибка сервера');
      } else {
        showToast('Платежный метод добавлен', true);
        history.push('/app/profile');
      }
    } catch (e) {
      showToast('Неизвестная ошибка');
    }
  };

  const cardLabel = (value) => {
    switch (value) {
      case 'CARD':
        return 'Номер карты';
      case 'YANDEX':
        return 'Номер кошелька';
      case 'WEBMONEY':
        return 'Номер кошелька';
      case 'RAPIDA':
        return 'Номер счета';
      default:
        return '';
    }
  };

  return (
    <Formik
      initialValues={{
        // Contact info
        fullName: '',
        phoneNumber: '',
        email: '',

        // Passport
        country: '',
        passportSerialNumber: '',
        passportNumber: '',
        issuedBy: '',
        issuedDepartmentCode: '',
        issueDate: '',
        registrationAddress: '',
        taxpayerNumber: '',

        equareType: '',

        // Card account
        cardNumber: '',
        type: 'DEBIT',
        currency: 'RUB',

        // Bank account
        bik: '',
        bankName: '',
        bankCountry: '',
        correspondedAccount: '',
        checkingAccount: '',
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className={classes.padding}>
          <Panel
            width="372px"
            height="48px"
            style={{ marginBottom: '37px', position: 'relative' }}
          >
            <Polyline className={classes.polyline} />
            <GrayCircle
              width="11px"
              height="11px"
              className={classes.grayCircle}
            />
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Общая информация о физическом лице
            </Typography>
          </Panel>
          <Panel
            width="184px"
            height="22px"
            type="primary"
            style={{ marginBottom: '22px' }}
          >
            <Typography className={classes.primaryBadgeText}>
              Основная информация
            </Typography>
          </Panel>
          <div className={classes.formWrapper}>
            <div className={classes.leftInputs}>
              <InputField
                name="fullName"
                label="Полное ФИО владельца счета"
              />
              <InputField
                name="phoneNumber"
                label="Номер телефона"
              />
              {/* <InputField
                            name="bankName"
                            label="Название банка"
                        /> */}
            </div>
            <div className={classes.centerInputs}>
              <InputField
                name="email"
                label="Электронная почта"
              />
              {/* <InputField name="bankBik" label="БИК банка" /> */}
              {/* <InputField
                            name="checkingAccount"
                            label="Расчетный счет"
                        /> */}
            </div>
          </div>
          <Panel
            width="184px"
            height="22px"
            type="primary"
            style={{ marginBottom: '22px' }}
          >
            <Typography className={classes.primaryBadgeText}>
              Документ
            </Typography>
          </Panel>
          <div className={classes.formWrapper}>
            <div className={classes.leftInputs}>
              <InputField name="country" label={t(COUNTRY)} />
              <InputField
                name="passportSerialNumber"
                label="Серия паспорта"
              />
              <InputField
                name="passportNumber"
                label="Номер паспорта"
              />
              <InputField name="issuedBy" label="Кем выдан" />
            </div>
            <div className={classes.centerInputs}>
              <InputField
                name="issuedDepartmentCode"
                label="Код подразделения"
              />
              <InputField name="issueDate" label="Дата выдачи" />
              <InputField
                name="registrationAddress"
                label="Адресс регистрации"
              />
              <InputField name="taxpayerNumber" label="ИНН" />
            </div>
          </div>
          <Panel
            width="250px"
            height="48px"
            style={{ marginBottom: '35px', position: 'relative' }}
          >
            <Polyline className={classes.polyline} />
            <GrayCircle
              width="11px"
              height="11px"
              className={classes.grayCircle}
            />
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Счет самозанятого лица
            </Typography>
          </Panel>
        </div>
        <div
          className={classNames(classes.padding, classes.white)}
          style={{
            marginBottom: '32px',
            height: '107px',
          }}
        >
          <SelectField
            name="equareType"
            label="Выберите экваера"
            options={[
              { label: 'Банковская карта', value: 'CARD' },
              { label: 'Банковский счет', value: 'BANK' },
              { label: 'Yandex Money', value: 'YANDEX' },
              // { label: "Webmoney", value: "WEBMONEY" },
              { label: 'Rapida', value: 'RAPIDA' },
            ]}
            style={{
              paddingTop: '17px',
              paddingBottom: '29px',
              width: '284px',
              height: '40px',
            }}
          />
        </div>
        <div className={classes.padding}>
          <div className={classes.formWrapper}>
            {/* <div className={classes.leftInputs}>
                        <Badge
                            width="184px"
                            height="22px"
                            type="primary"
                            style={{ padding: "0px", marginBottom: "36px" }}
                        >
                            <Typography
                                className={classes.primaryBadgeText}
                            >
                                Основная информация
                            </Typography>
                        </Badge>
                        <InputField name="schet" label="Название счета" />
                        <SelectField
                            name="schetType"
                            label="Тип счета"
                            options={[
                                { label: "Тип счета 1", value: "1" },
                                { label: "Тип счета 2", value: "2" },
                            ]}
                        />
                        <SelectField
                            name="currency"
                            label="Валюта"
                            options={[
                                { label: "Валюта 1", value: "1" },
                                { label: "Валюта 2", value: "2" },
                            ]}
                        />
                    </div> */}
            <div className={classes.leftInputs}>
              <Panel
                width="152px"
                height="22px"
                type="primary"
                style={{
                  padding: '0px',
                  // marginLeft: "45px",
                  marginBottom: '36px',
                }}
              >
                <Typography
                  className={classes.primaryBadgeText}
                >
                  Реквизиты счета
                </Typography>
              </Panel>
              <Field name="equareType">
                {({ field }) => (
                  <>
                    {field.value === 'BANK' && (
                    <>
                      <InputField
                        name="bik"
                        label="БИК"
                      />
                      <InputField
                        name="bankName"
                        label="Название банка"
                      />
                      <InputField
                        name="bankCountry"
                        label="Страна бенефициара"
                      />
                      <InputField
                        name="correspondedAccount"
                        label="Кореспондентский счет"
                      />
                      <InputField
                        name="checkingAccount"
                        label="Расчетный счет"
                      />
                    </>
                    )}
                    {(field.value === 'CARD'
                                            || field.value === 'YANDEX') && (
                                            <InputField
                                              name="cardNumber"
                                              label={cardLabel(field.value)}
                                              mask={[
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                              ]}
                                            />
                    )}
                    {field.value === 'RAPIDA' && (
                    <InputField
                      name="cardNumber"
                      label={cardLabel(field.value)}
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                    />
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className={classes.centerInputs} />
          </div>
        </div>
        <div className={classes.padding} style={{ marginBottom: 30 }}>
          <PhoneVerification
            onSuccess={(token) => setValidationToken(token)}
            type="WITHDRAWAL_TICKET_CREATION"
            style={{ marginBottom: 30 }}
            field={{ field: { value: formValues.phoneNumber } }}
            disabled={false}
            editingDisabled
          />
          <Panel
            width="550px"
            height="150px"
            style={{
              marginBottom: '50px',
              position: 'relative',
            }}
            type="secondary"
          >
            <Polyline
              className={classes.polyline}
              style={{ top: '-783px' }}
            />
            <GrayCircle
              width="11px"
              height="11px"
              className={classes.grayCircle}
              style={{ top: '11px' }}
            />
            <Typography
              style={{
                fontSize: '13px',
                lineHeight: '16px',
                textAlign: 'left',
              }}
            >
              Нажимая "сохранить", вы подтверждаете, что введенные
              {' '}
              <b>данные верны</b>
              .
              <br />
              <br />
              Пожалуйста, учитывайте, что прямой проверки
              корректности вводимых вами данных на данный момент
              нет. Будьте бдительны при вводе.
              {' '}
              <br />
              {' '}
              <br />
              С
              уважением, Команда Aisales.
            </Typography>
          </Panel>
        </div>
        <div className={classes.padding}>
          <Button
            type="submit"
            variant="contained"
            style={{ marginRight: '37px' }}
          >
            Сохранить
          </Button>
          <Button
            type="reset"
            variant="outlined"
            onClick={() => history.push('/app/profile')}
          >
            Отмена
          </Button>
        </div>
      </Form>
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  grayCircle: {
    position: 'absolute',
    left: '-6px',
    top: '17px',
  },
  greenCircle: {
    position: 'absolute',
    right: '-5px',
    top: '20px',
  },
  polyline: {
    top: '-778px',
    left: '-345px',
  },
  line: {
    width: '32px',
    top: '23px',
    right: '-32px',
  },
  rightInfo: {
    position: 'absolute',
    display: 'flex',
    top: '14px',
    right: '-153px',
    alignItems: 'center',
  },
  padding: {
    padding: '0 7% 0 calc(7% + 84px)',
  },
  white: {
    background: 'white',
  },
  primaryBadgeText: {
    fontSize: '11px',
    lineHeight: '13px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.67)',
    whiteSpace: 'nowrap',
    padding: '0',
  },
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '56px',
    width: '73%',
  },
  leftInputs: {
    flex: '1 0 31%',
    '& > div': {
      marginRight: '40px',
      paddingBottom: '13px',
    },
  },
  centerInputs: {
    flex: '1 0 37%',
    '& > div': {
      padding: '0 18px 13px 45px',
      borderLeft: '1px solid #E6E6E6',
    },
  },
}));
