import React from 'react';
import { Button, Modal } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';

const DashboardConfirmationModal = ({
  open, onClose, onClickNo, onClickYes, classes,
}) => (
  <Modal className={classes.dashboardConfirmationModal} open={open} onClose={onClose} title="Подтвердите действие">
    <div className={classes.dashboardConfirmationModal__text}>
      {/* У вас есть несохраненные изменения отчёта. */}
      You have unsaved change.
      {/* <div>Хотите сохранить этот отчёт?</div> */}
      <div>Save?</div>
    </div>
    <div className={classes.dashboardConfirmationModal__buttons}>
      {/* <Button onClick={onClickNo}>Нет</Button> */}
      <Button onClick={onClickNo}>No</Button>
      {/* <Button onClick={onClickYes}>Да</Button> */}
      <Button onClick={onClickYes}>Yes</Button>
    </div>
  </Modal>
);

const styles = () => ({
  dashboardConfirmationModal: {
    width: '500px',
  },
  dashboardConfirmationModal__text: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  dashboardConfirmationModal__buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(DashboardConfirmationModal);
