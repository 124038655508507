import { redColor } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import clsx from 'clsx';

const TagNew = ({ className, classes }) => <div className={clsx(classes.root, className)}>new</div>;

const tagNewStyle = ({}) => ({
  root: {
    background: redColor[100],
    color: 'white',
    padding: '3px 13px 3px 13px ',
    borderRadius: '5px 0px 0px 5px',
    width: 'fit-content',
    right: '0px',
  },
});

export default withStyles(tagNewStyle)(TagNew);
