import { greyColor } from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const offerIconStyle = () => ({
  root: {
    '& img': {
      width: '100%',
      padding: '5px',
    },
    left: '30px',
    display: 'flex',
    background: greyColor.background,
    borderRadius: '0px 0px 5px 5px',
    width: '60px',
    height: '60px',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const OfferIcon = withStyles(offerIconStyle)(({ className, classes, icon }) => (
  <div className={clsx(classes.root, className)}>
    <img src={icon} />
  </div>
));

export default OfferIcon;
