import DashboardReportCreationUpdateFormBase from '@pages/dashboard/components/ReportCreationUpdateFormBase';
import React, { useEffect, useState } from 'react';
import saveStatisticsReport from '@pages/dashboard/services/saveStatisticsReport';
import DashboardReportCreationUpdateFormBaseFinishedState
  from '@pages/dashboard/components/ReportCreationUpdateFormBase/components/FinishedState';
import updateStatisticsReport from '@pages/dashboard/services/updateStatisticsReport';

const reformatGroups = (groups) => {
  const options = ['year', 'month', 'day', 'hour'];
  const newColumns = groups.filter((column) => !options.includes(column)).map((column) => [column]);
  const dateGroupingValue = groups.filter((column) => options.includes(column));
  if (dateGroupingValue.length) {
    return [...newColumns, dateGroupingValue];
  }
  return newColumns;
};

const DashboardReportUpdateForm = ({
  open, onClose, newReportMode, currentReport, onFinish,
}) => {
  const [name, setName] = useState(currentReport.name || '');
  const [groupedCells, setGroupedCells] = useState(reformatGroups(currentReport.groups || []));
  const [cellsWithData, setCellsWithData] = useState(currentReport.columns || []);
  const [counters, setCounters] = useState(currentReport.counters || []);
  const [countersAreEnabled, setCountersAreEnabled] = useState(currentReport.need_counters || false);
  useEffect(() => {
    ``;
    setName(currentReport.name || '');
    setGroupedCells(reformatGroups(currentReport.groups || []));
    setCounters(currentReport.counters || []);
    setCountersAreEnabled(currentReport.need_counters || false);
    setCellsWithData(currentReport.columns || []);
  }, [currentReport]);
  return (
    <DashboardReportCreationUpdateFormBase
      onFinish={onFinish}
      open={open}
      name={name}
      counters={counters}
      setCounters={setCounters}
      setName={setName}
      countersAreEnabled={countersAreEnabled}
      setCountersAreEnabled={setCountersAreEnabled}
      modalTitle="Редактирование отчёта"
      onSave={newReportMode ? saveStatisticsReport : async (body) => updateStatisticsReport({ id: currentReport.id, ...body })}
      setGroupedCells={setGroupedCells}
      setCellsWithData={setCellsWithData}
      onClose={onClose}
      groupedCells={groupedCells}
      cellsWithData={cellsWithData}
      finishedStateElement={<DashboardReportCreationUpdateFormBaseFinishedState topText="Ваш отчёт успешно отредактирован" />}
    />
  );
};
export default DashboardReportUpdateForm;
