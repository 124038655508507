import { makeChannelsOptions } from '@pages/dashboard/components/FilterModal/utils/makeOptions';
import filterFields from '@pages/dashboard/components/FilterModal/constants/filterFields';

const maleVersion = ['Выбран', 'Выбраны', 'Выбрано'];
const femaleVersion = ['Выбрана', 'Выбраны', 'Выбрано'];

const channelsOptions = makeChannelsOptions();
export default {
  [filterFields.offer]: {
    label: 'По офферу',
    options: null,
    wordForms: ['оффер', 'оффера', 'офферов'],
    participleForms: maleVersion,
  },
  [filterFields.channel]: {
    label: 'По каналу',
    options: channelsOptions,
    wordForms: ['канал', 'канала', 'каналов'],
    participleForms: maleVersion,
  },
  [filterFields.platform]: {
    label: 'По площадке',
    options: null,
    wordForms: ['площадка', 'площадки', 'площадок'],
    participleForms: femaleVersion,
  },
  [filterFields.utm]: {
    label: 'По Utm метке',
    options: {
      utm1: 'utm1', utm2: 'utm2', utm3: 'utm3', utm4: 'utm4', utm5: 'utm5',
    },
    wordForms: ['метка', 'метки', 'меток'],
    participleForms: femaleVersion,
    inputs: true,
  },
};
