import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import folderSrc from '../assets/folder.svg';

export default function Folder({ active, name, ...props }) {
  const classes = useStyles({ active });

  return (
    <div className={classes.root} {...props}>
      <img src={folderSrc} alt="Folder" width="40px" height="40px" />
      <Typography className={classes.name}>{name}</Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    padding: '8px 0',
    height: 80,
    width: '100%',
    background: props.active ? 'rgba(171, 171, 183, 0.23)' : 'inherit',
    textAlign: 'center',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(171, 171, 183, 0.23)',
    },
  }),
  name: {
    fontWeight: 'bold',
    fontSize: 15,
    textAlign: 'center',
    color: '#1F2652',
  },
}));
