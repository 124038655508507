import { formatCurrency, formatCurrencyWithSymbol } from '@components/utils';
import { getPercentColorMaker } from '@pages/dashboard/utils/getPercentColorMaker';
import {
  greenColor, greyColor, orangeColor, redColor,
} from '@aisales/react-ui-kit';
import { getTooltipTextMaker } from '@pages/dashboard/utils/getTooltipTextMaker';

export const defaultRenderer = formatCurrency;

// export const fieldTitleMapping = {
//
//   date: { title: 'Дата' },
//   users: { title: 'Подписчики', description: 'Пример описания' },
//   active: { title: 'Активные' },
//   ban: {
//     title: 'Отписки',
//     ranges: {
//       red: [45, 100],
//       orange: [30, 44],
//       green: [0, 29],
//     },
//   },
//   combo: { title: 'Cовершившие все действия' },
//   conversions: { title: 'Конверсии' },
//   income_per_conversation: { title: 'Доход с одной конверсии', renderer: formatCurrencyWithSymbol },
//   pending: { title: 'В ожидании' },
//   approved: { title: 'Доход', renderer: formatCurrencyWithSymbol },
//   rejected: { title: 'Отклонённые' },
//   event_1: { title: 'Количество конверсий первого ивента' },
//   event_percent_1: { title: 'Процент конверсий первого ивента' },
//   conversations: {
//     title: 'Действия',
//     ranges: {
//       red: [-100, 44],
//       orange: [45, 59],
//       green: [60, 100],
//     },
//   },
// };

export const fieldTitleMapping = {
  date: { title: 'Date' },
  users: { title: 'Subscribers', description: '' },
  active: { title: 'Active' },
  ban: {
    title: 'Banned',
    ranges: {
      red: [45, 100],
      orange: [30, 44],
      green: [0, 29],
    },
  },
  combo: { title: 'Completed all actions' },
  conversions: { title: 'Conversions' },
  income_per_conversation: { title: 'Revenue per conversion', renderer: formatCurrencyWithSymbol },
  pending: { title: 'Hold' },
  approved: { title: 'Revenue', renderer: formatCurrencyWithSymbol },
  rejected: { title: 'Rejected' },
  event_1: { title: 'Number of first event conversions' },
  event_percent_1: { title: 'First event conversion percentage' },
  conversations: {
    title: 'Events',
    ranges: {
      red: [-100, 44],
      orange: [45, 59],
      green: [60, 100],
    },
  },
};

const reverseFieldTitleMapping = {};
for (const [key, value] of Object.entries(fieldTitleMapping)) {
  reverseFieldTitleMapping[value.title] = key;
}
export { reverseFieldTitleMapping };
