import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginSuccess, loginUser } from '@store/actions';
import { validatePassword } from '@pages/entrance/components/Registration/utils/validators';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Input, Link,
  MailIcon,
} from '@aisales/react-ui-kit';

import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import PasswordInput from '@pages/entrance/components/PasswordInput';
import FacebookInit from '@pages/entrance/components/Login/components/FacebookInit';
import AlternativeEnterButton from '@pages/entrance/components/Login/components/AlternativeEnterIcon';
import { tryLoginViaServices } from '@pages/entrance/components/Login/services';
import WordWithLine from '@pages/entrance/components/Login/components/WordWithLine';
import HelpText from '@pages/entrance/components/HelpText';
import alternativeEnterInfo from '@pages/entrance/components/Login/constants/alternativeEnterInfo';

const Login = ({ classes }) => {
  const [refCode, setRefCode] = useState(null);
  const userType = 'WEBMASTER';
  const [error, setError] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('allOffers')) {
      localStorage.removeItem('allOffers');
    }
  }, []);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setRefCode(searchParams.get('ref'));

    tryLoginViaServices(userType).then((json) => {
      if (json) {
        localStorage.setItem('user', JSON.stringify(json));
        dispatch(loginSuccess({
          username: json.user.username,
          userEmail: json.user.user_mail,
          userPicture: json.user.picture,
        }));
      }
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async (login, password) => {
    setError(validatePassword(password));
    if (error) {
      // setError('Неверный формат ввода');
      setError('Invalid input format');
    }
    await dispatch(loginUser(login, password, rememberMe));
  };

  const ForgotPasswordElement = () => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    // <a onClick={() => {
    //   history.push('/reset_password');
    // }}
    // >
    //
    // </a>
    <Link onClick={() => {
      history.push('/reset_password');
    }}
    >
      {/* Забыли пароль? */}
      Forgot your password?
    </Link>
  );
  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/app/dashboard' }} />;
  }

  return (
    <div className={classes.root}>
      <form onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(login, password);
      }}
      >
        <Input
          className={classes.login__input}
          // label="E-mail или Телефон"
          label="E-mail or phone"
          onChange={(event) => {
            setLogin(event.target.value);
            setError('');
          }}
          icon={<MailIcon />}
        />
        <PasswordInput
          errorText={error
        && (
        <div className={classes.login__forgetPasswordWrapper}>
          {/* Неверный формат ввода */}
          Invalid input format
          <ForgotPasswordElement />
        </div>
        )}
          setError={setError}
          setPassword={setPassword}
          // label="Пароль"
          label="Password"
          className={classes.login__input}
        />
        {!error && (
        <div className={classes.login__forgetPasswordWrapper}>
          <div className={classes.login__rememberMe}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(event.target.checked);
                  }}
                />
                )}
              // label="Запомнить меня"
              label="Remember me"
            />
          </div>
          <ForgotPasswordElement />
        </div>
        )}
        <div className={classes.center}>
          <Button
            type="submit"
            className={classes.login__button}
          >
            Войти
          </Button>
          <HelpText
            className={classes.login__helpText_privacy}
            detailed
            // textBeforeLink="Входя в аккаунт или создавая новый, вы соглашаетесь на "
            textBeforeLink="By logging into an account or creating a new one, you agree to"
            // linkText="обработку персональных данных"
            linkText="processing of personal data"
            link="/policy"
          />
          <WordWithLine word="or" className={classes.login__wordWithLine} />
          <div className={classes.login__buttons}>
            {Object.keys(alternativeEnterInfo).map((property) => (
              <AlternativeEnterButton
                className={classes.login__button}
                icon={alternativeEnterInfo[property].icon}
                href={alternativeEnterInfo[property].getHref(refCode)}
                platformName={alternativeEnterInfo[property].name}
                key={property}
              />
            ))}
            <FacebookInit />
          </div>
          <HelpText
            className={classes.login__helpText_register}
            // textBeforeLink="Если у Вас нет аккаунта, "
            textBeforeLink="If you don't have an account, "
            // linkText="Зарегистрируйтесь"
            linkText="Register"
            link="/register"
          />
        </div>
      </form>
    </div>
  );
};

const styles = (() => ({
  login__wordWithLine: {
    marginBottom: '0px',
  },
  login__helpText_privacy: {
    marginTop: '15px',
    marginBottom: '40px',
  },
  login__helpText_register: {
    marginTop: '40px',
  },
  login__buttons: {
    width: '100%',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  login__input: {
    marginBottom: '20px',
    width: '100%',
    '&>div:nth-of-type(2)': {
      width: '100%',
    },

  },
  login__forgetPasswordWrapper: {
    marginTop: '-20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  login__rememberMe: {
    display: 'flex',
    alignItems: 'center',
  },
  login__button: {
    width: '100%',
    marginTop: '20px',
  },

}));

export default withStyles(styles)(Login);
