import { AccountIcon, Button, Typography } from '@aisales/react-ui-kit';
import ChangeButton
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/ChangeButton';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';

const style = () => ({
  auth: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span:first-child': {
      width: '100%',
    },
  },
  authButton: {
    '&&&&&&': {
      marginTop: '30px',
      width: '290px',
    },
  },
  accountIcon: {
    marginRight: '15px',
  },
  accountName: {

    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: '1px',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  changeButton: {
    // marginRight: '53px'
  },
});

const Authentication = ({
  chosenChannel, classes, connect, currentStep, groupToAdd, isLogged, setLogged, setStep, socialNetworkName, userName,
}) => {
  useEffect(() => {
    if (isLogged) {
      if (groupToAdd) {
        setStep(4);
      } else {
        setStep(3);
      }
    } else if (!chosenChannel) {
      setStep(1);
    } else {
      setStep(2);
    }
  }, [isLogged, groupToAdd]);

  return (
    <div>
      <Typography className={classes.title} variant="subtitle">
        {/* Авторизация */}
        Authorization
        {isLogged && (
        <ChangeButton
          onClick={() => {
            setLogged(false);
            setStep(2);
          }}
          className={classes.changeButton}
        />
        )}
      </Typography>
      {!isLogged ? (
        <div>
          <div className={classes.auth}>
            <Typography>
              {/* Для дайнейшей работы вам нужно подключить Ваш аккаунт */}
              For further work you need to connect your account
              {' '}
              {socialNetworkName}
              {' '}
              {/* к нашей */}
              {/* системе. */}
              to our
              system.
            </Typography>
            <Button
              className={clsx('offersVKAuthTour__enterButton', classes.authButton)}
              variant="contained"
              onClick={connect}
              disabled={currentStep === 1}
            >
              {/* Авторизироваться */}
              Login
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.accountName}>
          <AccountIcon
            className={classes.accountIcon}
          />
          {userName}
        </div>
      )}
    </div>
  );
};

export default withStyles(style)(Authentication);
