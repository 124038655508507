import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

function Block({
  children, className, classes, style, top,
}) {
  return (
    <div className={clsx(classes.root, className)} style={style}>
      {top && React.cloneElement(top, { className: clsx(classes.top, top.className), ...top })}
      {Array.isArray(children) ? children.map((child) => (
        <div>
          {child}
        </div>
      )) : children}
    </div>
  );
}

const styles = {
  root: {
    padding: '52px 60px 40px 60px',
    width: '500px',
    height: 'fit-content',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    borderRadius: '3px',
  },
  top: {
    marginBottom: '60px',
  },
  title: {
    background: 'linear-gradient(90deg, rgba(248, 248, 248, 0.9) 0%, rgba(248, 248, 248, 0) 100%)',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#767676',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '30px',
  },
};

export default withStyles(styles)(Block);
