import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBanners,
  fetchFinanceTickets,
  fetchPaymentInfo,
  fetchUserInfo,
  getBalance,
  setBanner,
} from '@store/actions';
import InformationBlock from '@pages/finance/pages/BalancePage/components/InformationBlock';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { blueColor, Button, greenColor, orangeColor, } from '@aisales/react-ui-kit';

import fetch from '@main/utils/handledFetch';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import NewTicketForm from '@pages/finance/pages/BalancePage/NewTicketForm';
import { useHistory } from 'react-router';
import { financePaths } from '@pages/finance/constants/financePaths';
import { ReactComponent as Bag } from './assets/illustrationBag.svg';
import { ReactComponent as Card } from './assets/illustrationCard.svg';
import { ReactComponent as Coins } from './assets/illustrationCoins.svg';
import Chart from '../../components/Chart';
import { formatCurrency, getCookie } from '../../utils';
import Message from './components/Message';
import { ReactComponent as Illustration } from './assets/illustration.svg';

const BalancePage = (props) => {
  const start = new Date();
  const end = new Date();
  start.setDate(end.getDate() - 29);

  const initDateRange = {
    start,
    end,
  };

  const [messageIsShown, setMessageIsShown] = useState(true);
  const classes = useStyles();
  const banners = useSelector(state => state?.banners.banners)
  const modalOpen = window.location.pathname.includes('create-ticket');
  // const [modalOpen, setModalOpen] = useState(false)
  const tickets = useSelector((state) => state.finance.tickets);
  const paymentInfo = useSelector((state) => state.finance.paymentInfo);
  const [selectValue, setSelectValue] = useState(7);
  const [chartData, setChartData] = useState(null);
  const [chartIsDisabled, setChartIsDisabled] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const balance = useSelector((state) => state.balance.balance);
  const { t } = useTranslation();
  const history = useHistory();
  const firstName = JSON.parse(localStorage.getItem('user')).user.username;

  const accountID = getCookie('account_id');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchPaymentInfo());
    dispatch(fetchFinanceTickets(accountID));
    dispatch(fetchBanners())
  }, []);

  useEffect(() => {
    setMessageIsShown(Boolean(banners.finance_hello))
  }, [banners])

  useEffect(() => {

  }, [chartData]);

  const fetchChartData = async () => {
    try {
      const response = await fetch(
        `${backendURL
        }/twirp/statistics.cpa.CpaService/GetIncomeStatistics`,
        {
          ...twirpFetchOptions,
          body: JSON.stringify({
            account: Number(accountID),
            days: selectValue,
            time_zone: -new Date().getTimezoneOffset() / 60,
          }),
        },
      );

      if (!response.ok) {
        // throw new Error('Ошибка загрузки способов выплат');
        throw new Error('Error loading payment methods');
      }

      const json = await response.json();
      setChartData(json.rows.sort((a, b) => new Date(a.date) - new Date(b.date)));
      setChartDataLoading(false);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [selectValue]);

  useEffect(() => {
    dispatch(getBalance(accountID));
  }, []);

  const AvailablePaymentBlock = ({ withoutBottomText }) => (
    <InformationBlock
      tooltipStyle={{
        openedEllipseColor: 'white',
        openedQuestionMarkColor: blueColor[100],
        ellipseColor: blueColor['30'],
        questionMarkColor: blueColor[100],
      }}
      tickets={tickets}
      paymentInfo={paymentInfo}
      className={clsx(classes.informationBlock)}
      // description="Деньги, которые в текущий момент доступны для вывода."
      description="Money that is currently available for withdrawal."
      classes={{
        numberText: classes.availableBlockNumber,
        topPart: clsx(classes.blueBackground, classes.availableBlock),
      }}
      // topText="Доступно для вывода"
      topText="Available for withdrawal"
      numberText={`${
        formatCurrency(balance?.available) || 0
      } ₽`}
      // bottomText={withoutBottomText ? undefined : 'Выплаты, которые вы заказали'}
      bottomText={withoutBottomText ? undefined : 'Payments you ordered'}
      image={<Card />}
    />
  );

  return (
    <div className={classes.root}>
      {messageIsShown

            && (
            <Message
              // upperText={`${firstName}, добро пожаловать в Финансы`}
              upperText={`${firstName}, welcome to Finance`}
              classes={{ root: classes.messageRoot }}
              illustration={<Illustration />}
              // bottomText="На данной странице Вы можете выводить свои средста, просматривать историю выплат, сравнивать доходность и т.д."
              bottomText="On this page you can withdraw your funds, view your payout history, compare profitability, etc."
              onClose={() => {
                setMessageIsShown(false);
                dispatch(setBanner({key: 'finance_hello', value: 0}))
              }}
            />
            )}

      <div className={classes.grid}>
        <div className={classes.leftColumn}>
          <AvailablePaymentBlock />
          <InformationBlock
            classes={{ numberText: classes.greenText }}
            className={classes.informationBlock}
            // description="Сумма доступных для вывода денег и денег в холде."
            description="The amount of money available for withdrawal and money in hold."
            // topText="Всего на счету"
            topText="Total on account"
            image={<Bag />}
            numberText={`${formatCurrency(balance?.total) || 0} ₽`}
          />

          <InformationBlock
            image={<Coins />}
            // description="Деньги, которые в текущий момент недоступны для вывода."
            description="Money that is currently not available for withdrawal."
            classes={{ numberText: classes.yellowText }}
            className={classes.informationBlock}
            topText="Холд"
            numberText={`${
              (!Number.isNaN(
                                              balance?.total - balance?.available,
              )
                                              && formatCurrency(
                                                  balance?.total - balance?.available,
                                              ))
                                          || 0
            } ₽`}
          />
          <Button
            className={classes.button}
            onClick={() => {
              history.push(`${financePaths.balancePagePath}/create-ticket`);
            }}
          >
            {/* Заказать выплату */}
            Order a payout
          </Button>
        </div>
        <div>

          <Chart
            chartDataLoading={chartDataLoading}
            // chartTitle="График доходности"
            chartTitle="Yield chart"
            enableSelect
            chartData={chartData}
            className={classes.chart}
            setSelectValue={setSelectValue}
            selectValue={selectValue}
            tooltipData={[
              // ['Доступно', 'available', 'green'],
              ['Available', 'available', 'green'],
              // ['Холд', 'hold', 'yellow'],
              ['Hold', 'hold', 'yellow'],
              // ['Доход за день', 'total', 'blue'],
              ['Income per day', 'total', 'blue'],
            ]}
          />

        </div>
      </div>
      <NewTicketForm
        availablePaymentBlock={<AvailablePaymentBlock withoutBottomText />}
        modalOpen={modalOpen}
        balanceAvailable={balance?.available}
        paymentInfo={paymentInfo}
        classes={classes}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  informationBlock: {},
  availableBlock: {
    color: 'white',

  },
  availableBlockNumber: {
    '& span': {
      lineHeight: '36px',
      fontSize: '30px',
    },

  },
  chart: {},
  blueBackground: {
    backgroundColor: blueColor[100],
  },
  button: {
    width: '100%',
  },

  greenText: {
    color: greenColor[100],
  },
  yellowText: {
    color: orangeColor[100],
  },
  grid: {
    marginTop: '30px',
    display: 'grid',
    gridTemplateColumns: '36.40552995391705% auto',
    gap: '30px',
  },
  leftColumn: {
    '&>div:nth-of-type(1)': {
      marginBottom: '30px',
    },
    '&>div:nth-of-type(2)': {
      marginBottom: '15px',
    },
    '&>div:nth-of-type(3)': {
      marginBottom: '35px',
    },
  },
  messageRoot: {
    height: '120px',
  },

}));

export default BalancePage;
