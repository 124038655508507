import {
  ACTION_TYPE,
  ACTIONS,
  ACTIONS_COUNT,
  ACTIVE,
  ACTIVE_USERS,
  ADD_CHANNEL,
  ADD_COLUMNS,
  ADD_FILTER,
  ADD_GROUPING,
  ADDITIONAL_PARAMETERS,
  ALL_CHANNELS,
  ALL_GOALS,
  AMOUNT,
  ANALYTICS,
  APPROVED_CONVERSIONS,
  AUDIENCE_CHURN,
  AUTHORIZATION,
  AVAILABLE_CHANNELS,
  AVAILABLE_FOR_WITHDRAWAL,
  BALANCE,
  BILL,
  BILLING_METHOD,
  BY_DAY,
  BY_INTERACTION,
  CAMPAIGN_NAME,
  CHANNEL,
  CHANNELS,
  CHANNELS_ADD_BUTTON_TOOLTIP,
  CHANNELS_ANALYTICS_TOOLTIP,
  CHANNELS_DIALOGS_TOOLTIP,
  CHANNELS_EXPANSION_LIST_TOOLTIP,
  CHANNELS_LINKS_TOOLTIP,
  CHURN,
  CHURN_COUNT,
  CITIES,
  CLICK_TO_COPY,
  CLOSED,
  COMMENT,
  CONNECT,
  CONNECTING_TELEGRAM_BOT,
  CONNECTING_VIBER_BOT,
  COPIED,
  COUNTRY,
  CR_CLICKED_LINKS,
  CR_GIVEN_LINKS,
  CREATED,
  CURRENT_MONTH,
  CUSTOM_DATES,
  CZECH_REPUBLIC,
  DATE,
  DAY,
  DESCRIPTION,
  DIALOG_COST,
  DIALOGS,
  DIALOGS_COUNT,
  DIRECT_LINK,
  DOWNLOAD,
  DOWNLOAD_REPORT,
  EARNINGS_PER_CONVERSION,
  EARNINGS_PER_DIALOG,
  ENTER_THE_AMOUNT_TO_WITHDRAW,
  ESTIMATE_DATE,
  EXTRA_FIELD,
  FINANCES,
  GEOGRAPHY,
  GERMANY,
  GOALS_AND_ACTIONS,
  GROUPING_BY_DAYS,
  GROUPING_BY_HOURS,
  GROUPING_BY_MONTHS,
  GROUPING_BY_YEARS,
  GROUPS_VK,
  HOLD,
  HOLD_CONVERSIONS,
  HOUR,
  HTML_CODE,
  IN_PROGRESS,
  INCOME,
  ITALY,
  KAZAKHSTAN,
  LAST_14_DAYS,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_MONTH,
  LEADS,
  LINKS,
  MAIN_PARAMETERS,
  MANAGE,
  MONTH,
  NAME,
  NEW_REQUEST,
  NEW_USERS,
  NEWEST_FIRST,
  NO_DATA,
  OFFER,
  OFFER_CONNECT_TOOLTIP,
  OFFERS,
  OLDEST_FIRST,
  ON_HOLD,
  ORDER_A_PAYMENT,
  PAGES_FACEBOOK,
  PAUSE,
  PAYMENTS_AMOUNT,
  PAYOUT_HISTORY,
  PLATFORM,
  POLAND,
  PREFERRED_CITIES,
  RATING_NOT_YET_AVAILABLE,
  REJECTED_CONVERSIONS,
  REMOVE,
  REPORTS,
  REQUEST,
  RESET,
  RUSSIA,
  SETTINGS,
  SIGN_IN,
  SOURCE,
  SPAIN,
  START,
  STATUS,
  SWITCH_PROFILE,
  TODAY,
  TOKEN,
  TOTAL,
  TOTAL_ON_ACCOUNT,
  TOOLS,
  TRACKING_LINK,
  TRAFFIC_TYPE,
  UKRAINE,
  UNWANTED_CITIES,
  USA,
  USERS,
  WHICH_CHANNEL_TO_CONNECT,
  YEAR,
  YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED,
  YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED, APPEARANCE,
} from '@main/i18n/aliases';

export default {
  [BALANCE]: 'Total on account',
  [REPORTS]: 'Reports',
  [GOALS_AND_ACTIONS]: 'Goals & Actions',
  [DESCRIPTION]: 'Description',
  [AVAILABLE_CHANNELS]: 'Available channels',
  [HOLD]: 'Hold',
  [ON_HOLD]: 'On hold',
  [GEOGRAPHY]: 'Geography',
  [UNWANTED_CITIES]: 'Unwanted cities',
  [PREFERRED_CITIES]: 'Preferred cities',
  [BY_INTERACTION]: 'Report by actions',
  [BY_DAY]: 'Report by days',
  [GROUPING_BY_HOURS]: 'Group by hour',
  [GROUPING_BY_DAYS]: 'Group by day',
  [GROUPING_BY_MONTHS]: 'Group by month',
  [GROUPING_BY_YEARS]: 'Group by year',
  [HOUR]: 'Hour',
  [DAY]: 'Day',
  [MONTH]: 'Month',
  [YEAR]: 'Year',
  [CUSTOM_DATES]: 'Custom dates',
  [TODAY]: 'Today',
  [NEW_USERS]: 'New users',
  [ACTIVE]: 'Active',
  [ACTIVE_USERS]: 'Active users',
  [ACTIONS]: 'Actions',
  [ACTIONS_COUNT]: 'Actions in dialogs count',
  [CHURN]: 'Churn',
  [CHURN_COUNT]: 'Churn count',
  [LAST_7_DAYS]: 'Last 7 days',
  [LAST_14_DAYS]: 'Last 14 days',
  [LAST_30_DAYS]: 'Last 30 days',
  [CURRENT_MONTH]: 'Current month',
  [LAST_MONTH]: 'Last month',
  [ADD_FILTER]: 'Add filter',
  [ADD_GROUPING]: 'Add group',
  [ADD_COLUMNS]: 'Add column',
  [DATE]: 'Date',
  [USERS]: 'Users',
  [APPROVED_CONVERSIONS]: 'Approved conversions',
  [HOLD_CONVERSIONS]: 'Hold conversions',
  [REJECTED_CONVERSIONS]: 'Rejected conversions',
  [ALL_GOALS]: 'All actions',
  [OFFER]: 'Offer',
  [CHANNEL]: 'Channel',
  [PLATFORM]: 'Page',
  [OFFERS]: 'Offers',
  [OLDEST_FIRST]: 'Oldest first',
  [NEWEST_FIRST]: 'Newest first',
  [COUNTRY]: 'Country',
  [CZECH_REPUBLIC]: 'Czech Republic',
  [UKRAINE]: 'Ukraine',
  [SPAIN]: 'Spain',
  [POLAND]: 'Poland',
  [USA]: 'USA',
  [KAZAKHSTAN]: 'Kazakhstan',
  [RUSSIA]: 'Russia',
  [ITALY]: 'Italy',
  [GERMANY]: 'Germany',
  [RESET]: 'Reset',
  [DIALOGS_COUNT]: 'Dialogs count',
  [LEADS]: 'Leads',
  [CR_CLICKED_LINKS]: 'CR Clicked Links',
  [EARNINGS_PER_CONVERSION]: 'Earning per conversion',
  [CR_GIVEN_LINKS]: 'CR Given links',
  [AUDIENCE_CHURN]: 'Audience churn',
  [EARNINGS_PER_DIALOG]: 'Earning per dialog',
  [PAYMENTS_AMOUNT]: 'Payments amount',
  [RATING_NOT_YET_AVAILABLE]: 'Rating is not yet available',
  [DIALOG_COST]: 'Dialog cost',
  [CONNECT]: 'Connect',
  [CHANNELS]: 'Channels',
  [NAME]: 'Name',
  [SETTINGS]: 'Settings',
  [DIALOGS]: 'Dialogs',
  [LINKS]: 'Links',
  [ANALYTICS]: 'Analytics',
  [REMOVE]: 'Remove',
  [FINANCES]: 'Finance',
  [INCOME]: 'Income',
  [TOTAL]: 'Total',
  [CITIES]: 'Cities',
  [ACTION_TYPE]: 'Action type',
  [NO_DATA]: 'No data',
  [ALL_CHANNELS]: 'All channels',
  [ADD_CHANNEL]: 'Add channel',
  [TRACKING_LINK]: 'Tracking link',
  [TRAFFIC_TYPE]: 'Traffic type',
  [SOURCE]: 'Source',
  [CAMPAIGN_NAME]: 'Campaign name',
  [EXTRA_FIELD]: 'Extra field',
  [MAIN_PARAMETERS]: 'Main parameters',
  [ADDITIONAL_PARAMETERS]: 'Additional parameters',
  [DIRECT_LINK]: 'Direct link',
  [HTML_CODE]: 'HTML code',
  [CLICK_TO_COPY]: 'Click to copy',
  [COPIED]: 'Copied',
  [ORDER_A_PAYMENT]: 'Order a payment',
  [TOTAL_ON_ACCOUNT]: 'Total on account',
  [AVAILABLE_FOR_WITHDRAWAL]: 'Available',
  [PAYOUT_HISTORY]: 'Payout history',
  [DOWNLOAD_REPORT]: 'Download report',
  [AMOUNT]: 'Amount',
  [STATUS]: 'Status',
  [ESTIMATE_DATE]: 'Estimate date',
  [BILL]: 'Bill',
  [BILLING_METHOD]: 'Billing method',
  [COMMENT]: 'Comment',
  [CREATED]: 'Created',
  [IN_PROGRESS]: 'In progress',
  [CLOSED]: 'Closed',
  [NEW_REQUEST]: 'New request',
  [ENTER_THE_AMOUNT_TO_WITHDRAW]: 'Enter amount to withdraw',
  [DOWNLOAD]: 'Download',
  [REQUEST]: 'Request',
  [SWITCH_PROFILE]: 'Switch profile',
  [YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED]: 'You need to log into your Facebook account to proceed',
  [YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED]: 'You need to log into your VK account to proceed',
  [GROUPS_VK]: 'VK groups',
  [PAGES_FACEBOOK]: 'Facebook Pages',
  [CONNECTING_TELEGRAM_BOT]: 'Connecting Telegram bot',
  [CONNECTING_VIBER_BOT]: 'Connecting Viber bot',
  [TOKEN]: 'Token',
  [AUTHORIZATION]: 'Authorization',
  [SIGN_IN]: 'Sign in',
  [WHICH_CHANNEL_TO_CONNECT]: 'Which channel to connect?',
  [PAUSE]: 'Pause',
  [START]: 'Start',
  [MANAGE]: 'Manage',
  [TOOLS]: 'Tools',
  [OFFER_CONNECT_TOOLTIP]: 'Choose channel to connect bot',
  [CHANNELS_ADD_BUTTON_TOOLTIP]: 'Choose offer to connect platform',
  [CHANNELS_EXPANSION_LIST_TOOLTIP]: 'Filter connected platforms by channel',
  [CHANNELS_DIALOGS_TOOLTIP]: 'Show dialogs history',
  [CHANNELS_LINKS_TOOLTIP]: 'Tracking settings',
  [CHANNELS_ANALYTICS_TOOLTIP]: 'Show detailed report by platform',
  [APPEARANCE]: 'Appearance',
};
