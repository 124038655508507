import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { ExpansionList, ListElem, Panel } from '@aisales/react-uikit';
import arrowLeftIcon from './assets/arrowLeft.svg';
import LegalPersonForm from './components/LegalPersonForm';
import IndividualForm from './components/IndividualForm';
import SelfEmployed from './components/SelfEmployed';

const types = {
  legal: 'Юридическое лицо',
  selfEmployed: 'Самозанятый',
  individual: 'Физическое лицо',
};

export default function AddPayment() {
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = useState('legal');

  return (
    <div style={{ backgroundColor: '#F6F7FD' }}>

      <div className={classes.root}>
        <div className={classes.title}>
          <Panel
            width="64px"
            height="60px"
            style={{ marginRight: '20px', cursor: 'pointer' }}
            onClick={() => history.push('/app/profile')}
          >
            <img
              src={arrowLeftIcon}
              alt="Arrow left"
              style={{ margin: '4px 0 0 -4px' }}
            />
          </Panel>
          <Panel width="413px" height="60px">
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                fontHeight: '22px',
              }}
            >
              Добавить платежную информацию
            </Typography>
          </Panel>
        </div>

        <div
          className={classNames(classes.white, classes.padding)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '59px',
            paddingTop: '30px',
            paddingBottom: '35px',
          }}
        >
          <span style={{ marginBottom: '6px' }}>
            Выберите форму организации
          </span>
          <ExpansionList
            closeOnClick
            maxHeight="300px"
            textValue={types[type]}
            style={{ width: '284px' }}
          >
            <ListElem onClick={() => setType('legal')}>
              {types.legal}
            </ListElem>
            <ListElem onClick={() => setType('individual')}>
              {types.individual}
            </ListElem>
            <ListElem onClick={() => setType('selfEmployed')}>
              {types.selfEmployed}
            </ListElem>
          </ExpansionList>
        </div>
        {type === 'legal' && <LegalPersonForm />}
        {type === 'individual' && <IndividualForm />}
        {type === 'selfEmployed' && <SelfEmployed />}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 85px)',
    overflow: 'auto',
    paddingBottom: '200px',
    paddingTop: '50px',
  },
  title: {
    display: 'flex',
    marginBottom: '40px',
    padding: '0 7% 0 7%',
  },
  padding: {
    padding: '0 7% 0 calc(7% + 84px)',
  },
  white: {
    background: 'white',
  },
}));
