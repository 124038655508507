import React, { useEffect, useState } from 'react';
import {
  BotIcon, Button, greyColor, Input, LinkIcon, Modal, Typography,
  AlertContents,
} from '@aisales/react-ui-kit';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { toast } from 'react-toastify';

const utmsNum = 5;
const utmPrefix = 'Utm_';
const initialUtmInputs = Object.fromEntries([...Array(utmsNum).keys()].map((key) => [`${utmPrefix}${key + 1}`, '']));
const gap = '20px';

const ChannelsTrackingLinkModal = ({
  classes,
  name = 'Streat role play',
  channel,
  ...otherProps
}) => {
  const [utmInputs, setUtmInputs] = useState(initialUtmInputs);
  const [linkValue, setLinkValue] = useState('');

  useEffect(() => {
    setLinkValue(channel?.url);
  }, [channel]);

  const getUtmsFromLink = (link) => {
    const search = link.split('?')[1];
    let newUtmInputs = { ...utmInputs };
    for (const entry of Object.entries(initialUtmInputs)) {
      const utm = JSON.parse(new URLSearchParams(search).get(entry[0]));
      if (utm) {
        newUtmInputs = { ...newUtmInputs, [entry[0]]: utm };
      }
    }
    return newUtmInputs;
  };

  const getLinkFromUtms = (utms) => {
    let search = '?';
    const utmEntries = Object.entries(utms);
    for (const [index, utm] of utmEntries.entries()) {
      if (utm[1]) {
        search = `${search + utm[0]}=${utm[1]}${index !== utmEntries.length - 1 ? '&' : ''}`;
      }
    }
    return channel?.url + search;
  };
  return (
    <Modal
      className={classes.channelsTrackingLinkModal}
      title="Трекинговая ссылка"
      {...otherProps}
    >
      <div className={classes.channelsTrackingLinkModal__content}>
        <div className={classes.channelsTrackingLinkModal__topPart}>
          <div className={classes.channelsTrackingLinkModal__botIconWrapper}>
            <BotIcon />
            <Typography variant="subtitle" className={classes.channelsTrackingLinkModal__botIconWrapperTitle}>{name}</Typography>
          </div>
          <Input
            onChange={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            value={linkValue}
            className={classes.channelsTrackingLinkModal__topPartInput}
            label="Трекинговая ссылка"
            endAdornment={(
              <LinkIcon
                className={classes.channelsTrackingLinkModal__topPartInputLinkIcon}
              />
)}
          />
          <Button
            className={classes.channelsTrackingLinkModal__topPartButton}
            onClick={() => {
              navigator.clipboard.writeText(linkValue);
              toast(<AlertContents severity="success" title="Ссылка скопирована" />);
            }}
          >
            Копировать ссылку
          </Button>
        </div>
        <div className={classes.channelsTrackingLinkModal__bottomPart}>
          <div className={classes.channelsTrackingLinkModal__bottomPartTitle}>
            <Typography variant="subtitle">Utm метки</Typography>
          </div>
          <div className={classes.channelsTrackingLinkModal__bottomPartHint}>
            Вы можете указать «Utm метки» и забрать сгенерированную
            ссылку здесь или  указать метки напрямую в адресной строке.
          </div>
        </div>
        <div className={clsx(classes.channelsTrackingLinkModal__bottomPartInputs,
          classes.emulatedFlexGap)}
        >
          {
            Object.entries(utmInputs).map((utm) => (
              <div key={utm[0]}>
                <Input
                  className={classes.channelsTrackingLinkModal__bottomPartInputsInput}
                  label={utm[0]}
                  value={utm[1]}
                  onChange={(event) => {
                    const tmp = { ...utmInputs, [utm[0]]: event.target.value };
                    setUtmInputs(tmp);
                    setLinkValue(`${linkValue.split('?')[0]}?${Object.keys(tmp).map((key) => `${key.toLowerCase()}=${encodeURIComponent(tmp[key])}`).join('&')}`);
                  }}
                />
              </div>
            ))
        }
        </div>
      </div>

    </Modal>
  );
};

const styles = () => ({
  channelsTrackingLinkModal: {
    width: '800px',
  },
  channelsTrackingLinkModal__content: {
    paddingRight: '70px',
    paddingLeft: '70px',

  },
  channelsTrackingLinkModal__botIconWrapper: {
    backgroundColor: greyColor.background,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px',
    width: '100%',
  },
  channelsTrackingLinkModal__botIconWrapperTitle: {
    marginLeft: '22px',

  },
  channelsTrackingLinkModal__topPart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid #CACACA',
  },
  channelsTrackingLinkModal__topPartInput: {
    marginTop: '20px',
    marginBottom: '30px',
    width: '100%',

  },
  channelsTrackingLinkModal__topPartInputLinkIcon: {
    height: '16px',
    width: '16px',
  },
  channelsTrackingLinkModal__topPartButton: {
    marginBottom: '40px',
    width: '290px',
  },

  channelsTrackingLinkModal__bottomPart: {

  },

  channelsTrackingLinkModal__bottomPartTitle: {
    marginTop: '40px',
    marginBottom: '10px',
  },
  channelsTrackingLinkModal__bottomPartHint: {
    marginBottom: '20px',
  },

  channelsTrackingLinkModal__bottomPartInputs: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  emulatedFlexGap: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    margin: `calc(-1 * ${gap}) 0 0 calc(-1 * ${gap})`,
    // width: `calc(100% + ${gap})`,
    '& > *': {
      margin: `${gap} 0 0 ${gap}`,
    },
  },
  list: {
    padding: '30px 28px 30px 30px',
  },
  channelsTrackingLinkModal__bottomPartInputsInput: {
    width: '290px',
    // marginRight: '20px',
    // marginBottom: '20px',
  },
});

export default withStyles(styles)(ChannelsTrackingLinkModal);
