import React from 'react';
import { ReactComponent as MyOffers } from '../assets/emptyPagesImages/myOffers.svg';
import { ReactComponent as HoldWithdrawal } from '../assets/emptyPagesImages/holdWithdrawal.svg';
import { ReactComponent as PaymentHistory } from '../assets/emptyPagesImages/paymentHistory.svg';
import { ReactComponent as FavoriteOffers } from '../assets/emptyPagesImages/favoriteOffers.svg';
import { ReactComponent as Dashboard } from '../assets/emptyPagesImages/dashboard.svg';

export const emptyPages = (name) => ({
  offers: {
    myOffers: {
      title: 'Упс , вы не подключили ни одного оффера',
      text: 'Для подключения офферов перейдите на страницу «Все офферы», там вы можете выбрать интересующее вас предложени. После подключения оффера,\n'
          + 'он появится на данной странице, где вы сможете управлять им.',
      image: <MyOffers />,
      buttonText: 'Перейти на страницу офферы',
      link: '/app/offers/all-offers',
    },
    favoriteOffers: {
      title: 'Пусто',
      text: 'Вы можете добавить офферы в избранное из списка, нажав\n'
          + 'на звёздочку в правом верхнем углу карточки оффера.',
      image: <FavoriteOffers />,
      buttonText: '',
    },
  },
  finance: {
    holdWithdrawal: {
      title: 'Холд отсутствует',
      text: `${name}, когда вы начнёте лить трафик, Вы сможете отслеживать когда и какие суммы будут в холде,выходить из него и доступны Вам для вывода средст.`,
      image: <HoldWithdrawal />,
      buttonText: 'Перейти на страницу офферы',
      link: '/app/offers/all-offers',
    },
    paymentHistory: {
      title: `${name}, вы не совершали ни одной выплаты`,
      text: 'После создании выплаты она попадает на данную страницу, где вы можете отслеживать её статус',
      image: <PaymentHistory />,
      buttonText: 'Заказать выплату',
      link: '/app/finance/balance',
    },
  },
  dashboard: {
    title: 'Нет данных',
    text: 'Мы не смогли найти данные для отчёта по выбранным фильтрам.',
    image: <Dashboard />,
    buttonText: '',
  },
  channels: {
    title: 'Вы не подключили ни одного канала',
    text: 'Для подключения каналов перейдите на страницу «Все офферы», там вы можете выбрать интересующее вас предложение. После подключения канала к выбранному офферу,\nон появится на данной странице, где вы сможете управлять им.',
    image: <MyOffers />,
    buttonText: 'Перейти на страницу офферы',
    link: '/app/offers/all-offers',
  },
});
