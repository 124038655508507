import DashboardEditableTabText from '@pages/dashboard/components/EditableTabText';
import { dashboardPaths } from '@pages/dashboard/constants/dashboardPaths';
import notEditableReportsIds from '@pages/dashboard/constants/notEditableReportsIds';
import React from 'react';
import { lastDashboardTabs } from '@pages/dashboard/constants/lastDashboardTabs';
import { ReactComponent as SevenDaysIcon } from '@pages/dashboard/assets/calendars/7days.svg';
import { ReactComponent as FourteenDaysIcon } from '@pages/dashboard/assets/calendars/14days.svg';
import { ReactComponent as CurrentMonthIcon } from '@pages/dashboard/assets/calendars/currentMonth.svg';
import { ReactComponent as LastMonthIcon } from '@pages/dashboard/assets/calendars/lastMonth.svg';
import { ReactComponent as CustomIcon } from '@pages/dashboard/assets/calendars/custom.svg';
import { Calendar } from '@aisales/react-ui-kit';
import { Menu } from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import defaultNamePrefix from '@pages/dashboard/constants/defaultNamePrefix';
import {
  getCurrentMonthPeriod,
  getLastMonthPeriod,
  getUnixPeriodWithinNLastDays,
} from '@pages/dashboard/utils/dateGetters';
import { mergeSearch } from '@pages/dashboard';

export const getDashboardTabsForNavigation = (reports) => {
  const tabs = [];
  for (const report of reports) {
    tabs.push({
      text: report.name,
      link: `${dashboardPaths.indexPath}/${report.id}`,
      regex: `${dashboardPaths.indexPath}/${report.id}`,
    });
  }
  return tabs;
};

const pushToHistory = (history, prefix, date) => {
  history.push(
    {
      pathname: prefix,
      search: mergeSearch(window.location.href, { date }).replace('&customDate=true', ''),
    },
  );
};

export const getDashboardTabs = (newReportMode,
  onClickOtherTabWhileNewReportMode,
  reports,
  activeReportId,
  onClickCorrectIcon,
  onChangeCustomDate,
  onCloseCalendarMenu,
  onClickCustomDateTab,
  calendarIsOpened,
  newTabName,
  history) => {
  const weekDate = getUnixPeriodWithinNLastDays(7);
  const days14Date = getUnixPeriodWithinNLastDays(14);
  const currentMonthDate = getCurrentMonthPeriod();
  const lastMonthDate = getLastMonthPeriod();

  const getDashboardSubTabs = (prefix) => [{
    // text: 'За 7 дней',
    text: 'For 7 days',
    icon: <SevenDaysIcon />,
    onClick: () => {
      const date = weekDate;
      pushToHistory(history, prefix, date);
    },
    regex: `${escapeRegExp(prefix)}.*${escapeRegExp(dashboardPaths.sevenDaysPostfix)}`,
  },
  {
    // text: 'За 14 дней',
    text: 'For 14 days',
    onClick: () => {
      const date = days14Date;
      pushToHistory(history, prefix, date);
    },
    regex: `${escapeRegExp(prefix)}.*${escapeRegExp(dashboardPaths.fourteenDaysPostfix)}`,
    icon: <FourteenDaysIcon />,
  },
  {
    // text: 'За текущий месяц',
    text: 'For the current month',
    onClick: () => {
      const date = currentMonthDate;
      pushToHistory(history, prefix, date);
    },
    icon: <CurrentMonthIcon />,
    regex: `${escapeRegExp(prefix)}.*${escapeRegExp(dashboardPaths.currentMonthPostfix)}`,
  },
  {
    // text: 'За прошлый месяц',
    text: 'Last month',
    onClick: () => {
      const date = lastMonthDate;
      pushToHistory(history, prefix, date);
    },
    icon: <LastMonthIcon />,
    regex: `${escapeRegExp(prefix)}.*${escapeRegExp(dashboardPaths.lastMonthPostfix)}`,
  },
  {
    text: <Calendar
      onChange={onChangeCustomDate}
      menuProps={{
        open: calendarIsOpened,
        onClick: ((event) => event.stopPropagation()),
        onClose: () => { onCloseCalendarMenu(); },
      }}
    >
      {/* <div>Выбрать даты</div> */}
      <div>Select dates</div>
          </Calendar>,
    icon: <CustomIcon />,
    regex: new RegExp(`${escapeRegExp(prefix)}.*customDate*`),
    onClick: () => { onClickCustomDateTab(); },
  }];
  const getTabsFromReports = () => {
    const renderText = (report) => {
      if (notEditableReportsIds.includes(report.id) || activeReportId !== report.id) {
        return report.name;
      }
      return (
        <DashboardEditableTabText
          onClickCorrectIcon={onClickCorrectIcon}
          text={report.name}
        />
      );
    };
    const tabs = [];
    for (const report of reports) {
      tabs.push({
        text: renderText(report),
        link: `${dashboardPaths.indexPath}/${report.id}`,
        onClick: () => {
          history.push(
            {
              pathname: `${dashboardPaths.indexPath}/${report.id}`,
              search: mergeSearch(window.location.href, { }),
            },
          );
          if (newReportMode) {
            onClickOtherTabWhileNewReportMode(report.id);
          }
        },
        regex: escapeRegExp(`${dashboardPaths.indexPath}/${report.id}*`),
        subtabs: getDashboardSubTabs(`${dashboardPaths.indexPath}/${report.id}`),
      });
    }
    tabs.push(...lastDashboardTabs);
    return tabs;
  };

  const getCustomReportDashboardTabs = () => {
    const getNewDashboardTab = () => ({
      text: <DashboardEditableTabText
        text={newTabName}
        onClickCorrectIcon={onClickCorrectIcon}
      />,
      link: dashboardPaths.customReportPath,
      regex: dashboardPaths.customReportPath,
      subtabs: getDashboardSubTabs(dashboardPaths.customReportPath),
    });
    return [
      ...getTabsFromReports().slice(0, reports.length),
      getNewDashboardTab(),
      lastDashboardTabs[lastDashboardTabs.length - 1],
    ];
  };

  return (newReportMode
    ? getCustomReportDashboardTabs()
    : getTabsFromReports());
};
