export default function vkRedirectGroup() {
  const tmp = window.location.hash;
  if (tmp.indexOf('access_token') !== -1) {
    const str = tmp.split('&');
    const res = str[1].split('=');
    localStorage.setItem('group_access_token', res[1]);
    window.close();
  } else {
    window.close();
  }
}
